import axios from "axios";
import API_URL from "../helpers/globals.js";
import { notify } from "../toasts/toasts.js";
import ReactPixel from 'react-facebook-pixel';

// export const validateEmail = async (email) => {
//   try {
//     const response = await axios.post(
//       `${API_URL}User/sendEmailVerification?emailAddress=${email}`
//     );
//     if (response) {
//       return response.data;
//     }
//   } catch (err) {
//     console.log(err);
//   }
// };

export const resetPassword = async (email) => {
  try {
    const response = await axios.post(
      `${API_URL}User/forgotPassword?emailAddress=${email}`
    );
    if (response) {
      return response.data;
    }
  } catch (err) {
    console.log(err);
  }
};

export const sendApplicationStarted = async (jobIds) => {
  try {
    const response = await axios.post(
      `${API_URL}Job/applicationstarted`,
      JSON.stringify({ jobIdList: jobIds }),
      {
        headers: {
          "Content-Type": "application/json"
        }
      }
    );
    if (response) {
      return response.data;
    }
  } catch (err) {
    console.log(err);
  }
};

export const sendProfileCompleted = async () => {
  try {
    const response = await axios.post(`${API_URL}Candidate/complete`);
    console.log(response)
    if (response) {
      if(response.data == false) {
        ReactPixel.trackCustom('AmedCompleteProfile');
      }
      return response.data;
    }
  } catch (err) {
    console.log(err);
  }
};
