import React from "react";
import {Grid, Card, Hidden} from "@material-ui/core";
import Item from "@material-ui/core/Grid";
import careTeam from "../../../../images/careteam.png";

import "./CareTeam.scss";

const CareTeam = () => {
    return (
        <div className="dashboard-box care-team">
            <Card className="careteam-banner">
                <Grid container>
                    <Item item className="careteam-info" xs={12} md={10}>
                        <h3 className="text-lg leading-6 font-medium text-gray-900 ">Your care team is just a chat away!</h3>
                        <p className="text-md">As a travel nurse, you'll have a whole team behind you every step of your journey</p>

                        <div>
                            <a href="mailto:care@advantismed.com" className="color-button next-step email-button font-medium text-sm">Email</a>

                            <Hidden lgUp>
                                <a href="tel:9724356208" className="color-button next-step font-medium text-sm">Call/Text</a>
                            </Hidden>
                        </div>
                    </Item>

                    <Hidden smDown>
                        <Item item className="careteam-img" md={2}>
                            <img src={careTeam} width="100%" height="100%" />
                        </Item>
                    </Hidden>
                </Grid>
            </Card>
        </div>
    );
}

export default CareTeam;
