import axios from "axios";
import API_URL from "../helpers/globals.js";
import { notify } from "../toasts/toasts.js";
import { history } from "../helpers/history";

export const getApplications = async () => {
  try {
    const response = await axios.get(`${API_URL}Job/applications`);
    if (response) {
      return response.data;
    }
  } catch (err) {
    console.log(err);
  }
};

export const getJobDetails = async (id) => {
  try {
    const response = await axios.get(`${API_URL}Job/details?id=${id}`);
    if (response) {
      return response.data;
    }
  } catch (err) {
    console.log(err);
  }
};

export const jobApply = async (jobIds) => {
  const jobData = jobIds.map((id) => ({ jobId: id }));
  const data = { jobApplicationList: jobData };


  try {
    const response = await axios.post(
      `${API_URL}Job/applications`,
      JSON.stringify(data),
      {
        headers: {
          "Content-Type": "application/json"
        }
      }
    );
    if (response) {
      notify("You have successfully applied", "success");
      console.log(jobData[0].jobId)
      history.push("/job/"+jobData[0].jobId);
      return response.data;
    }
  } catch (err) {
    notify("Unable to apply. Try again later.", "error");
    console.log(err);
  }
};

export const getFeaturedJobs = async () => {
  try {
    const response = await axios.get(`${API_URL}Job/featured`);
    return response?.data;
  } catch (err) {
    console.log(err);
  }
};

export const getMatchedJobs = async () => {
  try {
    const response = await axios.get(`${API_URL}Job/matched`);
    return response?.data;
  } catch (err) {
    console.log(err);
  }
};


export const getSimilarJobs = async (id) => {
  try {
    const response = await axios.get(`${API_URL}Job/similar?jobId=${id}`);
    if (response) {
      return response?.data;
    }
  } catch (err) {
    console.log(err);
  }
};

export const getPopularJobs = async () => {
  const body = {
    page: 2,
    count: 8
  };
  try {
    const response = await axios.post(
      `${API_URL}Job/search`,
      JSON.stringify(body),
      {
        headers: {
          "Content-Type": "application/json"
        }
      }
    );
    if (response) {
      return response.data;
    }
  } catch (err) {
    console.log(err);
  }
};

export const addFavoriteJob = async (id) => {
  try {
    const response = await axios.post(`${API_URL}Job/favorite?jobId=${id}`);
    if (response) {
      return response.data;
    }
  } catch (err) {
    console.log(err);
  }
};

export const acceptOffer = async (id) => {
  try {
    const response = await axios.put(`${API_URL}Job/acceptOffer?applicationId=${id}`);
    return response;

  } catch (err) {
    console.log(err);
  }
};

export const rejectOffer = async (id) => {
  try {
    const response = await axios.put(`${API_URL}Job/rejectOffer?applicationId=${id}`);
    return response;
  } catch (err) {
    console.log(err);
  }
};

export const deleteFavoriteJob = async (id) => {
  try {
    const response = await axios.delete(`${API_URL}Job/favorite?jobId=${id}`);
    if (response) {
      return response.data;
    }
  } catch (err) {
    console.log(err);
  }
};

export const getFavoriteJobs = async () => {
  try {
    const response = await axios.get(`${API_URL}Job/favorites`);
    if (response) {
      return response.data;
    }
  } catch (err) {
    console.log(err);
  }
};

export const searchJobs = async (filters, page) => {
  const { sortBy, salary, locations, specialties, shift, shifts, range, isfeatured } =
    filters || {};

    const {startDateBegin, startDateEnd} = range || {};

  const formattedLocations = locations?.map((location) => {
    const array = location.split(", ");
    if (array.length === 2) {
      return {
        city: array[0],
        state: array[1]
      };
    } else {
      return {
        state: location
      };
    }
  });

  const body = {
    page: page || 1,
    count: 10,
    sortBy: sortBy,
    weeklySalary: salary,
    specialties,
    locations: formattedLocations,
    startDateBegin,
    startDateEnd,
    shift: shift,
    shifts: shifts,
    isFeatured: isfeatured
  };
  try {
    const response = await axios.post(
      `${API_URL}Job/search`,
      JSON.stringify(body),
      {
        headers: {
          "Content-Type": "application/json"
        }
      }
    );
    if (response) {
      return response.data;
    }
  } catch (err) {
    notify("Something went wrong. Please try again later.", "error");
    console.log(err);
  }
};

export const getHighestPayJobs = async () => {
  try {
    const response = await axios.get(`${API_URL}Job/highestpay`);
    return response?.data;
  } catch (err) {
    console.log(err);
  }
};
