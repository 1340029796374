import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  TextField,
  FormControlLabel,
  Select,
  InputLabel,
  MenuItem,
  FormControl,
  FormHelperText,
  Checkbox,
  CircularProgress,
  Box
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from "@material-ui/pickers";
import { Delete } from "@material-ui/icons";

import ConfirmationModal from "../../../common/ConfirmationModal/ConfirmationModal";
import { AddIcon } from "../../../common/Icons";
import { getFacilities, getSpecialties } from "../../../../services/datalist";
import { deleteData } from "../../../../actions/candidateEntry";
import { useDebouncedSearch } from "../../../../hooks/search";

import "./WorkExperience.scss";

const useFacilitySearch = () =>
  useDebouncedSearch((text) => getFacilities(text));

const WorkExperience = ({
  change,
  values = [],
  error,
  clearError,
  addForm,
  handleDelete
}) => {
  const { inputText, setInputText, searchResults } = useFacilitySearch();
  const { loading, result: facilities = [] } = searchResults;
  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const [workId, setWorkId] = useState();
  const [isDeleting, setDeleting] = useState(false);
  const [open, setOpen] = useState(false);
  const [key, setKey] = useState(undefined);
  const [specialties, setSpecialties] = useState([]);

  const dispatch = useDispatch();

  useEffect(() => {
    let mounted = true;

    getSpecialties()
      .then((data) => {
        if (mounted && data) {
          setSpecialties(data);
        }
      })
      .catch((err) => console.log(err));
    return () => (mounted = false);
  }, []);

  useEffect(() => {
    values.length === 0 && add();
  }, [values])

  const openSelect = (id) => {
    setKey(id);
    setOpen(true);
  };

  const closeSelect = (id) => {
    setKey(id);
    setOpen(false);
  };

  const handleChange = (field, value, id) => {
    if (values.length > 0) {
      const updatedValues = values.map((item) => {
        if (item.id === id) {
          item[field] = value;
          if (field === "facility") {
            item[field] = value?.name;
            const facilityData = facilities.filter(
              (facility) => facility.name == value?.name
            );
            if (facilityData.length > 0)
              item["location"] = facilityData[0].location;
            item["unitBed"] =
              facilityData[0] && facilityData[0].beds.toString();
          }
        }
        return item;
      });
      change(updatedValues);
    }
  };

  const add = () => {
    const newValue = addForm();
    const updatedValues = [...values];
    updatedValues.unshift(newValue);

    change(updatedValues);
  };

  const deleteCard = (indx) => {
    const newValues = [...values];
    const filteredValues = newValues.filter((value, index) => index !== indx);
    change(filteredValues);
  };

  const deleteWork = (id) => {
    setConfirmationOpen(true);
    setWorkId(id);
  };

  const confirmDeletion = async () => {
    setDeleting(true);
    try {
      await handleDelete(workId);
    } catch (err) {
      console.log(err);
    }
    setConfirmationOpen(false);
    setDeleting(false);
  };

  const getSpecialtyLabelWithCategory = (specialty) => {
    return `${specialty?.label} (${specialty?.category})`;
  }

  return (
    <>
      <div className="work-form" data-testid="work">
        <div className="header ">
          <h6 className="text-lg leading-6 font-medium text-gray-900">Work Experience</h6>{" "}
          <div className="add" onClick={add} className="icon-button">
            <AddIcon iconClass="blue-icon" />
          </div>
        </div>
        {values &&
          values.map((value, index) => {
            const errors = error && error[`${index}`];
            return (
              <Box key={index} className="edit-card work-card">
                {value.id && (
                  <div
                    onClick={() => deleteWork(value.id)}
                    data-testid="delete"
                  >
                    <Delete className="delete-icon" />
                  </div>
                )}
                {!value.id && values.length > 1 && (
                  <div
                    onClick={() => deleteCard(index)}
                    data-testid="delete"
                    className="icon-button"
                  >
                    <Delete className="delete-icon" />
                  </div>
                )}
                <Box mt={2}>
                  <Autocomplete
                    id={`facility${value.id}`}
                    fullWidth
                    open={key === value.id ? open : false}
                    onOpen={() => {
                      openSelect(value.id);
                    }}
                    onClose={() => {
                      closeSelect(value.id);
                    }}
                    value={value.facility ? value.facility : ""}
                    getOptionSelected={(option, value) => {
                      if (value === "") {
                        return true;
                      } else if (option.name === value.name) {
                        return true;
                      }
                    }}
                    filterOptions={(options, object) => options}
                    options={facilities}
                    loading={loading}
                    getOptionLabel={(option) =>
                      option.name ? option.name : option
                    }
                    onChange={(e, val) =>
                      handleChange("facility", val, value.id)
                    }
                    blurOnSelect
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Facility"
                        variant="outlined"
                        onChange={(e) => setInputText(e.target.value)}
                        InputProps={{
                          ...params.InputProps,
                          "data-testid": "facility",
                          endAdornment: (
                            <React.Fragment>
                              {loading ? (
                                <CircularProgress color="inherit" size={20} />
                              ) : null}
                              {params.InputProps.endAdornment}
                            </React.Fragment>
                          )
                        }}
                      />
                    )}
                  />
                </Box>
                <Box mt={2}>
                  <TextField
                    name="location"
                    label="Location"
                    variant="outlined"
                    onChange={(e) =>
                      handleChange("location", e.target.value, value.id)
                    }
                    inputProps={{
                      "data-testid": "location"
                    }}
                    value={value.location ? value.location : ""}
                    mb={2}
                    fullWidth
                    disabled
                  />
                </Box>
                <Box mt={2}>
                  <TextField
                    name="unit-bed"
                    variant="outlined"
                    label="Unit Bed Count (optional)"
                    inputProps={{
                      "data-testid": "unitBed"
                    }}
                    InputLabelProps={{
                      shrink: true
                    }}
                    value={value.unitBed ? value.unitBed : ""}
                    fullWidth
                    disabled
                    onChange={(e) =>
                      handleChange("unitBed", e.target.value, value.id)
                    }
                  />
                </Box>
                <Box mt={2} display="flex" flexDirection="column" width={3 / 4}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      variant="inline"
                      format="MM/dd/yyyy"
                      placeholder="MM/DD/YYYY"
                      id={`startDate${value.id}`}
                      autoOk={true}
                      label="Start Date*"
                      value={value.startDate ? value.startDate : null}
                      onChange={(e, val) =>
                        handleChange("startDate", val, value.id)
                      }
                      onFocus={() => clearError(index, "startDate")}
                      error={errors?.startDate ? true : false}
                      helperText={errors?.startDate || "required"}
                      KeyboardButtonProps={{
                        "aria-label": "change date"
                      }}
                    />
                  </MuiPickersUtilsProvider>
                  {!value.isCurrent && (
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <KeyboardDatePicker
                        variant="inline"
                        format="MM/dd/yyyy"
                        placeholder="MM/DD/YYYY"
                        margin="normal"
                        autoOk={true}
                        id={`endDate${value.id}`}
                        label="End Date*"
                        value={value.endDate ? value.endDate : null}
                        error={errors?.endDate ? true : false}
                        helperText={errors?.endDate || "required"}
                        onFocus={() => clearError(index, "endDate")}
                        onChange={(e, val) =>
                          handleChange("endDate", val, value.id)
                        }
                        KeyboardButtonProps={{
                          "aria-label": "change date"
                        }}
                      />
                    </MuiPickersUtilsProvider>
                  )}
                  <FormControlLabel
                    control={
                      <Checkbox
                        color="primary"
                        checked={value.isCurrent ? value.isCurrent : false}
                        onChange={(e) => {
                          clearError(index, "endDate");
                          handleChange("isCurrent", e.target.checked, value.id);
                        }}
                        name="isCurrent"
                      />
                    }
                    label="Current Position"
                  />
                </Box>

                <TextField
                  name="position"
                  label="Position"
                  variant="outlined"
                  onChange={(e) =>
                    handleChange("position", e.target.value, value.id)
                  }
                  inputProps={{
                    "data-testid": "position",
                    maxLength: 100
                  }}
                  value={value.position}
                  mb={2}
                  fullWidth
                />
                <FormControl fullWidth variant="outlined">
                  <InputLabel id="specialty-label">Specialty</InputLabel>
                  <Select
                    label="Specialty"
                    id="specialty-select"
                    value={value.specialty ? value.specialty : ""}
                    data-testid="specialty"
                    onChange={(e) =>
                      handleChange("specialty", e.target.value, value.id)
                    }
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    {specialties?.map((specialty) => (
                      <MenuItem key={specialty.id} value={getSpecialtyLabelWithCategory(specialty)}>
                        {getSpecialtyLabelWithCategory(specialty)}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <FormControl fullWidth variant="outlined">
                  <InputLabel id="unit-label">Unit</InputLabel>
                  <Select
                    label="Unit"
                    id="unit-select"
                    value={value.unit ? value.unit : ""}
                    data-testid="unit"
                    onChange={(e, val) =>
                      handleChange("unit", e.target.value, value.id)
                    }
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    {specialties?.map((specialty) => (
                      <MenuItem key={specialty.id} value={getSpecialtyLabelWithCategory(specialty)}>
                        {getSpecialtyLabelWithCategory(specialty)}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
            );
          })}
      </div>
      {confirmationOpen && (
        <ConfirmationModal
          open={confirmationOpen}
          confirm={() => confirmDeletion()}
          handleClose={() => setConfirmationOpen(false)}
          loading={isDeleting}
          message="Are you sure you want to delete this work experience?"
          subMessage="All of your changes will be lost"
          cancelButtonText="Cancel"
        />
      )}
    </>
  );
};

export default WorkExperience;
