import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import DateFnsUtils from "@date-io/date-fns";

import JobCardCondensed from "../../../common/JobCardCondensed/JobCardCondensed";
import LinkButton from "../../../common/Buttons/LinkButton";
import {
  setFavoriteJobs,
  setSimilarJobs
} from "../../../../actions/jobs";

import "./SimilarJobs.scss";
import { ArrowForward, ArrowBack } from "@material-ui/icons";
import Skeleton from "@material-ui/lab/Skeleton";
import Hidden from "@material-ui/core/Hidden";

import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
    partialVisibilityGutter: 40
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2
  }
};

const CustomRightArrow = ({ onClick }) => {
  return <button className="react-multiple-carousel__arrow react-multiple-carousel__arrow--right" aria-label="go to next slide" onClick={() => onClick()}><ArrowForward style={{ color: "#00283C"}} /></button>;
};

const CustomLeftArrow = ({ onClick }) => {
  return <button className="react-multiple-carousel__arrow react-multiple-carousel__arrow--left" aria-label="go to previous slide" onClick={() => onClick()}><ArrowBack style={{ color: "#00283C"}}/></button>;
};

const dateFns = new DateFnsUtils();

const SimilarJobs = (props) => {
  const [isLoading, setLoading] = useState(false);
  const { similarJobs, favoriteJobs } = useSelector((state) => state || {});

  const dispatch = useDispatch();

  const cloudFrontBaseURL = "https://ik.imagekit.io/yksbahzmx/connect/";
  const placeholderImage = cloudFrontBaseURL + "images/job-placeholder.jpg";

  useEffect(() => {
    let mounted = true;

    const loadData = async () => {

      setLoading(true);
      await dispatch(setSimilarJobs(props.jobId));
      setLoading(false);
      if (!favoriteJobs) {
        await dispatch(setFavoriteJobs());
      }
    };
    if (mounted) {
      loadData();
    }

    return () => {
      mounted = false;
    };
  }, []);

  return (
    <>
      <div>
        <h5 className="w-full text-lg leading-6 font-medium text-gray-900 flex justify-between">
          Similar Jobs
        </h5>
      </div>
      <div className="job-container">
        {!isLoading ? (
          similarJobs && similarJobs?.total > 0 ? (
            <>
              <Hidden smDown>
                <Carousel responsive={responsive} customRightArrow={<CustomRightArrow />} centerMode={true} customLeftArrow={<CustomLeftArrow />} containerClass="matched-jobs">
                  {similarJobs.jobList.map((job) => {
                    return (
                      <Link key={job.jobId} to={`/job/${job.jobId}`}>
                        <div className="job-card condensed">
                          <JobCardCondensed
                            job={job}
                            hidePills={true}
                            hasImage={true}
                          />
                        </div>
                      </Link>
                    )
                    })}
                </Carousel>
              </Hidden>

              <Hidden mdUp>
                {similarJobs.jobList.map((job) => {
                    return (
                      <Link key={job.jobId} to={`/job/${job.jobId}`}>
                        <div className="job-card condensed">
                          <JobCardCondensed
                            job={job}
                            hidePills={true}
                            hasImage={true}
                          />
                        </div>
                      </Link>
                    )
                    })}
              </Hidden>
            </>
          ) : (
            <p className="center-align">No similar jobs</p>
          )
        ) : (
          <div className="matched-jobs-skeleton">
            <Skeleton
              variant="rect"
              className="matched-card"
              height="12rem"
            ></Skeleton>
            <Skeleton
              variant="rect"
              className="matched-card"
              height="12rem"
            ></Skeleton>
            <Skeleton
              variant="rect"
              className="matched-card"
              height="12rem"
            ></Skeleton>
            <Skeleton
              variant="rect"
              className="matched-card"
              height="12rem"
            ></Skeleton>
          </div>
        )}
      </div>
    </>
  );
};

export default SimilarJobs;
