import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import Logo from "../common/Logo/Logo";
import { isAdmin } from "../Admin/services/admin";
import ConfirmLogout from "./ConfirmLogout";
import Avatar from "@material-ui/core/Avatar";
import {
  HomeIcon,
  ProfileIcon,
  SupportIcon,
  SearchBarIcon,
  HeartMenuIcon,
  ConnectIcon,
  MyTeamIcon
} from "../common/Icons";
import { ExitToApp } from "@material-ui/icons";
import ContactModal from "../ContactModal/ContactModal";
import Hidden from "@material-ui/core/Hidden";
import NavLayoutMobile from "../NavLayoutMobile/NavLayoutMobile";

import "./NavLayout.scss";

import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import connectLogo from "../../images/connect-logo-2.png";
import MobileDownload from '../views/MobileDownload/MobileDownload';

const NavLayout = ({
  headerText,
  showProgress,
  children,
  showFilter,
  handleOpen,
  activeFilter
}) => {
  const location = useLocation();
  const [modalOpen, setModal] = useState(false);
  const [openConfirm, setOpenConfirm] = useState(false);
  const { jobFilters } = useSelector((state) => state || {});
  const { filterCount } = jobFilters || {};
  const { personalInformation } = useSelector((state) => state.candidateData);
  const { user, isLoggedIn } = useSelector((state) => state.auth);
  const openModal = () => {
    setModal(true);
  };

  const closeModal = () => {
    setModal(false);
  };

  const getBackground = () => {
    let style = null;

    if (location.pathname === "/profile") {
      style = '#fafafb'
    }

    return style;
  }

  const list = () => (
    <div
      role="presentation"
      className="main-list"
    >
      <List>
          <ListItem
            button key="Dashboard"
            selected={location.pathname === "/home"} style={{padding: 0}}>
              <Link className="desktop-link nav-link" to="/home">
                <div
                    className={`icon-box ${
                    location.pathname === "/home" ? "blue-underline" : ""
                    }`}
                >
                    <ListItemIcon>
                        <HomeIcon iconClass={"basic-icon"} active={location.pathname === "/home"}/>
                    </ListItemIcon>
                    <ListItemText primary="Dashboard" />
                </div>
              </Link>
          </ListItem>

          <ListItem
            button key="Job Board"
            selected={location.pathname === "/search"} style={{padding: 0}}>
              <Link className="desktop-link nav-link" to="/search">
                <div
                    className={`icon-box ${
                    location.pathname === "/search" ? "blue-underline" : ""
                    }`}
                >
                    <ListItemIcon>
                        <SearchBarIcon iconClass={"basic-icon"} active={location.pathname === "/search"}/>
                    </ListItemIcon>
                    <ListItemText primary="Job Board" />
                </div>
              </Link>
          </ListItem>

          <ListItem
            button key="My Jobs"
            selected={location.pathname === "/myjobs"} style={{padding: 0}}>
              <Link className="desktop-link nav-link" to="/myjobs">
                <div
                    className={`icon-box ${
                    location.pathname === "/myjobs" ? "blue-underline" : ""
                    }`}
                >
                    <ListItemIcon>
                      <HeartMenuIcon iconClass="personal-icon" />
                    </ListItemIcon>
                    <ListItemText primary="My Jobs" />
                </div>
              </Link>
          </ListItem>

          <ListItem
            button key="My Profile"
            selected={location.pathname === "/profile"} style={{padding: 0}}>
              <Link className="desktop-link nav-link" to="/profile">
                <div
                    className={`icon-box ${
                    location.pathname === "/profile" ? "blue-underline" : ""
                    }`}
                >
                    <ListItemIcon className="user">
                        <ProfileIcon />
                    </ListItemIcon>
                    <ListItemText primary="My Profile" />
                </div>
              </Link>
          </ListItem>

          <ListItem
            button key="My Team"
            selected={location.pathname === "/my-team"} style={{padding: 0}}>
              <Link className="desktop-link nav-link" to="/my-team">
                <div
                    className={`icon-box ${
                    location.pathname === "/my-team" ? "blue-underline" : ""
                    }`}
                >
                    <ListItemIcon className="user">
                        <MyTeamIcon />
                    </ListItemIcon>
                    <ListItemText primary="My Team" />
                </div>
              </Link>
          </ListItem>

          <ListItem
            button key="Resource Center"
            selected={location.pathname === "/support"} style={{padding: 0}}>
            <Link className="desktop-link nav-link" to="/support">
                <div className="icon-box support">
                    <ListItemIcon>
                        <SupportIcon iconClass="nav-header-icon"/>
                    </ListItemIcon>
                    <ListItemText primary="Resource Center" />
                </div>
              </Link>
          </ListItem>

          {isAdmin(user) ? (
            <ListItem
              button key="Admin Portal"
              selected={location.pathname === "/users"} style={{padding: 0}}>
                <Link className="desktop-link nav-link" to="/users">
                  <div
                      className={`icon-box ${
                      location.pathname === "/users" ? "blue-underline" : ""
                      }`}
                  >
                      <ListItemIcon className="user">
                          <MyTeamIcon />
                      </ListItemIcon>
                      <ListItemText primary="Admin Portal" />
                  </div>
                </Link>
            </ListItem>
          ) : null}
      </List>
    </div>
  );


  const getClassName = () => {
    return location.pathname === "/profile"
                      ? "layout-wrapper profile-view"
                      : location.pathname.indexOf('job') !== -1
                      ? "layout-wrapper job-view"
                      : location.pathname === "/home"
                      ? "layout-wrapper home-view"
                      : location.pathname === "/search"
                      ? "layout-wrapper search"
                      : (location.pathname.indexOf("skill") > -1)
                      ? "layout-wrapper skill-view"
                      : "layout-wrapper";
  }

  return (
    <div className="layout" style={{background: getBackground()}}>
      <div className={getClassName()}>
        <Hidden smDown>
        <div className={`nav-header ${
              location.pathname === "/search" ? "job-search" : ""
          }`}>
          <Drawer
            sx={{
              width: 240,
              flexShrink: 0,
              '& .MuiDrawer-paper': {
                width: 240,
                boxSizing: 'border-box',
              },
            }}
            variant="permanent"
            anchor="left"
          >
            <Link to="/home" className="header-link">
              <div className="header-logo">
                <ConnectIcon iconClass={"home-icon"} />
              </div>
            </Link>

            {list('left')}

            <MobileDownload></MobileDownload>
          </Drawer>
          <div className="right-nav">
            <Link to="/profile">
                <div
                  className={`font-medium icon-box ${
                    location.pathname === "/profile" ? "blue-nounderline" : ""
                  }`}
                >
                  <Avatar
                    alt="profile photo"
                    src={
                      personalInformation?.avatar.src ||
                      "https://d2wqn2ab54ir3q.cloudfront.net/images/avatar-placeholder.png"
                    }
                    style={{ width: "35px", height: "35px" }}
                  />
                </div>
              </Link>
              <div className="logout"
                onClick={() => {
                  setOpenConfirm(true);
                }}
              >
                <ExitToApp className="gray-icon" />
              </div>
          </div>
        </div>
        </Hidden>
        <Hidden mdUp>
          <div className="nav-header mobile">
            <NavLayoutMobile user={user}></NavLayoutMobile>
            <div className="right-nav">
              <Link to="/profile">
                  <div
                    className={`icon-box ${
                      location.pathname === "/profile" ? "blue-nounderline" : ""
                    }`}
                  >
                    <Avatar
                      alt="profile photo"
                      src={
                        personalInformation?.avatar.src ||
                        "https://d2wqn2ab54ir3q.cloudfront.net/images/avatar-placeholder.png"
                      }
                      style={{ width: "35px", height: "35px" }}
                    />
                  </div>
              </Link>
              <div className="logout"
                onClick={() => {
                  setOpenConfirm(true);
                }}
                >
                <ExitToApp className="gray-icon" />
              </div>
            </div>

          </div>
        </Hidden>
        <main className="main-content nav-control">{children}</main>
      </div>
      <ContactModal open={modalOpen} handleClose={closeModal} />
      {openConfirm && (
        <ConfirmLogout
          open={openConfirm}
          close={() => {
            setOpenConfirm(false);
          }}
        />
      )}
    </div>
  );
};

export default NavLayout;
