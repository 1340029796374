import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import DateFnsUtils from "@date-io/date-fns";
import ReactGA from "react-ga";
import MixPanel from "mixpanel-browser";
import { HeartIcon } from "../../common/Icons";
import Apply from "../../Apply/Apply";
import Hidden from "@material-ui/core/Hidden";
import PrimaryButton from "../../common/Buttons/PrimaryButton";
import {
  DateIcon,
  LocationIcon,
  SalaryIcon
} from "../../common/Icons";

import "./JobCard.scss";
import { addFavorite, removeFavorite } from "../../../actions/jobs";

import applicationStatus from "../../../helpers/application";

const dateFns = new DateFnsUtils();

const JobCard = ({ job, status, callToAction, onApplyClickedHandler }) => {
  const dispatch = useDispatch();
  const { applications } = useSelector((state) => state);
  const [hasApplied, setHasApplied] = useState(false);
  const { favoriteJobs } = useSelector((state) => state);
  const [isFavorite, setFavorite] = useState(false);
  const [addingFavorite, setAddingFavorite] = useState(false);

  const cloudFrontBaseURL = "https://ik.imagekit.io/yksbahzmx/connect/";
  const placeholderImage = cloudFrontBaseURL + "images/job-placeholder.jpg";

  const checkApplied = () => {
    if (applications) {
      const applied = applications.filter(
        (application) => application?.job?.jobId === job.jobId
      );

      if (applied && applied.length > 0) {
        setHasApplied(true);
      }
    }
  };

  useEffect(() => {
    if (applications) {
      checkApplied();
    }
  }, [applications]);

  const selectShiftType = (shift) => {
    const value = shift?.toLowerCase();
    let shiftType = "--";
    if (shift){
      if (value.includes('am') || value.includes('day') || value.includes("07:00-19:00") || value.includes("morning")){
        if (!value.includes('noc') && !value.includes('night') && !value.includes('evening') && !value.includes("19:00-07:00")){
          shiftType = 'Day';
        }
      }
      if (value.includes('noc') || value.includes('night') || value.includes('evening') || value.includes("19:00-07:00")){
        if (!value.includes('am') && !value.includes('day') && !value.includes("07:00-19:00") && !value.includes("morning")){
          shiftType = 'Night';
        }
      }
    }
    return shiftType;
  }

  const shiftType = selectShiftType(job.shift);

  const addToFavorites = async (event) => {
    event.preventDefault();
    event.stopPropagation();
    setAddingFavorite(true);

    const isFavoriteJob = checkIsFavorite(job.jobId);
    if (isFavoriteJob) {
        await dispatch(removeFavorite(job.jobId));
        setFavorite(false);
    } else {
        await dispatch(addFavorite(job.jobId));
        setFavorite(true);
    }

    setAddingFavorite(false);
    ReactGA.event({ category: "JobDetails", action: "Add Favorite" });
    MixPanel.track("JobDetails", { action: "Add Favorite" });

  };

  const checkIsFavorite = (id) => {
      let isFavorite = false;

      favoriteJobs &&
        favoriteJobs.jobList.forEach((favorite) => {
          if (favorite.jobId === id) isFavorite = true;
          return;
        });

      return isFavorite;
    };

  const isAvailable = () => {
    return status && (status != "unavailable")
  }

  const checkFavorite = () => {
    const favorite = favoriteJobs?.jobList?.find(
      (currentJob) => currentJob.jobId === job.jobId
    );
    if (favorite) setFavorite(true);
  };

  useEffect(() => {
      checkFavorite();
  }, [favoriteJobs]);

  return (
    <>
      {job.jobId ? (
        <div className={`card ${isAvailable ? 'clickable':''}`}
             key={job.jobId}
             data-testid="job">
          <div className="p-4">
            <div className="content">
              <div className="no-shrink favorite" onClick={addToFavorites}>
                {status != "unavailable" ? (
                  <div>
                    <HeartIcon iconClass="small-blue-icon" active={isFavorite} />
                    <PrimaryButton
                        buttonClass="transparent-button"
                        disabled={addingFavorite}
                    />
                  </div>
                ) : (
                  <div>
                    <HeartIcon iconClass="small-blue-icon" active={true} />
                  </div>
                )}
              </div>
              <div className="no-shrink hide-sm">
                <img className="cover round size-12" src={job.pictureUrlPath ? cloudFrontBaseURL + "tr:w-40,h-40/" + job.pictureUrlPath : placeholderImage} />
              </div>

              <div className="grid-5" onClick={(e) => {
                if (isAvailable) {
                  e.preventDefault();
                  window.location.href = `/job/${job.jobId}`;
                }
              }}>
                <div className="col-2">
                  <h6 className="text-sm truncate">{job.jobTitle}</h6>
                  {job.location && (
                    <p className="text-sm font-light flex-center">
                      <LocationIcon iconClass="show-xs icon"/>
                      {job.location}
                    </p>
                  )}
                  <div className="show-xs">
                    <p className="text-sm font-light flex-center">
                        <DateIcon iconClass="show-xs icon" />
                        Starts &nbsp;
                        {dateFns.format(
                          new Date(job.startDate),
                          "MMM d"
                        )}

                        {job?.durationWeeks && (
                          <span className="text-xs pill"> {parseInt(job.durationWeeks)} weeks</span>
                        )}
                    </p>

                    <p className="text-sm font-light flex-center">

                        <SalaryIcon iconClass="show-xs icon" />

                        {job?.weeklySalary && job.weeklySalary && (
                          <span>{`${job.weeklySalary.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')} / week`}</span>
                        )}

                    </p>
                  </div>

                </div>

                <div className="col hide-xs">
                  {job.durationWeeks && (
                    <p className="text-sm font-light ">
                      {parseInt(job.durationWeeks)}-weeks
                    </p>
                  )}
                  {job?.weeklySalary && job.weeklySalary !== 0 ? (
                    <div>
                      <h6 className="text-sm ">
                        {`$${job.weeklySalary.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}/week`}
                      </h6>
                    </div>
                  ) : (
                    <div>
                      <h6 className="text-sm">Call/Text for Details</h6>
                    </div>
                  )}
                </div>
                {/* </Hidden> */}

                <div className="col hide-xs">
                  <p className="text-sm font-light">Start Date</p>
                  <h6 className="text-sm">
                    {dateFns.format(
                      new Date(job.startDate),
                      "MMM d"
                    )}
                  </h6>
                </div>

                <div className="col hide-xs">
                  <p className="text-sm font-light">Shift</p>
                  {job.shift ? (
                    <h6 className="text-sm">
                      {shiftType === 'Night'}
                      {shiftType === 'Day'}
                      {shiftType === '--'}
                      {shiftType || "Day/Night"}
                    </h6>
                  ) : (
                    <h6 className="text-sm">--</h6>
                  )}
                </div>
              </div>

                { !callToAction && (
                   <div className="no-shrink ">
                      {(status != 'unavailable') ? (
                        <>
                        { !hasApplied ? (
                          <button
                            className={`text-sm btn ${status ? ' unavailable' : ''}`}
                            onClick={() => onApplyClickedHandler(job)}
                            disabled={hasApplied} >
                              <span>
                                Apply
                                <span className="hide-sm pl-4">
                                  Now
                                </span>
                              </span>
                          </button>
                        ) : (
                          <span className={`text-sm status pill step-${applicationStatus.applied.indexOf(status)}`}>
                            { applicationStatus.getFriendlyStatus(status) }
                          </span>
                        )}
                      </>
                    ) : (
                      <span className="text-sm unavailable pill">
                        Not Available
                      </span>
                    )}
                   </div>
              )}

              {
                 callToAction && (
                  <div className="col">{ callToAction }</div>
                )
              }
            </div>
          </div>
        </div>
      ) : (
        null
      )}
    </>
  );
};

export default JobCard;
