import React, { useEffect, useState, useRef, memo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import Skeleton from "@material-ui/lab/Skeleton";
import DateFnsUtils from "@date-io/date-fns";
import Empty from "../../common/Empty/Empty";

import {
  FormControlLabel,
  Checkbox,
  MenuItem,
  TextField,
  CircularProgress,
  Grid,
  ClickAwayListener,
  Avatar,
  Hidden
} from "@material-ui/core";
import { ExitToApp } from "@material-ui/icons";
import CloseIcon from "@material-ui/icons/Close";

import MuiSelect from '@material-ui/core/Select';
import Select from "react-select";

import NavLayout from "../../NavLayout/NavLayout";

import JobCardCondensed from "../../common/JobCardCondensed/JobCardCondensed";
import FilterJobs from "./FilterJobs";
import { getSearchJobs } from "../../../actions/jobFilters";
import { getSpecialties } from "../../../services/datalist";
import { setJobFilters } from "../../../actions/jobFilters";
import { getCandidateMatchCriteria } from "../../../services/candidateData";
import { getJobApplications } from "../../../actions/applications";
import { getLocations } from "../../../services/datalist";
import FilterIcon from "../../common/Icons/FilterIcon/FilterIcon";
import {
  setFeaturedJobs
} from "../../../actions/jobs";

import { sortByOptions, shiftOptions, dateRanges, dates } from "./options";

// Accordion
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

// CheckboxGroup
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import { withStyles } from '@material-ui/core/styles';
import { grey } from '@material-ui/core/colors';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

import { Autocomplete } from "@material-ui/lab";
import { useDebouncedSearch } from "../../../hooks/search";

import "./JobSearch.scss";
import JobDetails from "../JobDetails/JobDetails";
import ConfirmLogout from "../../NavLayout/ConfirmLogout";
import { getCandidateDetails } from "../../../services/candidateData";

const dateFns = new DateFnsUtils();
const useLocationSearch = () =>
  useDebouncedSearch((text) => getLocations(text));

const JobSearch = (props, {handleSearch}) => {
  const { personalInformation, basicInfo } = useSelector((state) => state.candidateData);
  const [specialtyOptions, setSpecialtyOptions] = useState();
  const [currentSpecialtyList, setSpecialtyList] = useState([]);
  const [filteredResults, setResults] = useState([]);
  const { featuredJobs } = useSelector((state) => state);
  const [candidateData, setCandidateData] = useState();
  const { filters, filterCount } = useSelector(
    (state) => state.jobFilters || {}
  );
  const { jobSearch, applications } = useSelector((state) => state);
  const { total } = jobSearch || {};
  const dispatch = useDispatch();
  let resultsRef = useRef();
  const prevPageRef = useRef();
  const url = props.location;
  const prevPage = prevPageRef.current;
  const { range } = filters || {};

  const [filteredJobs, setFilteredJobs] = useState([]);
  const [page, setPage] = useState(0);
  const [pageTotal, setPageTotal] = useState(1);
  const [loading, setLoading] = useState(false);
  const [showFilters, setShowFilters] = useState(false);
  const [newSearch, setNewSearch] = useState(false);
  const { inputText, setInputText, searchResults } = useLocationSearch();
  const { result = [] } = searchResults;
  const locationOptions = result.map((location) => location.name);
  const [selectedJob, showJob] = useState(-1);
  const [isFetching, setIsFetching] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [currentRefData, setCurrentRefData] = useState({});
  const type = (new URLSearchParams(url).get('type') == null ? '' : new URLSearchParams(url).get('type'));
  let[filterSave, setFilterSave] = useState(false);
  let[filterModalSave, setFilterModalSave] = useState(false);
  const [currentLocations, setCurrentLocations] = useState([]);
  const [currentSortBy, setSortBy] = useState("Relevant");
  const [shiftsArr, setShiftsArr] = useState([]);
  const [datesArr, setDatesArr] = useState([]);
  const [isFeaturedJobsClicked, setIsFeaturedJobsClicked] = useState(type === "featured" || false);

  const getOptionValue = (option) => option;
  const getOptionLabel = (option) => option;


  useEffect(() => {
    let mounted = true;
    const loadData = async () => {
      const userDetails = await getCandidateDetails();
      await dispatch(setJobFilters({ sortBy: 'weeklySalary' }));

      if (!currentSpecialtyList?.length) {
        var specialtyData = await getSpecialties();

        if (userDetails?.basicInfo) {
          const { basicInfo } = userDetails;
          if (basicInfo?.specialtyList?.length > 0) {
            specialtyData = specialtyData.filter(x => x.category === basicInfo.specialtyList[0].category);
          }
        }

        setSpecialtyOptions(specialtyData);
      }
      if (userDetails?.basicInfo) {
        const { basicInfo } = userDetails;
        setCandidateData(basicInfo)
      }
      if (!featuredJobs) {
       await dispatch(setFeaturedJobs());
      }
    };
    if (mounted && !basicInfo) {
      loadData();
    }

    return () => {
      mounted = false;
    };
  }, [basicInfo]);

  useEffect(() => {
    prevPageRef.current = page;
  });

  useEffect(() => {
    let mounted = true;
    setLoading(true);

    const loadData = async () => {

      if(filteredJobs?.length) {
        let results = [];

        filteredJobs.map(job => {
          results.push(job);
        });

        setResults([...filteredResults, ...results]);

        if (window.innerWidth > 768 && selectedJob === -1) {
          changeJob(filteredJobs[0].jobId);
        }
      }

      if (!applications) {
        await dispatch(getJobApplications());
      }
    };

    if (mounted) {
      loadData();
      setLoading(false);
    }

    return () => {
      mounted = false;
    };
  }, [filteredJobs]);

  const changeSpecialties = (value) => {
    dispatch(setJobFilters({ specialties: value }));
  };

  useEffect(() => {
    setPageTotal(Math.ceil(total / 10));
  }, [total]);

  //Searches the page selected by the Pagination Component
  useEffect(() => {
    const load = async () => {
      await search();

      if (currentSortBy !== 'Relevant') {
        dispatch(setJobFilters({ sortBy: currentSortBy}));
      }
    };
    if (prevPage !== page && page > 0 || prevPage === page && newSearch === true) {
      load();
    }
  }, [page, newSearch]);

  useEffect(() => {
    switch (type) {
      case "featured":
        dispatch(setJobFilters({specialties: []}));
        dispatch(setJobFilters({isfeatured: true}));
        dispatch(setJobFilters({ sortBy: 'weeklySalary' }));
        onFilterSave();
        break;
      case "highestpaid":
        dispatch(setJobFilters({specialties: []}));
        dispatch(setJobFilters({isfeatured: false}));
        dispatch(setJobFilters({ sortBy: 'weeklySalary' }));
        onFilterSave();
        break;
      default:
        break;
    }
  }, [type]);

  useEffect(() => {
    dispatch(setJobFilters({ isfeatured: isFeaturedJobsClicked }));
    onFilterSave(true);
  }, [isFeaturedJobsClicked]);

  useEffect(() => {
    let mounted = true;
    setLoading(true);

    const loadData = async () => {
      if (specialtyOptions && specialtyOptions?.length && type !== "featured") {
        let data = await getCandidateMatchCriteria();

        if (data) {
          const { specialties: specialtyMatches } = data || {};
          let list = [];

          if (specialtyMatches?.length > 0) {
            for (let i = 0; i < specialtyOptions.length; i++) {
                for (let j = 0; j < specialtyMatches.length; j++) {
                    if (specialtyOptions[i].id === specialtyMatches[j]) {
                        list.push(specialtyOptions[i].label);
                    }
                }
            }
          }

          setSpecialtyList(list);
          changeSpecialties(list);
          setFilterSave(true);
        }
      }
    }

    if (mounted) {
      loadData();
    }

    return () => {
      mounted = false;
    };
  }, [specialtyOptions]);

  useEffect(() => {
      if(specialtyOptions && specialtyOptions.length) {
        let list = [];

        for (let i = 0; i < specialtyOptions.length; i++) {
          for (let j = 0; j < currentSpecialtyList.length; j++) {
              if (specialtyOptions[i].label === currentSpecialtyList[j]) {
                  list.push(specialtyOptions[i].id);
              }
          }
      }

      changeSpecialties(list);
    }
  }, [currentSpecialtyList]);

  useEffect(() => {
    if (filterModalSave || filterSave) {
      setFilterSave(false);
      setFilterModalSave(false);
      onFilterSave();
    }
  }, [filterModalSave, filterSave]);

  useEffect(() => {
    dispatch(setJobFilters({ locations: [...currentLocations] }));
    setInputText("");
  }, [currentLocations]);

  const changeSpecialty = (value, saveOnFilter) => {
    if (!currentSpecialtyList.includes(value[0])) {
      let currentList = [...currentSpecialtyList];
      setSpecialtyList([...currentList, value[0]]);
      setFilterSave(saveOnFilter);
    }
  };

  const removeSpecialty = (value, saveOnFilter) => {
    if (currentSpecialtyList.includes(value)) {
      let currentList = [...currentSpecialtyList];
      const list = currentList.filter((item) => { return item !== value});
      setSpecialtyList(list);
      setFilterSave(saveOnFilter);
    }
  };

  const changeLocations = (value, saveOnFilter) => {
    if (!currentLocations.includes(value[0])) {
      let currentList = [...currentLocations];
      setCurrentLocations([...currentList, value[0]]);
      setFilterSave(saveOnFilter);
    }
  };

  const removeLocation = (value, saveOnFilter) => {
    if (currentLocations.includes(value)) {
      let currentList = [...currentLocations];
      const list = currentList.filter((item) => { return item !== value});
      setCurrentLocations(list);
      setFilterSave(saveOnFilter);
    }
  };

  const onFilterSave = (isSave) => {
    setFilterModalSave(isSave);
    changeJob(-1);
    setResults([]);
    if (prevPage === 1) setNewSearch(true);
    setPage(1);
  };

  const search = async () => {
    if(!isFetching) {
      setLoading(true);
    }
    const result = await dispatch(getSearchJobs(page));

    if (result && candidateData && currentSpecialtyList) {
      setFilteredJobs(result.jobList);
      setShowFilters(false)
      setNewSearch(false);
      setIsFetching(false);
      setLoading(false);
    }
  };

  const handleShiftChange = (event) => {
    let currentShiftsArr = [...shiftsArr];

    if(currentShiftsArr == undefined && event.target.checked == true) {
      currentShiftsArr = [];
      currentShiftsArr.push(event.target.name);
    } else if(currentShiftsArr != undefined && event.target.checked == true) {
      currentShiftsArr.push(event.target.name);
    } else {
      currentShiftsArr = currentShiftsArr.filter((item) => {
        return item != event.target.name;
      });
    }

    setShiftsArr(currentShiftsArr);
    dispatch(setJobFilters({ shifts: currentShiftsArr }));
    onFilterSave();
  };

  const removeShift = (value) => {
    let currentShiftsArr = [...shiftsArr];

      if (currentShiftsArr.includes(value)) {
        currentShiftsArr = currentShiftsArr.filter((item) => {
          return item != value;
      });
    }

    setShiftsArr(currentShiftsArr);
    dispatch(setJobFilters({ shifts: currentShiftsArr }));
    onFilterSave();
  };

  const handleDateChange = (event, isRemovePill = false, saveOnFilter) => {
    var start = range?.startDateBegin;
    var end = range?.startDateEnd;
    let currentDates = [...datesArr];

    if(!isRemovePill && event.target.checked == true) {
      if(!currentDates.includes(event.target.name)) {
        currentDates.push(event.target.name);
      }
    } else {
      currentDates = currentDates.filter((item) => {
        let currentItem = isRemovePill ? event : event.target.name;
        return item != currentItem;
      });
    }

    if (!currentDates.length) {
      start = null;
      end = null;
    }

    if(currentDates.includes('0')) {
      start = dates.now;
    } else if((!currentDates.includes('0') && currentDates.includes('1') && !currentDates.includes('2')) || (!currentDates.includes('0') && currentDates.includes('1') && currentDates.includes('2'))) {
      start = dates.nextStart;
    } else if(!currentDates.includes('0') && !currentDates.includes('1') && currentDates.includes('2')) {
      start = dates.twoStart;
    }

    if(currentDates.includes('2')) {
      end = dates.twoEnd;
    } else if((!currentDates.includes('0') && currentDates.includes('1') && !currentDates.includes('2')) || currentDates.includes('0') && currentDates.includes('1') && !currentDates.includes('2')) {
      end = dates.nextEnd;
    } else if(currentDates.includes('0') && !currentDates.includes('1') && !currentDates.includes('2')) {
      end = dates.monthEnd;
    }

    dispatch(
      setJobFilters({
        range: {
          startDateBegin: start,
          startDateEnd: end
        }
      })
    );
    setDatesArr(currentDates);
    setFilterSave(saveOnFilter);
  }

  const changeSort = (value) => {
    dispatch(setJobFilters({ sortBy: value != "Relevant" ? value : "" }));
    setSortBy(value);
    onFilterSave();
  };

  const handleFeatureChange = (isClicked) => {
    const loadData = async () => {
      if (specialtyOptions && specialtyOptions?.length && !currentSpecialtyList?.length) {
        setLoading(true);
        let data = await getCandidateMatchCriteria();

        if (data?.specialties.length) {
          const { specialties: specialtyMatches } = data || {};
          if (specialtyMatches?.length > 0) {
            let list = [];

            for (let i = 0; i < specialtyOptions.length; i++) {
                for (let j = 0; j < specialtyMatches.length; j++) {
                    if (specialtyOptions[i].id === specialtyMatches[j]) {
                        list.push(specialtyOptions[i].label);
                    }
                }
            }

            setSpecialtyList(list);
            changeSpecialties(list);
            setIsFeaturedJobsClicked(!isClicked);
            setLoading(false);
          }
        }
      } else {
        setIsFeaturedJobsClicked(!isClicked);
      }

    }

    if (isClicked) {
      loadData();
    } else {
      setIsFeaturedJobsClicked(!isClicked);
    }
  };

  const GreenCheckbox = withStyles({
    root: {
      color: grey[400],
      '&$checked': {
        color: '#6dd19d',
      },
    },
    checked: {},
  })((props) => <Checkbox color="default" {...props} />);

  const changeJob = (job) => {
      showJob(job);
  }

  const handleScroll = () => {
    if(isFetching) return;

    const jobs = document.getElementById('jobs-container');

		if ((jobs.offsetHeight + jobs.scrollTop) === jobs.scrollHeight || !isFetching) {
      setIsFetching(true);

      if(page + 1 <= pageTotal) {
        setPage(page + 1);
      }
    }
	};

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <>
      {!showFilters ? (
        <NavLayout
          headerText="Job Board"
          showFilter
          handleOpen={() => {
            setShowFilters(true)
          }}
        >
          <div className="search-content" ref={resultsRef}>
            <div className="default-view">
                <div className="mobile-filter-options">
                  <div className="mobile-filter-svg w-1/8"  onClick={() =>{
                      setShowFilters(true);
                      setCurrentRefData({
                        originalLocations: currentLocations,
                        originalSpecialty: currentSpecialtyList,
                        originalDates: datesArr,
                        originalShifts: shiftsArr
                      });
                  }}>
                    <FilterIcon />
                  </div>
                  {/* <div className="w-full inline-flex">
                    <div className="mobile-scroll-filters inline-flex">
                      {currentSpecialtyList?.map((specialty, index) => (
                          <div key={`specialty-${index}`} onClick={()=>{removeSpecialty(specialty, true)}} className="btn-md text-sm status pill-blue text-hover">
                            <p>{specialty}</p>
                            <div className="margin-l-10 close" ><CloseIcon className="icon-button text-hover" /></div>
                          </div>
                        ))
                      }
                      {currentLocations?.map((location, index) => (
                          <div key={`location-${index}`} onClick={()=>{removeLocation(location, true)}} className="btn-md text-sm status pill-blue text-hover">
                            <p> {location}</p>
                            <div className="margin-l-10 close" ><CloseIcon className="icon-button text-hover" /></div>
                          </div>
                        ))
                      }
                      {shiftsArr?.map((shift, index) => (
                          <div key={`shift-${index}`} onClick={()=>{removeShift(shift)}} className="btn-md text-sm status pill-blue text-hover">
                            <p>{shift}</p>
                            <div className="margin-l-10 close" ><CloseIcon className="icon-button text-hover" /></div>
                          </div>
                        ))
                      }
                      {datesArr?.map((date, index) => (
                          <div key={`date-${index}`} onClick={()=>{handleDateChange(date, true, true)}} className="btn-md text-sm status pill-blue text-hover">
                            <p>{dateRanges[0].label}</p>
                            <div className="margin-l-10 close" ><CloseIcon className="icon-button text-hover" /></div>
                          </div>
                        ))
                      }
                    </div>
                  </div> */}
                </div>
                <div className="desktop-search">
                  <div className="search-bar">
                    <div className="explore-search-bar" >
                      <ClickAwayListener onClickAway={handleChange()}>
                        <div className="w-full inline-flex">
                          {locationOptions && (
                            <FormControl fullWidth variant="outlined">
                              <Autocomplete
                                multiple
                                size="small"
                                id="locations-autocomplete"
                                fullWidth
                                value={[]}
                                options={locationOptions || []}
                                onChange={(e, val) => changeLocations(val, true)}
                                inputValue={inputText || ""}
                                getOptionLabel={(option) => option || ""}
                                blurOnSelect
                                disabled={loading}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label="City or City, State"
                                    variant="outlined"
                                    onChange={(e) => setInputText(e.target.value)}
                                    InputProps={{
                                      ...params.InputProps,
                                      endAdornment: (
                                        <React.Fragment>
                                          {loading ? (
                                            <CircularProgress color="inherit" size={20} />
                                          ) : null}
                                          {params.InputProps.endAdornment}
                                        </React.Fragment>
                                      )
                                    }}
                                  />
                                )}
                                fullWidth
                              />
                              </FormControl>
                          )}

                          <FormControl fullWidth variant="outlined">
                            <Select
                              className="specialty-select"
                              data-testid="JobSearchSpecialty"
                              id="currentSpecialtyList"
                              name="currentSpecialtyList"
                              options={specialtyOptions?.filter((specialty) => {
                                return specialty && specialty.label !== "EKG/Tele Tech";
                              }).map((specialty) => (
                                  specialty.label
                              ))}
                              isMulti
                              value="Select specialties"
                              getOptionValue={getOptionValue}
                              getOptionLabel={getOptionLabel}
                              onChange={(e) => changeSpecialty(e, true)}
                              placeholder="Select specialties"
                              disabled={loading}
                            />
                          </FormControl>

                          <div className="accordion-wrap">
                            <Accordion expanded={expanded === 'shift'} onChange={handleChange('shift')}>
                              <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                              >
                                Shifts
                              </AccordionSummary>
                              <AccordionDetails>
                                <FormControl component="fieldset">
                                  <FormGroup>
                                    {shiftOptions?.map((option, index) => (
                                      <FormControlLabel disabled={loading} key={index} checked={shiftsArr != undefined && shiftsArr.includes(option.value)} onChange={handleShiftChange} name={`${option.value}`} control={<GreenCheckbox />} label={option.label} />
                                    ))}
                                  </FormGroup>
                                </FormControl>
                              </AccordionDetails>
                            </Accordion>
                            <Accordion expanded={expanded === 'date'} onChange={handleChange('date')}>
                              <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                              >
                                Dates
                              </AccordionSummary>
                              <AccordionDetails>
                                <FormControl component="fieldset">
                                  <FormGroup>
                                    {dateRanges?.map((option, index) => (
                                      <FormControlLabel disabled={loading} key={index} checked={datesArr.includes(option.value)} onChange={(event) => { handleDateChange(event, false, true) }} name={`${option.value}`} control={<GreenCheckbox />} label={option.label} />
                                    ))}
                                  </FormGroup>
                                </FormControl>
                              </AccordionDetails>
                            </Accordion>
                          </div>
                        </div>
                      </ClickAwayListener>
                    </div>
                    <div className="right-nav">
                      <Link to="/profile">
                        <div
                          className={`icon-box `}
                        >
                          <Avatar
                            alt="profile photo"
                            src={
                              personalInformation?.avatar.src ||
                              "https://d2wqn2ab54ir3q.cloudfront.net/images/avatar-placeholder.png"
                            }
                            style={{ width: "35px", height: "35px" }}
                          />
                        </div>
                      </Link>
                      <div className="logout"
                          onClick={() => {
                            setOpenConfirm(true);
                          }}
                          >
                          <ExitToApp className="gray-icon" />
                      </div>
                    </div>
                  </div>
                  <div className="explore">
                    <div className="subheader">
                      <div className="condensed-pill-section">
                          {currentSpecialtyList?.map((specialty, index) => (
                              <div key={`specialty-${index}`} onClick={()=>{removeSpecialty(specialty, true)}} className="text-sm status pill-blue text-hover">
                                {specialty}<div className="margin-l-10 close" ><CloseIcon className="icon-button text-hover" /></div>
                              </div>
                            ))
                          }
                          {currentLocations?.map((location, index) => (
                              <div key={`location-${index}`} onClick={()=>{removeLocation(location, true)}} className="text-sm status pill-blue text-hover">
                                {location}<div className="margin-l-10 close" ><CloseIcon className="icon-button text-hover" /></div>
                              </div>
                            ))
                          }
                          {shiftsArr?.map((shift, index) => (
                              <div key={`shift-${index}`} onClick={()=>{removeShift(shift)}} className="text-sm status pill-blue text-hover">
                                {shift}<div className="margin-l-10 close" ><CloseIcon className="icon-button text-hover" /></div>
                              </div>
                            ))
                          }
                          {datesArr?.map((date, index) => (
                              <div key={`date-${index}`} onClick={()=>{handleDateChange(date, true, true)}} className="text-sm status pill-blue text-hover">
                                {dateRanges[0].label}<div className="margin-l-10 close" ><CloseIcon className="icon-button text-hover" /></div>
                              </div>
                            ))
                          }
                        </div>
                    </div>
                  </div>
                </div>
                {!loading && filteredJobs ? (
                  <div className="matched">
                    {(filteredJobs?.length === 0 ? (
                      <>
                        <Grid container>
                          <Grid item xs={12} md={4} className="jobs jobs-container w-full" id="jobs-container" onScroll={handleScroll}>
                            <div className="filter-header">
                              <div className="featured">
                                  <a className="text-hover text-sm text-blue-500" disabled={loading} onClick={() => { handleFeatureChange(isFeaturedJobsClicked) }}>
                                    {!isFeaturedJobsClicked ? "Show Featured Jobs" : "Show All Jobs"}
                                  </a>
                              </div>

                              <div className="filter-category">
                                <FormControl fullWidth className="display-contents">
                                  <p className="inline-flex w-1/3 text-sm text-gray-400 justify-right"> Sort by </p>
                                  <MuiSelect
                                    className="inline-flex w-2/3"
                                    id="sortby-select"
                                    value={currentSortBy || "Relevant"}
                                    data-testid="sortby"
                                    onChange={(e) => changeSort(e.target.value)}
                                    disabled={loading}
                                    label="Sort by"
                                  >
                                    <MenuItem key={0} value={"Relevant"}>
                                      <em className="pl-4 ">Relevant</em>
                                    </MenuItem>
                                    {sortByOptions?.map((option) => (
                                      <MenuItem key={option.value} value={option.value}>
                                        {option.label}
                                      </MenuItem>
                                    ))}
                                  </MuiSelect>
                                </FormControl>
                              </div>
                            </div>
                          </Grid>
                        </Grid>
                        <Empty
                          title="No matches"
                          subtitle="There are no matches at the moment. Jobs are updated in real-time and can become available at any moment"
                          callToAction={
                            <button className="btn" onClick={(e) => {
                              e.preventDefault();
                              window.location.href = `/profile`;
                            }}>
                            Update preferences
                            </button>}
                          />
                      </>
                    ) : null )}
                    {(filteredJobs?.length > 0 ? (
                      <Grid container>
                        <Grid item xs={12} md={4} className="jobs jobs-container w-full" id="jobs-container" onScroll={handleScroll}>
                          <div className="filter-header">
                            <div className="featured">
                                <a className="text-hover text-sm text-blue-500" disabled={loading} onClick={() => { handleFeatureChange(isFeaturedJobsClicked) }}>
                                  {!isFeaturedJobsClicked ? "Show Featured Jobs" : "Show All Jobs"}
                                </a>
                            </div>
                            <div className="filter-category">
                              <FormControl fullWidth className="display-contents">
                                <p className="inline-flex w-1/3 text-sm text-gray-400 justify-right"> Sort by </p>
                                <MuiSelect
                                  className="inline-flex w-2/3"
                                  id="sortby-select"
                                  value={currentSortBy || "Relevant"}
                                  data-testid="sortby"
                                  onChange={(e) => changeSort(e.target.value)}
                                  disabled={loading}
                                  label="Sort by"
                                >
                                  <MenuItem key={0} value={"Relevant"}>
                                    <em className="pl-4 ">Relevant</em>
                                  </MenuItem>
                                  {sortByOptions?.map((option) => (
                                    <MenuItem key={option.value} value={option.value}>
                                      {option.label}
                                      </MenuItem>
                                    ))}
                                  </MuiSelect>
                                </FormControl>
                            </div>
                          </div>
                            <h6 className="search-total text-md leading-6 font-medium text-gray-900">
                              {!isFeaturedJobsClicked ? (
                                <p>Found {`${total ? total : 0}`} jobs</p>
                              ) : (
                                <p>Found {`${total ? total : 0}`} featured jobs</p>
                              )}
                            </h6>
                            {filteredResults?.length ? (
                              [...new Map(filteredResults.map(job => [job.jobId, job])).values()].map((thisJob) => (
                                <div
                                  className={"job-card condensed w-full" + (selectedJob === thisJob.jobId ? ' selected' : '')}
                                  key={thisJob.jobId}
                                  onClick={() => changeJob(thisJob.jobId)}>
                                    <JobCardCondensed
                                      job={thisJob}
                                      loading={loading}
                                      candidateData={candidateData}
                                      featuredJobs={featuredJobs}
                                    />
                                </div>
                              ))
                            ) : null }
                            {isFetching && page < pageTotal ? (
                              <div className="loading-wrap">
                                <CircularProgress color="inherit" size={30} />
                              </div>
                            ) : null}
                          </Grid>
                          {selectedJob !== -1 ? (
                            <Grid item md={8} className="job-details">
                              <p onClick={() => changeJob(-1)} className="back-arrow"><ArrowBackIcon /> Back</p>
                              <JobDetails
                                match={{params: {id: selectedJob}}}
                                key={selectedJob}
                              />
                            </Grid>
                          ) : (
                            null
                          )}
                      </Grid>
                    ) : null )}
                  </div>
                  ) : (
                    <div className="matched skeleton">
                      <Grid container>
                        <Grid item xs={12} md={4} className="jobs" id="jobs-container-skeleton">
                          <Skeleton
                            variant="text"
                            width="8rem"
                            height="3rem"
                            className="subheader"
                          ></Skeleton>

                          <Skeleton
                            variant="rect"
                            width="100%"
                            height="8rem"
                          ></Skeleton>
                          <Skeleton
                            variant="rect"
                            width="100%"
                            height="8rem"
                          ></Skeleton>
                          <Skeleton
                            variant="rect"
                            width="100%"
                            height="8rem"
                          ></Skeleton>
                          <Skeleton
                            variant="rect"
                            width="100%"
                            height="8rem"
                          ></Skeleton>
                        </Grid>

                        <Grid item md={8} className="job-details">
                          <Skeleton
                            width="100%"
                            height="19rem"
                            className="job-image"
                          ></Skeleton>
                          <div className="job-details skeleton-details">
                            <Skeleton
                              variant="rect"
                              width="100%"
                              height="3.5rem"
                              className="job-section"
                            ></Skeleton>
                            <Skeleton
                              variant="rect"
                              height="2.5rem"
                              width="100%"
                              className="job-section"
                            ></Skeleton>
                            <Skeleton
                              variant="rect"
                              height="3rem"
                              width="100%"
                              className="job-section"
                            ></Skeleton>
                            <Skeleton
                              variant="rect"
                              height="13rem"
                              width="100%"
                              className="job-section"
                            ></Skeleton>
                          </div>
                        </Grid>
                      </Grid>
                    </div>
                  )}
            </div>
          </div>
        </NavLayout>
      ) : (
        <FilterJobs
          handleClose={() => {
            setShowFilters(false);
            setCurrentLocations(currentRefData.originalLocations);
            setSpecialtyList(currentRefData.originalSpecialty);
            setDatesArr(currentRefData.originalDates);
            setShiftsArr(currentRefData.originalShifts);
            setFilterModalSave(true);
          }}
          handleSearch={onFilterSave}
          specialtyOptions={specialtyOptions}
          currentSpecialtyList={currentSpecialtyList}
          updateSpecialties={changeSpecialty}
          updateRemovedSpecialty={removeSpecialty}
          updateRemovedLocation={removeLocation}
          updateLocations={changeLocations}
          updateDates={handleDateChange}
          shiftArray={shiftsArr}
          dateArray={datesArr}
          currentLocations={currentLocations}
        />
      )}
      {openConfirm && (
        <ConfirmLogout
          open={openConfirm}
          close={() => {
            setOpenConfirm(false);
          }}
        />
      )}
    </>
  );
};

export default memo(JobSearch);
