import { VALIDATE_PHONE } from "../actions/types";

const initialState = {};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case VALIDATE_PHONE:
      return { contactValidation: payload };

    default:
      return state;
  }
}
