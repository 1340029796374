import React, { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import InputMask from "react-input-mask";
import Form from "react-validation/build/form";
import { Grid, TextField } from "@material-ui/core";
import { notify } from "../../../toasts/toasts";
import isEmail from "validator/lib/isEmail";
import isMobilePhone from "validator/lib/isMobilePhone";

import NavLayout from "../../NavLayout/NavLayout";
import PrimaryButton from "../../common/Buttons/PrimaryButton";
import { getCand } from "../../../actions/candidateEntry";
import { DateRangeOutlined } from "@material-ui/icons";
import { LocationIcon, EmailIcon } from "../../common/Icons";
import { clearMessage } from "../../../actions/message";
import UserService from "../../../services/user.service";

import "./Support.scss";

const Support = (props) => {
  const form = useRef();
  const dispatch = useDispatch();
  const { personalInformation } = useSelector((state) => state.candidateData);
  const [loading, setLoading] = useState(false);
  const { message } = useSelector((state) => state.message);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    description: ""
  });
  const [formErrors, setErrors] = useState({});

  useEffect(() => {
    let mounted = true;
    const loadData = async () => {
      setLoading(true);
      if (personalInformation) {
        prepopulateCandidateValues(personalInformation);
      }
      else {
        let candidateDetails = await dispatch(getCand());
        prepopulateCandidateValues(candidateDetails?.basicInfo);
      }

      setLoading(false);
    };

    if (mounted) {
      loadData();
    }

    return () => {
      mounted = false;
    };
  }, []);

  const prepopulateCandidateValues = (candidateDetails) => {
    const newState = { ...formData };
    if (candidateDetails) {
      if (candidateDetails.firstName && candidateDetails.lastName) {
        newState["name"] = `${candidateDetails.firstName} ${candidateDetails.lastName}`;
      }

      if (candidateDetails.email) {
        newState["email"] = candidateDetails.email;
      }

      if (candidateDetails.phone) {
        newState["phone"] = candidateDetails.phone;
      }
    }

    setFormData(newState);
  };

  const handleChange = (field, value) => {
    setValue(field, value);
  };

  const setValue = (type, value) => {
    const newState = { ...formData };
    newState[type] = value;
    setFormData(newState);
  };

  const validateField = (type, value, errors) => {
    if (!value) {
      errors[type] = "required";
      return;
    }

    switch (type) {
      case "email":
        const validEmail = isEmail(value);
        if (!validEmail) errors["email"] = "Please enter a valid email";
        break;
      case "phone":
        const validPhone = isMobilePhone(value);
        if (!validPhone) errors["phone"] = "Please enter a valid phone number";
        break;
      default:
    }
  };

  const validate = () => {
    setErrors({});
    let errors = {};
    let isValid = true;

    for (let key in formData) {
      validateField(key, formData[key], errors);
    }
    if (Object.keys(errors).length > 0) {
      isValid = false;
      setErrors(errors);
    }
    return isValid;
  };

  const clearError = (type) => {
    const errors = { ...formErrors };
    delete errors[type];
    setErrors(errors);
  };

  const send = async (e) => {
    e.preventDefault();
    dispatch(clearMessage());
    const isValid = validate();

    if (isValid) {
      setLoading(true);
      try {
        await UserService.sendSupportMessage(formData.name, formData.email, formData.phone, formData.description);
        notify("Successfully sent message.", "success");
        setValue("description", "");
      } catch (error) {
        notify("Failed to send message.", "error");
        console.log(error);
      }
      setLoading(false);
    }
  };

  return (
    <NavLayout headerText="Support">
      <div className="support-container-main">
        <div className="support-form-container">
            <h1 className="text-lg leading-6 font-medium text-gray-900 text-center mt-4">We are here to help you!</h1>
            <div className="sub-title px-4 py-2">Our recruiters can answer questions for you or help with your job applications.</div>
            <Form
              onSubmit={send}
              ref={form}
              className="support-form"
            >
              <div className="text-md text-gray-500 ml-8-sm">Send us a message</div>
              <div  className="form-input-container shadow-md"  >
                <Grid item>
                  <TextField
                    id="name"
                    className="input-field"
                    name="name"
                    label="Name"
                    value={formData.name}
                    onChange={(e) =>
                      handleChange(e.target.id, e.target.value)
                    }
                    error={
                      formErrors["name"] !== undefined
                    }
                    helperText={formErrors.name || ""}
                    fullWidth
                    inputProps={{maxLength: 100}}
                  />
                </Grid>
                <Grid item>
                  <TextField
                    id="email"
                    className="input-field"
                    name="email"
                    label="Email"
                    value={formData.email}
                    onChange={(e) =>
                      handleChange(e.target.id, e.target.value)
                    }
                    error={
                      formErrors["email"] !== undefined
                    }
                    helperText={formErrors.email || ""}
                    fullWidth
                    inputProps={{maxLength: 100}}
                  />
                </Grid>
                <Grid item>
                  <InputMask
                    mask="999-999-9999"
                    value={formData.phone}
                    disabled={false}
                    onChange={(e) =>
                      handleChange(e.target.id, e.target.value)
                    }
                    onFocus={() => clearError("phone")}
                    maskChar=" "
                  >
                  {() => (
                    <TextField
                      id="phone"
                      className="input-field"
                      name="phone"
                      label="Phone number (optional)"
                      type="text"
                      fullWidth
                      error={formErrors["phone"] !== undefined}
                      helperText={formErrors?.phone || ""}
                    />
                  )}
                </InputMask>
                </Grid>
                <Grid item>
                  <div className="description-title">How can we help you?</div>
                  <TextField
                    id="description"
                    className="input-field"
                    name="description"
                    label="I need help with..."
                    value={formData.description}
                    onChange={(e) =>
                      handleChange(e.target.id, e.target.value)
                    }
                    error={
                      formErrors["description"] !== undefined
                    }
                    helperText={formErrors.description || ""}
                    fullWidth
                    multiline
                    rows={3}
                    rowsMax={3}
                    inputProps={{maxLength: 1000}}
                  />
                </Grid>
                <Grid item className="send-button-container">
                  <PrimaryButton type="submit" text="Send" disabled={loading} loading={loading} buttonClass="color-button"/>
                </Grid>
              </div>
            </Form>
        </div>
        <div className="support-info-container">
            <div className="tips support-info">
              <div className="text-md leading-6 font-medium text-gray-900">Tips to get better support</div>
              <div className="description">For any job application questions please refer to a recruiter. The best way to get a hold of them is scheduling an appointment.</div>
            </div>
            <div className="support-image-container support-info">
              <img className="support-image" src="https://d2wqn2ab54ir3q.cloudfront.net/images/support-team.png"/>
            </div>
            <div className="support-team-container support-info">
            <div className="text-md text-gray-500">Support Team</div>
              <div className="support-team-contact">
                <LocationIcon iconClass="support-icon" />
                <div><a href="https://goo.gl/maps/477KZ8FCayHKu6Kx8" target="_blank">14850 Montfort Drive, Suite 160,Dallas, TX  75254</a></div>
              </div>
              <div className="support-team-contact">
                <EmailIcon iconClass="support-icon" />
                <div><a href="mailto:connect@advantismed.com" target="_blank">connect@advantismed.com</a></div>
              </div>
            </div>
        </div>
      </div>
    </NavLayout>
  );
};

export default Support;
