import axios from "axios";
import API_URL from "../helpers/globals.js";

export const confirmEmail = async (code) => {
  try {
    const response = await axios.post(
      `${API_URL}User/confirmEmail?code=${code}`
    );
    if (response) {
      return response.data;
    }
  } catch (err) {
    console.log(err);
  }
};

export const confirmPassword = async (code) => {
  try {
    const response = await axios.post(
      `${API_URL}User/confirmPassword?code=${code}`
    );
    if (response) {
      return response.data;
    }
  } catch (err) {
    console.log(err);
  }
};
