const status = {
  applied: ['New Submission', 'Candidate Interested', 'Client Submission' ],
  offered: ['Offer Extended'],
  history: ['Rejected', 'Accepted - Pending Start'],
  getFriendlyStatus: function (status) {
    let name = '';
    switch(status) {
      case 'New Submission':
      case 'Candidate Interested':
      case 'Candidate Submission':     name = 'Interested';
                                       break;
      case 'Offer Extended':           name = 'Offer';
                                       break;
      case 'Rejected':                 name = 'Declined';
                                       break;
      case 'Accepted - Pending Start': name = 'Accepted';
                                       break;
    }
    return name;
  }
}

export default status
