import React, { useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { PersonOutline } from "@material-ui/icons";
import {
  Grid,
  TextField,
  Box,
} from "@material-ui/core";
import Form from "react-validation/build/form";
import TopLogoLayout from "../../TopLogoLayout/TopLogoLayout";
import PrimaryButton from "../../common/Buttons/PrimaryButton";

import "./ForgotPassword.scss";
import { clearMessage } from "../../../actions/message";
import { Auth } from 'aws-amplify';
import { history } from "../../../helpers/history";
import { setMessage } from "../../../actions/message";
import { SET_USER } from "../../../actions/types";
import { Link } from "react-router-dom";
import API_URL from "../../../helpers/globals";
import userService from "../../../services/user.service";
import userHelper from "../../../helpers/user"
import PhoneVerificationConsent from '../PhoneVerificationConsent/PhoneVerificationConsent';
import LinkButton from "../../common/Buttons/LinkButton";

import axios from "axios";

const ForgotPassword = (props) => {
  const form = useRef();

  const [username, setUsername] = useState("");
  const [fieldError, setFieldError] = useState({});
  const [loading, setLoading] = useState(false);
  const [userType, setUserType] = useState("")
  const { message } = useSelector((state) => state.message);

  const dispatch = useDispatch();


  const onChangeUsername = (e) => {
    const username = e.target.value;
    setUsername(username?.trim());
  };

  const handleFormValidation = () => {
    let errors = {};
    let formIsValid = true;
    if (username.length === 0) {
      errors["username"] = "Username or email is required";
      formIsValid = false;
    }

    setFieldError(errors);
    return formIsValid;
  };

  const updateUserType = async (username) => {
    try {
      let result = await userService.getUserType(username);
      return result
    } catch(e) {
      console.log('Verifying signup method: '+ e);
      setLoading(false);
    }
  }

  const handleForgotPassword = async (e) => {
    e.preventDefault();
    setFieldError({});
    dispatch(clearMessage());
    let username = e.target.elements.username.value.trim()
    const validForm = handleFormValidation(
      username
    );

    if (validForm) {
      setLoading(true);

      let ut = await updateUserType(username);
      setUserType(ut);

      switch(ut) {
        case(userHelper.types.GOOGLE):
        case(userHelper.types.FACEBOOK): handleSocialMediaUser();
                        break;
        case(userHelper.types.INTERNAL): handleInternalUser();
                        break;
        default:  handleNotFoundUser();
                  break;
      }
    }
  };

  const handleInternalUser = async () => {
    try {
      var status = await userService.updateForgotPasswordStatus(username);
      if (status) {
        await Auth.forgotPassword(username);
        dispatch({ type: SET_USER, payload: { username }});
        history.push("/reset");
      } else {
        console.log('error sending forgot password code, forgot password status invalid');
        dispatch(setMessage("Failed to send reset code, invalid status."));
      }

      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log('error sending forgot password code', error);
      dispatch(setMessage("Failed to send reset code"));
    }
  }

  const handleSocialMediaUser = () => {
    setLoading(false);
  }

  const handleNotFoundUser = () => {
    dispatch(setMessage("User not found"));
    setLoading(false);
  }


  return (
    <div className="forgot-password-container">

      <div className="forgot-password-form-container">
        <TopLogoLayout>
        {( (userType === userHelper.types.GOOGLE)  ||
           (userType === userHelper.types.FACEBOOK)) ?
          (
            <section className="forgot-password-form">
              <h1 className="text-lg leading-6 font-medium text-gray-900">Email was registered using {userType}.</h1>
               <p className="pt-4 text-center">
                   Please use
                  <button className="sign-in-btn" onClick={() => { Auth.federatedSignIn({provider: userType})}}>
                    {userType} to sign in
                  </button>.
                </p>
              <p className="pt-4 text-center">
                <Link to="/">
                  Back
                </Link>
              </p>
            </section>
          ) : (
            <Form
              data-testid="form"
              onSubmit={handleForgotPassword}
              ref={form}
              className="forgot-password-form"
            >
              <h1 className="text-lg leading-6 font-medium text-gray-900">Recover Password</h1>
              <p className="text-md leading-6 font-small text-gray-500 text-center">
              Don’t worry, happens to the best of us.
              </p>
              <PhoneVerificationConsent isForgotPassword={true} useCenterTextAlignment={true}></PhoneVerificationConsent>
              <Box width={1} mt={5}>
                <Grid
                  container
                  spacing={1}
                  alignItems="flex-end"
                  justify="center"
                  className="input-field"
                >
                  <Grid item>
                    <PersonOutline className="input-icon" />
                  </Grid>
                  <Grid item xs={10}>
                    <TextField
                      id="username"
                      className="input-field"
                      name="username"
                      label="Username or Email"
                      value={username}
                      onChange={onChangeUsername}
                      error={
                        message !== undefined || fieldError["username"] !== undefined
                      }
                      helperText={fieldError.username ? fieldError.username : ""}
                      fullWidth
                    />
                  </Grid>
                </Grid>
              </Box>

              {message && (
                <div className="form-group">
                  <div className="alert alert-danger text-center p-4 text-sm" role="alert">
                    {message}
                  </div>
                </div>
              )}

              <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                width="80%"
                margin="0 auto"
              >
                <PrimaryButton type="submit" text="Send me a verification code" disabled={loading} loading={loading} buttonClass="color-button btn-link-sm w-full mt-6"/>
              </Box>
              <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                width="80%"
                margin="0 auto"
              >
                <p className="txt-center padding-t-15p text-sm text-gray-500">
                    <span>
                    <LinkButton text="Back to Login" url="/login" textButton />
                    </span>
                </p>
              </Box>
            </Form>
          )}
        </TopLogoLayout>
      </div>
    </div>
  );
};

export default ForgotPassword;
