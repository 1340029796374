import React, { useEffect, useState } from "react";
import { Redirect, useHistory } from "react-router-dom";
import { useSelector, connect } from "react-redux";
import Form from "react-validation/build/form";
import Frame from "../Frame/Frame";

import {
  Box,
  Button,
  Container,
  Drawer,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Tab, 
  Tabs
} from "@material-ui/core";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import { mainStyles } from "../../../mainStyles.js";
import clsx from "clsx";
import axios from "axios";
import API_URL from "../../../../helpers/globals.js";

import UserDataCard from "../CandidateDataCard/UserDataCard";
import "./Users.scss";

const useStyles = mainStyles;

const mapStateToProps = (state) => {
  return {
    currentUser: state.auth,
    navig: state.nav.navig,
  };
};

const Users = (props) => {
  const [state, setState] = useState({
    right: false,
    type: "a",
    updateUser: "",
  }); // Add this
  const [value, setValue] = useState(0);

  const [assessments, setAssessments] = useState({ users: [] }); // Add this

  const history = useHistory();

  useEffect(() => {
    const body = {
      count: 10
    };
    axios
      .post(
        `${API_URL}admin/users`,
        JSON.stringify(body),
        {
          headers: {
            "Content-Type": "application/json",
            "DataProvider": "AdvantisOne"
          }
        }
      )
      .then((json) => {
        if (json.data > "") {
          setAssessments({ ...assessments, 
            userData: { 
              userList: json.data.userList,
              nextPaginationToken: json.data.nextPaginationToken,
              previousPaginationToken: json.data.previousPaginationToken
            }
          });
        }
      })
      .catch((err) => console.log(err));
  }, []);

  const toggleDrawer = (openClose, typ) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setState({ right: openClose, type: typ });
  };

  const closeDrawer = (event) => {
    console.log("Close Drawer");
    setState({ right: false });
  };

  const openDrawer = (ukid) => (event) => {
    console.log(ukid);
    setState({ ...state, right: true, updateUser: ukid });
  };

  const { user: currentUser } = useSelector((state) => state.auth);

  const classes = useStyles();

  const fixedHeightPaper = clsx(classes.paper);

  if (!currentUser) {
    return <Redirect to="/login" />;
  }

  function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box>
            {children}
          </Box>
        )}
      </div>
    );
  }

  function tabProps(index) {
    return {
      id: `tab-${index}`,
      'aria-controls': `tabpanel-${index}`,
    };
  }

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const innerFrame = (
    <div className={classes.root}>
      <Container maxWidth="lg" className={classes.container + ` user-container`}>
        <Grid container spacing={3} className="user-container-body">
          <Grid item xs={12}>
            <UserDataCard handleClick={toggleDrawer} userData={assessments.userData}></UserDataCard>
          </Grid>
        </Grid>
      </Container>

      <Drawer anchor="right" open={state.right}>
        <div width="500px">
          <Box display="flex" className={classes.drawerHeader}>
            <Box p={1} flexGrow={1}>
              <div className={classes.drawHeaderArrow}>
                <ArrowBackIosIcon onClick={closeDrawer}></ArrowBackIosIcon>{" "}
              </div>
            </Box>
            <Box p={1} className={classes.saveBox}>
              <Button className={classes.saveBox}>Save </Button>
            </Box>
          </Box>

          <Form>
            <div className={classes.editMargin}>
              <Grid container spacing={2} className={classes.editGrid}>
                <Grid item xs={12}>
                  <FormControl className={classes.formControl} fullWidth>
                    <InputLabel id="simple-select-label">User Role</InputLabel>
                    <Select
                      labelId="simple-select-label"
                      id="simple-select"
                      value={state.userRole}
                      onChange={(e) => {
                        var o = {
                          key: state.updateUser,
                          value: e.target.value
                        };

                        axios
                          .post(API_URL + "saveadvdata/", {
                            key: "userType",
                            saveData: JSON.stringify(o),
                            token: currentUser.accessToken
                          })
                          .then((response) => {
                            setState({ ...state, userRole: e.target.value });
                          })
                          .catch((err) => console.log(err));
                      }}
                    >
                      <MenuItem value="ROLE_USER">User</MenuItem>
                      <MenuItem value="ROLE_ADMIN">Admin</MenuItem>
                      <MenuItem value="ROLE_ADMINS">Super Admin</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={12}>
                  <FormControl className={classes.formControl} fullWidth>
                    <InputLabel id="simple-select-label">
                      User Active
                    </InputLabel>
                    <Select
                      labelId="simple-select-label"
                      id="simple-select"
                      value={state.userRole}
                      onChange={(e) => {
                        var o = {
                          key: state.updateUser,
                          value: e.target.value
                        };

                        axios
                          .post(API_URL + "saveadvdata/", {
                            key: "userActive",
                            saveData: JSON.stringify(o),
                            token: currentUser.accessToken
                          })
                          .then((response) => {
                            setState({ ...state, userActive: e.target.value });
                          })
                          .catch((err) => console.log(err));
                      }}
                    >
                      <MenuItem value="active">Active</MenuItem>
                      <MenuItem value="notactive">Not Active</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </div>
          </Form>
        </div>
      </Drawer>
    </div>
  );

  return <Frame className="candidate-container" innerFrame={innerFrame}></Frame>;
};

export default connect(mapStateToProps)(Users);
