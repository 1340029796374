import React, { useState } from "react";

import { DocumentIcon, OperatorIcon, UrlIcon } from "../../common/Icons";
import ContactModal from "../../ContactModal/ContactModal";
import PdfModal from "../PdfModal/PdfModal";

import "./Resources.scss";

const Resources = (props) => {
  const [open, setOpen] = useState(false);
  const [openContact, setOpenContact] = useState(false);
  return (
    <>
      <div className="dashboard-box resources">
        <div className="subheader">
          <h5 className="text-lg leading-6 font-medium text-gray-900 ">Resources and guides</h5>
        </div>
        <div className="resources">
          <a href="https://www.advantismed.com/blog/travel-nursing-travel-allied-health-guide" target="__blank">
            <div className="resource">
              <DocumentIcon iconClass="blue-icon" />
              <span className="pl-2 text-sm">Getting Started Traveler Guide</span>
            </div>
          </a>

          <a href="https://www.advantismed.com/blog" target="__blank">
            <div className="resource">
              <UrlIcon />
              <span className="pl-2 text-sm">Our Blog</span>
            </div>
          </a>
        </div>
      </div>
      {open && (
        <PdfModal
          pdf="https://d2wqn2ab54ir3q.cloudfront.net/documents/Travel Nurse and Travel Allied Guide _ Getting Started.pdf"
          open={open}
          close={() => setOpen(false)}
        />
      )}
      {openContact && (
        <ContactModal
          open={openContact}
          handleClose={() => setOpenContact(false)}
        />
      )}
    </>
  );
};

export default Resources;
