import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Redirect } from "react-router-dom";

import LinkButton from "../../common/Buttons/LinkButton";
import ContactBox from "../../common/ContactBox/ContactBox";
import ContactModal from "../../ContactModal/ContactModal";
import "./Welcome.scss";

const Welcome = (props) => {
  const { isLoggedIn } = useSelector((state) => state.auth);
  const [showModal, setModal] = useState(false);

  useEffect(() => {
    if (!isLoggedIn) {
      // console.log("Logged in: " + isLoggedIn);
      return <Redirect to="/login" />;
    }
  }, [isLoggedIn]);

  const openModal = () => {
    setModal(true);
  };

  const closeModal = () => {
    setModal(false);
  };

  return (
    <>
      <div className="welcome-container">
        <ContactBox handleClick={openModal} />
        <div className="welcome-content">
          <h1>Welcome</h1>
          <p>
            Advantis Medical's purpose is to connect Nurses, LPNs, CNAs, and Allied Health
            Professionals to their dream opportunities across the United States
          </p>
          <div className="welcome-links">
            <LinkButton
              data-testid="profile-link"
              text="Complete Profile"
              url="/profile"
            />
            <LinkButton
              data-testid="jobs-link"
              text="See Travel Jobs"
              url="/search"
            />
          </div>
        </div>
      </div>
      <ContactModal open={showModal} handleClose={closeModal} />
    </>
  );
};

export default Welcome;
