import { sendProfileCompleted } from "../services/email";
import { UPDATE_PROGRESS } from "./types";

const calculateProgress =
  (completedNumber, totalNumber) => (dispatch, getState) => {
    dispatch({
      type: UPDATE_PROGRESS,
      payload: { completed: completedNumber, total: totalNumber }
    });
    if (completedNumber === totalNumber) {
      sendProfileCompleted();
    }
  };

export const updateProgress = () => (dispatch, getState) => {
  const { candidateData } = getState();
  const {
    references,
    license,
    skills,
    education,
    certifications,
    work,
    resumes,
    personalInformation
  } = candidateData;

  const { ssn, dateOfBirth } = personalInformation;

  const referenceList = formatList(references);
  const skillsList = formatList(skills);
  const educationList = formatList(education);
  const certificationsList = formatList(certifications);
  const workList = formatList(work);
  const resumeList = formatList(resumes);
  const licenseData = formatObject(license);
  const personal = !ssn || !dateOfBirth ? undefined : "complete";

  const profileFields = [
    referenceList,
    licenseData,
    skillsList,
    educationList,
    certificationsList,
    workList,
    resumeList,
    personal
  ];

  const completed = calculateCompletion(profileFields);
  let completedLength = 0;

  if(skillsList) {
    let completeSkills = skillsList.filter(function(obj) {
      return obj.isComplete == true;
    });

    if(completeSkills.length == 0) {
      completedLength = completed.length - 1;
    } else {
      completedLength = completed.length;
    }
  } else {
    completedLength = completed.length;
  }

  dispatch(calculateProgress(completedLength, profileFields.length));
};

const formatList = (list) => (list.length > 0 ? list : undefined);
const formatObject = (object) =>
  Object.keys(object).length > 0 ? object : undefined;

const calculateCompletion = (array) => {
  return array.filter((value) => value && value);
};
