import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  FormControlLabel,
  Checkbox
} from "@material-ui/core";
import PrimaryButton from "../../common/Buttons/PrimaryButton";

import "./Share.scss";

const Share = ({ openShare, closeShare, path }) => {
  const [copied, setCopied] = useState(false);
  const getFullPathToCopy = () => {
    return path ? window.location.origin + path : window.location.href;
  };

  const copyToClipboard = (e) => {
    navigator.clipboard.writeText(getFullPathToCopy());

    setCopied(true);
  };
  return (
    <Dialog disablePortal open={openShare} onClose={closeShare}>
      <DialogTitle id="alert-dialog-title" className="dialog-title">
        Share this job
        <p className="close-btn" onClick={closeShare}>
          X
        </p>
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Get the link to this page to share with others.
        </DialogContentText>
        <div className="link-share">
          <p>{getFullPathToCopy()}</p>
          <PrimaryButton
            textButton
            text={copied ? "Copied" : "Copy Link"}
            handleClick={copyToClipboard}
          />
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default Share;
