import React from "react";
import { ReactComponent as Logo } from "./tune_black_24dp.svg";
import SvgIcon from "@material-ui/core/SvgIcon";

import "../Icons.scss";

const FilterIcon = (props) => {
  return (
    <Logo />
  );
};

export default FilterIcon;