import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";

import "./ProgressBox.scss";

const ProgressBox = () => {
  const progressData = useSelector((state) => state.profileProgress);
  const [percentage, setPercentage] = useState(undefined);

  const calculatePercentage = (data) => {
    if (data) {
      const percentage = (data.completed / data.total) * 100;
      setPercentage(Math.round(percentage));
    }
  };
  useEffect(() => {
    calculatePercentage(progressData);
  }, [progressData]);

  return (
    <>
      {progressData && (
        <div
          data-testid="progress"
          className={`progress ${percentage < 50 ? "red" : "blue"}`}
        >
          {percentage}% completed
        </div>
      )}
    </>
  );
};

export default ProgressBox;
