import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Form from "react-validation/build/form";
import isEmail from "validator/lib/isEmail";
import isMobilePhone from "validator/lib/isMobilePhone";
import ReactGA from 'react-ga';
import MixPanel from 'mixpanel-browser';
import {
  REGISTER_SUCCESS
} from "../../../../actions/types";
import authService from "../../../../services/auth.service";

import TopLogoLayout from "../../../TopLogoLayout/TopLogoLayout";
import RecruiterAccount from "./RecruiterAccount";
import RecruiterVerifyAccount from "./RecruiterVerifyAccount";
import { recruiterAuthRegister, recruiterLogin } from "../../../../actions/auth";
import userService from "../../../../services/user.service";
import { clearMessage } from "../../../../actions/message";
import { getSpecialties } from "../../../../services/datalist";
import InfoCarousel from "../../../views/InfoCarousel/InfoCarousel";
import { CircularProgress } from "@material-ui/core";
import { v4 as uuidv4 } from 'uuid';
import LinkButton from "../../../common/Buttons/LinkButton";

import "./RecruiterSignup.scss";

const RecruiterSignup = (props) => {
    const [loading, setLoading] = useState(false);
    const [specialties, setSpecialties] = useState([]);
    const [step, setStep] = useState(1);
    const [formData, setFormData] = useState({
        account: {
            cognitoId: "",
            avatar: {},
            biography: "",
            firstName: "",
            lastName: "",
            phone: "",
            email: "",
            password: "",
            passwordConfirm: "",
            assignedTeam: "",
            calendlyLink: "",
            specialtyUidList: [],
            primarySpecialty: "",
            username: ""
        },
        verifyAccount: {
            cognitoId: "",
            username: "",
            password: "",
            email: "",
            phone: "",
            emailAddressConfirmed: false,
            phoneConfirmed: false
        }
    });

    const [formErrors, setErrors] = useState({});
    const states = useSelector((state) => (state.states ? state.states : []));
    const { message } = useSelector((state) => state.message);
    const dispatch = useDispatch();
    const url = props.location.search;
    const registrationId = (new URLSearchParams(url).get('registrationId') == null ? '' : new URLSearchParams(url).get('registrationId'));

    //display error message
    useEffect(() => {
        message && setLoading(false);
        if (message?.includes("Email is already in use")) {
        setErrors({ email: "Email is already in use." });
        }

    }, [message]);

    useEffect(() => {
        if (registrationId) {
          getUserData(registrationId);
        }
    }, [registrationId]);

    //get Specialties List
    useEffect(() => {
        let mounted = true;
        getSpecialties()
        .then((data) => {
            if (mounted) {
                setSpecialties(data);
            }
        })
        .catch((err) => {
            if (mounted) {
            console.log(err);
            }
        });
        return () => (mounted = false);
    }, []);

    const validateEmail = async(value) => {
        let errors = {};
        await validateField('email', value, errors);

        if (Object.keys(errors).length > 0) {
        setErrors(errors);
        }
    };

    const isValidFileName = async(fileName) => {
        const fileNameRegex = /(\..*){2,}/;
        return !fileNameRegex.test(fileName);
      }

    /*
        Submit Functions
    */

    const getGeneratedUsername = () => {
        return `internal_${uuidv4()}`;
    }

    const handleSubmit = async () => {
        dispatch(clearMessage());
        const isValid = await validateStep();
        if (isValid) {
            let email = formData.account.email;
            let password = formData.account.password;

            const {
                avatar,
                biography,
                firstName,
                lastName,
                phone,
                assignedTeam,
                calendlyLink,
                specialtyUidList,
            } = formData.account;

            let username = getGeneratedUsername();

            setLoading(true);
            let response = await dispatch(recruiterAuthRegister({
                    pictureFileName: avatar?.file?.name && isValidFileName(avatar.file.name) ? avatar.file.name : "",
                    pictureFileBase64Str: avatar?.file?.fileBase64Str,
                    biography,
                    firstName,
                    lastName,
                    phone,
                    email,
                    password,
                    assignedTeam,
                    calendlyLink,
                    specialtyUidList,
                    username,
                    emailVerified: false,
                    phoneVerified: false
                })
            );
            if(response) {
                const newState = {
                    account: {
                        ...formData.account,
                        id: response.id,
                        cognitoId: response.cognitoId,
                        avatar: {
                            pictureFileName: avatar?.file?.name && isValidFileName(avatar.file.name) ? avatar.file.name : "",
                            pictureFileBase64Str: avatar?.file?.fileBase64Str,
                        },
                        biography,
                        firstName,
                        lastName,
                        phone,
                        email,
                        password,
                        assignedTeam,
                        calendlyLink,
                        specialtyUidList,
                        username
                    },
                    verifyAccount: {
                        cognitoId: response,
                        username,
                        password,
                        email,
                        phone,
                        emailVerified: false,
                        phoneVerified: false
                    }
                };
                setLoading(false);
                setFormData(newState);
                setStep(2);
            }
        }
    };

    const handleFinalSubmit = async (e) => {
        e.preventDefault();

        dispatch(clearMessage());
        const isValid = formData.verifyAccount.emailVerified && formData.verifyAccount.phoneVerified;
        if (isValid) {
            setLoading(true);
            try {
                ReactGA.event({
                    category: "Recruiter User",
                    action: "Registered an Account"
                });
                MixPanel.track("Recruiter User", { action: "Registered an Account" });

                dispatch({
                    type: REGISTER_SUCCESS,
                    payload: {
                    cognitoId: formData.account.cognitoId,
                    ...formData
                    },
                    username: formData.account.username,
                    password: formData.account.password
                });

                await authService.updateUser(dispatch);
                dispatch(recruiterLogin(formData.account.username, formData.account.password));
              } catch(error) {
                console.log(error);
            }
        }
    };

    const validateStep = async() => {
        setErrors({});
        let errors = {};
        let isValid = true;
        const { account } = formData;

        for (let key in account) {
            await validateField(key, account[key], errors);
        }

        if (Object.keys(errors).length > 0) {
            setErrors(errors);
            isValid = false;
        }
        return isValid;
    };

    const passwordMatch = () => {
        if(formData.account.password == formData.account.passwordConfirm) {
            return true;
        } else {
            return false;
        }
    }

    const validateField = async(type, value, errors) => {
        if (!value && type != "biography" && type != "calendlyLink" && type != "cognitoId" && type !=="username") {
            errors[type] = "required";
            return;
        }

        switch (type) {
        case "email":
            const validEmail = isEmail(value);
            const isValidAdminEmail = /^\"?[\w-_\.]*\"?@advantisglobal|@advantismed\.com$/.test(value);
            if (!validEmail || (validEmail && !isValidAdminEmail)) {
                errors["email"] = "Please enter a valid email";
            } else {
                const userExists = await userService.checkExists(value);
                if (userExists) {
                    errors["email"] = "Email already in use";
                }
            }
            break;
        case "calendlyLink":
            const validCalendlyLink = /(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/.test(value);
            if (value.length && !validCalendlyLink) {
                errors["calendlyLink"] = "Please enter a valid Meeting Link URI, it should include https:// or http://";
            }
            break;
        case "phone":
            const validPhone = isMobilePhone(value);
            if (!validPhone) errors["phone"] = "Please enter a valid phone number";
            break;
        case "assignedTeam":
            const validTeam = value.length !== 0;
            if (!validTeam) errors["assignedTeam"] = "Please select a team";
            break;
        case "firstName:":
            const validFirstName = value.length !== 0;
            if (!validFirstName) errors["firstName:"] = "Please enter your first name.";
            break;
        case "lastName":
            const validLastName = value.length !== 0;
            if (!validLastName) errors["lastName"] = "Please enter your last name.";
            break;
        case "password":
            let containsUpperCase = new RegExp(/([A-Z])+/g);
            let containsLowerCase = new RegExp(/([a-z])+/g);
            let containsNumber = new RegExp(/([0-9])+/g);
            let containsSpecial = new RegExp(/([^\w\s\d])+/g);
            let passwordErrors = [];
            if (!containsUpperCase.test(formData.account.password)) {
            passwordErrors.push("Must contain at least 1 uppercase letter.");
            }
            if (!containsLowerCase.test(formData.account.password)) {
            passwordErrors.push("Must contain at least 1 lowercase letter.");
            }
            if (!containsNumber.test(formData.account.password)) {
            passwordErrors.push("Must contain at least 1 number.");
            }
            if (!containsSpecial.test(formData.account.password)) {
            passwordErrors.push("Must contain at least 1 special character.");
            }
            if (formData.account.password.length < 8) {
            passwordErrors.push("Must be at least 8 characters long.");
            }
            if (passwordErrors.length > 0) {
            errors["password"] = passwordErrors.join(" ");
            }
            break;
        case "passwordConfirm":
            const validPassword = passwordMatch();
            if (!validPassword) errors["password"] = "Passwords do not match";
            break;
        case "specialtyUidList":
            const validSpecialtyList = Boolean(value.length);
            if (!validSpecialtyList) errors["specialtyUidList"] = "Must select at least one specialty";
        default:
        }
    };

    const clearError = (type) => {
        const errors = { ...formErrors };
        delete errors[type];
        setErrors(errors);
    };

    const handleChange = (step, field, value) => {
        setValue(step, field, value);
    };

    const setValue = (step, type, value) => {
        const newState = { ...formData };
        newState[step][type] = value;
        setFormData(newState);
    };

    const getUserData = async (id) => {
        setLoading(true);
        const preRegData = await userService.getRecruiterPreRegistration(id);

        if(preRegData) {
          formData.account.firstName = preRegData.firstName;
          formData.account.lastName = preRegData.lastName;
          formData.account.phone = preRegData.phone;
          formData.account.email = preRegData.email;
          formData.account.specialtyUidList = preRegData.specialtyUidList;
          formData.account.biography = preRegData.biography;
          formData.account.assignedTeam = preRegData.assignedTeam;
          formData.account.calendlyLink = preRegData.calendlyLink;

          setFormData(formData);
        }

        setLoading(false);
      }

    const accountProps = {
        data: formData.account,
        formErrors,
        specialties,
        handleSubmit,
        handleChange,
        clearError,
        validateEmail,
        loading,
        states
    };

    const verifyAccountProps = {
        data: formData.verifyAccount,
        userData: formData.account,
        handleFinalSubmit,
        handleChange,
        loading
    };

    return (
        <div className="signup-container-main recruiter">
            <div className={step === 1 ? "signup-form-container account" : "signup-form-container verify" }>
                <TopLogoLayout>
                <Form className="signup-form">
                    {!loading ? (
                    <>
                        {step === 1 ? <RecruiterAccount {...accountProps} /> : null}
                        {step === 2 ? <RecruiterVerifyAccount {...verifyAccountProps} /> : null}
                    </>
                    ) : (
                    <div style={{height: `80vh`, minWidth: `544px`, textAlign: `center`}}>
                        <h4 style={{marginBottom: `1em`}}>Loading Registration Data</h4>
                        <CircularProgress className="dataLoading" />
                    </div>
                    )}
                    <p className="txt-center padding-t-5p text-sm text-gray-500">
                        Already have an account?
                        <span>
                        <LinkButton text="Log in here" url="/login" textButton />
                        </span>
                    </p>
                </Form>
                </TopLogoLayout>
            </div>
        </div>
    );
};

export default RecruiterSignup;
