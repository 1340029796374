import React from "react";
import { ReactComponent as Logo } from "./add.svg";

import "../Icons.scss";

const AddIcon = (props) => {
  const { iconClass } = props;
  return (
    <Logo className={iconClass} />
  );
};

export default AddIcon;
