import { SET_FAVORITEJOBS, CLEAR_FAVORITEJOBS } from "../actions/types";

const favoriteJobs = (state = null, action) => {
  const { type, payload } = action;
  switch (type) {
    case SET_FAVORITEJOBS:
      return payload;
    case CLEAR_FAVORITEJOBS:
      return null;
    default:
      return state;
  }
};

export default favoriteJobs;
