import React from "react";
import { ReactComponent as Logo } from "./help.svg";
import "../Icons.scss";

const SupportIcon = (props) => {
  const { iconClass } = props;
  return (
    <Logo className={iconClass} />
  );
};

export default SupportIcon;
