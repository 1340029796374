import React from "react";
import { ReactComponent as Logo } from "./date.svg";
import SvgIcon from "@material-ui/core/SvgIcon";

import "../Icons.scss";

const DateIcon = (props) => {
  const { iconClass } = props;
  return <SvgIcon className={iconClass} component={Logo} viewBox="0 0 448 512" />;
};

export default DateIcon;
