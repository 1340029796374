import React from "react";
import { Redirect } from "react-router-dom";
import { useSelector } from "react-redux";
import Frame from "../../Admin/Components/Frame/Frame";

import { globalState } from "../../sharedstate.js";

import {
  Box,
  Button,
  Container,
  Drawer,
  Grid,
  Paper,
  Typography,
  Table,
  TextField,
  ButtonGroup
} from "@material-ui/core";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import { useState } from "react";
import { mainStyles } from "../../mainStyles.js";
import SortableTree, {
  addNodeUnderParent,
  removeNodeAtPath,
  changeNodeAtPath
} from "react-sortable-tree";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle";
import FileExplorerTheme from "react-sortable-tree-theme-file-explorer";
import { useEffect } from "react";
import {
  getAssessments,
  postAssessment,
  deleteSkillAssessment
} from "../../Admin/services/admin";


const useStyles = mainStyles;

const Assessments = () => {
  const [state, setState] = useState({
    right: false,
    type: "a",
    selectedPosition: 0,
    tree: [{ key: 1, name: "", expanded: true, children: [] }],
    selectedAssessment: {},

    assessments: []
  }); // Add this

  const empty = { key: 1, name: "", expanded: true, children: [] };

  useEffect(() => {
    let mounted = true;
    const loadData = async () => {
      const assessmentList = await getAssessments();
      setState({ ...state, assessments: assessmentList });
    };

    if (mounted) {
      loadData();
    }

    return () => {
      mounted = false;
    };
  }, []);

  const saveData = async () => {
    var newAr = state.assessments.slice();
    var edAr = newAr.find((element) => element.id == state.selectedPosition);
    var indxpo = newAr.indexOf(edAr);

    newAr[indxpo] = state.tree[0];
    await postAssessment(state.tree[0]);
    const assessmentList = await getAssessments();
      setState({ ...state, right: false, assessments: assessmentList });
   
  };

  const deleteAssessment = async () => {
    await deleteSkillAssessment(state.selectedAssessment.id);
    var newAr = state.assessments.slice();

    var edAr = newAr.find((element) => element.id == state.selectedPosition);

    var indxpo = newAr.indexOf(edAr);

    if (indxpo > -1) {
      newAr.splice(indxpo, 1);
    }

    setState({ ...state, right: false, assessments: newAr });
  };

  const theCand = useSelector((state) => state.candidateData);

  const toggleDrawer = (openClose, typ) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setState({ right: openClose, type: typ });
  };

  const closeDrawer = (event) => {
    setState({ ...state, right: false });
  };

  const openDrawer = (event, key) => {
    var theKey = key;

    var newAr = state.assessments.slice();

    if (key == 0) {
      newAr.push({ id: theKey, name: "", expanded: true, children: [] });
    }

    var edAr = newAr.find((element) => element.id == theKey);

    var indxpo = newAr.indexOf(edAr);

    var v = [];
    v.push(newAr[indxpo]);

    setState({
      ...state,
      right: true,
      selectedPosition: theKey,
      tree: [edAr],
      selectedAssessment: edAr,
      assessments: newAr
    });
  };

  const { user: currentUser } = useSelector((state) => state.auth);

  const classes = useStyles();

  const getNodeKey = ({ treeIndex }) => treeIndex;

  if (!currentUser) {
    return <Redirect to="/login" />;
  }

  const assessmentList = state.assessments.map((td) => {
    return (
      <tr key={td.id}>
        <td style={{ whiteSpace: "nowrap" }}>{td.name}</td>

        <td>
          <ButtonGroup>
            <Button
              color="primary"
              onClick={(event) => openDrawer(event, td.id)}
            >
              Edit
            </Button>
          </ButtonGroup>
        </td>
      </tr>
    );
  });

  const getTD = (path, node) => {
    var x = addNodeUnderParent({
      treeData: state.tree,
      parentKey: path[path.length - 1],
      expandParent: true,
      getNodeKey,
      newNode: {
        name: ""
      },
      addAsFirstChild: false //treeData.addAsFirstChild,
    }).treeData;

    return x;
  };

  const removeTD = (path, node) => {
    var x = removeNodeAtPath({
      treeData: state.tree,
      path,
      getNodeKey
    });

    return x;
  };

  const innerFrame = (
    <div className={classes.root}>
      <Container maxWidth="lg" className={classes.container}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <div className="float-right">
              <Button color="primary" onClick={(event) => openDrawer(event, 0)}>
                Add Skills Checklist
              </Button>
            </div>
            <h3>Skills Checklist List</h3>
            <Table className="mt-4">
              <thead>
                <tr>
                  <th width="20%">Skills Checklist</th>

                  <th width="10%">Actions</th>
                </tr>
              </thead>
              <tbody>{assessmentList}</tbody>
            </Table>
          </Grid>
        </Grid>

        <Box pt={4}>
          <Typography
            variant="body2"
            color="textSecondary"
            align="center"
          ></Typography>
        </Box>
      </Container>

      <Drawer anchor="right" open={state.right}>
        <div width="500px">
          <Box display="flex" className={classes.drawerHeaderAss}>
            <Box p={1} flexGrow={1}>
              <div className={classes.drawHeaderArrow}>
                <ArrowBackIosIcon onClick={closeDrawer}></ArrowBackIosIcon>{" "}
              </div>
            </Box>
            <Box p={1} className={classes.saveBox}>
              <Button className={classes.saveBox} onClick={saveData}>
                Save{" "}
              </Button>
            </Box>
          </Box>
          <Grid container spacing={2} className={classes.editGridAssessment}>
            <Grid item xs={12}>
              <Paper elevation={0} className={classes.centerPaper}>
                <Button onClick={deleteAssessment}>
                  <Typography variant="body2" style={{ textTransform: "none" }}>
                    Delete Skills Checklist
                  </Typography>
                </Button>
              </Paper>
            </Grid>
            <Grid item xs={12}>
              <Box>
                <Grid container spacing={3} m={3}>
                  <Grid item xs={12}>
                    <div style={{ padding: "10px" }}></div>
                  </Grid>
                </Grid>
              </Box>
            </Grid>

            <Grid item xs={12}>
              <div style={{ height: 800 }}>
                <SortableTree
                  maxDepth={4}
                  className={classes.sortTree}
                  theme={FileExplorerTheme}
                  rowHeight={45}
                  treeData={state.tree}
                  fullWidth
                  canDrop={(hasharr) => {
                    return hasharr.nextPath.length == 1 ? false : true;
                  }}
                  onChange={(td) => setState({ ...state, tree: td })}
                  generateNodeProps={({ node, path }) => ({
                    canDrag: path.length == 1 ? false : true,

                    title: (
                      <TextField
                        placeholder={
                          path.length == 1 ? "Skills Checklist Name" : ""
                        }
                        value={node.name}
                        variant="outlined"
                        size="small"
                        margin="dense"
                        className={classes.input}
                        onChange={(event) => {
                          const name = event.target.value;

                          setState({
                            ...state,
                            tree: changeNodeAtPath({
                              treeData: state.tree,
                              path,
                              getNodeKey: getNodeKey,
                              newNode: { ...node, name }
                            })
                          });
                        }}
                      />
                    ),
                    buttons: [
                      path.length < 4 && (
                        <Button
                          style={{
                            marginTop: "20px",
                            maxWidth: "25px",
                            maxHeight: "25px",
                            minWidth: "25px",
                            minHeight: "25px"
                          }}
                          onClick={() =>
                            setState({ ...state, tree: getTD(path, node) })
                          }
                        >
                          <AddCircleIcon
                            color="primary"
                            style={{
                              maxWidth: "25px",
                              maxHeight: "25px",
                              minWidth: "25px",
                              minHeight: "25px"
                            }}
                          />
                        </Button>
                      ),
                      <Button
                        style={{
                          marginTop: "20px",

                          maxWidth: "25px",
                          maxHeight: "25px",
                          minWidth: "25px",
                          minHeight: "25px"
                        }}
                        onClick={() =>
                          setState({ ...state, tree: removeTD(path, node) })
                        }
                      >
                        <RemoveCircleIcon
                          color="secondary"
                          style={{
                            maxWidth: "25px",
                            maxHeight: "25px",
                            minWidth: "25px",
                            minHeight: "25px"
                          }}
                        />
                      </Button>
                    ]
                  })}
                />
              </div>
            </Grid>
          </Grid>
        </div>
      </Drawer>
    </div>
  );

  return <Frame innerFrame={innerFrame}></Frame>;
};

export default Assessments;
