import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Box,
  TextField,
  FormControl,
  FormControlLabel,
  FormLabel,
  RadioGroup,
  Radio,
  Container
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import DateFnsUtils from "@date-io/date-fns";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider
} from "@material-ui/pickers";
import { Delete } from "@material-ui/icons";

import UploadFile from "../../../common/UploadFile/UploadFile";
import ConfirmationModal from "../../../common/ConfirmationModal/ConfirmationModal";
import PrimaryButton from "../../../common/Buttons/PrimaryButton";

import { deleteData } from "../../../../actions/candidateEntry";
import { getCandidateFile } from "../../../../services/candidateData";

import "./Licenses.scss";

const LicenseForm = ({ change, values = {}, error, clearError }) => {
  const stateOptions = useSelector((state) =>
    state.states ? state.states.map((state) => state.name) : []
  );
  const { candidateData } = useSelector((state) => state);

  const { license } = candidateData;

  const { licenseStateList: states } = values || [];
  const { isCompactLicense: isCompact } = values || false;
  const { expirationDate } = values;
  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const [certId, setCertId] = useState();

  const dispatch = useDispatch();
  const saveFile = (file) => {
    change(file.name, "fileName");
    change(file.fileBase64Str, "fileBase64Str");
  };

  const getFile = (fileId, fileName) => {
    getCandidateFile(fileId, fileName);
  };

  const removeCert = (id) => {
    setCertId(id);
    setConfirmationOpen(true);
  };
  const confirmDeletion = async () => {
    await dispatch(deleteData("certification", certId));
    setConfirmationOpen(false);
  };

  const handleChange = (value, field) => {
    clearError(field);
    change(value, field);
  };

  return (
    <>
      <Container data-testid="license" maxWidth="sm">
        <div className="header">
          <h6 className="text-lg leading-6 font-medium text-gray-900">License</h6>
        </div>
        <div className="edit-card">
          {stateOptions && (
            <Autocomplete
              id="state"
              multiple
              options={stateOptions || []}
              value={states ? states : []}
              getOptionLabel={(option) => (option ? option : "")}
              getOptionSelected={(option, value) => {
                if (value === "") {
                  return true;
                } else if (option === value) {
                  return true;
                }
              }}
              blurOnSelect
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="State*"
                  error={error?.licenseStateList ? true : false}
                  helperText={error?.licenseStateList || "required"}
                  onFocus={() => clearError("licenseStateList")}
                />
              )}
              onChange={(e, val) => change(val, "licenseStateList")}
              fullWidth
            />
          )}

          <FormControl component="fieldset">
            <FormLabel component="legend">
              Is this a compact license (eNLC)?*
            </FormLabel>
            <RadioGroup
              row
              aria-label="compact"
              name="compact"
              value={isCompact === true ? "yes" : "no"}
              onChange={(e) =>
                change(
                  e.target.value === "yes" ? true : false,
                  "isCompactLicense"
                )
              }
            >
              <FormControlLabel
                value="no"
                control={<Radio color="primary" />}
                label="No"
              />
              <FormControlLabel
                value="yes"
                control={<Radio color="primary" />}
                label="Yes"
              />
            </RadioGroup>
          </FormControl>
          {license?.licenseDocs?.length === 0 && (
            <>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  variant="inline"
                  placeholder="MM/DD/YYYY"
                  format="MM/dd/yyyy"
                  margin="normal"
                  id="expirationDate"
                  label="Expiration Date*"
                  autoOk={true}
                  fullWidth
                  value={expirationDate ? expirationDate : null}
                  error={error?.expirationDate ? true : false}
                  helperText={error?.expirationDate}
                  onChange={(e, val) => handleChange(val, "expirationDate")}
                  KeyboardButtonProps={{
                    "aria-label": "change date"
                  }}
                />
              </MuiPickersUtilsProvider>
              <UploadFile
                prefix='license-'
                storeFile={saveFile}
                hideUpload
                error={error?.fileName}
                possibleFileExtensions={["png", "jpg", "jpeg", "gif"]}
              />
            </>
          )}
          {license?.licenseDocs?.length > 0 && (
            <div className="license-docs">
              <div className="mt-4 text-sm font-medium text-gray-500 pt-6">Uploaded</div>
              {license.licenseDocs.map((doc, index) => (
                <div key={index} className="p-4 border border-gray-50 rounded-md ">
                  <div className="pl-3 pr-4 py-3 flex items-center justify-between text-sm">
                    <div className="w-0 flex-1 flex items-center">
                      <svg className="flex-shrink-0 h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M15.172 7l-6.586 6.586a2 2 0 102.828 2.828l6.414-6.586a4 4 0 00-5.656-5.656l-6.415 6.585a6 6 0 108.486 8.486L20.5 13" />
                      </svg>
                      <span className="ml-2 flex-1 w-0 truncate">{doc.fileName}</span>
                    </div>
                  </div>
                  <div className="mt-4 flex-shrink-0 flex">
                    <PrimaryButton
                      buttonClass="link-button"
                      text="Download"
                      handleClick={() => getFile(doc.fileId, doc.fileName)}
                    />
                    <PrimaryButton
                      buttonClass="link-button ml-4 font-medium text-red-700 hover:text-red-600"
                      text="Delete"
                      handleClick={() => removeCert(doc.id)}
                    />
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      </Container>
      {confirmationOpen && (
        <ConfirmationModal
          open={confirmationOpen}
          confirm={() => confirmDeletion()}
          handleClose={() => setConfirmationOpen(false)}
          message="Are you sure you want to delete this License?"
          subMessage="All of your changes will be lost"
          cancelButtonText="Cancel"
        />
      )}
    </>
  );
};

export default LicenseForm;
