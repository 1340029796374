import React from "react";

import { EditIcon, AddIcon } from "../Icons";
import { Box, Grid, styled } from "@material-ui/core";

import "./DetailCard.scss";


const StyledMobileView = styled('div')(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    display: 'flex'
  },
  [theme.breakpoints.up('md')]: {
    display: 'none'
  },
  [theme.breakpoints.up('lg')]: {
    display: 'none'
  },
}));

const StyledDesktopView = styled('div')(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    display: 'none'
  },
  [theme.breakpoints.up('md')]: {
    display: 'flex',
    width: '100%',
    maxWidth: 'inherit'
  },
  [theme.breakpoints.up('lg')]: {
    display: 'flex',
    width: '100%',
    maxWidth: 'inherit'
  }
}));

const DetailCard = ({ title, children, handleClick, add, incomplete, buttonDescription = {}, isHeaderAligned = false }) => {

  const desktopView = (
    <StyledDesktopView>
        {add ? (
          <Box className="add-button">
            <AddIcon iconClass="blue-icon" />
          </Box>
        ) : (
          <Box className="edit-button">
            {buttonDescription.title ? buttonDescription.title : <EditIcon iconClass="blue-icon" />}
          </Box>
        )}
    </StyledDesktopView>
  );

  const mobileView = (
    <StyledMobileView>
        {add ? (
          <AddIcon iconClass="blue-icon" />
        ) : (
          <EditIcon iconClass="blue-icon" />
        )}
    </StyledMobileView>
  );

  return (
    <div className="card-wrapper relative">
      <Grid container>
        {!isHeaderAligned ? (
          <div className="main-card-view">
            <Grid item className="subheader" xs={12} sm={12} md={12} lg={12}>
                <h4 className="text-lg leading-6 font-medium text-gray-900">{title}</h4>
                {incomplete && <p className="alert-danger text-sm font-medium">(Incomplete)</p>}
                <div onClick={handleClick} data-testid="click" className="icon-button header-icon">
                  <AddIcon iconClass="blue-icon" />
                </div>
            </Grid>
            <Grid item className="main-content" xs={12} sm={12} md={12} lg={12}>
              {children}
            </Grid>
          </div>
        ) :
        <>
          <Grid item className="main-content aligned" xs={10} sm={10} md={10} lg={10}>
            {children}
          </Grid>
          <Grid item className="subheader aligned" xs={2} sm={2} md={2} lg={2}>
            {incomplete && <p className="alert-danger text-sm font-medium">(Incomplete)</p>}
            <div onClick={handleClick} data-testid="click" className="icon-button header-icon">
              { mobileView }
              { desktopView }
            </div>
          </Grid>
        </>
        }
      </Grid>
    </div>
  );
};

export default DetailCard;
