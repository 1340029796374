import React, { useState, useEffect } from "react";
import {
  Grid,
  InputLabel,
  MenuItem,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Container,
  RadioGroup,
  Radio
} from "@material-ui/core";
import { getSpecialties } from "../../../../services/datalist";
import Select from "react-select";

import "./Specialties.scss";

const SpecialtyForm = ({ values, change, error, clearError }) => {
  const [specialties, setSpecialties] = useState([]);
  const [currentSpecialtyList, setSpecialtyList] = React.useState([]);
  const { specialtyList, primarySpecialty } = values;

  const getOptionValue = (option) => option;
  const getOptionLabel = (option) => option;

  const changeSpecialty = (value) => {
      setSpecialtyList(value);
  };

  const setList = (curerntList) => {
    let labelList = [];

    if (curerntList.length) {
      for (let j = 0; j < curerntList.length; j++) {
        labelList.push(curerntList[j].name);
      }

      return labelList;
    }
  }

  const getPrimarySpecialty = (data) => {
    let primary = null;

    if (data && data.length) {
      if (specialtyList && specialtyList.length){
        primary = data?.filter((specialty) => {
          return specialty.id  === specialtyList[0].id
        });
      } else {
          primary = data?.filter((specialty) => {
            return specialty.id  === primarySpecialty.id
          });
      }
    }

    return primary && primary[0] && !primary[0].category.includes("Nurse") ? primary[0].category : "Nurse";
  }

  const [primaryCategory, setCategory] = React.useState(specialtyList && specialtyList.length ? getPrimarySpecialty(specialties) : 'Nurse');

  function getModifiedSpecialtyCategory(category) {
    return category === 'Nurse' ? 'Registered Nurse' : category;
  }

  useEffect(() => {
    const updatedValues = { ...values };
    let list = [];

    if (currentSpecialtyList) {
      for (let i = 0; i < specialties.length; i++) {
        for (let j = 0; j < currentSpecialtyList.length; j++) {
          if (specialties[i].label === currentSpecialtyList[j] && specialties[i].category === getModifiedSpecialtyCategory(primaryCategory)) {
            list.push(specialties[i].id);
          }
        }
      }
    }
    change({...updatedValues, primarySpecialty: { id: list.length > 0 ? list[0] : 0 },  specialtyList: list});
  }, [currentSpecialtyList]);

  //get Specialties List
  useEffect(() => {
    let mounted = true;
    getSpecialties()
      .then((data) => {
        if (mounted) {
          setSpecialties(data);
          setSpecialtyList(setList(specialtyList));
          setCategory(getPrimarySpecialty(data))
        }
      })
      .catch((err) => console.log(err));
    return () => (mounted = false);
  }, []);

  return (
    <Container
      width={1}
      className="specialty-edit"
      data-testid="specialties"
      maxWidth="sm"
    >
      <h6 className="text-lg leading-6 font-medium text-gray-900">Specialties</h6>
      <div className="specialty-section primary">
      <Grid container spacing={1}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
                <FormControl
                        error={error?.primaryCategory ? true : false}
                        fullWidth
                    >
                    <p>Are you a Nurse, LPN, CNA, or an Allied Health Professional?</p>
                    <RadioGroup
                        className="primary-speciality-options"
                        row
                        aria-label="compact"
                        name="compact"
                        value={primaryCategory}
                        onChange={(e) => {
                            setSpecialtyList([]);
                            setCategory(e.target.value);
                          }
                        }
                        >
                        <FormControlLabel
                            value="Nurse"
                            control={<Radio color="primary" />}
                            label="Nurse"
                        />
                        <FormControlLabel
                            value="Allied"
                            control={<Radio color="primary" />}
                            label="Allied"
                        />
                        <FormControlLabel
                            value="LPN"
                            control={<Radio color="primary" />}
                            label="LPN"
                        />
                        <FormControlLabel
                            value="CNA"
                            control={<Radio color="primary" />}
                            label="CNA"
                        />
                    </RadioGroup>
                    <FormHelperText>
                    {error?.primaryCategory || "required to select specialties"}
                    </FormHelperText>
                </FormControl>
            </Grid>
            {specialties && (
                <Grid item xs={12} sm={12} md={12} lg={12}>
                <p className="pb-2">What are your Specialities - List of specialties related to Option above (can choose more than one)</p>
                <FormControl
                    error={error?.specialtyList ? true : false}
                    fullWidth
                    disabled={!primaryCategory}
                    variant="outlined"
                    className="speciality-select"
                >
                    <Select
                      data-testid="workSpecialty"
                      id="currentSpecialtyList"
                      name="currentSpecialtyList"
                      options={specialties?.filter((specialty) => {
                          return specialty && specialty.label !== "EKG/Tele Tech" && specialty.category && specialty.category.includes(primaryCategory);
                      }).map((specialty) => (
                          specialty.label
                      ))}
                      isSearchable={false}
                      isMulti
                      onFocus={() => clearError("specialtyList")}
                      value={currentSpecialtyList}
                      getOptionValue={getOptionValue}
                      getOptionLabel={getOptionLabel}
                      onChange={changeSpecialty}
                      placeholder="Select specialties"
                  />
                    <FormHelperText>
                    {error?.specialtyList || "required"}
                    </FormHelperText>
                </FormControl>
                </Grid>
            )}
        </Grid>
      </div>

    </Container>
  );
};

export default SpecialtyForm;
