import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    Avatar,
    Grid,
    Paper
} from "@material-ui/core";

import NavLayout from "../../NavLayout/NavLayout";
import LinkButton from "../../common/Buttons/LinkButton";
import { getCand } from "../../../actions/candidateEntry";
import UserService from "../../../services/user.service";
import Skeleton from "@material-ui/lab/Skeleton";

import './CongratsSignup.scss'

const CongratsSignup = () => {
    const dispatch = useDispatch();
    const { personalInformation } = useSelector((state) => state.candidateData);
    const [loading, setLoading] = useState(false);
    const [recruiter, setRecruiter] = useState(false);
    const [candidate, setCandidateDets] = useState();

    useEffect(() => {
        let mounted = true;

        const loadData = async () => {
            setLoading(true);
            let candidateDetails = await dispatch(getCand());
            setCandidateDets(candidateDetails);

            let recruiterInStorage = localStorage.getItem("userrecruiter");
            let recruiter = recruiterInStorage ? JSON.parse(recruiterInStorage) : null;
            if (recruiter) {
                setRecruiter(recruiter);
                setLoading(false);
                localStorage.removeItem("userrecruiter");
            } else {
                let specialtyIdList = candidateDetails?.basicInfo?.specialtyList?.map(x => x.id);
                assignRecruiter(candidateDetails?.basicInfo?.specialtyList[0].category, specialtyIdList, candidateDetails?.basicInfo.state);
            }
        };

        if (mounted) {
          loadData();
        }

        return () => {
          mounted = false;
        };
      }, []);

    const getRecruiter = async (name, specialty, email, phone, retries) => {
        try {
            let recruiterObj = await UserService.getRecruiterInfo(name, specialty, email, phone);
            console.log(recruiterObj);

            if(recruiterObj.status == 204) {
                if(retries > 0) {
                    getRecruiter(name, specialty, email, phone, retries - 1);
                } else {
                    setRecruiter(null);
                    setLoading(false);
                }
            } else {
                setRecruiter(recruiterObj.data);
                setLoading(false);
            }
        } catch (error) {
            console.log(error);
            if(retries > 0) {
                getRecruiter(name, specialty, email, phone, retries - 1);
            } else {
                setRecruiter(null);
                setLoading(false);
            }
        }
    }

    const assignRecruiter = async (specialtyCategory, specialtyList, state) => {
        try {
            let recruiterObj = await UserService.assignRecruiter(specialtyCategory, specialtyList, state, null);
            console.log(recruiterObj);

            setRecruiter(recruiterObj.data);
            setLoading(false);
        } catch (error) {
            console.log(error);
            setRecruiter(null);
            setLoading(false);
        }
    }

    return (
        <NavLayout headerText="Congrats">
            {!loading ? (
                <Grid container spacing={2} justify="center" className="congrats-landing">
                    {recruiter != null ? (
                        <>
                        <Grid item className="congrats-wrap">
                            <h1>{personalInformation?.firstName != null ? `Congratulations, ${personalInformation?.firstName}!` : "Congratulations!"}</h1>
                            <p>Congrats on becoming part of the Advantis Medical Family, we are happy you are here.<br/>Here is your personal recruiter that we've matched based on your preferences:</p>
                        </Grid>

                        <Grid item className="paper-wrap">
                            <Paper className="recruiter_wrap">
                                <div className="recruiter_img">
                                    <Avatar
                                        alt="recruiter photo"
                                        src={recruiter?.headshot ? `https://d2wqn2ab54ir3q.cloudfront.net/${recruiter.headshot}` : "https://d2wqn2ab54ir3q.cloudfront.net/images/avatar-placeholder.png"}
                                        style={{ width: "80px", height: "80px", margin: "0 auto" }}
                                    />
                                </div>

                                <h3 className="recruiter_name">{recruiter?.recruiter}</h3>

                                <p className="recruiter_info">{recruiter?.email}</p>
                                {/* <p className="recruiter_info">+1 490-283-9873</p> */}

                                <div className="recruiter_tags">
                                    <span className="tag">{candidate?.basicInfo.primarySpecialty.name}</span>
                                    <span className="tag">{candidate?.basicInfo.desiredQuality}</span>
                                    <span className="tag">
                                        {candidate?.basicInfo.desiredLocationList?.length ? (
                                            candidate?.basicInfo.desiredLocationList.map((location, index) => (
                                                <span key={index}>
                                                    {index != 0 ? `,  ${location}` : `${location}`}
                                                </span>
                                            ))) : null
                                        }
                                    </span>
                                </div>

                                <div className="recruiter_schedule">
                                    {/* link to recruiter Calendy */}
                                    {recruiter?.calendly ? (
                                        <a target="_blank" href={recruiter.calendly} className="color-button next-step">Schedule a call</a>
                                    ) : (
                                        <LinkButton url="/support" text="Schedule a call" buttonClass="color-button next-step" />
                                    )}
                                </div>

                                <div className="recruiter_jobs">
                                    <LinkButton url="/dashboard" textButton text="See job matches" />
                                </div>
                            </Paper>
                        </Grid>
                        </>
                    ) : (
                        <>
                            <Grid item className="congrats-wrap">
                                <h1>Something went wrong!</h1>
                                <p>We were unable to find a recruiter at this time.<br/>In the meantime, browse open jobs based on your preferences:</p>
                            </Grid>

                            <Grid item className="paper-wrap">
                                <Paper className="recruiter_wrap">
                                    <div className="recruiter_schedule">
                                        <LinkButton url="/dashboard" text="See job matches" buttonClass="color-button next-step" />
                                    </div>
                                </Paper>
                            </Grid>
                        </>
                    )}
                </Grid>
            ) : (
                <Grid container spacing={2} justify="center" className="congrats-landing">
                    <Grid item className="congrats-wrap">
                        <h1>Hang tight, we're finding your personal recruiter!</h1>
                    </Grid>

                    <Grid item className="paper-wrap skeleton-wrap">
                        <Paper className="recruiter_wrap">
                            <Skeleton className="skeleton" variant="circle" width={80} height={80}></Skeleton>
                            <Skeleton className="skeleton" variant="rect" width={200} height={28}></Skeleton>
                            <Skeleton className="skeleton" variant="rect" width={150} height={18}></Skeleton>
                            <Skeleton className="skeleton" variant="rect" width={150} height={18}></Skeleton>
                        </Paper>
                    </Grid>
                </Grid>
            )}
        </NavLayout>
    )
}

export default CongratsSignup;
