const user = {
  validateEmail: function (str) {
    let res = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    return res.test(str);
  },
  types: {
    GOOGLE: 'Google',
    FACEBOOK: 'Facebook',
    APPLE: 'Apple',
    INTERNAL: 'Internal'
  }
}

export default user
