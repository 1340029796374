import axios from "axios";
import API_URL from "../helpers/globals.js";
import ClientSideDataStorageService from "./client-side-data-storage.service";

export const getSpecialties = async () => {
  return axios
    .get(API_URL + "datalist/AdvantisOneSpecialties",            {
                headers: {
                    "DataProvider": "AdvantisOne"
                }
            })
    .then((data) => data?.data)
    .catch((err) => console.log(err));
};

export const getStates = async () => {
  try {
    let states = ClientSideDataStorageService.getJsonItem('datalist_states');
    if (!states) {
      const response = await axios.get(`${API_URL}datalist/states`);
      if (response) {
        states = response.data;
        ClientSideDataStorageService.setJsonItem('datalist_states', states);
      }
    }

    return states;
  } catch (err) {
    console.log(err);
  }
};

export const getSchools = async (searchText) => {
  try {
    const response = await axios.get(
      `${API_URL}datalist/schools?name=${searchText}`
    );
    if (response) {
      return response.data;
    }
  } catch (err) {
    console.log(err);
  }
};

export const getFacilities = async (searchText) => {
  try {
    const response = await axios.get(
      `${API_URL}datalist/facilities?name=${searchText}`
    );
    if (response) {
      return response.data;
    }
  } catch (err) {
    console.log(err);
  }
};

export const getLocations = async (searchText, excludeStates) => {
  try {
    const response = await axios.get(
      `${API_URL}datalist/locations?input=${searchText}`
    );
    if (response) {
      if (excludeStates) {
        return response.data.filter(x => x.name.indexOf(",") !== -1 || !isNaN(x.name));
      } else {
        return response.data;
      }
    }
  } catch (err) {
    console.log(err);
  }
};

export const getCertifications = async () => {
  try {
    const response = await axios.get(`${API_URL}datalist/certifications`);
    if (response) {
      return response.data;
    }
  } catch (err) {
    console.log(err);
  }
};
