import React from "react";
import { useSelector } from "react-redux";
import {
  Grid,
  TextField,
  Box,
  Select,
  InputLabel,
  MenuItem,
  FormControl,
  FormHelperText,
  RadioGroup,
  Radio,
  FormControlLabel
} from "@material-ui/core";
import {
  PhoneEnabledOutlined,
  PersonOutline,
  MailOutline,
  Notifications
} from "@material-ui/icons";
import { Autocomplete } from "@material-ui/lab";
import InputMask from "react-input-mask";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";

import AvatarC from "../../../../avatar/avatar";
import { LocationIcon } from "../../../common/Icons";
import PhoneVerificationConsent from '../../../views/PhoneVerificationConsent/PhoneVerificationConsent';

import "./PersonalInformation.scss";

const PersonalInformationForm = ({ values, error, change, clearError, errorMessage }) => {
  const { firstName, lastName, city, phone, email, avatar, state, ssn, preferredContactMethod, notificationsActive } =
    values || "";
  const dateOfBirth = values?.dateOfBirth;

  const states = useSelector((state) =>
    state.states ? state.states.map((state) => state.name) : []
  );

  const onCropDefault = (preview, file) => {
    var base64result = preview.split(",")[1];

    file["fileBase64Str"] = base64result;

    change(
      {
        file,
        src: preview
      },
      "avatar"
    );
  };

  return (
    <Box
      width={1}
      data-testid="personalInformation"
      display="flex"
      flexDirection="column"
      className="personal-information"
    >
      <div className="header ">
        <h3 className="text-lg leading-6 font-medium text-gray-900">Personal Information</h3>
      </div>
      <div>
        <p className="text-red-600">{errorMessage}</p>
      </div>
      <Box width={1} display="flex" justify="center" flexDirection="column">
        <Box mt={2}>
          <AvatarC
            width={200}
            height={200}
            onCrop={onCropDefault}
            initialImage={
              avatar?.src && avatar.src.length > 0
                ? avatar.src
                : "https://d2wqn2ab54ir3q.cloudfront.net/images/avatar-placeholder.png"
            }
            className="avatar-edit"
          />
        </Box>

        <Box width={1} display="flex" mb={3}>
          <Grid
            container
            spacing={1}
            alignItems="flex-end"
            justify="center"
            className="input-field"
          >
            <Grid item>
              <PersonOutline className="input-icon" />
            </Grid>
            <Grid item xs={8}>
              <TextField
                id="firstname"
                className="input-field"
                name="firstname"
                label="First Name*"
                value={firstName}
                onChange={(e) => change(e.target.value, "firstName")}
                error={error?.firstName ? true : false}
                helperText={error?.firstName || "required"}
                onFocus={() => clearError("firstName")}
                fullWidth
                inputProps={{maxLength: 50}}
              />
            </Grid>
          </Grid>
          <Grid container spacing={1} alignItems="flex-end" justify="center">
            <Grid item xs={9}>
              <TextField
                id="lastname"
                className="input-field"
                name="lastname"
                label="Last Name*"
                type="text"
                value={lastName}
                onChange={(e) => change(e.target.value, "lastName")}
                fullWidth
                error={error?.lastName ? true : false}
                helperText={error?.lastName || "required"}
                onFocus={() => clearError("lastName")}
                inputProps={{maxLength: 50}}
              />
            </Grid>
          </Grid>
        </Box>
        <Box width={1} display="flex" mb={3}>
          <Grid container spacing={1} alignItems="flex-end" justify="center">
            <Grid item>
              <PhoneEnabledOutlined className="input-icon" />
            </Grid>
            <Grid item xs={10}>
              <InputMask
                data-testid="phone-input"
                mask="999-999-9999"
                value={phone}
                disabled={false}
                onChange={(e) => change(e.target.value, "phone")}
                onFocus={() => clearError("phone")}
                maskChar=" "
              >
                {() => (
                  <TextField
                    id="phone"
                    className="input-field"
                    name="phone"
                    label="Mobile Phone*"
                    type="text"
                    fullWidth
                    error={error?.phone ? true : false}
                    helperText={error?.phone || "required"}
                  />
                )}
              </InputMask>
            </Grid>
          </Grid>
        </Box>
        <Box width={1} display="flex" mb={3}>
          <Grid container spacing={1} alignItems="flex-end" justify="center">
            <Grid item></Grid>
            <Grid item xs={10}>
              <PhoneVerificationConsent isForgotPassword={false} useCenterTextAlignment={false}></PhoneVerificationConsent>
            </Grid>
          </Grid>
        </Box>
        <Box width={1} display="flex" mb={3}>
          <Grid
            container
            spacing={1}
            alignItems="flex-end"
            justify="center"
            className="input-field"
          >
            <Grid item>
              <MailOutline className="input-icon" />
            </Grid>
            <Grid item xs={10}>
              <TextField
                id="email"
                className="input-field"
                name="email"
                label="Email*"
                value={email}
                onChange={(e) => change(e.target.value, "email")}
                error={error?.email ? true : false}
                helperText={error?.email || "required"}
                onFocus={() => clearError("email")}
                fullWidth
                inputProps={{maxLength: 100}}
              />
            </Grid>
          </Grid>
        </Box>
        <Box width={1} mb={3} display="flex" justify="flex-start">
          <Grid
            container
            spacing={2}
            item
            xs={12}
            alignItems="flex-end"
            justify="center"
            className="input-field"
          >
            <Grid item>
              <Notifications className="input-icon" />
            </Grid>
            <Grid item md={5} xs={10} mr={6}>
              <InputLabel id="simple-select-label">
                Job Notifications*
              </InputLabel>
              <RadioGroup
                row
                aria-label="notificationsActive"
                name="notificationsActive"
                value={notificationsActive === true ? "enabled" : "disabled"}
                onChange={(e) =>
                  change(
                    e.target.value === "enabled" ? true : false,
                    "notificationsActive"
                  )
                }
              >
                <FormControlLabel
                  value="disabled"
                  control={<Radio color="primary" />}
                  label="Disabled"
                />
                <FormControlLabel
                  value="enabled"
                  control={<Radio color="primary" />}
                  label="Enabled"
                />
              </RadioGroup>
            </Grid>
            <Grid item md={5} xs={10} mr={6}>
              <FormControl fullWidth>
                <InputLabel id="simple-select-label">
                  Preferred contact method*
                </InputLabel>
                <Select
                  label="Preferred contact method*"
                  id="preferredContactMethod"
                  value={preferredContactMethod}
                  data-testid="contact"
                  onFocus={() => clearError("preferredContactMethod")}
                  onChange={(e) =>
                    change(e.target.value, "preferredContactMethod")
                  }
                >
                  <MenuItem value="Email">Email</MenuItem>
                  <MenuItem value="Text">Text</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </Box>
        <Box width={1} display="flex" justify="center" mb={3}>
          <Grid
            container
            spacing={2}
            item
            xs={12}
            alignItems="flex-end"
            justify="center"
            className="input-field"
          >
            <Grid item>
              <LocationIcon iconClass="input-icon" />
            </Grid>
            <Grid item xs={5} mr={6}>
              <TextField
                id="city"
                name="city"
                label="City*"
                value={city}
                onChange={(e) => change(e.target.value, "city")}
                error={error?.city ? true : false}
                helperText={error?.city || "required"}
                onFocus={() => clearError("city")}
                fullWidth
                inputProps={{maxLength: 100}}
              />
            </Grid>
            {states && (
              <Grid item xs={5}>
                {states && (
                  <FormControl fullWidth error={error?.state ? true : false}>
                    <InputLabel id="state-label">State*</InputLabel>
                    <Select
                      label="State*"
                      id="state-select"
                      value={state ? state : ""}
                      data-testid="state"
                      onChange={(e, val) => change(e.target.value, "state")}
                      onFocus={() => clearError("state")}
                    >
                      {states?.map((state) => (
                        <MenuItem key={state} value={state}>
                          {state}
                        </MenuItem>
                      ))}
                    </Select>
                    <FormHelperText>
                      {error?.state || "required"}
                    </FormHelperText>
                  </FormControl>
                )}
              </Grid>
            )}
          </Grid>
        </Box>
        <Box width={1} display="flex" justify="center" mb={3}>
          <Grid
            container
            spacing={1}
            item
            xs={12}
            alignItems="flex-end"
            justify="center"
            className="input-field"
          >
            <Grid item xs={6} style={{ marginLeft: "2em" }}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  variant="inline"
                  format="MM/dd/yyyy"
                  placeholder="MM/DD/YYYY"
                  id="dob"
                  label="Date of Birth"
                  margin="normal"
                  autoOk={true}
                  value={dateOfBirth || null}
                  onChange={(e, val) => change(val, "dateOfBirth")}
                  onFocus={() => clearError("dateOfBirth")}
                  error={error?.dateOfBirth ? true : false}
                  helperText={error?.dateOfBirth || "required"}
                  required
                  fullWidth
                  KeyboardButtonProps={{
                    "aria-label": "change date"
                  }}
                />
              </MuiPickersUtilsProvider>
            </Grid>
            <Grid item xs={4}>
              <InputMask
                mask="9999"
                value={ssn}
                disabled={false}
                onChange={(e) => change(e.target.value, "ssn")}
                onFocus={() => clearError("ssn")}
                maskChar="*"
              >
                {() => (
                  <TextField
                    label="Last 4 of SSN*"
                    data-testid="ssn"
                    margin="normal"
                    error={error?.ssn ? true : false}
                    helperText={error?.ssn || "required"}
                    fullWidth
                  />
                )}
              </InputMask>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Box>
  );
};

export default PersonalInformationForm;
