import React from "react";
import { ReactComponent as HeartLogo } from "./heart2.svg";

import "../Icons.scss";

const HeartIcon = (props) => {
  const { iconClass, active } = props;
  return (
    <HeartLogo
      className={iconClass}
    />
  );
};

export default HeartIcon;
