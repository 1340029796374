import React from "react";
import {
  CircularProgress
} from "@material-ui/core";

import "./Buttons.scss";

const PrimaryButton = (props) => {
  const { type, disabled, text, handleClick, buttonClass, loading } = props;

  const click = (e) => {
    e.target.blur();
    handleClick && handleClick();
  };
  return (
    <button
      type={type}
      className={buttonClass ? buttonClass : "color-button"}
      disabled={disabled}
      onClick={(e) => click(e)}
    >
      {!loading ? text : <CircularProgress size={24} className="" />}
    </button>
  );
};

export default PrimaryButton;
