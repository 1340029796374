import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Button, Grid } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import ProgressBar from "../../ProgressBar/ProgressBar";
import { getCandidateFile, updateCandidateData } from "../../../../services/candidateData";
import { getCand, formatCandidateData } from "../../../../actions/candidateEntry";
import DownloadIcon from "@material-ui/icons/GetApp";
import { notify } from "../../../../toasts/toasts";

import DetailCard from "../../../common/DetailCard/DetailCard";

import "./SkillsChecklistDetails.scss";

const SkillsChecklistDetails = ({ handleClick }) => {
  const { skills } = useSelector((state) => state.candidateData);
  const [downloadingList, setDownloadingList] = useState([]);
  const history = useHistory();
  const dispatch = useDispatch();

  const downloadSkill = async(assessmentId, fileId, fileName) => {
    let newDownloadingList = downloadingList.slice();
    newDownloadingList.push(assessmentId);
    setDownloadingList(newDownloadingList.slice());

    if (!fileId) {
      await updateCandidateData("skillfile", {AssessmentId: assessmentId}, true);
      let candidateData = await dispatch(getCand());
      let formattedCandidateData = formatCandidateData(candidateData, skills);
      let skill = formattedCandidateData.skills.find(x => x.assessmentId === assessmentId);
      fileId = skill?.fileId;
      fileName = skill?.fileName;
    }

    if (fileId && fileName) {
      getCandidateFile(fileId, fileName);
    } else {
      notify("Error downloading file.", "error");
    }

    let assessmentIndex = newDownloadingList.indexOf(assessmentId);
    newDownloadingList.splice(assessmentIndex, 1);
    setDownloadingList(newDownloadingList.slice());
  }

  const isDownloading = (assessmentId) => {
    return downloadingList && downloadingList.indexOf(assessmentId) !== -1;
  }

  return (
    <div data-testid="skill-details" className="skill-details-card">
      {skills && (
        <DetailCard title="Skills Checklist" handleClick={handleClick}>
          <div className="skill-details-description text-md leading-6 font-small text-gray-500">Skills checklists help recuiters better match you with proper jobs. Take a skills checklist now.</div>
          {skills.map((skill, index) => (
            <div className="details-card" key={index}>
              <div className="skill" key={index}>
                <Grid container spacing={2} className="progress-card-grid-container">
                    <Grid item xs={3} sm={3} md={3} lg={3} className="name">
                      <div className="text-sm font-smalltext-gray-900">{skill.assessmentName}</div>
                      {skill.isComplete && skill.createdDate && (
                          <div>&nbsp;</div>
                        )}
                    </Grid>
                    <Grid item xs={5} sm={5} md={5} lg={5}>
                        <ProgressBar percentage={skill.percentageComplete.toFixed(0).replace(/[.,]00$/, "") + "%"} className="progress-bar"/>
                        {skill.isComplete && skill.createdDate && (
                          <div className="completion-date">Completion Date: {new Date(skill.createdDate).toLocaleDateString()}</div>
                        )}
                    </Grid>
                    <Grid item xs={2} sm={2} md={2} lg={2}>
                        <div className="percentage-label">{skill.percentageComplete.toFixed(0).replace(/[.,]00$/, "") + "%"} <span className="hide-sm">completed</span></div>
                        {skill.isComplete && skill.createdDate && (
                          <div>&nbsp;</div>
                        )}
                    </Grid>
                    <Grid item xs={2} sm={2} md={2} lg={2} className="manage-skill-button-container">
                      <Button variant="text" onClick={() => history.push(`/skill/${skill.assessmentId}`)}>
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M5 3L19 12L5 21V3Z" stroke="#008DD1" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                        </svg>
                      </Button>
                      {skill.isComplete && (
                        <Button variant="text" onClick={() => downloadSkill(skill.assessmentId, skill.fileId, skill.fileName)} disabled={isDownloading(skill.assessmentId)}>
                          <DownloadIcon style={{ fill: isDownloading(skill.assessmentId) ? '#8D9292' : '#008DD1' }}></DownloadIcon>
                        </Button>
                      )}
                    </Grid>
                </Grid>
              </div>
            </div>
          ))}
        </DetailCard>
      )}
    </div>
  );
};

export default SkillsChecklistDetails;
