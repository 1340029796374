import React, { useState } from "react";

import PrimaryButton from "../../common/Buttons/PrimaryButton";
import LinkButton from "../../common/Buttons/LinkButton";
import ReferModal from "./ReferModal";

import "./Refer.scss";

const Refer = (props) => {
  const [open, setOpen] = useState(false);

  const logo = "https://d2wqn2ab54ir3q.cloudfront.net/images/refer.png";

  return (
    <>
      <div className="refer-banner">
        <div className="refer-img">
          <img src={logo}></img>
        </div>
        <div className="refer-content">
          <h6>Refer a friend and earn</h6>
          <div className="row">
            <h5>$500</h5>
            <a
              href="https://www.advantismed.com/referrals"
              target="__blank"
              rel="noopener"
            >
              <PrimaryButton
                buttonClass="inverse-button"
                text="Invite Friends"
                // handleClick={() => setOpen(true)}
              />
            </a>
          </div>
        </div>
      </div>
      {open && <ReferModal open={open} handleClose={() => setOpen(false)} />}
    </>
  );
};

export default Refer;
