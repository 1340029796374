import React from "react";
import { ReactComponent as Logo } from "./timer.svg";
import SvgIcon from "@material-ui/core/SvgIcon";

import "../Icons.scss";

const TimerIcon = (props) => {
  const { iconClass } = props;
  return (
    <SvgIcon className={iconClass} component={Logo} viewBox="0 0 612 612" />
  );
};

export default TimerIcon;
