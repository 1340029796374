import React, { useEffect, useState } from "react";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import Form from "react-validation/build/form";
import { Drawer, CircularProgress } from "@material-ui/core";
import { ArrowBackIos } from "@material-ui/icons";
import CloseIcon from "@material-ui/icons/Close";

import CandidateForm from "../../common/Candidate/CandidateForm/CandidateForm";
import ConfirmationModal from "../../common/ConfirmationModal/ConfirmationModal";
import { deleteData } from "../../../actions/candidateEntry";

import "./FormDrawer.scss";

import ModalFooter from "../../common/ModalFooter/ModalFooter";
import { cleanObject, cleanArray, arraysEqual } from "./helpers";

var _ = require("lodash");

const FormDrawer = ({
  open,
  handleClose,
  type,
  handleSave,
  saving,
  clearError,
  formError
}) => {
  const data = useSelector((state) => state.candidateData);

  const formData = _.cloneDeep(data[type]);
  const dispatch = useDispatch();
  const [openConfirm, setOpenConfirm] = useState(false);
  const [isDisabled, setDisabled] = useState(false);
  const setFormState = (data) => {
    if (type === "certifications") {
      return { certification: {} };
    } else if (type === "skills") {
      return { skills: [] };
    } else {
      return data;
    }
  };
  const [fieldState, setFieldState] = useState(
    setFormState({ [type]: formData })
  );

  useEffect(() => {
    setFieldState(setFormState({ [type]: formData }))
  }, [data])

  useEffect(() => {
    setDisabled(checkDisabled());
  }, [saving, fieldState[type]])

  const handleChange = (value, field) => {
    switch (type) {
      case "personalInformation":
        setFieldState((prevState) => ({
          personalInformation: {
            ...prevState.personalInformation,
            [field]: value
          }
        }));
        break;
      case "jobPreferences":
        setFieldState((prevState) => ({
          jobPreferences: {
            ...prevState.jobPreferences,
            [field]: value
          }
        }));
        break;
      case "certifications":
        setFieldState((prevState) => ({
          certifications: {
            ...prevState.certifications,
            [field]: value
          }
        }));
        break;
      case "license":
        setFieldState((prevState) => ({
          license: {
            ...prevState.license,
            [field]: value
          }
        }));
        break;
      case "resumeUpload":
        setFieldState((prevState) => ({
          resumeUpload: {
            ...prevState.resumeUpload,
            [field]: value
          }
        }));
        break;
      default:
        if (value.length === 0) value = undefined;
        setFieldState({
          [type]: value
        });
    }
  };
  const handleDelete = async (id) => {
    await dispatch(deleteData(type, id));
  };

  const addCard = () => {
    let newEntry;

    switch (type) {
      case "education":
        newEntry = {
          school: "",
          state: "",
          degree: "",
          graduationDate: null
        };

        break;
      case "work":
        newEntry = {
          position: "",
          facility: "",
          location: "",
          unitBed: "",
          startDate: null,
          endDate: null,
          isCurrent: false,
          specialty: "",
          unit: ""
        };
        break;

      case "references":
        newEntry = {
          firstName: "",
          lastName: "",
          jobTitle: "",
          preferredContactMethod: "",
          email: "",
          phone: "",
          companyName: "",
          ratio: ""
        };
        break;
      default:
    }
    return newEntry;
  };

  const cancelChanges = () => {
    if (type === "skills" && fieldState[type].length === 0) {
      closeModals();
      return;
    }

    if (type === "certifications" && !fieldState[type]) {
      closeModals();
      return;
    }
    if (
      Array.isArray(fieldState[type]) && Array.isArray(formData) &&
      arraysEqual(formData, cleanArray(fieldState[type]))
    ) {
      closeModals();
      return;
    }
    if (type === "work"){
      const workArray = cleanArray(fieldState[type]);
      if(!workArray || workArray && workArray.length === 1 && Object.keys(workArray[0]).length === 1) {
        closeModals();
        return;
      }
    }
    if (JSON.stringify(cleanObject(formData)) === JSON.stringify(cleanObject(fieldState[type]))) {
      closeModals();
      return;
    }
    setOpenConfirm(true);
  };

  const closeModals = () => {
    setFieldState(null);
    handleClose();

    setOpenConfirm(false);
  };

  const checkDisabled = () => {
    if (saving === true) {
      return true;
    } else if (Array.isArray(fieldState[type])){
      if (fieldState[type].length === 0) return true;
    } else if (fieldState[type] && Object.keys(fieldState[type]).length === 0){
      return true;
    } else if (checkDisabledForAdmin()) {
      return true;
    } else {
      return false;
    }
  }

  let checkDisabledForAdmin = () => {
    return (type === "personalInformation" && sessionStorage.getItem("BecomeUser"))
  }

  return (
    <div className="profile-modal drawer">
      <Drawer
        anchor={'right'}
        open={open}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        className="modal"
      >
        <div className="modal-content white-background">
          {saving ? (
            <div className="spinner">
              <CircularProgress size={80} />
              <p>Saving...</p>
            </div>
          ) : (
            <>
              <div className="back" onClick={cancelChanges}>
                <CloseIcon className="icon-button" />
              </div>

              <CandidateForm
                type={type}
                error={formError}
                change={handleChange}
                handleDelete={handleDelete}
                values={fieldState && fieldState[type]}
                addForm={addCard}
                clearError={clearError}
                errorMessage={checkDisabledForAdmin()?'Only the candidate may change this information.':null}
              />
            </>
          )}
        </div>
        <ModalFooter
            handleClose={cancelChanges}
            handleSave={() => handleSave(type, fieldState)}
            disableCancel={saving}
            disableSave={isDisabled}
            closeButtonText="Cancel"
            saveButtonText="Save"
        />
      </Drawer>

      {openConfirm && (
        <ConfirmationModal
          open={openConfirm}
          confirm={closeModals}
          handleClose={() => setOpenConfirm(false)}
          message="Are you sure you want to close this page?"
          subMessage="All your changes will be lost"
          cancelButtonText="Cancel"
        />
      )}
    </div>
  );
};

export default FormDrawer;
