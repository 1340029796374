import React from "react";
import { ReactComponent as Logo } from "./chat.svg";
import { ReactComponent as LogoCircle } from "./message-circle.svg";
import SvgIcon from "@material-ui/core/SvgIcon";

import "../Icons.scss";

const ChatIcon = ({ iconClass, outline }) => (
  <SvgIcon className={iconClass} component={outline? LogoCircle : Logo} />
);

export default ChatIcon;
