import React from "react";


const CloseButton = ({ handleClick, text }) => {
  return (
    <button className="close-btn" onClick={handleClick}>
      <svg className="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12"></path>
      </svg>
    </button>
  );
};

export default CloseButton;
