import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Box, styled, Grid, Tab, Tabs } from "@material-ui/core";

import AddButton from "../common/Buttons/AddButton";
import ProgressCard from "../common/ProgressCard/ProgressCard";
import ProgressCheckboxCard from "../common/ProgressCheckboxCard/ProgressCheckboxCard";
import AddTileButton from "../common/Buttons/AddTileButton";
import FormDrawer from "./FormDrawer/FormDrawer";
import Hidden from "@material-ui/core/Hidden";

import "./CandidateData.scss";

import {
  validateCertification,
  validateLicense,
  validateWork,
  validatePersonalInformation,
  validateEducation,
  validateReferences,
  validateResumeUpload,
  validateSpecialties,
  validateJobPreferences,
  validateSkills
} from "./validation";
import { updateData } from "../../actions/candidateEntry";
import { getStatesList } from "../../actions/datalists";

import CandidateDetails from "../common/Candidate/CandidateDetails/CandidateDetails";

const CandidateData = ({ showOptional, pageView }) => {
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.candidateData);

  useEffect(() => {
    dispatch(getStatesList());
  }, []);

  const {
    personalInformation,
    professionalSummary,
    jobPreferences,
    references: referenceList,
    license,
    skills,
    education,
    specialties,
    certifications: certificationList,
    resumes: resumeList,
    work: workList
  } = userData;

  const [showModal, setModal] = useState(false);
  const [modalType, setModalType] = useState(null);

  const [isSaving, setIsSaving] = useState(false);
  const [formError, setFormError] = useState();

  const StyledDesktopMobileView = styled('div')(({ theme }) => ({
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      width: '100%',
      maxWidth: 'inherit'
    },
    [theme.breakpoints.up('md')]: {
      display: 'flex',
      width: '100%',
      maxWidth: 'inherit'
    },
    [theme.breakpoints.up('lg')]: {
      display: 'flex',
      width: '100%',
      maxWidth: 'inherit'
    }
  }));

  const StyledMobileTabletProgressView = styled('div')(({ theme }) => ({
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      width: '100%',
      maxWidth: 'inherit'
    },
    [theme.breakpoints.up('md')]: {
      display: 'flex',
      width: '100%',
      maxWidth: 'inherit'
    },
    [theme.breakpoints.up('lg')]: {
      display: 'none'
    }
  }));

  const StyledDesktopProgressView = styled('div')(({ theme }) => ({
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    },
    [theme.breakpoints.up('md')]: {
      display: 'none'
    },
    [theme.breakpoints.up('lg')]: {

      display: 'contents',
      width: '100%',
      maxWidth: 'inherit'
    }
  }));

  const personalButtons = [
    {
      text: "Personal Information",
      type: "personalInformation",
      data: personalInformation
    },
    {
      text: "Education",
      type: "education",
      data: education && education.length > 0 && education
    },
    {
      text: "Specialties",
      type: "specialties",
      data:  specialties && specialties.primarySpecialty && specialties
    },
    {
      text: "Your job preferences",
      type: "jobPreferences",
      data:
        jobPreferences &&
        Object.keys(jobPreferences).length > 0 &&
        jobPreferences
    },
    {
      text: "Professional Summary",
      type: "professionalSummary",
      data:
        professionalSummary &&
        professionalSummary.length > 0 &&
        professionalSummary
    },
    {
      text: "Work Experience",
      type: "work",
      data: workList && workList.length > 0 && workList
    }
  ]

  const documentButtons = [
    {
      text: "Import resume/CV",
      type: "resumeUpload",
      data: resumeList && resumeList.length > 0 && resumeList
    },
    {
      text: "Certifications",
      type: "certifications",
      data:
        certificationList && certificationList.length > 0 && certificationList
    },
    {
      text: "License",
      type: "license",
      data: license && license.licenseStateList && license
    },
    {
      text: "References",
      type: "references",
      data: referenceList && referenceList.length > 0 && referenceList
    },
    {
      text: "Skills Checklist",
      type: "skills",
      data: skills && skills.length > 0 && skills && skills[skills.length - 1].isComplete
    }
  ]

  const requiredButtons = [
    {
      description: "",
      text: "Skills Checklist",
      type: "skills",
      data: skills && skills.length > 0 && skills
    },
    {
      description: "Add any <section specific description> here",
      text: "Import resume/CV",
      type: "resumeUpload",
      data: resumeList && resumeList.length > 0 && resumeList
    },
    {
      description: "Add any <section specific description> here",
      text: "Work Experience",
      type: "work",
      data: workList && workList.length > 0 && workList
    },
    {
      description: "Add any <section specific description> here",
      text: "References",
      type: "references",
      data: referenceList && referenceList.length > 0 && referenceList
    },
    {
      description: "Add any <section specific description> here",
      text: "License",
      type: "license",
      data: license && license.licenseStateList && license
    },
    {
      description: "Add any <section specific description> here",
      text: "Education",
      type: "education",
      data: education && education.length > 0 && education
    },
    {
      description: "Add any <section specific description> here",
      text: "Certifications",
      type: "certifications",
      data:
        certificationList && certificationList.length > 0 && certificationList
    }
  ];

  const optionalButtons = showOptional
    ? [
        {
          description: "Add any <section specific description> here",
          text: "Specialties",
          type: "specialties",
          data: specialties && specialties.primarySpecialty && specialties
        },
        {
          description: "Add any <section specific description> here",
          text: "Professional Summary",
          type: "professionalSummary",
          data:
            professionalSummary &&
            professionalSummary.length > 0 &&
            professionalSummary
        },
        {
          description: "Add any <section specific description> here",
          text: "Your job preferences",
          type: "jobPreferences",
          data:
            jobPreferences &&
            Object.keys(jobPreferences).length > 0 &&
            jobPreferences
        }
      ]
    : [];

  const details = [
    { type: "personalInformation", data: personalInformation },
    ...requiredButtons,
    ...optionalButtons
  ];

  const checkCompleted = (array) => {
    let completed = true;
    array.forEach((field) => {
      if (!field.data) completed = false;
    });
    return completed;
  };

  const openModal = (type) => {
    setModal(true);
    setModalType(type);
  };

  const closeModal = () => {
    setFormError();
    setModal(false);
  };

  const saveField = async (type, data) => {
    let validData = { valid: true };
    setFormError();

    if (type === "certifications") {
      validData = validateCertification(data?.certifications);
    }
    if (type === "license") {
      validData = validateLicense(data?.license);
    }
    if (type === "work") {
      validData = validateWork(data?.work);
    }

    if (type === "personalInformation") {
      validData = validatePersonalInformation(data?.personalInformation);
    }
    if (type === "education") {
      validData = validateEducation(data?.education);
    }
    if (type === "references") {
      validData = validateReferences(data?.references);
    }
    if (type === "resumeUpload") {
      validData = validateResumeUpload(data?.resumeUpload);
    }

    if (type === "specialties") {
      validData = validateSpecialties(data?.specialties);
    }

    if (type === "jobPreferences"){
      validData = validateJobPreferences(data?.jobPreferences);
    }

    if (type === "skills"){
      validData = validateSkills(data?.skills);
      delete data?.skills.incomplete;
    }

    setFormError(validData.errors);
    if (validData.valid) {
      setIsSaving(true);
      if (type === "skills" && data.skills && data.skills.length > 1){
        await dispatch(updateData(type, data));
      } else {
        await dispatch(updateData(type, data));
      }

      if (type === "personalInformation") {
        localStorage.setItem("skipCongrats", true);
      }

      setIsSaving(false);
      closeModal();
    } else {
    }
  };

  const clearError = (type) => {
    const errors = { ...formError };
    if (errors[type]) delete errors[type];
    setFormError(errors);
  };

  const clearWorkError = (index, type) => {
    const errors = { ...formError };

    const workErrors = errors[`${index}`];
    if (workErrors) delete workErrors[type];
    setFormError(errors);
  };

  const sectionIsNotOfType = (section) => {
    return (
      section.type !== 'personalInformation' &&
      section.type !== 'professionalSummary' &&
      section.type !== 'jobPreferences' &&
      section.type !== 'specialties' &&
      section.type !== 'resumeUpload'
    );
  }

  const handleOpenModal = (type) => {
    if(type) {
      openModal(type);
    }
  }

  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box>
            {children}
          </Box>
        )}
      </div>
    );
  }

  function tabProps(index) {
    return {
      id: `tab-${index}`,
      'aria-controls': `tabpanel-${index}`,
    };
  }

  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const desktopMobileView = (
    <StyledDesktopMobileView>
      <div className="information-wrapper desktop-view">
        <Grid container>
          <Grid item xs={12} sm={12} md={12} lg={9} className="main-container">
            <Box width={1} className="profile-fields">
                <CandidateDetails
                  detailType={'personalInformation'}
                  handleClick={() => openModal('personalInformation')}
                />
                <Tabs
                  className="tabs-container"
                  value={value}
                  onChange={handleChange}
                  textColor="primary"
                  indicatorColor="primary"
                  aria-label="basic tabs example">
                    <Tab label="Profile" {...tabProps(0)} />
                    <Tab label="Preferences" {...tabProps(1)} />
                    <Tab label="Credentials" {...tabProps(2)} />
                </Tabs>
                <TabPanel value={value} index={0}>
                  <Grid container>
                    <StyledMobileTabletProgressView className="tablet-mobile-view">
                      <Grid item xs={12} sm={12} md={12} className="checkbox-container">
                          <div className="information-toggle-options desktop-view">
                            <Box className="detail-toggle-container">
                              <ProgressCard></ProgressCard>
                            </Box>
                            <Box>
                              {!requiredButtons[1].data && requiredButtons ?
                                <>
                                  <AddTileButton
                                    secondaryText={requiredButtons[1].description}
                                    data-testid={`add-resumeUpload-desktop`}
                                    text={requiredButtons[1].text}
                                    handleClick={() => openModal('resumeUpload')}
                                  />
                                </>
                                :
                                <CandidateDetails
                                  detailType={'resumeUpload'}
                                  handleClick={() => openModal('resumeUpload')}
                                />
                              }
                            </Box>
                          </div>
                      </Grid>
                    </StyledMobileTabletProgressView>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12} className="additional-fields">
                    {details?.map(
                      (section, index) =>
                        section.data  && sectionIsNotOfType(section) && (
                            <CandidateDetails
                              key={index}
                              detailType={section.type}
                              handleClick={() => openModal(section.type)}
                            />
                          )
                    )}
                    {showOptional && !optionalButtons[0].data && optionalButtons && !checkCompleted(optionalButtons) ?
                      <>
                        <AddTileButton
                          secondaryText={optionalButtons[0].description}
                          data-testid={`add-specialties-desktop`}
                          text={optionalButtons[0].text}
                          handleClick={() => openModal('specialties')}
                        />
                      </>
                      :
                      <CandidateDetails
                        detailType={'specialties'}
                        handleClick={() => openModal('specialties')}
                      />}
                    {!checkCompleted(requiredButtons) && requiredButtons && (
                      <>
                        <h3 className="category text-md leading-6 font-medium text-gray-900 pr-4">Required</h3>
                        <div className="add-container required">
                          {requiredButtons.map((button, index) => (
                            <div className="add-wrapper" key={index}>
                              {!button.data && (
                                <AddTileButton
                                  secondaryText={button.description}
                                  data-testid={`add-${button.type}-desktop`}
                                  text={button.text}
                                  handleClick={() => openModal(button.type)}
                                />
                              )}
                            </div>
                          ))}
                        </div>
                      </>
                    )}
                  </Grid>
                </TabPanel>
                <TabPanel value={value} index={1}>
                  <Grid item xs={12} sm={12} md={12} lg={12} className="personal-fields">
                    <Grid container spacing={3}>
                      <Grid item xs={12} sm={12} md={12} lg={12}>
                        {showOptional && !optionalButtons[2].data && optionalButtons && !checkCompleted(optionalButtons) ?
                          <AddTileButton
                            secondaryText={optionalButtons[2].description}
                            data-testid={`add-jobPreferences-desktop`}
                            text={optionalButtons[2].text}
                            handleClick={() => openModal('jobPreferences')}
                          />
                        :
                        <CandidateDetails
                          detailType={'jobPreferences'}
                          handleClick={() => openModal('jobPreferences')}
                        />}
                      </Grid>
                    </Grid>
                  </Grid>
                </TabPanel>
                <TabPanel value={value} index={2}>
                  <Grid item xs={12} sm={12} md={12} lg={12} className="additional-fields">
                    {details &&
                      <CandidateDetails
                        detailType="medicalCertifications"
                        handleClick={() => openModal("certifications")}
                      />
                    }
                  </Grid>
                </TabPanel>
            </Box>
          </Grid>
          <StyledDesktopProgressView>
            <Grid item xs={12} sm={12} md={12} lg={3} className="checkbox-container">
                <div className="information-toggle-options desktop-view">
                  <Box className="detail-toggle-container">
                    <ProgressCard></ProgressCard>
                  </Box>
                  <Box>
                    {!requiredButtons[1].data && requiredButtons ?
                      <>
                        <AddTileButton
                          secondaryText={requiredButtons[1].description}
                          data-testid={`add-resumeUpload-desktop`}
                          text={requiredButtons[1].text}
                          handleClick={() => openModal('resumeUpload')}
                        />
                      </>
                      :
                      <CandidateDetails
                        detailType={'resumeUpload'}
                        handleClick={() => openModal('resumeUpload')}
                      />
                    }
                  </Box>
                </div>
            </Grid>
          </StyledDesktopProgressView>
        </Grid>
      </div>
    </StyledDesktopMobileView>
  );

  const dashboardView = (
    <Grid item className="checkbox-container">
        <div className="information-toggle-options desktop-view">
          <Box className="detail-toggle-container">
            <ProgressCard></ProgressCard>
          </Box>
        </div>
    </Grid>
  );

  const modalView = (
      <FormDrawer
        open={showModal}
        saving={isSaving}
        handleClose={closeModal}
        type={modalType}
        handleSave={saveField}
        formError={formError}
        clearError={modalType === "work" ? clearWorkError : clearError}
      />
  );


  return (
    <>
      {(pageView == null || pageView == undefined) ? (
        <>{desktopMobileView}</>
      ) : pageView == 'dashboard' ? (
        <>{dashboardView}</>
      ) : null}
      {showModal && modalView}
    </>
  );
};;

export default CandidateData;
