import React from "react";
import { ReactComponent as Logo } from "./salary.svg";
import SvgIcon from "@material-ui/core/SvgIcon";

import "../Icons.scss";

const SalaryIcon = (props) => {
  const { iconClass } = props;
  return <SvgIcon className={iconClass} component={Logo} viewBox="0 0 256 256" />;
};

export default SalaryIcon;
