import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Skeleton from "@material-ui/lab/Skeleton";

import { HeartIcon } from "../../common/Icons";
import LinkButton from "../../common/Buttons/LinkButton";
import NavLayout from "../../NavLayout/NavLayout";
import Apply from "../../Apply/Apply";

import { setFavoriteJobs, removeFavorite } from "../../../actions/jobs";
import "./JobFavorites.scss";
import DeleteModal from "./DeleteModal";
import PrimaryButton from "../../common/Buttons/PrimaryButton";



const JobFavorites = (props) => {
  const { favoriteJobs } = useSelector((state) => state);

  const [isLoading, setLoading] = useState(false);
  const [isSaving, setSaving] = useState(false);
  const [apply, setApply] = useState(false);
  const [selectedFavorite, setSelectedFavorite] = useState();
  const [open, setOpen] = useState(false);

  const dispatch = useDispatch();
  const cloudFrontBaseURL = "https://ik.imagekit.io/yksbahzmx/connect/";
  const placeholderImage = cloudFrontBaseURL + "images/job-placeholder.jpg";

  useEffect(() => {
    let mounted = true;
    const loadData = async () => {
      if (mounted) {
        setLoading(true);
        await dispatch(setFavoriteJobs());
        setLoading(false);
      }
    };
    if (favoriteJobs == null) {
      loadData();
    }
    return () => {
      mounted = false;
    };
  }, []);

  const deleteFavorite = async () => {
    setSaving(true);
    await dispatch(removeFavorite(selectedFavorite));
    setSaving(false);
    setOpen(false);
  };

  const openModal = (e, id) => {
    e.preventDefault();
    e.stopPropagation();

    setSelectedFavorite(id);
    setOpen(true);
  };

  const getJobIds = (jobs) => {
    const jobIds = jobs?.map((job) => job.jobId);
    return jobIds;
  };

  const jobIds = getJobIds(favoriteJobs?.jobList);

  return (
    <>
      <NavLayout headerText="Favorites">
        {!isLoading ? (
          favoriteJobs?.total > 0 ? (
            <div className="favorites">
              <div className="subheader">
                <h2>{`${favoriteJobs.jobList.length} jobs saved`}</h2>{" "}
                <PrimaryButton
                  buttonClass="transparent-button"
                  text="Apply to all"
                  handleClick={() => setApply(true)}
                />
              </div>

              {favoriteJobs?.jobList.map((job) => (
                <Link key={job.jobId} to={`/job/${job.jobId}`}>
                  <div className="favorite-card" data-testid="job">
                    <div
                      className="favorite"
                      onClick={(e) => openModal(e, job.jobId)}
                      data-testid="favorite-button"
                      name="favorite"
                    >
                      <HeartIcon iconClass="blue-icon" active />
                    </div>
                    <div className="job-img">
                      <img src={job.pictureUrlPath ? cloudFrontBaseURL + "tr:w-200,h-100/" + job.pictureUrlPath : placeholderImage} />
                    </div>

                    <div className="favorite-details">
                      <div className="job-summary">
                        <p className="job-location">{job.location}</p>
                        <p className="job-title">{job.jobTitle}</p>
                      </div>
                      <div className="salary">
                        {job.weeklySalary && job.weeklySalary !== 0 ? (
                          <p>
                            <span className="job-salary">
                              ${job.weeklySalary}
                            </span>
                            / week{" "}
                          </p>
                        ) : (
                          <p>Call/Text for details</p>
                        )}
                      </div>
                    </div>
                  </div>
                </Link>
              ))}
            </div>
          ) : (
            <div className="no-favorites">
              <p>You haven't selected any favorites yet.</p>{" "}
              <LinkButton url="/search" text="Explore all open jobs" />
            </div>
          )
        ) : (
          <div className="skeleton-container">
            <Skeleton width="100%" height="4rem" className="subheader">
              <h2></h2>
              <Link to="/apply">APPLY TO ALL</Link>
            </Skeleton>
            <Skeleton
              variant="rect"
              className="favorite-card"
              width="100%"
              height="10rem"
            ></Skeleton>
            <Skeleton
              variant="rect"
              className="favorite-card"
              height="10rem"
              width="100%"
            ></Skeleton>
            <Skeleton
              variant="rect"
              className="favorite-card"
              height="10rem"
              width="100%"
            ></Skeleton>
          </div>
        )}
      </NavLayout>
      {open && (
        <DeleteModal
          open={open}
          handleClose={() => setOpen(false)}
          confirm={deleteFavorite}
          saving={isSaving}
        />
      )}
      {apply && (
        <Apply
          open={apply}
          handleClose={() => setApply(false)}
          jobIds={jobIds}
          jobDetails={favoriteJobs?.length === 1 && favoriteJobs[0]}
        />
      )}
    </>
  );
};

export default JobFavorites;
