import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";

import JobCardCondensed from "../../../common/JobCardCondensed/JobCardCondensed";
import LinkButton from "../../../common/Buttons/LinkButton";
import {
  setHighestPayJobs
} from "../../../../actions/jobs";

import "../MatchedJobs/MatchedJobs.scss";
import { ArrowForward, ArrowBack } from "@material-ui/icons";
import Skeleton from "@material-ui/lab/Skeleton";
import Hidden from "@material-ui/core/Hidden";

import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { getSearchJobs, setJobFilters } from "../../../../actions/jobFilters";

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
    partialVisibilityGutter: 40
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2
  }
};

const CustomRightArrow = ({ onClick }) => {
  return <button className="react-multiple-carousel__arrow react-multiple-carousel__arrow--right" aria-label="go to next slide" onClick={() => onClick()}><ArrowForward style={{ color: "#00283C"}} /></button>;
};

const CustomLeftArrow = ({ onClick }) => {
  return <button className="react-multiple-carousel__arrow react-multiple-carousel__arrow--left" aria-label="go to previous slide" onClick={() => onClick()}><ArrowBack style={{ color: "#00283C"}}/></button>;
};

const HighestPay = () => {
  const [isLoading, setLoading] = useState(false);
  const { highestPay } = useSelector((state) => state || {});
  const [newSearch, setNewSearch] = useState(false);
  const [page, setPage] = useState(0);

  const dispatch = useDispatch();

  const allHighestPaid = () => {
    dispatch(setJobFilters({specialties: []}));
    dispatch(setJobFilters({isfeatured: false}));
  
    setPage(1);
    dispatch(getSearchJobs(page));
    setNewSearch(true);
  }

  useEffect(() => {
    let mounted = true;

    const loadData = async () => {
      if (!highestPay) {
        setLoading(true);
        await dispatch(setHighestPayJobs());
        setLoading(false);
      }
    };
    if (mounted) {
      loadData();
    }

    return () => {
      mounted = false;
    };
  }, []);

  return (
    <div className="dashboard-box">
      <div className="subheader">
        <h5 className="w-full text-lg leading-6 font-medium text-gray-900 flex justify-between">
          Highest Pay
          <span>
            <LinkButton url="/search?type=highestpaid" textButton text={(<>View All <span aria-hidden="true">→</span></>)} />
          </span>
        </h5>
      </div>
      <div className="job-container">
        {!isLoading ? (
          highestPay && highestPay?.total > 0 ? (
            <>
              <Hidden smDown>
                <Carousel responsive={responsive} customRightArrow={<CustomRightArrow />} centerMode={true} customLeftArrow={<CustomLeftArrow />} containerClass="matched-jobs">
                  {highestPay.jobList.map((job, index) => {
                    return (
                        <Link key={job.jobId} to={`/job/${job.jobId}`}>
                            <div className="job-card condensed" key={index}>
                                <JobCardCondensed
                                  job={job}
                                  hidePills={true}
                                  hasImage={true}
                                />
                            </div>
                        </Link>
                    )
                    })}
                </Carousel>
              </Hidden>

              <Hidden mdUp>
                {highestPay.jobList.map((job, index) => {
                  return (
                    <Link key={job.jobId} to={`/job/${job.jobId}`}>
                        <div className="job-card condensed" key={index}>
                            <JobCardCondensed
                              job={job}
                              hidePills={true}
                              hasImage={true}
                            />
                        </div>
                    </Link>
                  )
                })}
              </Hidden>
            </>
          ) : (
            <p className="center-align">No matched jobs</p>
          )
        ) : (
          <div className="matched-jobs-skeleton">
            <Skeleton
              variant="rect"
              className="matched-card"
              height="12rem"
            ></Skeleton>
            <Skeleton
              variant="rect"
              className="matched-card"
              height="12rem"
            ></Skeleton>
            <Skeleton
              variant="rect"
              className="matched-card"
              height="12rem"
            ></Skeleton>
            <Skeleton
              variant="rect"
              className="matched-card"
              height="12rem"
            ></Skeleton>
          </div>
        )}
      </div>
    </div>
  );
};

export default HighestPay;
