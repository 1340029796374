import React from "react";
import { Modal } from "@material-ui/core";

import PrimaryButton from "../../common/Buttons/PrimaryButton";
import { CircularProgress } from "@material-ui/core";

import "./DeleteModal.scss";

const DeleteModal = ({
  open,
  handleClose,
  confirm,
  cancelButtonText,
  saving
}) => {
  return (
    <Modal
      open={open}
      aria-labelledby="delete-modal"
      className="modal"
      data-testid="delete-modal"
    >
      <div className="delete-modal">
        <div className="close-modal" onClick={handleClose}>
          X
        </div>
        {!saving ? (
          <>
            <div className="message">
              <h3>Do you wish to remove this job from your favorites?</h3>
            </div>
            <div className="buttons">
              <div className="cancel">
                <PrimaryButton
                  buttonClass="transparent-button"
                  handleClick={handleClose}
                  text={cancelButtonText ? cancelButtonText : "Cancel"}
                />
              </div>
              <div className="save">
                <PrimaryButton handleClick={confirm} text="Yes" />
              </div>
            </div>
          </>
        ) : (
          <div className="saving">
            <CircularProgress size={48} />
            <p>Saving changes...</p>
          </div>
        )}
      </div>
    </Modal>
  );
};

export default DeleteModal;
