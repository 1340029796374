import { SET_JOBAPPLICATIONS } from "./types";

import { getApplications, jobApply } from "../services/jobs";

export const getJobApplications = () => async (dispatch) => {
  try {
    const results = await getApplications();

    if (results) {
      dispatch({
        type: SET_JOBAPPLICATIONS,
        payload: results
      });
    }
  } catch (err) {
    console.log(err);
  }
};

export const applyToJob = (jobIds) => async (dispatch) => {
  try {
    const results = await jobApply(jobIds);

    if (results) {
      await dispatch(getApplications);
    }
  } catch (err) {
    console.log(err);
  }
};
