import React from "react";
import { useDispatch, useSelector } from "react-redux";
import Avatar from "@material-ui/core/Avatar";
import DateFnsUtils from "@date-io/date-fns";
import { Notifications } from "@material-ui/icons";

import DetailCard from "../../../common/DetailCard/DetailCard";
import "./PersonalInformation.scss";
import { TextField, Dialog, DialogTitle, DialogContent, Chip } from "@material-ui/core";
import authService from "../../../../services/auth.service";
import { CircularProgress } from "@material-ui/core";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import ErrorOutline from "@material-ui/icons/ErrorOutline";

const dateFns = new DateFnsUtils();

const PersonalInformationDetails = ({ handleClick }) => {
  const { personalInformation, specialties, jobPreferences} = useSelector((state) => state.candidateData);
  const { user } = useSelector((state) => state.auth);
  const { phoneConfirmed } = user || {};
  const [phoneCodeInput, setPhoneCodeInput] = React.useState(false);
  const [verifyingPhoneFailure, setVerifyingPhoneFailure] = React.useState(false);
  const [confirmingPhone, setConfirmingPhone] = React.useState(false);
  const [confirmingPhoneSuccess, setConfirmingPhoneSuccess] = React.useState(false);
  const [confirmingPhoneFailure, setConfirmingPhoneFailure] = React.useState(false);
  const [completedPhoneVerification, setCompletedPhoneVerification] = React.useState(false);
  const { emailAddressConfirmed } = user || {};
  const [emailAddressCodeInput, setEmailAddressCodeInput] = React.useState(false);
  const [verifyingEmailAddressFailure, setVerifyingEmailAddressFailure] = React.useState(false);
  const [confirmingEmailAddress, setConfirmingEmailAddress] = React.useState(false);
  const [confirmingEmailAddressSuccess, setConfirmingEmailAddressSuccess] = React.useState(false);
  const [confirmingEmailAddressFailure, setConfirmingEmailAddressFailure] = React.useState(false);
  const [completedEmailAddressVerification, setCompletedEmailAddressVerification] = React.useState(false);
  const [incomplete, setIncomplete] = React.useState(false);
  const [openEmail, setOpenEmail] = React.useState(false);
  const [openPhone, setOpenPhone] = React.useState(false);
  const dispatch = useDispatch();
  const {
    firstName = '',
    lastName = '',
    city,
    state,
    phone,
    email,
    avatar,
    ssn,
    dateOfBirth,
    preferredContactMethod, 
    notificationsActive
  } = personalInformation || {};

  React.useEffect(() => {
    if (!ssn || !dateOfBirth){
      setIncomplete(true);
    }
  }, [personalInformation]);

  const doesUserPhoneExist = () => {
    const user = JSON.parse(localStorage.getItem("user"));
    return user && (user.phone || (user.attributes && user.attributes.phone));
  }

  const verifyPhone = async () => {
    if (!doesUserPhoneExist() && phone) {
      console.log('Updating user phone in Cognito.');
      await authService.updateCognitoFields(dispatch, {phone: phone});
    } else {
      sendPhoneCode();
    }

    setOpenPhone(true);
    setPhoneCodeInput(true);
  };

  const sendPhoneCode = async () => {
    let phoneVerified = await authService.verifyPhone();
    if (phoneVerified)
    {
      setVerifyingPhoneFailure(false);
    } else {
      setVerifyingPhoneFailure(true);
    }
  };

  const confirmPhone = async (phoneCode) => {
    setConfirmingPhone(true);
    
    let phoneConfirmed = await authService.confirmPhone(phoneCode);
    
    if (phoneConfirmed) {
      setConfirmingPhoneSuccess(true);
      setConfirmingPhoneFailure(false);
      setPhoneCodeInput(false);
      setConfirmingPhone(false);

      setTimeout(async() => {
        setCompletedPhoneVerification(true);
        setConfirmingPhoneSuccess(false);

        await authService.updateUser(dispatch);
      }, 5000);
      
    } else {
      setConfirmingPhoneSuccess(false);
      setConfirmingPhoneFailure(true);
      setConfirmingPhone(false);
    }
  };

  const doesUserEmailExist = () => {
    const user = JSON.parse(localStorage.getItem("user"));
    return user && (user.emailAddress || (user.attributes && user.attributes.email));
  }

  const verifyEmailAddress = async () => {
    if (!doesUserEmailExist() && email) {
      console.log('Updating user email in Cognito.');
      await authService.updateCognitoFields(dispatch, {email: email});
    } else {
      sendEmailCode();
    }
    setOpenEmail(true);
    setEmailAddressCodeInput(true);
  };

  const confirmEmailAddress = async (code) => {
    setConfirmingEmailAddress(true);
    
    let confirmed = await authService.confirmEmailAddress(code);
    if (confirmed) {
      setConfirmingEmailAddressSuccess(true);
      setConfirmingEmailAddressFailure(false);
      setEmailAddressCodeInput(false);
      setConfirmingEmailAddress(false);

      setTimeout(async() => {
        setCompletedEmailAddressVerification(true);
        setConfirmingEmailAddressSuccess(false);

        await authService.updateUser(dispatch);
      }, 5000);

    } else {
      setConfirmingEmailAddressSuccess(false);
      setConfirmingEmailAddressFailure(true);
      setConfirmingEmailAddress(false);
    }
  };

  const sendEmailCode = async () => {
    let emailVerified = await authService.verifyEmailAddress();
    if (emailVerified)
    {
      setVerifyingEmailAddressFailure(false);
    } else {
      setVerifyingEmailAddressFailure(true);
    }
  };

  const handleEmailClose = () => {
    setOpenEmail(false);
    setConfirmingEmailAddress(false);
    setEmailAddressCodeInput(false);
  };

  const handlePhoneClose = () => {
    setOpenPhone(false);
    setConfirmingPhone(false);
    setPhoneCodeInput(false);
  };

  const isInBecomeMode = () => {
    return sessionStorage.getItem("BecomeUser");
  }

  return (
    <div className="personal-details-container" data-testid="personalInformation-details">
      <DetailCard title="Personal Information" incomplete={incomplete} handleClick={handleClick} isHeaderAligned={true}>
        <div className="personal-wrapper">
          <Avatar
            alt="profile photo"
            src={
              avatar?.src ||
              "https://d2wqn2ab54ir3q.cloudfront.net/images/avatar-placeholder.png"
            }
            className="profile-photo"
          />
          <div className="profile-details">
            <div className="name">
              <h3 className="text-lg leading-6 font-medium text-gray-900">{firstName} {lastName}</h3>
            </div>
            {email && (
              <div className="detail detail-contact email">
                <div className="email-wrapper">
                <p className="email text-md leading-6 font-small text-gray-500">{email}</p>
                {!emailAddressConfirmed && email && !emailAddressCodeInput && !confirmingEmailAddress && 
                !confirmingEmailAddressSuccess && !completedEmailAddressVerification && !isInBecomeMode() && (
                    <p>
                      <span className="verify-contact" onClick={verifyEmailAddress}>Verify</span>
                    </p>
                  )}
                  <div className="code-input">                  
                    {emailAddressCodeInput && (
                      <Dialog open={openEmail} onClose={handleEmailClose}>
                        <DialogTitle id="alert-dialog-title" className="dialog-title">
                          Enter Verification Code
                          <p className="verify-close" onClick={handleEmailClose}>
                          X
                          </p>
                        </DialogTitle>
                        
                        <DialogContent>
                          <TextField
                            id="email-address-code"
                            label="Email Code"
                            className="input-field"
                            name="email-address-code"
                            type="number"
                            onChange={(e) => {
                              if (e.target.value.length === 6) {
                                confirmEmailAddress(e.target.value);
                              }
                            }
                            }
                            disabled={confirmingEmailAddress}
                            inputProps={{style: {fontSize: 14, width: 100}, maxLength: 6}}
                            InputLabelProps={{style: {fontSize: 14}}}
                          />
                          <div className="resend" onClick={sendEmailCode}>
                            <span className="color-button">Resend</span>
                            {verifyingEmailAddressFailure && (
                              <span className="verifying-failure">
                                <ErrorOutline fontSize="small" edge="start" aria-label="menu"></ErrorOutline>
                              </span> 
                            )}
                          </div>
                          {confirmingEmailAddressFailure && (
                            <div className="confirm-failure">
                              <ErrorOutline fontSize="small" edge="start" aria-label="menu"></ErrorOutline>
                              <p className="msg-text">Verification Failed</p>
                            </div> 
                          )}
                        </DialogContent>
                      </Dialog>
                    )}
                    {confirmingEmailAddress && (
                      <CircularProgress size={20} /> 
                    )}
                    {confirmingEmailAddressSuccess && (
                      <div className="confirm-success">
                        <CheckCircleOutlineIcon color="inherit" fontSize="small" edge="start" aria-label="menu"></CheckCircleOutlineIcon>
                        <p className="msg-text">Email Verified</p>
                      </div> 
                    )}
                  </div>
                </div>
              </div>
            )}
            {phone && (
              <div className="detail detail-contact">
                <div className="phone-wrapper">
                  <p className="text-md leading-6 font-small text-gray-500">{phone}</p>
                  {!phoneConfirmed && phone && !phoneCodeInput && !confirmingPhone && 
                  !confirmingPhoneSuccess && !completedPhoneVerification && !isInBecomeMode() && (
                    <p>
                      <span className="verify-contact" onClick={verifyPhone}>Verify</span>
                    </p>
                  )}
                  <div className="code-input">                  
                    {phoneCodeInput && (
                      <Dialog open={openPhone} onClose={handlePhoneClose}>
                        <DialogTitle id="alert-dialog-title" className="dialog-title">
                          Enter Verification Code
                          <p className="verify-close" onClick={handlePhoneClose}>
                            X
                          </p>
                        </DialogTitle>
                        
                        <DialogContent>
                          <TextField
                            id="phone-code"
                            label="Phone Code"
                            className="input-field"
                            name="phone"
                            type="number"
                            onChange={(e) => {
                              if (e.target.value.length === 6) {
                                confirmPhone(e.target.value);
                              }
                            }
                            }
                            disabled={confirmingPhone}
                            inputProps={{style: {fontSize: 14, width: 100}, maxLength: 6}}
                            InputLabelProps={{style: {fontSize: 14}}}
                          />  
                          <div className="resend" onClick={sendPhoneCode}>
                            <span className="color-button">Resend</span>
                            {verifyingPhoneFailure && (
                              <span className="verifying-failure">
                                <ErrorOutline fontSize="small" edge="start" aria-label="menu"></ErrorOutline>
                              </span> 
                            )}  
                          </div>
                          {confirmingPhone && (
                            <CircularProgress size={20} /> 
                          )}
                          {confirmingPhoneFailure && (
                            <div className="confirm-failure">
                              <ErrorOutline fontSize="small" edge="start" aria-label="menu"></ErrorOutline>
                              <p className="msg-text">Verification Failed</p>
                            </div> 
                          )}
                        </DialogContent>
                      </Dialog>
                    )}
                    {confirmingPhoneSuccess && (
                      <div className="confirm-success">
                        <CheckCircleOutlineIcon fontSize="small" edge="start" aria-label="menu"></CheckCircleOutlineIcon>
                        <p className="msg-text">Phone Verified</p>
                      </div> 
                    )}
                  </div>
                </div>
              </div>
            )}
            {city && (
              <div className="detail">
                <p className="text-md leading-6 font-small text-gray-500">{`${city} ${state ? `, ${state}` : ""}`}</p>
              </div>
            )}
            {notificationsActive && preferredContactMethod && (
              <div className="detail">
                <Notifications className="input-icon" />
                <p className="notifications-active">{notificationsActive ? "Enabled (" + preferredContactMethod + ")" : "Disabled"}</p>
                {notificationsActive && ((preferredContactMethod === "Email" && !emailAddressConfirmed && !confirmingEmailAddressSuccess && !completedEmailAddressVerification) || 
                  (preferredContactMethod === "Text" && !phoneConfirmed && !confirmingPhoneSuccess && !completedPhoneVerification)) && (
                  <p className="notifications-active-error">Verification Needed</p>
                )}
              </div>
            )}
            {specialties && specialties.specialtyList.length && (
              <Chip label={specialties.specialtyList[0].category} />
            )}
            {jobPreferences && jobPreferences.quality && (
              <Chip label={jobPreferences.quality} />
            )}
            {jobPreferences && jobPreferences.locations?.length >  0 && (
              <Chip 
                label={jobPreferences.locations ? jobPreferences.locations?.map((location, index) => ( (index > 0 ? (', ') : ('')) + location)) : null}
              />
            )}
          </div>
        </div>
      </DetailCard>
    </div>
  );
};

export default PersonalInformationDetails;
