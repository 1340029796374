import React from "react";
import { ReactComponent as Logo } from "./facebook.svg";
import SvgIcon from "@material-ui/core/SvgIcon";

import "../Icons.scss";

const FacebookIcon = (props) => {
  const { iconClass } = props;
  return (
    <SvgIcon
      className={iconClass}
      component={Logo}
      viewBox="0 0 155.139 155.139"
    />
  );
};

export default FacebookIcon;
