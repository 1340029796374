class ClientSideDataStorageService {
    setItem(name, value, useSessionStorage) {
        if (useSessionStorage && this.isSessionStorageEnabled()) {
          sessionStorage.setItem(name, value);
        } else if (!useSessionStorage && this.isLocalStorageEnabled()) {
          localStorage.setItem(name, value);
        }
    }
    
    setJsonItem(name, value, useSessionStorage = true) {
        this.setItem(name, JSON.stringify(value), useSessionStorage);
    }
    
    getJsonItem(name, useSessionStorage = true) {
        const itemRaw = this.getItem(name, useSessionStorage);
        return itemRaw ? JSON.parse(itemRaw) : null;
    }
    
    getItem(name, useSessionStorage) {
        let result;
        try {
            result = useSessionStorage ? sessionStorage.getItem(name) : localStorage.getItem(name);
        } catch (e) {
            console.log('Error getting item from localStorage');
            console.log(e);
        }
        
        return result;
    }
    
    removeItem(name) {
        localStorage.removeItem(name);
        sessionStorage.removeItem(name);
    }
    
    isSessionStorageEnabled() {
        try {
          const mod = '__storage_test__';
          sessionStorage.setItem(mod, mod);
          sessionStorage.removeItem(mod);
          return true;
        } catch (e) {
          console.log('sessionStorage disabled');
          console.log(e);
          return false;
        }
    }
    
    isLocalStorageEnabled() {
        try {
          const  mod = '__storage_test__';
          localStorage.setItem(mod, mod);
          localStorage.removeItem(mod);
          return true;
        } catch (e) {
          console.log('localStorage disabled');
          console.log(e);
          return false;
        }
    }
}

export default new ClientSideDataStorageService();