import React from "react";
import { ReactComponent as VisibilityLogo } from "./visibility.svg";
import { ReactComponent as VisibilityFillLogo } from "./visibility-off.svg";
import SvgIcon from "@material-ui/core/SvgIcon";

import "../Icons.scss";

const VisibilityIcon = (props, children) => {
  const { iconClass, active } = props;
  return (
    <SvgIcon
      className={iconClass}
      component={active ? VisibilityLogo : VisibilityFillLogo}
    />
  );
};

export default VisibilityIcon;
