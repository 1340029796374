import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Select,
  InputLabel,
  MenuItem,
  FormControl,
  FormHelperText,
  Container
} from "@material-ui/core";
import DateFnsUtils from "@date-io/date-fns";

import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider
} from "@material-ui/pickers";
import { Delete } from "@material-ui/icons";

import UploadFile from "../../../common/UploadFile/UploadFile";
import PrimaryButton from "../../../common/Buttons/PrimaryButton";
import ConfirmationModal from "../../../common/ConfirmationModal/ConfirmationModal";
import "./CertificationForm.scss";
import { getCertifications } from "../../../../services/datalist";
import { deleteData } from "../../../../actions/candidateEntry";
import { getCandidateFile } from "../../../../services/candidateData";

const CertificationForm = ({ change, values = {}, error, clearError }) => {
  const [certificationOptions, setCertificationOptions] = useState(undefined);
  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const [certId, setCertId] = useState();
  const { candidateData } = useSelector((state) => state);
  const dispatch = useDispatch();
  const { certifications } = candidateData;

  const { certificationId, fileName, fileBase64Str } = values;
  const expirationDate = values?.expirationDate;

  //get Certification List
  useEffect(() => {
    let mounted = true;

    getCertifications()
      .then((data) => {
        if (mounted) {
          setCertificationOptions(data);
        }
      })
      .catch((err) => console.log(err));
    return () => (mounted = false);
  }, []);

  const saveFile = (file) => {
    clearError("fileName");
    change(file.name, "fileName");
    change(file.fileBase64Str, "fileBase64Str");
  };

  const getFile = (fileId, fileName) => {
    getCandidateFile(fileId, fileName);
  };

  const removeCert = (id) => {
    setCertId(id);
    setConfirmationOpen(true);
  };
  const confirmDeletion = async () => {
    await dispatch(deleteData("certification", certId));
    setConfirmationOpen(false);
  };

  const handleChange = (value, field) => {
    clearError(field);
    change(value, field);
  };

  return (
    <>
      <Container width={1} data-testid="certifications" maxWidth="md">
        <div className="header">
          <h6 className="text-lg leading-6 font-medium text-gray-900">Certifications</h6>
        </div>
        <FormControl
          fullWidth
          error={error?.certificationId ? true : false}
          data-testid="certification"
        >
          <InputLabel htmlFor="certification-select" id="certification-label">
            Certification*
          </InputLabel>
          <Select
            label="Certification*"
            id="certification-select"
            value={certificationId || ""}
            onChange={(e, val) => change(e.target.value, "certificationId")}
          >
            {certificationOptions?.map((cert) => (
              <MenuItem key={cert.id} value={cert.id}>
                {cert.label}
              </MenuItem>
            ))}
          </Select>
          <FormHelperText>
            {error?.certificationId || "required"}
          </FormHelperText>
        </FormControl>

        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <KeyboardDatePicker
            variant="inline"
            format="MM/dd/yyyy"
            margin="normal"
            id="expirationDate"
            label="Expiration Date*"
            placeholder="MM/DD/YYYY"
            autoOk={true}
            fullWidth
            value={expirationDate || null}
            onChange={(e, val) => handleChange(val, "expirationDate")}
            onFocus={() => clearError("expirationDate")}
            error={error?.expirationDate ? true : false}
            helperText={error?.expirationDate || "required"}
            KeyboardButtonProps={{
              "aria-label": "change date"
            }}
          />
        </MuiPickersUtilsProvider>

        <UploadFile
          prefix='cert-'
          storeFile={saveFile}
          hideUpload
          error={error?.fileName}
          possibleFileExtensions={["png", "jpg", "jpeg", "gif", "txt", "pdf", "doc", "docx"]}
        />

        {certifications && certifications.length > 0 && (
          <>
            <h6>Existing Certifications</h6>

            {certifications.map((value, index) => (
              <div key={index} className="certification-card">
                <div className="details">
                  {" "}
                  <p>Certification: {value.name}</p>
                  <p className="filename">Document: {value.fileName}</p>
                </div>
                <div className="buttons">
                  <PrimaryButton
                    buttonClass="link-button"
                    text="Download"
                    handleClick={() => getFile(value.fileId, value.fileName)}
                  />
                  <div onClick={() => removeCert(value.id)}>
                    <Delete className="delete-icon" />
                  </div>
                </div>
              </div>
            ))}
          </>
        )}
      </Container>
      {confirmationOpen && (
        <ConfirmationModal
          open={confirmationOpen}
          confirm={() => confirmDeletion()}
          handleClose={() => setConfirmationOpen(false)}
          message="Are you sure you want to delete this Certification?"
          subMessage="All of your changes will be lost"
          cancelButtonText="Cancel"
        />
      )}
    </>
  );
};

export default CertificationForm;
