import { CLEAR_JOBSEARCH, SET_JOBSEARCH } from "../actions/types";

const jobSearch = (state = null, action) => {
  const { type, payload } = action;
  switch (type) {
    case SET_JOBSEARCH:
      return payload;
    case CLEAR_JOBSEARCH:
      return null;
    default:
      return state;
  }
};

export default jobSearch;
