const today = new Date();
const lastOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0);
const nextMonthStart = new Date(today.getFullYear(), today.getMonth() + 1, 1);
const nextMonth = new Date(today.getFullYear(), today.getMonth() + 2, 0);
const twoMonthsStart = new Date(today.getFullYear(), today.getMonth() + 2, 1);
const twoMonths = new Date(today.getFullYear(), today.getMonth() + 3, 0);

const setMonthAbbrev  = (monthInt) => {
  var month = '';

  switch (monthInt) {
    case 0:
      month = 'Jan'
      break;
    case 1:
      month = 'Feb'
      break;
    case 2:
      month = 'Mar'
      break;
    case 3:
      month = 'Apr'
      break;
    case 4:
      month = 'May'
      break;
    case 5:
      month = 'Jun'
      break;
    case 6:
      month = 'Jul'
      break;
    case 7:
      month = 'Aug'
      break;
    case 8:
      month = 'Sept'
      break;
    case 9:
      month = 'Oct'
      break;
    case 10:
      month = 'Nov'
      break;
    default:
      month = 'Dec'
      break;
  }
  
  return month;
}

export const sortByOptions = [
  { value: "weeklySalary", label: "Highest salary" },
  { value: "startDate", label: "Start date" },
  { value: "dateAdded", label: "Posted date"}
];

export const shiftOptions = [
  { value: "days", label: "Day" },
  { value: "nights", label: "Night" },
  { value: "mids", label: "Mid" }
];

export const dateRanges = [
  { value: "0", label: (setMonthAbbrev(today.getMonth())) + ' ' + today.getDate() + ' - ' + (setMonthAbbrev(today.getMonth())) + ' ' + lastOfMonth.getDate() },
  { value: "1", label:(setMonthAbbrev(nextMonth.getMonth())) + ' 1 - ' + (setMonthAbbrev(nextMonth.getMonth())) + ' ' + nextMonth.getDate() },
  { value: "2", label: (setMonthAbbrev(twoMonths.getMonth())) + ' 1 - ' + (setMonthAbbrev(twoMonths.getMonth())) + ' ' + twoMonths.getDate() }
]

export const dates = {
  now: today,
  monthEnd: lastOfMonth,
  nextStart: nextMonthStart,
  nextEnd: nextMonth,
  twoStart: twoMonthsStart,
  twoEnd: twoMonths
}
