import React, { useState } from "react";

import "./WeCareMore.scss";
import Icon1 from "../../../images/check.png";
import Icon2 from "../../../images/artificial-intelligence.png";
import Icon3 from "../../../images/welfare.png";

const WeCareMore = () => {

  return (
    <section className="flex container">
      <div className="flex flex-col items-center p-4 m-2 ">
        <h6 className="text-sm font-bold mb-2 text-center">Your success is everything</h6>
        <img src={Icon1} width="70px" height="70px" className="p-2 mt-1 mb-1" />
        <p className="text-xs">
          Whatever you need, whenever you need it. Your dedicated Care Team is ready, willing, and able to deliver.
        </p>
      </div>
      <div className="flex flex-col items-center p-4 m-2 ">
        <h6 className="text-sm font-bold mb-2 text-center">AI empowered smart search</h6>
        <img src={Icon2} width="80px" height="80px" className="p-2"/>
        <p className="text-xs">
          The latest AI tech to help you find the roles you want with easy filters and empowered smart searches.
        </p>
      </div>
      <div className="flex flex-col items-center p-4 m-2 ">
        <h6 className="text-sm font-bold mb-2 text-center">Finding your future takes talent</h6>
        <img src={Icon3} width="80px" height="80px" className="p-2"/>
        <p className="text-xs">
          Your dedicated recruiting team of talent agents brings unmatched expertise to your job search. 
        </p>
      </div>
    </section>
  );
};

export default WeCareMore;
