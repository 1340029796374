import { toast } from "react-toastify";
import ToastCloseButton from "./ToastCloseButton";


export const notify = (message, type) => {
  const position = toast.POSITION.BOTTOM_CENTER;
  const options = {
    position
  };

  switch (type) {
    case "success":
      toast.success(message, {
        position,
        className: "toast",
        pauseOnHover: false,
        autoClose: 2000,
        position: "bottom-center",
        rtl: false,
        pauseOnFocusLoss: false,
        draggable: false,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        progress: undefined,
      });
      break;
    case "error":
      toast.error(message, {
        position,
        className: "toast",
        pauseOnHover: false,
        autoClose: 2000,
        position: "bottom-center",
        rtl: false,
        pauseOnFocusLoss: false,
        draggable: false,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        progress: undefined,
      });
      break;
    default:
      toast(message, {
        position,
        className: "toast",
        autoClose: false,
        pauseOnHover: false,
        position: "bottom-center",
        rtl: false,
        pauseOnFocusLoss: false,
        draggable: false,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        progress: undefined,
      });
  }
};
