import { GET_JOBDETAILS } from "../actions/types";

const jobDetails = (state = null, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_JOBDETAILS:
      return payload;
    default:
      return state;
  }
};

export default jobDetails;
