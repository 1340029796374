import React from "react";

import { OperatorIcon } from "../Icons";

import "./ContactBox.scss";

const ContactBox = ({ handleClick }) => {
  return (
    <div
      className="notification-container"
      data-testid="contact-button"
      onClick={handleClick}
    >
      <p>At any time feel free to contact us. We are here to help you.</p>
      <div>
        <OperatorIcon iconClass="contact-icon" />
      </div>
    </div>
  );
};

export default ContactBox;
