import { searchJobs } from "../services/jobs";
import {
  SET_JOBFILTERS,
  CLEAR_JOBFILTERS,
  REMOVE_JOBFILTER,
  SET_JOBSEARCH,
  CLEAR_JOBSEARCH
} from "./types";

export const setJobFilters = (updatedFilters) => (dispatch, getState) => {
  const { filters, filterCount } = getState().jobFilters || {};
  let count = 0;
  const newFilters = { ...filters, ...updatedFilters };

  for (let key in newFilters) {
    if (Array.isArray(newFilters[key])) {
      const length = newFilters[key]?.length;

      count += length || 0;
    } else if (newFilters[key]) {
      count += 1;
    }
  }

  const updatedState = {
    filters: newFilters,
    filterCount: count
  };
  dispatch({
    type: SET_JOBFILTERS,
    payload: updatedState
  });
};

export const removeFilter = (type, filterIndex) => (dispatch, getState) => {
  const { filterCount, filters } = getState().jobFilters || {};

  if (filters) {
    const count = filterCount - 1;
    let updatedFilters = { ...filters };

    if (filterIndex >= 0) {
      updatedFilters[type] = updatedFilters[type].filter(
        (item, index) => index !== filterIndex
      );
    } else {
      delete updatedFilters[type];
    }

    const updatedState = {
      filters: updatedFilters,
      filterCount: count
    };
    dispatch({
      type: REMOVE_JOBFILTER,
      payload: updatedState
    });
  }
};

export const clearJobFilters = () => (dispatch) => {
  dispatch({
    type: CLEAR_JOBFILTERS
  });
};

export const clearJobSearch = () => (dispatch) => {
  dispatch({
    type: CLEAR_JOBSEARCH
  });
};

export const getSearchJobs = (page) => async (dispatch, getState) => {
  const state = getState();
  const { jobFilters } = state || {};
  const { filters } = jobFilters || {};

  const results = await searchJobs(filters, page);
  if (results) {
    dispatch({
      type: SET_JOBSEARCH,
      payload: results
    });
  }
  return results;
};
