import React, { useCallback } from "react";
import { useDropzone } from "react-dropzone";
import { CloudUpload } from "@material-ui/icons";
import { AddIcon } from "../Icons";

import "./Dropzone.scss";

const Dropzone = (props) => {
  const { add } = props;
  const onDrop = useCallback(
    (acceptedFiles) => {
      add(acceptedFiles);
    },
    [add]
  );
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  return (
    <div {...getRootProps()}>
      <input data-testid="drop-input" {...getInputProps()} />
      {isDragActive ? (
        <div className="drop-field active">
          <AddIcon iconClass="blue-icon" />
        </div>
      ) : (
        <div className="drop-field inactive">
          <CloudUpload className="nav-header-icon" />
          <p>Drag &#x26; drop to Upload File</p>
          <p>OR</p>
          <button>Choose a file </button>
        </div>
      )}
    </div>
  );
};

export default Dropzone;
