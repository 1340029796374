import axios from "axios";
import API_URL from "../../../helpers/globals.js";
import { notify } from "../../../toasts/toasts.js";

export const isAdmin = (user) => {
  let isAdmin = false;

  if (user?.groupList?.includes('admin') || user?.groupList?.includes('recruiter')) {
    isAdmin = true;
  }

  return isAdmin;
};

export const hasRecruiterFullAccess = async () => {
  try {
    const response = await axios.get(API_URL + "Admin/fullrecruiteraccess");
    return true;
  } catch (err) {
    console.log(err);
    return false;
  }
};

export const getAssessments = async () => {
  try {
    const response = await axios.get(API_URL + "Admin/skillassessments");
    if (response) {
      const data = formatAssessment(response.data);
      return data;
    }
  } catch (err) {
    console.log(err);
  }
};

export const postAssessment = async (assessment) => {
  const body = formatTreeData(assessment);
  try {
    const response = await axios.post(
      API_URL + "Admin/skillassessment",
      JSON.stringify(body),
      {
        headers: {
          "Content-Type": "application/json"
        }
      }
    );
    if (response) {
      notify("Your changes have been made successfully.", "success");
      return response.data;
    }
  } catch (err) {
    notify("Failed to save your changes. Please try again.", "error");
    console.log(err);
  }
};

export const deleteSkillAssessment = async (id) => {
  try {
    const response = await axios.delete(
      `${API_URL}Admin/skillassessment?id=${id}`
    );
    if (response) {
      notify("Your changes have been made successfully.", "success");
      return response.data;
    }
  } catch (err) {
    notify("Failed to save your changes. Please try again.", "error");
    console.log(err);
  }
};

//formatting assessment for the frontend sortable tree
const formatAssessment = (data) => {
  const formattedData = data?.map((item) => {
    const { id, name, categoryList } = item;
    const children = categoryList?.map((item) => {
      const { id, name, questionList } = item;
      const children = questionList?.map((item) => {
        const { id, name, questionList } = item;
        return {
          id,
          name,
          expanded: true,
          children: questionList
        };
      });
      return {
        id,
        name,
        expanded: true,
        children
      };
    });
    return {
      id,
      name,
      expanded: true,
      children
    };
  });
  return formattedData;
};

//format assessment data for saving to the backend
const formatTreeData = (data) => {
  const assessment = data;
  const { id, name, children } = assessment;

  const formattedData = children?.map((item) => {
    const { id, name, children: category } = item;
    const categoryList = category?.map((item) => {
      const { id, name, children: subCategory } = item;
      const questionList = subCategory?.map((item) => {
        const { id, name } = item;
        return {
          id : id !== 0 ? id : null,
          name
        };
      });
      return {
        id : id !== 0 ? id : null,
        name,
        questionList
      };
    });
    return {
      id : id !== 0 ? id : null,
      name,
      questionList: categoryList
    };
  });
  return {
    id : id !== 0 ? id : null,
    name,
    categoryList: formattedData
  };
};
