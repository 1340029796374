//import React from "react";
import { useSelector, useDispatch} from "react-redux";

import "./UserDataCard.scss";

import React, { useState, useEffect, useRef } from 'react';
import { Check } from "@material-ui/icons";
import { useTheme } from '@material-ui/core/styles';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';

import {
  Box,
  Button,
  Grid,
  Table,
  TableBody,
  TableHead,
  TableCell,
  TableContainer,
  TableFooter,
  TableRow,
  Paper,
  IconButton,
  TextField,
  Select,
  MenuItem,
  InputLabel,
  FormLabel,
  FormControl,
  RadioGroup,
  Radio,
  FormControlLabel
 } from "@material-ui/core";

import { deleteCandidateData, getCandidateList, getAdminCandidateDetails, searchCandidates } from "../../services/candidateDataStore";
import { deleteRecruiterData, getRecruiterList, getRecruiterDetails, searchRecruiters } from "../../services/recruiterDataStore";
import ConfirmationModal from "../../../common/ConfirmationModal/ConfirmationModal";
import ViewOrAddUserModal from "../Modals/ViewOrAddUserModal";
import ViewOrAddRecruiter from "../Modals/ViewOrAddRecruiter";
import { notify } from "../../../../toasts/toasts";
import { history } from "../../../../helpers/history";
import { CLEAR_MATCHEDJOBS, CLEAR_SIMILARJOBS, CLEAR_FAVORITEJOBS, CLEAR_JOBFILTERS, CLEAR_JOBSEARCH,CLEAR_JOBAPPLICATIONS, CLEAR_CANDIDATE } from "../../../../actions/types";

import { hasRecruiterFullAccess } from "../../services/admin";

const UserDataCard = ({ handleClick, userData }) => {
  const dispatch = useDispatch();
  const [userList, setUserList] = useState(userData?.userList || []);
  const [userId, setUserId] = useState();
  const [userCurrentData, setCurrentUserData] = useState({});
  const [paginationToken, setCurrentPaginationTokens] = useState({});
  const [paginationDataList, setPaginationDataList] = useState([]);
  const [candidateConfirmationOpen, setCandidateConfirmationOpen] = useState(false);
  const [recruiterConfirmationOpen, setRecruiterConfirmationOpen] = useState(false);
  const [page, setPage] = useState(0);
  const [isDeleting, setDeleting] = useState(false);
  const prevPageRef = useRef();
  const [showCandidateModal, setCandidateModal] = useState(false);
  const [showRecruiterModal, setRecruiterModal] = useState(false);
  const [isCandidateModalOpen, setIsCandidateModalOpen] = useState(false);
  const [isRecruiterModalOpen, setIsRecruiterModalOpen] = useState(false);
  const [isAddNewCandidate, setIsAddNewCandidate] = useState(false);
  const [isAddNewRecruiter, setIsAddNewRecruiter] = useState(false);
  const [searchName, setSearchName] = useState('');
  const [searchValue, setSearchValue] = useState('');
  const [recruiterFullAccess, setRecruiterFullAccess] = useState(false);
  const [authProvider, setAuthProvider] = useState('AWSCognito');

  const theme = useTheme();
  const user = JSON.parse(localStorage.getItem("user"));

  const handleChangePage = (event, newPage) => {
    prevPageRef.current = page;
    prevPage = prevPageRef.current;

    setPage(newPage);
  };

  const handleDeleteClick = async (id, type) => {
    if (type === "Recruiter") {
      setRecruiterConfirmationOpen(true);
    } else {
      setCandidateConfirmationOpen(true);
    }

    setUserId(id);
  };

  const confirmDeletion = async (type) => {
    setDeleting(true);
    try {
      const response = type === "Recruiter" ? await deleteRecruiterData(authProvider, userId) : await deleteCandidateData(authProvider, userId);

      if (response) {
        if (type === "Recruiter") {
          setRecruiterConfirmationOpen(false);
        } else {
          setCandidateConfirmationOpen(false);
        }

        setDeleting(false);

        const currentPage = page - 1 !== - 1 ? page - 1 : 0;
        const dataList = await searchCandidates(authProvider, searchName, searchValue, null);

        if (dataList) {
          const updatedCandidatePages = [...paginationDataList];
          updatedCandidatePages[currentPage].userList = dataList.userList;

          setUserList(dataList.userList);
          setPaginationDataList(updatedCandidatePages);
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleAddCandidateClick = async () => {
    setIsAddNewCandidate(true);
    setCurrentUserData({});
    setIsCandidateModalOpen(true);
  };

  const handleAddRecruiterClick = async () => {
    setIsAddNewRecruiter(true);
    setCurrentUserData({});
    setIsRecruiterModalOpen(true);
  };

  const isRecruiter = (type) => {
    return type === "Recruiter" || type === "Admin";
  }

  const handleViewClick = async (id, type) => {
    setUserId(id);
    let userData = isRecruiter(type) ? await getRecruiterDetails(authProvider, id) : await getAdminCandidateDetails(authProvider, id);

    if(userData && (userData.uid || (userData.authId && isRecruiter(type)))) {
      setCurrentUserData(userData);

      if (isRecruiter(type)) {
        setIsRecruiterModalOpen(true);
      } else {
        setIsCandidateModalOpen(true);
      }
    } else {
      notify("Error retrieving details.", "error");
    }
  };

  const resetBecomeUser = async () => {
    await dispatch({ type: CLEAR_MATCHEDJOBS });
    await dispatch({ type: CLEAR_SIMILARJOBS });
    await dispatch({ type: CLEAR_FAVORITEJOBS });
    await dispatch({ type: CLEAR_JOBFILTERS });
    await dispatch({ type: CLEAR_JOBSEARCH });
    await dispatch({ type: CLEAR_JOBAPPLICATIONS });
    await dispatch({ type: CLEAR_CANDIDATE });
  }

  const isNonProdEnvironment = () => window.location.hostname.includes("localhost") || window.location.hostname.includes("staging");

  const becomeFlutterUser = (id) => {
    var flutterWebUrl = isNonProdEnvironment() ? 
      'https://staging.connect.advantismed.com' : 'https://connect.advantismed.com';
    window.location.replace(`${flutterWebUrl}?becomeUser=${id}`);
  }

  const handleBecomeClick = async (id, type) => {
    if (type === "Candidate") {
      sessionStorage.setItem("BecomeUser", id);
      sessionStorage.setItem("AuthProvider", authProvider);

      if (window.location.hostname.includes("admin.connect.advantismed.com") || 
      window.location.hostname.includes("admin.staging.connect.advantismed.com")) {
        becomeFlutterUser(id);
      } else {
          await resetBecomeUser();
          history.push("/home");
      }
    }
  };

  const handleUpdate = async () => {
    const currentPage = isAddNewCandidate || isAddNewRecruiter? 0 : page - 1 !== - 1 ? page - 1 : 0;
    const paginationToken = isAddNewCandidate || isAddNewRecruiter ? null : paginationDataList[currentPage]?.nextPaginationToken || null;
    const dataList = await searchCandidates(authProvider, searchName, searchValue, paginationToken);

    if (dataList) {
      const updatedCandidatePages = [...paginationDataList];
      updatedCandidatePages[currentPage].userList = dataList.userList;

      setUserList(dataList.userList);
      setPaginationDataList(updatedCandidatePages);
      setIsAddNewCandidate(false);
      setIsAddNewRecruiter(false);
    }
  }

  const handleBackButtonClick = (event) => {
    handleChangePage(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    handleChangePage(event, page + 1);
  };

  const handleSearch = async (authProvider, filterName, filterValue) => {
    const dataList = await searchCandidates(authProvider, filterName, filterValue, null);

    if (dataList) {
      setUserList(dataList.userList);
      setCurrentPaginationTokens({next: dataList.nextPaginationToken});
      setPaginationDataList([{
          page: 0,
          userList: dataList.userList,
          nextPaginationToken: dataList.nextPaginationToken
      }]);
    }
  }

  const handleResetSearch = async (resetAuthProvider) => {
    setAuthProvider(resetAuthProvider);
    setUserList([]);
    const dataList = await searchCandidates(resetAuthProvider, '', '', null);

    if (dataList) {
      setSearchValue('');
      setSearchName('');
      setUserList(dataList.userList);
      setCurrentPaginationTokens({next: dataList.nextPaginationToken});
      setPaginationDataList([{
          page: 0,
          userList: dataList.userList,
          nextPaginationToken: dataList.nextPaginationToken
      }]);
    }
  }

  useEffect(() => {
    prevPageRef.current = page;
  });

  let prevPage = prevPageRef.current;

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      if (userCurrentData && isCandidateModalOpen) {
        openCandidateModal();
      }
    }
    return () => (mounted = false);
  }, [isCandidateModalOpen]);

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      if (userCurrentData && isRecruiterModalOpen) {
        openRecruiterModal();
      }
    }
    return () => (mounted = false);
  }, [isRecruiterModalOpen]);

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      if (userData?.userList && userData.userList.length) {
        setUserList(userData.userList);
        setCurrentPaginationTokens({next: userData.nextPaginationToken});
        setPaginationDataList([
          {
            page: page,
            userList: userData.userList,
            nextPaginationToken: userData.nextPaginationToken
          }]);
      }
    }
    return () => (mounted = false);
  }, [userData]);

  useEffect(() => {
    let mounted = true;

    const load = async () => {
      const dataList = await searchCandidates(authProvider, searchName, searchValue, paginationToken.next);

      if (dataList) {
        setUserList(dataList.userList);
        setCurrentPaginationTokens({next: dataList.nextPaginationToken});
        setPaginationDataList([...paginationDataList, {
            page: page,
            userList: dataList.userList,
            nextPaginationToken: dataList.nextPaginationToken
        }]);
      }
    };

    if (mounted) {
      if(prevPage !== undefined && prevPage !== page && page > prevPage && !paginationDataList[page]) {
        load();
      } else if (prevPage !== page && paginationDataList[page]?.userList.length) {
        const {userList, nextPaginationToken } = paginationDataList[page];
        setUserList(userList);
        setCurrentPaginationTokens({next: nextPaginationToken});
      }
    }
    return () => (mounted = false);
  }, [page]);

  useEffect(() => {
    let mounted = true;
    const checkRecruiterHasFullAccess = async () => {
        setRecruiterFullAccess((await hasRecruiterFullAccess()));
    };

    if (mounted) {
      checkRecruiterHasFullAccess();
    }

    return () => {
      mounted = false;
    };
}, []);

  const openCandidateModal = () => {
    setCandidateModal(true);
  };

  const openRecruiterModal = () => {
    setRecruiterModal(true);
  };

  const closeCandidateModal = () => {
    setCandidateModal(false);
    setIsCandidateModalOpen(false);
    setIsAddNewCandidate(false);
  };

  const closeRecruiterModal = () => {
    setRecruiterModal(false);
    setIsRecruiterModalOpen(false);
    setIsAddNewRecruiter(false);
  };

  const currentUserIsAdmin = () => {
    return user?.groupList?.indexOf("admin") !== -1;
  }

  const currentUserIsRecruiter = () => {
    return user?.groupList?.indexOf("recruiter") !== -1;
  }

  const canView = (type, authId, isFullyRegistered) => {
    let isAllowed = false;

    switch (type.toLowerCase())
    {
        case "admin":
          isAllowed = currentUserIsAdmin();
          break;
        case "recruiter":
          isAllowed = currentUserIsAdmin() || (currentUserIsRecruiter() && user?.authId === authId);
          break;
        case "candidate":
          isAllowed = (currentUserIsAdmin() || currentUserIsRecruiter()) && isFullyRegistered;
          break;
        default:
          isAllowed = false;
          break;
    }

    return isAllowed;
  }

  const canDelete = (type) => {
    let isAllowed = false;

    switch (type.toLowerCase())
    {
        case "admin":
          isAllowed = false;
          break;
        case "recruiter":
          isAllowed = currentUserIsAdmin() && recruiterFullAccess;
          break;
        case "candidate":
          isAllowed = (currentUserIsAdmin() || currentUserIsRecruiter()) && recruiterFullAccess;
          break;
        default:
          isAllowed = currentUserIsAdmin() && recruiterFullAccess;
          break;
    }

    return isAllowed;
  }

  const canBecome = (type, isFullyRegistered) => {
    let isAllowed = false;

    switch (type.toLowerCase())
    {
        case "admin":
          isAllowed = false;
          break;
        case "recruiter":
          isAllowed = false;
          break;
        case "candidate":
          isAllowed = isFullyRegistered;
          break;
        default:
          isAllowed = false;
          break;
    }

    return isAllowed && authProvider === 'AWSCognito';
  }

  const searchValueKeyPress = (e) => {
    if(e.keyCode == 13){
      handleSearch(authProvider, searchName, searchValue);
    }
 }

  const modalCandidateView = (
      <ViewOrAddUserModal
        open={showCandidateModal}
        handleClose={closeCandidateModal}
        userData={userCurrentData}
        handleUpdate={() => handleUpdate()}
        isAddNewCandidate = {isAddNewCandidate}
        authId={userId}
        authProvider={authProvider}
      />
  );

  const modalRecruiterView = (
    <ViewOrAddRecruiter
      open={showRecruiterModal}
      handleClose={closeRecruiterModal}
      userData={userCurrentData}
      handleUpdate={() => handleUpdate()}
      isAddNewRecruiter = {isAddNewRecruiter}
      authId={userId}
      isAdmin={currentUserIsAdmin()}
      authProvider={authProvider}
    />
);

  return (
    <>
      <div className="user-details" data-testid="user-details">
        <Box>
          {/* <Grid className="search-header-section" container spacing={1} alignItems="flex-end">
              <Grid item xs={12} sm={12} md={4} lg={4}>
                <FormControl className="auth-provider-fc">
                  <FormLabel id="auth-provider-label">Auth Provider</FormLabel>
                  <RadioGroup
                    row
                    aria-labelledby="auth-provider-label"
                    name="auth-provider-radio-buttons-group"
                    value={authProvider}
                    onChange={(e) => {
                      handleResetSearch(e.target.value);
                    }}
                    fullWidth
                  >
                    <FormControlLabel value="AWSCognito" control={<Radio color="primary"/>} label="Cognito" />
                    <FormControlLabel value="GoogleFirebase" control={<Radio color="primary"/>} label="Firebase" />
                  </RadioGroup>
                </FormControl>
              </Grid>
          </Grid> */}
          <Grid className="search-header-section" container spacing={1} alignItems="flex-end">
              <Grid item xs={6} sm={6} md={3} lg={2}>
                <FormControl className="search-name-fc">
                  <InputLabel id="search-type-label" className="search-type-label">Search Type</InputLabel>
                  <Select
                    variant="outlined"
                    id="searchname"
                    className="input-field search-name-select"
                    name="searchName"
                    labelId="search-type-label"
                    type="text"
                    size="small"
                    value={searchName}
                    onChange={(e) => {
                      setSearchName(e.target.value);
                    }}
                    fullWidth
                  >
                    {authProvider === 'AWSCognito' && (
                      <MenuItem value="name">Name</MenuItem>
                    )}
                    <MenuItem value="email">Email</MenuItem>
                    <MenuItem value="phone">Phone</MenuItem>
                    {authProvider === 'AWSCognito' && (
                      <MenuItem value="type">Type</MenuItem>
                    )}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={6} sm={6} md={5} lg={3}>
                <TextField
                  variant="outlined"
                  id="searchValue"
                  className="input-field"
                  name="searchValue"
                  label="Search Value"
                  type="text"
                  size="small"
                  value={searchValue}
                  onChange={(e) => {
                    setSearchValue(e.target.value);
                  }}
                  fullWidth
                  onKeyDown={searchValueKeyPress}
                  inputProps={{maxLength: 50}}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={3}>
                <IconButton
                    aria-label="row"
                    size="small"
                    className="text-button search"
                    onClick={() => {
                      handleSearch(authProvider, searchName, searchValue);
                    }}
                  >
                  Search
                </IconButton>
                <IconButton
                    aria-label="row"
                    size="small"
                    className="text-button reset"
                    onClick={() => {
                      handleResetSearch('AWSCognito');
                    }}
                  >
                  Reset
                </IconButton>
              </Grid>
              <Grid className="add-new-candidate" item xs={12} sm={12} md={6} lg={2}>
                <IconButton
                    aria-label="row"
                    size="small"
                    className="text-button add"
                    onClick={() => handleAddCandidateClick(null)}
                  >
                  Invite Candidate
                </IconButton>
            </Grid>
            <Grid className="add-new-recruiter" item xs={12} sm={12} md={6} lg={2}>
              <IconButton
                  aria-label="row"
                  size="small"
                  className="text-button add add-recruiter-button"
                  onClick={() => handleAddRecruiterClick(null)}
                  disabled={!currentUserIsAdmin()}
                >
                Invite Recruiter
              </IconButton>
            </Grid>
          </Grid>
        </Box>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 1480 }} aria-label="custom pagination table">
              <TableHead>
                <TableRow>
                    <TableCell style={{ width: 200 }} align="left"><h6>Name</h6></TableCell>
                    <TableCell style={{ width: 100 }} align="left"><h6>Type</h6></TableCell>
                    <TableCell style={{ width: 200 }} align="left"><h6>Phone</h6></TableCell>
                    <TableCell style={{ width: 200 }} align="left"><h6>Email</h6></TableCell>
                    <TableCell style={{ width: 100 }} align="left"><h6>Method</h6></TableCell>
                    <TableCell style={{ width: 300 }} align="left"><h6>Action</h6></TableCell>
                </TableRow>
              </TableHead>
              {userList && userList.length ? (
                <>
                  <TableBody>
                    {userList?.map((row, index) => (
                      <TableRow key={index}>
                        <TableCell style={{ width: 200 }} align="left">
                          <p>{row.name ? row.name : 'No Name'}</p>
                        </TableCell>
                        <TableCell style={{ width: 100 }} align="left">
                          <p>{row.userType.length ? row.userType : "No Type"}</p>
                        </TableCell>
                        <TableCell style={{ width: 200 }} align="left">
                          <p>{row.phone}
                          {row.phone && row.phoneConfirmed && (
                              <span className="verify-checkmark" title="Verified"><Check className="verified-check" /></span>
                            )}</p>
                        </TableCell>
                        <TableCell style={{ width: 200 }} align="left">
                          <p className="row-email">{row.emailAddress}
                          {row.emailAddress && row.emailAddressConfirmed && (
                              <span className="verify-checkmark" title="Verified"><Check className="verified-check" /></span>
                            )}</p>
                        </TableCell>
                        <TableCell style={{ width: 100 }} align="left">
                          <p>{row.signupMethod ? row.signupMethod : "None"}</p>
                        </TableCell>
                        <TableCell style={{ width: 300 }} align="left">
                          <IconButton
                              aria-label="row"
                              size="small"
                              className="text-button view-user action-button"
                              onClick={() => handleViewClick(row.authId, row.userType)}
                              disabled={!canView(row.userType, row.authId, row.isFullyRegistered)}
                            >
                              View
                          </IconButton>
                          <IconButton
                              aria-label="row"
                              size="small"
                              className="text-button delete-user action-button"
                              onClick={() => handleDeleteClick(row.authId, row.userType)}
                              disabled={!canDelete(row.userType)}
                            >
                              Delete
                          </IconButton>
                          <IconButton
                              aria-label="row"
                              size="small"
                              className="text-button become-user action-button"
                              onClick={() => handleBecomeClick(row.authId, row.userType)}
                              disabled={!canBecome(row.userType, row.isFullyRegistered)}
                            >
                              Become
                          </IconButton>
                          {!row.isFullyRegistered && (
                            <div className="corrupted-user">User profile corrupted. Please delete the profile and contact user to re-create it.</div>
                          )}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                  <TableFooter>
                    <TableRow>
                      <TableCell colSpan={12} align="right">
                        <Box sx={{ flexShrink: 0, ml: 2.5 }}>
                          <IconButton
                            onClick={handleBackButtonClick}
                            disabled={page === 0}
                            aria-label="previous page"
                          >
                            <p>Previous</p> {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
                          </IconButton>
                          <IconButton
                            onClick={handleNextButtonClick}
                            disabled={!paginationToken.next}
                            aria-label="next page"
                          >
                            {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />} <p>Next</p>
                          </IconButton>
                        </Box>
                      </TableCell>
                    </TableRow>
                  </TableFooter>
                </>
                ):
                <>
                    <TableBody>
                        <TableRow>
                          <TableCell style={{ width: 250 }} align="left">
                            <p>-</p>
                          </TableCell>
                          <TableCell style={{ width: 100 }} align="left">
                            <p>-</p>
                          </TableCell>
                          <TableCell style={{ width: 200 }} align="left">
                          <p>-</p>
                          </TableCell>
                          <TableCell style={{ width: 200 }} align="left">
                            <p className="row-email">-</p>
                          </TableCell>
                          <TableCell style={{ width: 100 }} align="left">
                            <p>-</p>
                          </TableCell>
                          <TableCell style={{ width: 300 }} align="left">
                            <IconButton
                                aria-label="row"
                                size="small"
                                className="text-button view-user action-button"
                                disabled={true}
                              >
                                View
                            </IconButton>
                            <IconButton
                                aria-label="row"
                                size="small"
                                className="text-button delete-user action-button"
                                disabled={true}
                              >
                                Delete
                            </IconButton>
                            <IconButton
                                aria-label="row"
                                size="small"
                                className="text-button become-user action-button"
                                disabled={true}
                              >
                                Become
                            </IconButton>
                          </TableCell>
                        </TableRow>
                    </TableBody>
                    <TableFooter>
                      <TableRow>
                        <TableCell colSpan={12} align="right">
                          <Box sx={{ flexShrink: 0, ml: 2.5 }}>
                            <IconButton
                              disabled={true}
                              aria-label="previous page"
                            >
                              <p>Previous</p> {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
                            </IconButton>
                            <IconButton
                              disabled={true}
                              aria-label="next page"
                            >
                              {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />} <p>Next</p>
                            </IconButton>
                          </Box>
                        </TableCell>
                      </TableRow>
                    </TableFooter>
                </>
              }
              </Table>
            </TableContainer>
      </div>
      {showCandidateModal && modalCandidateView}
      {candidateConfirmationOpen && (
        <ConfirmationModal
          open={candidateConfirmationOpen}
          confirm={() => confirmDeletion("Candidate")}
          handleClose={() => setCandidateConfirmationOpen(false)}
          loading={isDeleting}
          message="Are you sure you want to delete this user?"
          cancelButtonText="Cancel"
        />
      )}

      {showRecruiterModal && modalRecruiterView}
        {recruiterConfirmationOpen && (
          <ConfirmationModal
            open={recruiterConfirmationOpen}
            confirm={() => confirmDeletion("Recruiter")}
            handleClose={() => setRecruiterConfirmationOpen(false)}
            loading={isDeleting}
            message="Are you sure you want to delete this user?"
            cancelButtonText="Cancel"
          />
        )}
    </>
  );
};

export default UserDataCard;
