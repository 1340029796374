//import React from "react";
import { useSelector, useDispatch} from "react-redux";

import "./RecruiterDataCard.scss";

import React, { useState, useEffect, useRef } from 'react';
import Avatar from "@material-ui/core/Avatar";
import { useTheme } from '@material-ui/core/styles';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';

import { 
  Box,
  Button,
  Grid,
  Table,
  TableBody,
  TableHead,
  TableCell,
  TableContainer,
  TableFooter,
  TableRow,
  Paper,
  IconButton,
  TextField,
 } from "@material-ui/core";

import { deleteRecruiterData, getRecruiterList, getRecruiterDetails, searchRecruiters } from "../../services/recruiterDataStore";
import ConfirmationModal from "../../../common/ConfirmationModal/ConfirmationModal";
import ViewOrAddRecruiter from "../Modals/ViewOrAddRecruiter";
import { notify } from "../../../../toasts/toasts";

const RecruiterDataCard = ({ handleClick, userData }) => {
  const dispatch = useDispatch();
  const [userList, setUserList] = useState(userData?.userList || []);
  const [userId, setUserId] = useState();
  const [userCurrentData, setCurrentUserData] = useState({});
  const [paginationToken, setCurrentPaginationTokens] = useState({});
  const [paginationDataList, setPaginationDataList] = useState([]);
  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const [page, setPage] = useState(0);
  const [isDeleting, setDeleting] = useState(false);
  const prevPageRef = useRef();
  const [showModal, setModal] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isAddNewCandidate, setIsAddNewCandidate] = useState(false);
  const [searchName, setSearchName] = useState('');
  const [searchEmail, setSearchEmail] = useState('');

  let prevPage = prevPageRef.current;

  const theme = useTheme();

  const handleChangePage = (event, newPage) => {
    prevPageRef.current = page;
    prevPage = prevPageRef.current;

    setPage(newPage);
  };

  const handleDeleteClick = async (id) => {
    setConfirmationOpen(true);
    setUserId(id);
  };

  const confirmDeletion = async () => {
    setDeleting(true);
    try {
      const response = await deleteRecruiterData(userId);

      if (response) {
        setConfirmationOpen(false);
        setDeleting(false);

        const currentPage = page - 1 !== - 1 ? page - 1 : 0;
        const paginationToken = paginationDataList[currentPage]?.nextPaginationToken || null;
        const dataList = await getRecruiterList(paginationToken);
  
        if (dataList) {
          const updatedCandidatePages = [...paginationDataList];
          updatedCandidatePages[currentPage].userList = dataList.userList;

          setUserList(dataList.userList);
          setPaginationDataList(updatedCandidatePages);
        } 
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleAddClick = async () => {
    setIsAddNewCandidate(true);
    setCurrentUserData({});
    setIsModalOpen(true);
  };

  const handleViewClick = async (id) => {
    setUserId(id);
    let userData = await getRecruiterDetails(id);

    if(userData && userData.id) {
      setCurrentUserData(userData);
      setIsModalOpen(true);
    } else {
      notify("Error retrieving details.", "error");
    }
  };

  const handleUpdate = async () => {
    const currentPage = isAddNewCandidate ? 0 : page - 1 !== - 1 ? page - 1 : 0;
    const paginationToken = isAddNewCandidate ? null : paginationDataList[currentPage]?.nextPaginationToken || null;
    const dataList = await getRecruiterList(paginationToken);

    if (dataList) {
      const updatedCandidatePages = [...paginationDataList];
      updatedCandidatePages[currentPage].userList = dataList.userList;

      setUserList(dataList.userList);
      setPaginationDataList(updatedCandidatePages);
      setIsAddNewCandidate(false);
    } 
  }

  const handleBackButtonClick = (event) => {
    handleChangePage(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    handleChangePage(event, page + 1);
  };

  const handleSearch = async (name, email) => {
    const dataList = await searchRecruiters(name, email);

    if (dataList) {
      setUserList(dataList.userList);
      setCurrentPaginationTokens({next: dataList.nextPaginationToken});
      setPaginationDataList([{
          page: 0, 
          userList: dataList.userList,
          nextPaginationToken: dataList.nextPaginationToken 
      }]);
    } 
  }

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      if (userCurrentData && isModalOpen) {
        openModal();
      }
    }
    return () => (mounted = false);
  }, [isModalOpen]);

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      if (userData?.userList && userData.userList.length) {
        setUserList(userData.userList);
        setCurrentPaginationTokens({next: userData.nextPaginationToken});
        setPaginationDataList([
          {
            page: page, 
            userList: userData.userList,
            nextPaginationToken: userData.nextPaginationToken 
          }]);
      }
    }
    return () => (mounted = false);
  }, [userData]);

  useEffect(() => {
    let mounted = true;
    prevPageRef.current = page;
    prevPage = prevPageRef.current;

    const load = async () => {
      const dataList = await getRecruiterList(paginationToken.next);

      if (dataList?.userList.length) {
        setUserList(dataList.userList);
        setCurrentPaginationTokens({next: dataList.nextPaginationToken});
        setPaginationDataList([...paginationDataList, {
            page: page, 
            userList: dataList.userList,
            nextPaginationToken: dataList.nextPaginationToken 
        }]);
      } 
    };

    if (mounted) {
      if(prevPage !== undefined && prevPage !== page && page > prevPage && !paginationDataList[page]) {
        load();
      } else if (prevPage !== page && paginationDataList[page]?.userList.length) {
        const {userList, nextPaginationToken } = paginationDataList[page];
        setUserList(userList);
        setCurrentPaginationTokens({next: nextPaginationToken});
      }
    }
    return () => (mounted = false);
  }, [page]);

  const openModal = () => {
    setModal(true);
  };

  const closeModal = () => {
    setModal(false);
    setIsModalOpen(false);
    setIsAddNewCandidate(false);
  };

  const modalView = (
      <ViewOrAddRecruiter
        open={showModal}
        handleClose={closeModal}
        userData={userCurrentData}
        handleUpdate={() => handleUpdate()}
        isAddNewCandidate = {isAddNewCandidate}
        cognitoId={userId}
      />
  );

  return (
    <>
      <div className="user-details" data-testid="user-details">
        {/* <Box>
          <Grid className="search-header-section" container spacing={1} alignItems="flex-end">
              <Grid item xs={4} sm={4} md={3} lg={3}>
                <TextField
                  variant="outlined"
                  id="searchname"
                  className="input-field"
                  name="searchName"
                  label="Search by name"
                  type="text"
                  size="small"
                  value={searchName}
                  onChange={(e) => {
                    setSearchName(e.target.value);
                  }}
                  fullWidth
                  inputProps={{maxLength: 50}}
                />
              </Grid>
              <Grid item xs={4} sm={4} md={3} lg={3}>
                <TextField
                  variant="outlined"
                  id="searchEmail"
                  className="input-field"
                  name="serachEmail"
                  label="Search by email"
                  type="text"
                  size="small"
                  value={searchEmail}
                  onChange={(e) => {
                    setSearchEmail(e.target.value);
                  }}
                  fullWidth
                  inputProps={{maxLength: 50}}
                />
              </Grid>
              <Grid item xs={4} sm={4} md={2} lg={2}>
              <IconButton
                    aria-label="row"
                    size="small"
                    className="text-button  search"
                    onClick={() => {
                      handleSearch(searchName, searchEmail);
                    }}
                  >
                  Search
                </IconButton>
              </Grid>
              <Grid className="add-new-candidate" item xs={12} sm={12} md={4} lg={4}>
                <IconButton
                    aria-label="row"
                    size="small"
                    className="text-button add"
                    onClick={() => handleAddClick(null)}
                  >
                  Invite Recruiter
                </IconButton>
            </Grid>
          </Grid>
        </Box> */}
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 1480 }} aria-label="custom pagination table">
              <TableHead>
                <TableRow>
                    <TableCell style={{ width: 40 }} align="left"></TableCell>
                    <TableCell style={{ width: 250 }} align="left"><h6>Name</h6></TableCell>
                    <TableCell style={{ width: 250 }} align="left"><h6>Type</h6></TableCell>
                    <TableCell style={{ width: 250 }} align="left"><h6>Team</h6></TableCell>
                    <TableCell style={{ width: 250 }} align="left"><h6>Email</h6></TableCell>
                    <TableCell style={{ width: 350 }} align="left"><h6>Action</h6></TableCell>
                </TableRow>
              </TableHead>
              {userList && userList.length ? (
                <>
                  <TableBody>
                    {userList?.map((row, index) => (
                      <TableRow key={index}>
                        <TableCell style={{ width: 40 }} align="left">
                          <Avatar 
                            alt="profile photo"
                            src={
                              row.avatar?.src ||
                              "https://d2wqn2ab54ir3q.cloudfront.net/images/avatar-placeholder.png"
                            }
                            className="profile-photo"
                          />
                        </TableCell>
                        <TableCell style={{ width: 250 }} align="left">
                          <p>{row.name ? row.name : 'No Name'}</p>
                        </TableCell>
                        <TableCell style={{ width: 250 }} align="left">
                          <p>{row.userType.length ? row.userType : "No Type"}</p>
                        </TableCell>
                        <TableCell style={{ width: 250 }} align="left">
                          <p>{row.team ? row.team : "No Team"}</p>
                        </TableCell>
                        <TableCell style={{ width: 250 }} align="left">
                          <p className="row-email">{row.emailAddress}</p>
                        </TableCell>
                        <TableCell style={{ width: 300 }} align="left">
                          <IconButton
                              aria-label="row"
                              size="small"
                              className="text-button edit"
                              onClick={() => handleViewClick(row.cognitoId)}
                            >
                              View
                          </IconButton>
                          <IconButton
                              aria-label="row"
                              size="small"
                              className="text-button delete"
                              onClick={() => handleDeleteClick(row.cognitoId)}
                            >
                              Delete
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                  <TableFooter>
                    <TableRow>
                      <TableCell colSpan={12} align="right">
                        <Box sx={{ flexShrink: 0, ml: 2.5 }}>
                          <IconButton
                            onClick={handleBackButtonClick}
                            disabled={page === 0}
                            aria-label="previous page"
                          >
                            <p>Previous</p> {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
                          </IconButton>
                          <IconButton
                            onClick={handleNextButtonClick}
                            disabled={!paginationToken.next}
                            aria-label="next page"
                          >
                            {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />} <p>Next</p>
                          </IconButton>
                        </Box>
                      </TableCell>
                    </TableRow>
                  </TableFooter>
                </>
                ): 
                <>
                    <TableBody>
                        <TableRow>
                          <TableCell style={{ width: 40 }} align="left">
                            <Avatar 
                              alt="profile photo"
                              src={
                                "https://d2wqn2ab54ir3q.cloudfront.net/images/avatar-placeholder.png"
                              }
                              className="profile-photo"
                            />
                          </TableCell>
                          <TableCell style={{ width: 250 }} align="left">
                          <p>-</p>
                        </TableCell>
                        <TableCell style={{ width: 250 }} align="left">
                          <p>-</p>
                        </TableCell>
                        <TableCell style={{ width: 250 }} align="left">
                          <p>-</p>
                        </TableCell>
                        <TableCell style={{ width: 250 }} align="left">
                          <p className="row-email">-</p>
                        </TableCell>
                          <TableCell style={{ width: 300 }} align="left">
                            <IconButton
                                aria-label="row"
                                size="small"
                                className="text-button edit"
                                disabled={true}
                              >
                                View
                            </IconButton>
                            <IconButton
                                aria-label="row"
                                size="small"
                                className="text-button delete"
                                disabled={true}
                              >
                                Delete
                            </IconButton>
                          </TableCell>
                        </TableRow>
                    </TableBody>
                    <TableFooter>
                      <TableRow>
                        <TableCell colSpan={12} align="right">
                          <Box sx={{ flexShrink: 0, ml: 2.5 }}>
                            <IconButton
                              disabled={true}
                              aria-label="previous page"
                            >
                              <p>Previous</p> {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
                            </IconButton>
                            <IconButton
                              disabled={true}
                              aria-label="next page"
                            >
                              {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />} <p>Next</p>
                            </IconButton>
                          </Box>
                        </TableCell>
                      </TableRow>
                    </TableFooter>
                </>
              }
              </Table>
            </TableContainer>
      </div>
      {showModal && modalView}
      {confirmationOpen && (
        <ConfirmationModal
          open={confirmationOpen}
          confirm={() => confirmDeletion()}
          handleClose={() => setConfirmationOpen(false)}
          loading={isDeleting}
          message="Are you sure you want to delete this candidate?"
          cancelButtonText="Cancel"
        />
      )}
    </>
  );
};

export default RecruiterDataCard;