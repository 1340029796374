import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Grid from "@material-ui/core/Grid"
import Item from "@material-ui/core/Grid"
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import NavLayout from "../../NavLayout/NavLayout";
import Resources from "../Resources/Resources";
import ReferCode from "../ReferCode/ReferCode";
import CandidateData from "../../../components/CandidateData/CandidateData"

import { getCand } from "../../../actions/candidateEntry";
import HighestPay from "./HighestPay/HighestPay";
import MatchedJobs from "./MatchedJobs/MatchedJobs";
import FeaturedJobs from "./FeaturedJobs/FeaturedJobs";
import CareTeam from "./CareTeam/CareTeam";
import Hidden from "@material-ui/core/Hidden";

import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

import "./Dashboard.scss";
import EmailVerify from "./EmailVerify/EmailVerify";

function tabProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const Dashboard = (props) => {
  const dispatch = useDispatch();
  const {candidateData: userData, auth}  = useSelector((state) => state || {});
  const {user} = auth || {};

  const { profileProgress } = useSelector((state) => state);

  const { completed } = profileProgress || 0;
  const { total } = profileProgress || 10;

  const [isLoading, setIsLoading] = useState(false);
  const [tabVal, setTabVal] = useState(0);

  const handleChange = (event, newValue) => {
    setTabVal(newValue);
  };

  useEffect(() => {
    let mounted = true;
    const loadData = async () => {
      if (Object.keys(userData).length === 0) {
        setIsLoading(true);
        await dispatch(getCand());
        setIsLoading(false);
      }
    };
    if (mounted) {
      loadData();
    }

    return () => {
      mounted = false;
    };
  }, []);

  const responsive = {
    mobile: {
      breakpoint: { max: 690, min: 0 },
      items: 1,
      slidesToSlide: 1,
      paritialVisibilityGutter: 30
    }
  };

  return (
    <NavLayout headerText="Dashboard">
      <Grid container className="dashboard-main-container">
        <Grid item xs={12} lg={9} className="dashboard-main-content">
          {user && (!user?.userName.includes('google_') && !user?.userName.includes('facebook_')) &&<EmailVerify/>}
          <Grid item>
            <Item>
              <Hidden mdDown lgUp>
                {completed < total && (
                  <CandidateData pageView="dashboard"></CandidateData>
                )}
              </Hidden>
              <Hidden smUp>
                {completed < total && (
                    <Carousel 
                      centerMode={false}
                      showDots={true} 
                      responsive={responsive} 
                      swipeable={true} 
                      minimumTouchDrag={50}
                      slidesToSlide={1}
                      removeArrowOnDeviceType={["tablet", "mobile"]} 
                      containerClass="matched-jobs explore-area highlight"
                      itemClass={"carousel-data-slide"}>
                        <CandidateData pageView="dashboard"></CandidateData>
                        <CareTeam />
                    </Carousel>  
                  )}
                  {completed === total && (
                    <CareTeam />
                  )}
              </Hidden>
              <div className="hide-md-sm">
                <CareTeam /> 
              </div>
            </Item>
            <Hidden mdUp>
                <div className="px-4">
                  <h2 className="text-lg leading-6 font-medium text-gray-900 mt-4">Jobs</h2>
                  <Tabs value={tabVal} onChange={handleChange} aria-label="job's tabs" className="tab-wrapper">
                    <Tab label="Featured" {...tabProps(0)} />
                    <Tab label="Matches" {...tabProps(1)} />
                    <Tab label="Highest Pay" {...tabProps(2)} />
                  </Tabs>
                </div>
              
              <div role="tabpanel" hidden={tabVal !== 1} id={`simple-tabpanel-1`} aria-labelledby={`simple-tab-1`}>
                <MatchedJobs />
              </div>

              <div role="tabpanel" hidden={tabVal !== 0} id={`simple-tabpanel-0`} aria-labelledby={`simple-tab-0`}>
                <FeaturedJobs />
              </div>

              <div role="tabpanel" hidden={tabVal !== 2} id={`simple-tabpanel-2`} aria-labelledby={`simple-tab-2`}>
                <HighestPay />
              </div>
            </Hidden>

            <Hidden smDown>
              <FeaturedJobs />
              <MatchedJobs />
              <HighestPay />
            </Hidden>
          </Grid>
        </Grid>

        <Grid item xs={12} md={4} lg={3} className="dashboard-sidebar">
          <Grid container spacing={3}>
            <Hidden mdDown>
              {completed < total && (
                <CandidateData pageView="dashboard"></CandidateData>
              )}
            </Hidden>
            <ReferCode />
            <Resources />
          </Grid>
        </Grid>
      </Grid>
    </NavLayout>
  );
};

export default Dashboard;
