import React from "react";

import "./PayDisclaimer.scss";

const PayDisclaimer = () => {
  return (
      <div className="pay-disclaimer">
        *Weekly Pay Estimates available on Advantis Connect are intended only to approximate weekly pay, based on various factors (market conditions, patient acuity, and other conditions) that exist at the time the Weekly Pay Estimates are published.  Because market conditions are constantly in flux, weekly pay rates are subject to change at any time.  Accordingly, the Weekly Pay Estimates shown on Advantis Connect may be higher or lower than the actual weekly pay an applicant is offered.  Applicants offered a position with Advantis shall obtain their weekly pay rates from their Travel Assignment Contract (TAC) – not the Weekly Pay Estimates available on Advantis Connect.
      </div>
  );
};

export default PayDisclaimer;
