import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Info, Check, Close } from "@material-ui/icons";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  TextField,
  CircularProgress
} from "@material-ui/core";

import PrimaryButton from "../../../common/Buttons/PrimaryButton";

import { verifyEmail } from "../../../../actions/auth";
import authService from "../../../../services/auth.service";

import "./EmailVerify.scss";
import { clearMessage } from "../../../../actions/message";

const EmailVerify = () => {
  const [verifyOpen, setVerifyOpen] = useState(false);
  const [confirmingEmailAddress, setConfirmingEmailAddress] = useState(false);
  const { user } = useSelector((state) => state.auth || {}) ;
  const {message} = useSelector((state) => state.message || {})
  const { emailAddress, emailAddressConfirmed } = user || {};

  const dispatch = useDispatch();

  const confirmEmailAddress = async (code) => {
    dispatch(clearMessage());
    setConfirmingEmailAddress(true);
    await dispatch(verifyEmail(code));
    setConfirmingEmailAddress(false);
  };

  const sendCode = () => {
    dispatch(clearMessage());
    authService.verifyEmailAddress();
    verifyOpen === false && setVerifyOpen(true);
  };

  return (
    <>
      {emailAddressConfirmed === false && (
        <div className="verify-banner" onClick={sendCode}>
          <div className="message">
            <Info className="small-blue-icon" />
            <p>
              Welcome to AdvantisConnect! Click to verify your email and get
              started.
            </p>
          </div>
        </div>
      )}
      {verifyOpen && (
        <Dialog
          disablePortal
          open={verifyOpen}
          onClose={() => setVerifyOpen(false)}
        >
          {emailAddressConfirmed === false ? (
            <>
              <DialogTitle id="alert-dialog-title" className="verify-title">
                Verify your email
              </DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  Please enter the 6 digit code sent to{" "}
                  <span>{emailAddress}</span>.
                </DialogContentText>
                {!confirmingEmailAddress ? (
                  <div className="verify">
                    <TextField
                      id="email-address-code"
                      label="Email Code"
                      className="input-field"
                      name="email-address-code"
                      type="number"
                      onChange={(e) => {
                        if (e.target.value.length === 6) {
                          confirmEmailAddress(e.target.value);
                        }
                      }}
                      disabled={confirmingEmailAddress}
                      inputProps={{
                        style: { fontSize: 14, width: 100 },
                        maxLength: 6
                      }}
                      InputLabelProps={{ style: { fontSize: 14 } }}
                    />
                    <p className="alert alert-danger" role="alert">
                      {message}
                    </p>
                    <div className="options">
                      <p>Didn't receive a code?</p>
                      <PrimaryButton
                        text="Resend Code"
                        buttonClass="transparent-button"
                        handleClick={sendCode}
                      />
                    </div>
                  </div>
                ) : (
                  <div className="confirming">
                    <CircularProgress />
                  </div>
                )}
                <div className="dialog-buttons"></div>
              </DialogContent>
            </>
          ) : (
            <div className="success">
              <div className="dialog-header">
                <div
                  className="close-dialog"
                  onClick={() => setVerifyOpen(false)}
                >
                  <Close />
                </div>
              </div>
              <div className="verify-checkmark">
                <Check className="verified-check" />
              </div>
              <DialogTitle id="alert-dialog-title" className="dialog-title">
                Verified
              </DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  Congratulations! You have successfully verified your email
                  address.
                </DialogContentText>
              </DialogContent>
            </div>
          )}
        </Dialog>
      )}
    </>
  );
};

export default EmailVerify;
