import React, { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, useLocation, useHistory } from "react-router-dom";
import { PersonOutline, Lock } from "@material-ui/icons";
import {
  CircularProgress,
  InputAdornment,
  Grid,
  TextField,
  Box
} from "@material-ui/core";
import Form from "react-validation/build/form";
import CheckButton from "react-validation/build/button";

import TopLogoLayout from "../../TopLogoLayout/TopLogoLayout";
import InfoCarousel from "../InfoCarousel/InfoCarousel";
import { GoogleIcon, FacebookIcon, VisibilityIcon } from "../../common/Icons";
import PrimaryButton from "../../common/Buttons/PrimaryButton";
import LinkButton from "../../common/Buttons/LinkButton";

import "./Login.scss";
import { login } from "../../../actions/auth";
import { clearMessage } from "../../../actions/message";
import { Button } from "@material-ui/core";
import { Auth } from 'aws-amplify';
import userService from "../../../services/user.service";
import userHelper from "../../../helpers/user";

const Login = (props) => {
  const form = useRef();
  const checkBtn = useRef();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [userType, setUserType] = useState("")

  const [showPassword, setShowPassword] = useState({
    isShown: false,
    text: "show"
  });
  const [fieldError, setFieldError] = useState({});
  const [loading, setLoading] = useState(false);

  const timer = React.useRef();

  const [success, setSuccess] = React.useState(false);

  const { isLoggedIn } = useSelector((state) => state.auth);
  const { message } = useSelector((state) => state.message);

  const dispatch = useDispatch();


  useEffect(() => {
    setLoading(false);
  }, [message]);

  const onChangeUsername = (e) => {
    const username = e.target.value;
    setUsername(username?.trim());
  };

  const onChangePassword = (e) => {
    const password = e.target.value;
    setPassword(password);
  };

  const updateUserType = async (username) => {
    try {
      let result = await userService.getUserType(username);
      setUserType(result);
      return result
    } catch(e) {
      console.log('Verifying signup method: '+ e);
    }
  }

  const isAdminHost = () => window.location.hostname.includes("admin.connect.advantismed.com") || 
      window.location.hostname.includes("admin.staging.connect.advantismed.com");

  const handleFormValidation = (username, password) => {
    let errors = {};
    let formIsValid = true;
    if (username.length === 0) {
      errors["username"] = "A username or email is required";
      formIsValid = false;
    }

    if (isAdminHost() && 
      !username.endsWith("@advantismed.com") && !username.endsWith("@advantisglobal.com")) {
      errors["username"] = "Invalid username";
      formIsValid = false;
    }

    if (password.length === 0) {
      errors["password"] = "A password is required";
      formIsValid = false;
    }

    setFieldError(errors);
    return formIsValid;
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    setFieldError({});
    dispatch(clearMessage());
    let username = e.target.elements.username.value.trim();
    let password = e.target.elements.password.value;
    const validForm = handleFormValidation(
      username,
      password
    );


    if (validForm) {
      setLoading(true);
      let ut = await updateUserType(username);

      switch(ut) {
        case(userHelper.types.GOOGLE):
        case(userHelper.types.FACEBOOK): handleSocialMediaUser(ut);
                        break;
        case(userHelper.types.INTERNAL): handleInternalUser();
                        break;
        default:  handleNotFoundUser();
                  break;
      }
    }
  };

  const handleInternalUser =  (e) => {
    try {
      dispatch(login(username, password));
    } catch (error) {
      console.log(error);
    }
  }
  const handleSocialMediaUser =  (ut) => {
    let errors = {}
    errors["username"] = "Email was registered using " + ut + " Sign In";
    setFieldError(errors);
    setLoading(false);
  }

  const handleNotFoundUser =  (e) => {
    let errors = {}
    errors["username"] = "User not found";
    setFieldError(errors);
    setLoading(false);
  }

  const handleClickShowPassword = () => {
    setShowPassword({
      isShown: !showPassword.isShown,
      text: showPassword.isShown ? "show" : "hide"
    });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
      <div className="login-container-main">
        <div className={`login-form-container ${
              isAdminHost() ? "admin" : ""
              }`}>
        <TopLogoLayout>
          <Form
            data-testid="form"
            onSubmit={handleLogin}
            ref={form}
            className="login-form"
          >
            <h1 className="mt-4 leading-6 text-lg font-medium text-gray-900 text-center">Login to your account</h1>
            <Box width={1} mb={5} mt={3}>
              <Grid
                container
                spacing={1}
                alignItems="flex-end"
                justify="center"
                className="input-field"
              >
                <Grid item>
                  <PersonOutline className="input-icon" />
                </Grid>
                <Grid item xs={10}>
                  <TextField
                    id="username"
                    className="input-field"
                    name="username"
                    label="Username or Email"
                    value={username}
                    onChange={onChangeUsername}
                    error={
                      message !== undefined || fieldError["username"] !== undefined
                    }
                    helperText={fieldError.username ? fieldError.username : ""}
                    fullWidth
                  />
                </Grid>
              </Grid>
            </Box>
            <Box width={1} my={1}>
              <Grid container spacing={1} alignItems="flex-end" justify="center">
                <Grid item>
                  <Lock className="input-icon" />
                </Grid>
                <Grid item xs={10}>
                  <TextField
                    id="password"
                    className="input-field"
                    name="password"
                    label="Password"
                    type={showPassword.isShown ? "text" : "password"}
                    value={password}
                    onChange={onChangePassword}
                    fullWidth
                    error={
                      message !== undefined || fieldError["password"] !== undefined
                    }
                    helperText={fieldError.password ? fieldError.password : ""}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="start">
                          <div
                            className="show-password"
                            aria-label="toggle visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                          >
                            <VisibilityIcon
                              active={showPassword.isShown}
                            ></VisibilityIcon>
                          </div>
                        </InputAdornment>
                      )
                    }}
                  />
                </Grid>
              </Grid>
            </Box>
            <Box
              width={1}
              display="flex"
              flexDirection="row-reverse"
              justify="flex-end"
              mb={2}
            >
              <LinkButton text="Forgot?" url="/forgot" textButton/>
            </Box>

            {message && (
              <div className="form-group">
                <div className="alert alert-danger login-message" role="alert">
                  {message}
                </div>
              </div>
            )}

          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            width="90%"
            margin="5%"
          >
            <PrimaryButton type="submit" text="Login" disabled={loading} loading={loading} buttonClass="color-button"/>
          </Box>

            {!isAdminHost() && (
              <div>
                <div className="sign-up-with text-gray-400">Or sign in with</div>
                <Box
                  display="flex"
                  justifyContent="space-around"
                  width="80%"
                  margin="10%"
                  className="social-media-login"
                >
                  <Button className="social-login-button" onClick={() => { Auth.federatedSignIn({provider: 'Google'})}}>
                    <GoogleIcon />
                  </Button>

                  <Button className="social-login-button" onClick={() => { Auth.federatedSignIn({provider: 'Facebook'})}}>
                    <FacebookIcon iconClass="social-media-icon facebook"/>
                  </Button>
                </Box>
              </div>
            )}

            <CheckButton style={{ display: "none" }} ref={checkBtn} />
            <p className="sign-up text-sm text-gray-400">
              Don't have an account?
              <span>
                <LinkButton text="Sign up here" url={isAdminHost() ? "/recruitersignup" : "/signup"} textButton handleClick={localStorage.removeItem("isSocialSignup")}/>
              </span>
            </p>
          </Form>
          </TopLogoLayout>
        </div>
        {!isAdminHost() && (
          <div className="login-info-container">
              <InfoCarousel/>
          </div>
        )}
      </div>
  );
};

export default Login;
