
export const SET_NAV = "SET_NAV";

const initialState = {
    navig: 'profile'
  };

export default function (state = initialState, action) {
  
//    console.log('######nav reducer', state, action);
  
    switch(action.type) {
      
        case SET_NAV:
           
          return {
            navig: action.navig
          };
      
        default:
          return state;
      }

    return state;
  }
  