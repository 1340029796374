import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import DateFnsUtils from "@date-io/date-fns";

import DetailCard from "../../../common/DetailCard/DetailCard";
import ConfirmationModal from "../../../common/ConfirmationModal/ConfirmationModal";
import { deleteData } from "../../../../actions/candidateEntry";
import { getCandidateFile } from "../../../../services/candidateData";
import PrimaryButton from "../../../common/Buttons/PrimaryButton";

import { EditIcon, TrashIcon } from "../../Icons";
import { Grid } from "@material-ui/core";

const dateFns = new DateFnsUtils();

const CertificationDetails = ({ handleClick }) => {
  const dispatch = useDispatch();
  const { candidateData } = useSelector((state) => state);
  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const [isDeleting, setDeleting] = useState(false);
  const [certId, setCertId] = useState();

  const getFile = (fileId, fileName) => {
    getCandidateFile(fileId, fileName);
  };

  const handleDeleteClick = async (id) => {
    setConfirmationOpen(true);
    setCertId(id);
  };

  const confirmDeletion = async () => {
    setDeleting(true);
    try {
      await dispatch(deleteData('certification', certId));
    } catch (err) {
      console.log(err);
    }
  };

  const { certifications: certificationList } = candidateData;

  return (

    <>
      <div data-testid="certification-details">
        {certificationList && (
          <DetailCard title="Certifications" handleClick={handleClick}>
            {certificationList.map((certification, index) => (
              <div className="details-card" key={index}>
                <Grid container>
                  <Grid item className="work-details" xs={9} sm={9} md={9} lg={9}>
                  <div className="details">
                    <p className="text-sm font-smalltext-gray-900">Certification: {certification.name}</p>
                    {certification.expirationDate && (
                    <div className="certificate-data" key={index}>
                      <p className="text-sm font-smalltext-gray-900"> 
                        {"Expires: " + dateFns.format(new Date(certification.expirationDate), "MMMM yyyy")}
                      </p>                  
                      <PrimaryButton
                        buttonClass="link-button"
                        text={certification.fileName}
                        handleClick={() => getFile(certification.fileId, certification.fileName)}
                      />
                    </div> 
                    )}
                  </div>
                  </Grid>
                  <Grid item className="main-content buttons" xs={3} sm={3} md={3} lg={3}>
                    <div onClick={handleClick} data-testid="editClick" className="icon-button edit">
                      <EditIcon iconClass="blue-icon" />
                    </div>
                    <div onClick={() => handleDeleteClick(certification.id)} data-testid="deleteClick" className="icon-button">
                      <TrashIcon className="delete-icon" />
                    </div>
                  </Grid>
                </Grid>
              </div>
            ))}
          </DetailCard>
        )}
      </div>
      {confirmationOpen && (
        <ConfirmationModal
          open={confirmationOpen}
          confirm={() => confirmDeletion()}
          handleClose={() => setConfirmationOpen(false)}
          loading={isDeleting}
          message="Are you sure you want to delete this certification?"
          cancelButtonText="Cancel"
        />
      )}
    </>
  );
};

export default CertificationDetails;
