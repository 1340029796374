import { combineReducers } from "redux";
import { candidate } from "./Candidate";
import auth from "./auth";
import message from "./message";
import nav from "./nav";
import { candidateData } from "./candidateData";
import profileProgress from "./progress";
import states from "./datalists";
import contactValidation from "./contactValidation";
import jobDetails from "./jobDetails";
import jobFilters from "./jobFilters";
import featuredJobs from "./featuredJobs";
import matchedJobs from "./matchedJobs";
import favoriteJobs from "./favoriteJobs";
import jobSearch from "./jobSearch";
import applications from "./applications";
import highestPay from "./highestPay";
import similarJobs from "./similarJobs";

const appReducer = combineReducers({
  auth,
  message,
  nav,
  candidateData,
  profileProgress,
  states,
  contactValidation,
  jobDetails,
  jobFilters,
  jobSearch,
  featuredJobs,
  matchedJobs,
  favoriteJobs,
  applications,
  highestPay,
  similarJobs
});

const rootReducer = (state, action) => {
  // when a logout action is dispatched it will reset redux state
  if (action.type === "LOGOUT") {
    state = undefined;
  }

  return appReducer(state, action);
};

export default rootReducer;
