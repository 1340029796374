import React from "react";
import EducationForm from "../Education/EducationForm";
import JobPreferencesForm from "../JobPreferences/JobPreferencesForm";
import LicenseForm from "../Licenses/LicenseForm";
import PersonalInformationForm from "../PersonalInformation/PersonalInformationForm";
import ProfessionalSummaryForm from "../ProfessionalSummary/ProfessionalSummaryForm";
import CertificationForm from "../Certifications/CertificationForm";
import ReferenceForm from "../References/ReferenceForm";
import SkillsNewChecklistForm from "../SkillsChecklist/SkillsNewChecklistForm";
import SpecialtyForm from "../Specialties/SpecialtyForm";
import WorkExperience from "../WorkExperience/WorkExperienceForm";
import ResumeUploadForm from "../ResumeUpload/ResumeUploadForm";

const CandidateForm = (props) => {
  const { type } = props;
  switch (type) {
    case "personalInformation":
      return <PersonalInformationForm {...props} />;
    case "professionalSummary":
      return <ProfessionalSummaryForm {...props} />;
    case "education":
      return <EducationForm {...props} />;
    case "work":
      return <WorkExperience {...props} />;
    case "specialties":
      return <SpecialtyForm {...props} />;
    case "license":
      return <LicenseForm {...props} />;
    case "certifications":
      return <CertificationForm {...props} />;
    case "jobPreferences":
      return <JobPreferencesForm {...props} />;
    case "skills":
      return <SkillsNewChecklistForm {...props} />;
    case "references":
      return <ReferenceForm {...props} />;
    case "resumeUpload":
        return <ResumeUploadForm {...props} />;
    default:
      return null;
  }
};

export default CandidateForm;
