import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import Logo from "../common/Logo/Logo";
import { isAdmin } from "../Admin/services/admin";
import ConfirmLogout from "../NavLayout/ConfirmLogout";
import {
  HomeIcon,
  SupportIcon,
  ProfileIcon,
  BurgerIcon,
  SearchBarIcon,
  HeartMenuIcon,
  MyTeamIcon
} from "../common/Icons";
import { ExitToApp } from "@material-ui/icons";
import ContactModal from "../ContactModal/ContactModal";
import MenuIcon from '@material-ui/icons/Menu';
import Drawer from '@material-ui/core/Drawer';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Divider from '@material-ui/core/Divider';
import connectLogo from "../../images/connect-logo.svg";
import MobileDownload from '../views/MobileDownload/MobileDownload';

import "./NavLayoutMobile.scss";

const NavLayoutMobile = ({
  user
}) => {
  const location = useLocation();
  const [modalOpen, setModal] = useState(false);
  const [modalNavOpen, setNavModal] = useState(false);
  const [openConfirm, setOpenConfirm] = useState(false);
  const { jobFilters } = useSelector((state) => state || {});
  const { filterCount } = jobFilters || {};

  const [state, setState] = useState({
    left: false
  });

  const openModal = () => {
    setModal(true);
  };

  const closeModal = () => {
    setModal(false);
  };

  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setNavModal(open);
    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <div
      role="presentation"
      className="mobile-nav-header"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List>
          <ListItem
            button key="Dashboard"
            selected={location.pathname === "/home"}>
              <Link className="mobile-link" to="/home">
                <div
                    className={`icon-box ${
                    location.pathname === "/home" ? "blue-underline" : ""
                    }`}
                >
                    <ListItemIcon>
                        <HomeIcon iconClass={"basic-icon"} active={location.pathname === "/home"}/>
                    </ListItemIcon>
                    <ListItemText primary="Dashboard" />
                </div>
              </Link>
          </ListItem>

          <ListItem
            button key="Job Board"
            selected={location.pathname === "/search"}>
              <Link className="mobile-link" to="/search">
                <div
                    className={`icon-box ${
                    location.pathname === "/search" ? "blue-underline" : ""
                    }`}
                >
                    <ListItemIcon>
                        <SearchBarIcon iconClass={"basic-icon"} active={location.pathname === "/search"}/>
                    </ListItemIcon>
                    <ListItemText primary="Job Board" />
                </div>
              </Link>
          </ListItem>

          <ListItem
            button key="My Jobs"
            selected={location.pathname === "/myjobs"}>
              <Link className="mobile-link" to="/myjobs">
                <div
                    className={`icon-box ${
                    location.pathname === "/myjobs" ? "blue-underline" : ""
                    }`}
                >
                    <ListItemIcon>
                      <HeartMenuIcon iconClass="personal-icon" />
                    </ListItemIcon>
                    <ListItemText primary="My Jobs" />
                </div>
              </Link>
          </ListItem>

          <ListItem
            button key="My Profile"
            selected={location.pathname === "/profile"}>
              <Link className="mobile-link" to="/profile">
                <div
                    className={`icon-box ${
                    location.pathname === "/profile" ? "blue-underline" : ""
                    }`}
                >
                    <ListItemIcon className="user">
                        <ProfileIcon />
                    </ListItemIcon>
                    <ListItemText primary="My Profile" />
                </div>
              </Link>
          </ListItem>

          <ListItem
            button key="My Team"
            selected={location.pathname === "/my-team"}>
              <Link className="mobile-link" to="/my-team">
                <div
                    className={`icon-box ${
                    location.pathname === "/my-team" ? "blue-underline" : ""
                    }`}
                >
                    <ListItemIcon className="user">
                        <MyTeamIcon />
                    </ListItemIcon>
                    <ListItemText primary="My Team" />
                </div>
              </Link>
          </ListItem>

          <ListItem
            button key="Resource Center"
            selected={location.pathname === "/support"}>
            <Link className="mobile-link" to="/support">
                <div className="icon-box support">
                    <ListItemIcon>
                        <SupportIcon iconClass="nav-header-icon"/>
                    </ListItemIcon>
                    <ListItemText primary="Resource Center" />
                </div>
              </Link>
          </ListItem>

          {isAdmin(user) ? (
            <ListItem
              button key="Admin Portal"
              selected={location.pathname === "/users"}>
                <Link className="desktop-link" to="/users">
                  <div
                      className={`icon-box ${
                      location.pathname === "/users" ? "blue-underline" : ""
                      }`}
                  >
                      <ListItemIcon className="user">
                          <MyTeamIcon />
                      </ListItemIcon>
                      <ListItemText primary="Admin Portal" />
                  </div>
                </Link>
            </ListItem>
          ) : null}
      </List>
    </div>
  );

  return (
    <React.Fragment key="left">
        <div className="mobile-nav-buttons">
          
          <Button onClick={toggleDrawer("left", !modalNavOpen)}><BurgerIcon /></Button>
        </div>
        <Drawer className="mobile-menu-drawer" anchor="left" open={state['left']} onClose={toggleDrawer('left', false)}>
            <Divider />
            {list('left')}
            <MobileDownload></MobileDownload>
        </Drawer>

        <ContactModal open={modalOpen} handleClose={closeModal} />
        {openConfirm && (
          <ConfirmLogout
            open={openConfirm}
            close={() => {
              setOpenConfirm(false);
            }}
          />
        )}
    </React.Fragment>
  );
}

export default NavLayoutMobile;
