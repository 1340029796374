import React from "react";
import { ReactComponent as Logo } from "./burger.svg";
import SvgIcon from "@material-ui/core/SvgIcon";

import "../Icons.scss";

const BurgerIcon = ({ iconClass }) => (
  <SvgIcon className={iconClass} component={Logo} />
);

export default BurgerIcon;
