import { makeStyles } from "@material-ui/core/styles";

const drawerWidth = 240;

export const mainStyles = makeStyles((theme) => ({
  MuiTypographyColorPrimary: {
    color: "#0586CB",
  },

  assRow1: {
    backgroundColor: "#0586CB",
  },
  assRow2: {
    backgroundColor: "#E0E0E0",
  },
  nested: {
    paddingLeft: theme.spacing(8),
  },
  MuiSvgIconRoot: {
    MuiSelected: {
      fill: "white",
    },
  },
  gridChildRight: {
    float: "right",
  },

  gridChildLeft: {
    float: "left",
  },
  editMargin: {
    padding: "10px",
  },
  depositContext: { 
color: 'grey',
fontSize: '11pt'

},

workExpHead: {
fontSize: '11pt',
fontWeight: 'bold',
 
},


workExpUnitHead: {
  fontSize: '10pt',
  fontWeight: 'bold'
  },

  workExpUnitSubHead: {
    fontSize: '10pt',
   
    },
    
  
flexWrap: {
display: 'flex',
},
  sidePanelbox: {
    width: "360px",
    margin: "0px",
  },
  fullWidth: {
    textAlign: "left",
    width: "360px",
  },
  boxHeader: {
    border: "0",
    paddingTop: "5px",
    borderBottomWidth: "2px",
    borderColor: "white",
    borderStyle: "solid",
    backgroundColor: "white",
    verticalAlign: "center",
  },
  buttonAssessmentSelected: {
    backgroundColor: "#0586CB",
    color: "#FFF",
    "&:hover": {
      backgroundColor: "#0397e5",
      color: "#FFF",
    },
  },
hoverPointer: {
  "&:hover": {
    cursor: 'Pointer'
}},
  textField: {
    marginLeft: "auto",
    marginRight: "auto",
    paddingBottom: 0,
    paddingTop: 0,
    marginTop: 0,
  },
  input: {
    paddingBottom: 2,
    paddingTop: 2,
    height: "20px",
    fontSize: "10pt",
    width: "250px",
  },

  buttonAssessment: {
    color: "black",
    "&:hover": {
      backgroundColor: "#0397e5",
      color: "#FFF",
    },
  },

  buttonMod: {
    backgroundColor: "#0586CB",
    color: "#FFF",
    "&:hover": {
      backgroundColor: "#0397e5",
      color: "#FFF",
    },
  },
  smallFont: {
    fontSize: "10px",
  },
  bold: {
    fontWeight: 600,
  },
  sidePanelboxGrey: {
    width: "360px",
    background: "lightGrey",
    paddingLeft: "4px",
  },
  sidePanelboxBlue: {
    width: "360px",
    background: "#30D1FF",
    paddingLeft: "4px",
  },

  sidePanelboxBlueAssessment: {
    width: "100%",
    background: "#30D1FF",
  },

  padBox: {
    padding: "4px",
    paddingRight: "8px",
    paddingBottom: "8px",
  },
  whiteBox: {
    background: "white",
  },

  editGrid: {
    display: "inline",
  },

  editGridAssessment: {
    display: "inline",
  },

  selectedSt: {
    background: "black",
  },
  whiteback: {
    fill: "white",
  },

  compressedTable: {
    paddingTop: "3px",
    paddingBottom: "3px",
  },

  root: {
    display: "flex",
  },
  appBar: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
    [theme.breakpoints.up("md")]: {
      position: "relative",
    },
  },

  orderOne: {
    [theme.breakpoints.up("sm")]: {
      order: 1,
    },
  },

  orderTwo: {
    [theme.breakpoints.up("sm")]: {
      order: 2,
    },
  },

  // necessary for content to be below app bar
  toolbar: {
    textAlign: "center",
  },

  toolbarAnchor: {
    minHeight: "0px",
  },
  homeVerify: {
    width: "30px",
  },

  downloadWidth: {
    width: "550px",
  },
  licDownload: {
    paddingTop: "10px",
  },

  homeDownloadLic: {
    width: "90px",
    height: "90px",
    backgroundColor: "#b5def4",

    verticalAlign: "middle",
    textAlign: "center",
    display: "table-cell",
  },

  marginGrid: {
    marginLeft: "10px",
  },
  planeGrid: {
    marginLeft: "10px",
    backgroundColor: "#00B98B",
    color: "white",
  },
  bluePaper: {
    color: "grey",
    backgroundColor: "#F4FBFC",
  },

  blueText: {
    color: "#0586CB",
  },

  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    padding: 0, //theme.spacing(3),
  },
  pageHeaderColor: {
    // or hex cod   e, this is normal CSS background-color
    backgroundColor: "white",
  },
  pageHeader: {
    color: "#2F4F4F",
  },
  largeAv: {
    width: '125px !important',
    height: '125px !important',
    marginTop: "25px",
    marginLeft: "10px",

    
  },
  dropClass: {
    overflow: "hidden",
  },
  headerGrey: {
    color: "darkGrey",
  },
  headerGreen: {
    color: "green",
  },
  
  blockColor: {
    color: "black",
  },

  medAv: {
    width: theme.spacing(12),
    height: theme.spacing(12),
    marginTop: "5px",
    marginLeft: "10px",
  },

  homeHeaderRightOuter: {
    backgroundColor: "#E3F9F3",
    padding: "15px",

    width: "275px",
    height: "150px",
  },

  homeHeaderRightInner: {
    height: "50px",
    padding: "2px",
    marginBottom: "10px",
    color: "white",
    backgroundColor: "#00B98B",
  },

  lightGreenPaper: {
    backgroundColor: "#E3F9F3",
  },

  darkGreenPaper: {
    backgroundColor: "#00B98B",
  },

  whiteColor: {
    // or hex code, this is normal CSS background-color
    color: "#FFFFFF",
  },

  drawHeaderArrow: {
    padding: "1px",
  },

  saveBox: {
    backgroundColor: "#0586CB",
    fontColor: "white",
    color: "white",
    textAlign: "center",
  },

  drawerHeader: {
    padding: "0px",
    width: "400px",
    height: "50px",
    border: "0",
    borderBottomWidth: "1px",
    borderColor: "lightGrey",
    borderStyle: "solid",
  },

  drawerHeaderAss: {
    padding: "0px",
    width: "450px",
    height: "50px",
    border: "0",
    borderBottomWidth: "1px",
    borderColor: "lightGrey",
    borderStyle: "solid",
  },

  selectedTab: {
    backgroundColor: "#0586CB",
    color: "white",
    fill: "white",

    "&:hover": {
      backgroundColor: "#0586CB",
      color: "white",

      fill: "white",
    },
  },

  unselectedTab: {
    backgroundColor: "white",
    color: "black",
    fill: "white",

    "&.hover": {
      backgroundColor: "#0586CB",
      color: "#0586CB",

      fill: "white",
    },
  },
minorEditIcon: {
fontSize: "16px"
},
  checkBoxItems: {
    listStyleType: "none",
    paddingBottom: "2px",
    paddingTop: "2px",
    lineHeight: "0.5em",
  },
  borderNone: {
    borderStyle: "none",
    paddingTop: "0px",
    paddingBottom: "0px",
  },
  redColor: {
    color: "red",
  },
  hiddenForm: {
    display: "none",
  },

  profileButton: {
    textAlign: "right",
  },
  checkBoxItem: {
    paddingLeft: "4px",
    color: "#0586CB",
  },

  checkBoxItemChecked: {
    paddingLeft: "4px",
    color: "#575757",
  },

  checkBoxItemCheckedCheck: {
    paddingLeft: "4px",
    color: "green",
    marginRight: "8px",
  },
  checkBoxBorder: {
    marginRight: "8px",
  },
  toolbarIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "0 8px",
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: "none",
  },
  title: {
    flexGrow: 1,
  },
  drawerPaper: {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: "hidden",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto",
    background: "#F4FBFC",
  },
  selected: {
    background: "#30D1FF",
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingLeft: theme.spacing(0),
    paddingBottom: theme.spacing(0),
  },
  uploadIcon: {
    fontSize: "5.5rem",
    color: "#0586CB",
  },
  modalPaper: {
    overflow: "hidden",
    position: "absolute",
    width: "50%",
    height: "80%",
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  skillsForm: {
  overflow: 'scroll',
   height: "80%",
 

  },
  uploadMain: {
    padding: "2px",
    fontSize: "14px",
    fontStyle: "bold",
    color: "#0586CB",
  },
  uploadDrop: {
    height: "450px",
  },

  uploadMinor: {
    color: "#0586CB",
    paddingTop: "10px",
    fontSize: "11px",
  },

  accountGrid: {
    padding: "4px",
    paddingTop: "18px",
  },
  accountContainer: {
    paddingTop: theme.spacing(4),
    paddingLeft: "0px",
    paddingRight: "0px",

    paddingBottom: theme.spacing(0),
  },

  deleteButton: {
    color: "#BEBEBE",
    fontColor: "#BEBEBE",

    display: "flex",
    alignItems: "center",
    justifyContent: "center",

    "&:hover": {
      color: "#D80000",
      fontColor: "#D80000",

    },
  },

  editHover: {
    opacity: 0.15,
    "&:hover": {
      opacity: 0.9,
    },
  },
  toolbar: { background: "white" },
  title: { color: "#0586CB;" },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  centerPaper: {
    textAlign: "center",
  },
  paperLicDown: {
    padding: theme.spacing(0),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },

  fixedHeight: {
    height: 340,
  },
  Mui: {
    selected: {
      /* Increase the specificity */ color: "blue",
    },
  },

  profileProgress: {
    flex: 1,
    color: "red",
    fontSize: "14px",
  },

  bottom: {
    color: "#ededed",
  },

  redprogress: {
    color: "red",
  },
  greenprogress: {
    color: "green",
    fontSize: '9.5pt'
  },

  top: {
    color: "red",
    animationDuration: "550ms",
    position: "absolute",
    left: 0,
  },
  topG: {
    color: "green",
    animationDuration: "550ms",
    position: "absolute",
    left: 0,
  },
  


  skillsButton: {
    margin: theme.spacing(1),
    position: "relative",
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'block',
    }
  },
  
  skillsMobile: {
    [theme.breakpoints.up('md')]: {
      display: 'none',
    }
  },

  circle: {
    strokeLinecap: "round",
  },
}));
