import React, { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import applicationStatus from "../../../helpers/application"
import {
  Tab,
  Tabs,
  Box
} from "@material-ui/core";
import { HeartIcon } from "../../common/Icons";
import { Link } from "react-router-dom";
import NavLayout from "../../NavLayout/NavLayout";
import Skeleton from "@material-ui/lab/Skeleton";
import JobCard from "../../common/JobCard/JobCard";
import Empty from "../../common/Empty/Empty";
import {
  setFavoriteJobs,
  setMatchedJobs,
} from "../../../actions/jobs";
import { getJobApplications } from "../../../actions/applications";
import { sendApplicationStarted } from "../../../services/email";
import Apply from "../../Apply/Apply";

import "./MyJobs.scss";
import { removeFavorite } from "../../../actions/jobs";
import { acceptOffer, rejectOffer } from "../../../services/jobs";
import { CircularProgress } from "@material-ui/core";


const MyJobs = (props) => {
  const form = useRef();
  const dispatch = useDispatch();
  const { matchedJobs, favoriteJobs, applications } = useSelector((state) => state || {});
  const [isLoading, setIsLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const { message } = useSelector((state) => state.message);
  const [available, setAvailable] = useState([]);
  const [unavailable, setUnavailable] = useState([]);
  const [appliedTab, setAppliedTab] = useState([]);
  const [offeredTab, setOfferedTab] = useState([]);
  const [historyTab, setHistoryTab] = useState([]);
  const [showApply, setShowApply] = useState(false);
  const [applyJob, setApplyJob] = useState();

  useEffect(() => {
    let mounted = true;
    const loadData = async () => {
      if (!matchedJobs) {
        setIsLoading(true);
        await dispatch(setMatchedJobs());
        setIsLoading(false);
      }

      if (!favoriteJobs) {
        await dispatch(setFavoriteJobs());
      }

      if (!applications) {
        await dispatch(getJobApplications());
      }
    };

    if (mounted) {
      loadData();
    }

    return () => {
      mounted = false;
    };
  }, []);

  useEffect(() => {
    setStatus(favoriteJobs);
  }, [favoriteJobs])

  const setStatus = (favorites) => {
    if(favorites) {
      let available = favorites.jobList.filter(function(job) {
        return job.isOpen == true;
      });
      setAvailable(available);

      let unavailable = favorites.jobList.filter(function(job) {
        return job.isOpen == false;
      });
      setUnavailable(unavailable);
    }
  }


  useEffect(() => {
    setTabs(applications);
  }, [applications])

  const setTabs = (applicationJobs) => {
    if(applicationJobs) {
      let applied = applicationJobs.filter((application) => {
        return applicationStatus.applied.includes(application.status) &&
               application.job.isOpen === true
      });
      setAppliedTab(applied);

      let offered = applicationJobs.filter((application) => {
        return applicationStatus.offered.includes(application.status) &&
               application.job.isOpen === true
      });
      setOfferedTab(offered);

      let history = applicationJobs.filter((application) => {
        return applicationStatus.history.includes(application.status) ||
               application.job.isOpen === false
      });
      setHistoryTab(history);
    }
  }

  const removeFavorites = () => {
    unavailable.forEach(async (job) => {
      await dispatch(removeFavorite(job.jobId));
    });
  }

  function tabProps(index) {
    return {
      id: `tab-${index}`,
      'aria-controls': `tabpanel-${index}`,
    };
  }

  const [value, setValue] = useState(0); //external tabs
  const [innerValue, setInnerValue] = useState(0); //internal tabs

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleInnerChange = (event, newValue) => {
    setInnerValue(newValue);
  };

  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box>
            {children}
          </Box>
        )}
      </div>
    );
  }


  const onAcceptClicked = async (id) => {
    setLoading(true);
    await acceptOffer(id);
    await dispatch(getJobApplications());
    setLoading(false);
  }

  const onRejectClicked = async (id) => {
    setLoading(true);
    await rejectOffer(id);
    await dispatch(getJobApplications());
    setLoading(false);
  }


  function OfferResponse(props) {
    const { appId, loading, ...other } = props;

    return (
      <div className="col-xs">
        <button
          className={`text-sm btn margin-r-10`}
          onClick={() => onAcceptClicked(appId)} >
          {!loading ? "Accept" : <CircularProgress size={20} className="" />}
        </button>
        <button
          className={`text-sm btn secondary`}
          onClick={() => onRejectClicked(appId)} >
          {!loading ? "Decline" : <CircularProgress size={20} className="" />}
        </button>
      </div>
    )
  }

  const onApplyClickedHandler = async (job) => {
    setApplyJob(job);
    setShowApply(true);
    await sendApplicationStarted([parseInt(job.jobId)]);
  }

  return (
    <NavLayout headerText="My Jobs">
      <div className="my-jobs-container-main">
        <div className="jobs-container">
        <Tabs
          value={value}
          onChange={handleChange}
          TabIndicatorProps={{style: {background:'#008dd1'}}}>
            <Tab label={(
              <>
                <div>
                  <span className="inline-block">Matches</span>
                  {matchedJobs?.total > 0 ? (<span className=" pill hide-sm">{matchedJobs?.total}</span>):null}
                </div>
              </>
            )} {...tabProps(0)}/>
            <Tab label={(
              <>
                <div>
                  <span className="flex items-center">
                    <HeartIcon iconClass="text-gray-500 tab-icon hide-sm" active={false} />
                    Saved
                    {favoriteJobs?.total > 0 ? (<span className=" pill hide-sm">{favoriteJobs?.total}</span>):null}
                  </span>
                </div>
              </>
            )} {...tabProps(1)}/>
            <Tab label={(
              <>
                <div>
                  <span className="inline-block">Applications</span>
                  {applications?.length > 0  ? (<span className=" pill hide-sm">{applications?.length}</span>):null}
                </div>
              </>
            )} {...tabProps(2)}/>
        </Tabs>
          <div className="tab-panel-container">
            <TabPanel value={value} index={0}>
              <div className="tab-content">
                {!isLoading ? (
                  matchedJobs && matchedJobs?.total > 0 ? (
                    <div>
                      {matchedJobs.jobList.map((job) => (
                        <JobCard
                          job={job}
                          key={`matched-${job.jobId}`}
                          onApplyClickedHandler={onApplyClickedHandler}
                        />
                      ))}
                    </div>
                  ) : (
                    <Empty
                      title="No matches"
                      subtitle="There are no matches at the moment. Jobs are updated in real-time and can become available at any moment"
                      callToAction={
                        <button className="btn" onClick={(e) => {
                          e.preventDefault();
                          window.location.href = `/profile`;
                        }}>
                        Update preferences
                        </button>}
                      />
                  )) : (
                    <div>
                    {[...Array(9)].map((e,i) => (
                        <Skeleton
                          height="140px"
                          key={`matches-${i}`}
                          style={{marginBottom: `-30px`, marginTop: '-40px'}}
                        ></Skeleton>
                      ))
                    }
                    </div>
                )}
              </div>
            </TabPanel>
            <TabPanel value={value} index={1}>
              <div className="tab-content">

                <div>
                  <h2 className="pb-2 pl-2 text-lg font-medium text-gray-900 border-b-1 border-gray-50">
                    Available
                  </h2>
                  <p className="pt-4 pl-2 pb-6 text-sm text-gray-500">
                    Saved jobs are updated in real-time, but can become unavailable at any moment.
                    Interested in a match? <Link className="inline-block pl-2" to="/myjobs">Find matches now</Link>
                  </p>
                </div>
                {!isLoading ? (
                  favoriteJobs && favoriteJobs?.total > 0 ? (
                    <div>
                      {available?.map((job) => (
                        <JobCard
                          job={job}
                          key={`favorites-${job.jobId}`}
                          onApplyClickedHandler={onApplyClickedHandler}
                        />
                      ))}
                    </div>
                  ) : (
                    <Empty
                      title="No saved jobs"
                      subtitle="Saved jobs are updated real-time, but can become unavailable at any moment."
                      callToAction={
                        <button className="btn" onClick={(e) => {
                          e.preventDefault();
                          window.location.href = `/myjobs`;
                        }}>
                        Find Matches
                        </button>}
                      />))
                  : ( null )
                }

                {!isLoading ? (
                  unavailable.length > 0 && favoriteJobs?.total > 0 ? (
                    <>
                      <h2 className="pt-6 pl-2 pb-2 text-lg font-medium text-gray-900 border-b-1 border-gray-50">
                        No longer available
                      </h2>
                      <p className="pt-4 pl-2 pb-6 text-sm text-gray-500">
                        Jobs you were interested in may have been recently filled or withdrawed
                        <button className="inline-block pl-2 text-link " onClick={removeFavorites} >Clear All</button>
                      </p>

                      <div>
                        {unavailable?.map((job) => (
                          <JobCard
                            job={job}
                            status={`unavailable`}
                            key={`unavailable-${job.jobId}`}
                          />
                        ))}
                      </div>
                    </>
                  ) : ( null ))
                  : ( null )
                }
              </div>
            </TabPanel>
            <TabPanel value={value} index={2}>
              <div className="tab-content">

                <h2 className="pl-2 pb-2 text-lg font-medium text-gray-900 border-b-1 border-gray-50">
                Applied jobs
                </h2>
                <p className="pt-4 pl-2 pb-6 text-sm text-gray-500">
                  Follow up all the jobs you've applied to. Want to apply for more jobs?
                  <Link className="inline-block pl-2" to="/search">Find matches now</Link>
                </p>

                <div className="tab-list-container bg-white min-h-500">

                  <Tabs
                    className="tabs-container"
                    value={innerValue}
                    onChange={handleInnerChange}
                    TabIndicatorProps={{style: {background:'#008dd1'}}}>

                    <Tab label={(
                      <>
                        <div>
                          <span className="inline-block">Applied</span>
                          {appliedTab?.length > 0  ? (<span className=" pill">{appliedTab?.length }</span>):null}
                        </div>
                      </>
                    )} {...tabProps(0)} />
                    <Tab label={(
                      <>
                        <div>
                          <span className="inline-block">Offered</span>
                          {offeredTab?.length > 0  ? (<span className=" pill">{offeredTab?.length }</span>):null}
                        </div>
                      </>
                    )} {...tabProps(1)} />
                    <Tab label={(
                      <>
                        <div>
                          <span className="inline-block">History</span>
                          {historyTab?.length > 0  ? (<span className=" pill">{historyTab?.length }</span>):null}
                        </div>
                      </>
                    )} {...tabProps(2)} />

                  </Tabs>
                  <TabPanel className="tab-content" value={innerValue} index={0}>
                    {!isLoading ? (
                      appliedTab && appliedTab?.length > 0 ? (
                        <div>
                          {appliedTab.map((application, index) => (
                            <div
                              className={`application step-${applicationStatus.applied.indexOf(application.status)}`}
                              key={`applied-${index}`}>
                              <JobCard
                                job={application.job}
                                status={application.status}
                                key={`applied-${application.job.jobId}`}
                              />
                            </div>
                          ))}
                        </div>
                      ) : (
                        <Empty
                          title="There are no applications"
                          subtitle="Not finding what you are looking for?."
                          callToAction={
                            <button className="btn" onClick={(e) => {
                              e.preventDefault();
                              window.location.href = `/my-team`;
                            }}>
                            Contact a recruiter
                            </button>}
                          />
                      ))
                      : ( null )
                    }
                  </TabPanel>
                  <TabPanel className="tab-content" value={innerValue} index={1}>
                    {!isLoading ? (
                      offeredTab && offeredTab?.length > 0 ? (
                        <div>
                          {offeredTab.map((application, index) => (
                              <div key={`offered-${index}`}>
                                <JobCard
                                  callToAction={<OfferResponse appId={application.id} loading={loading}/>}
                                  job={application.job}
                                  status={application.status}
                                  key={`offered-${application.job.jobId}`}
                                />
                              </div>
                          ))}
                        </div>
                      ) : (
                        <Empty
                          title="No offers"
                          subtitle="Not finding what you are looking for?"
                          callToAction={
                            <button className="btn" onClick={(e) => {
                              e.preventDefault();
                              window.location.href = `/myTeam`;
                            }}>
                            Contact a recruiter
                            </button>}
                          />
                      ))
                      : ( null )
                    }
                  </TabPanel>
                  <TabPanel className="tab-content" value={innerValue} index={2}>
                    {!isLoading ? (
                      historyTab && historyTab?.length > 0 ? (
                        <div>
                          {historyTab.map((application) => (
                              <JobCard
                                job={application.job}
                                status={application.status}
                                key={`history-${application.job.jobId}`}
                              />
                          ))}
                        </div>
                      ) : (
                        <Empty
                          title="You haven't accept or decline offers yet"
                          subtitle="Not finding what you are looking for?"
                          callToAction={
                            <button className="btn" onClick={(e) => {
                              e.preventDefault();
                              window.location.href = `/myTeam`;
                            }}>
                            Contact a recruiter
                            </button>}
                          />
                      ))
                      : ( null )
                    }
                  </TabPanel>
                </div>

              </div>
            </TabPanel>
          </div>
        </div>
      </div>
      {showApply && (
        <Apply
          open={showApply}
          handleClose={() => setShowApply(false)}
          jobIds={[applyJob.jobId]}
          jobDetails={applyJob}
        />
      )}
    </NavLayout>
  );
};

export default MyJobs;
