import axios from "axios";
import API_URL from "../helpers/globals.js";
import mime from "mime-types";
import { notify } from "../toasts/toasts.js";

export const updateCandidateData = async (type, data, skipNotification) => {
  if (data && Object.keys(data).length > 0) {
    try {
      const response = await axios.post(
        `${API_URL}Candidate/${type}`,
        JSON.stringify(data),
        {
          headers: {
            "Content-Type": "application/json"
          }
        }
      );
      if (response) {
        if (!skipNotification) notify("Your changes have been made successfully.", "success");
        return response.data;
      }
    } catch (err) {
      if (isEmailSaveError(err)) {
          notify("Failed to save your changes. Email address cannot be changed. Either the email address already exists or this email address is tied to your social media account.", "error");
      } else {
        notify("Failed to save your changes. Please try again.", "error");
      }
      console.log(err);
    }
  }
};

export const isEmailSaveError = (err) => {
  return err && err.response && err.response.data && 
    (typeof err.response.data === 'string' || err.response.data instanceof String) &&
    err.response.data.indexOf("Email address cannot be changed.") !== -1;
}

export const deleteCandidateData = async (type, id) => {
  let field = type;
  if (type === "references") field = "reference";
  if (type === "work") field = "workHistory";
  if (type === "resume") field = "resume";
  try {
    const response = await axios.delete(
      `${API_URL}Candidate/${field}?id=${id}`
    );
    if (response) {
      return response.data;
    }
  } catch (err) {
    console.log(err);
  }
};

export const getCandidateDetails = async () => {
  try {
    const response = await axios.get(`${API_URL}Candidate/details`);
    if (response) {
      return response.data;
    }
  } catch (err) {
    console.log(err);
  }
};

export const getCandidateFile = async (id, fileName) => {
  try {
    const response = await axios.get(`${API_URL}Candidate/file?id=${id}`, {
      responseType: "blob",
      timeout: 30000
    });
    if (response) {
      showFile(fileName, response.data);
    }
  } catch (err) {
    console.log(err);
  }
};

export const getCandidateMatchCriteria = async () => {
  try {
    const response = await axios.get(
      `${API_URL}Candidate/matchedjobcriteria`,
      {
        headers: {
          "Content-Type": "application/json"
        }
      }
    );
    if (response) {
      return response.data;
    }
  } catch (err) {
    console.log(err);
  }
};

const showFile = (fileName, blob) => {
  var newBlob = new Blob([blob], { type: blob.type });

  if (newBlob.size < 5) return;
  if (window.navigator && window.navigator.msSaveOrOpenBlob) {
    window.navigator.msSaveOrOpenBlob(newBlob);
    return;
  }

  // For other browsers:
  // Create a link pointing to the ObjectURL containing the blob.
  const data = window.URL.createObjectURL(newBlob);
  var link = document.createElement("a");
  link.href = data;

  link.download = fileName;
  link.click();
  setTimeout(function () {
    // For Firefox it is necessary to delay revoking the ObjectURL
    window.URL.revokeObjectURL(data);
  }, 100);
};
