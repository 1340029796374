import React from "react";
import { Redirect } from "react-router-dom";
import { useSelector } from "react-redux";
import Frame from "../../Admin/Components/Frame/Frame";
import Dropzone from "react-dropzone-uploader";
import "react-dropzone-uploader/dist/styles.css";
import mime from "mime-types";
import { globalState } from "../../sharedstate.js";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import {
  Box,
  Container,
  Drawer,
  Grid,
  Typography,
  Table
} from "@material-ui/core";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import EditIcon from "@material-ui/icons/Edit";
import { useState } from "react";
import Form from "react-validation/build/form";
import { mainStyles } from "../../mainStyles.js";
import clsx from "clsx";
import { useEffect } from "react";
import axios from "axios";
import API_URL from "../../../helpers/globals.js";

const useStyles = mainStyles;
const DocTemplates = () => {
  const [state, setState] = useState({ right: false, type: "a" }); // Add this
  const [assessments, setAssessments] = useState({ users: [] }); // Add this
  const [upty, setUpty] = useState();
  const theCand = useSelector((state) => state.candidateData);

  useEffect(() => {}, []);

  const toggleDrawer = (openClose, typ) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setState({ right: openClose, type: typ });
  };

  const uuidv4 = () => {
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
      /[xy]/g,
      function (c) {
        var r = (Math.random() * 16) | 0,
          v = c == "x" ? r : (r & 0x3) | 0x8;
        return v.toString(16);
      }
    );
  };

  const getUploadParams = ({ file, meta }) => {
    const body = new FormData();
    var fn = uuidv4() + "_templatefile_" + upty;

    body.append("file", file);
    body.append("fn", fn);

    return { url: API_URL + "upload", body };
  };

  // Return the current status of files being uploaded
  const handleChangeStatus = ({ meta, file }, status) => {
    console.log(status, meta, file);
  };

  const closeDrawer = (event) => {
    console.log("Close Drawer");
    setState({ right: false });
  };

  const openDrawer = (ft) => (event) => {
    setUpty(ft);

    setState({ right: true });
  };

  const { user: currentUser } = useSelector((state) => state.auth);

  const classes = useStyles();

  const fixedHeightPaper = clsx(classes.paper);

  const dzic = (
    <div
      key="1"
      style={{
        marginTop: "-40px",
        textAlign: "center",
        overflow: "hidden",
        maxWidth: "364px"
      }}
    >
      <Box pt={4}>
        <CloudUploadIcon align="center" className={classes.uploadIcon} />

        <Typography
          component="div"
          variant="body2"
          className={classes.uploadMain}
          align="center"
        >
          {" "}
          Drop files here to upload or <u>choose files</u>
        </Typography>

        <Typography
          component="div"
          variant="body2"
          className={classes.uploadMinor}
          align="center"
        >
          {" "}
          if there's a back size, upload that as well.
        </Typography>
      </Box>
    </div>
  );

  if (!currentUser) {
    return <Redirect to="/login" />;
  }

  const showFile = (blob) => {
    var newBlob = new Blob([blob], { type: blob.type });

    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveOrOpenBlob(newBlob);
      return;
    }

    // For other browsers:
    // Create a link pointing to the ObjectURL containing the blob.
    const data = window.URL.createObjectURL(newBlob);
    var link = document.createElement("a");
    link.href = data;
    link.download = "file." + mime.extension(blob.type);
    link.click();
    setTimeout(function () {
      // For Firefox it is necessary to delay revoking the ObjectURL
      window.URL.revokeObjectURL(data);
    }, 100);
  };
  const downloadFile = (e) => {
    axios
      .get(
        API_URL + "admin/template?type=" + e.name,
        {
          responseType: "blob",
          timeout: 30000
        }
      )
      .then((json) => {
        showFile(json.data);
      })
      .catch((err) => console.log(err));
  };

  const genRow = (id, label, name) => {
    return (
      <tr key="1">
        <td
          style={{ whiteSpace: "nowrap", padding: "5px" }}
          onClick={() => downloadFile({ name })}
        >
          {label}
        </td>

        <td style={{ whiteSpace: "nowrap", padding: "5px" }}>
          <EditIcon
            edge="start"
            className={classes.minorEditIcon}
            aria-label="menu"
            onClick={openDrawer(name)}
            style={{ paddingRight: "20px", cursor: "Pointer" }}
          ></EditIcon>
        </td>
      </tr>
    );
  };

  const innerFrame = (
    <div className={classes.root}>
      <Container maxWidth="lg" className={classes.container}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <div className="float-right"></div>
            <h3>Templates</h3>
            <Table className="mt-4">
              <thead>
                <tr style={{ borderBottom: "10px solid #F4FBFC" }}>
                  <th width="80%">Template Name</th>

                  <th width="10%">Actions</th>
                </tr>
              </thead>
              <tbody>
                {genRow(1, "Candidate Profile Completed Email", "CandidateProfileCompletedEmail")}
                {genRow(1, "Candidate Profile Started Email", "CandidateProfileStartedEmail")}
                {genRow(1, "Candidate Skill Checklist Completed Email", "CandidateSkillChecklistCompletedEmail")}
                {genRow(1, "Job Application Completed Email", "JobApplicationCompletedEmail")}
                {genRow(1, "Job Application Started Email", "JobApplicationStartedEmail")}
              </tbody>
            </Table>
          </Grid>
        </Grid>

        <Box pt={4}>
          <Typography
            variant="body2"
            color="textSecondary"
            align="center"
          ></Typography>
        </Box>
      </Container>

      <Drawer anchor="right" open={state.right}>
        <div width="500px">
          <Box display="flex" className={classes.drawerHeader}>
            <Box p={1} flexGrow={1}>
              <div className={classes.drawHeaderArrow}>
                <ArrowBackIosIcon onClick={closeDrawer}></ArrowBackIosIcon>{" "}
              </div>
            </Box>
            <Box p={1}></Box>
          </Box>

          <Form>
            <Grid container>
              {" "}
              <Grid item xs={12} style={{ margin: "auto" }}>
                <Dropzone
                  getUploadParams={getUploadParams}
                  onChangeStatus={handleChangeStatus}
                  inputContent={dzic}
                  styles={{
                    dropzone: {
                      overflow: "hidden",
                      minHeight: 250,
                      maxHeight: 250,
                      maxWidth: "364px",

                      border: "14px",
                      borderStyle: "solid",
                      borderColor: "#0586CB"
                    }
                  }}
                />
              </Grid>
            </Grid>
          </Form>
        </div>
      </Drawer>
    </div>
  );

  return <Frame innerFrame={innerFrame}></Frame>;
};

export default DocTemplates;
