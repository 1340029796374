import React from "react";
import { Modal, Dialog } from "@material-ui/core";

import PrimaryButton from "../Buttons/PrimaryButton";
import "./ConfirmationModal.scss";

const ConfirmationModal = ({
  message,
  subMessage,
  open,
  handleClose,
  confirm,
  cancelButtonText,
  loading
}) => {
  return (
    <Dialog
      open={open}
      aria-labelledby="confirmation-modal"
      className="modal"
      data-testid="confirmation-modal"
    >
      <div className="modal-content confirmation">
        <div className="message">
          <h6 className="text-lg leading-6 font-medium text-gray-900">{message}</h6>
          <p>{subMessage}</p>
        </div>
        <div className="buttons">
          <div className="cancel">
            <PrimaryButton
              buttonClass="blue-outline-button"
              handleClick={handleClose}
              text={cancelButtonText ? cancelButtonText : "Cancel"}
            />
          </div>
          <div className="save">
            <PrimaryButton buttonClass="blue-outline-button" handleClick={confirm} text="Yes" loading={loading}/>
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export default ConfirmationModal;
