import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";

import LinkButton from "../../../common/Buttons/LinkButton";
import {
  setFeaturedJobs
} from "../../../../actions/jobs";

import "./FeaturedJobs.scss";
import Skeleton from "@material-ui/lab/Skeleton";

import { ArrowForward, ArrowBack } from "@material-ui/icons";
import Hidden from "@material-ui/core/Hidden";
import JobCardCondensed from "../../../common/JobCardCondensed/JobCardCondensed";

import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { getSearchJobs, setJobFilters } from "../../../../actions/jobFilters";

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
    partialVisibilityGutter: 40
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2
  }
};

const CustomRightArrow = ({ onClick }) => {
  return <button className="react-multiple-carousel__arrow react-multiple-carousel__arrow--right" aria-label="go to next slide" onClick={() => onClick()}><ArrowForward style={{ color: "#00283C"}} /></button>;
};

const CustomLeftArrow = ({ onClick }) => {
  return <button className="react-multiple-carousel__arrow react-multiple-carousel__arrow--left" aria-label="go to previous slide" onClick={() => onClick()}><ArrowBack style={{ color: "#00283C"}}/></button>;
};

const FeaturedJobs = (props) => {
  const [isLoading, setLoading] = useState(false);
  const { featuredJobs } = useSelector((state) => state || {});
  const [newSearch, setNewSearch] = useState(false);
  const [page, setPage] = useState(0);

  const dispatch = useDispatch();

  const cloudFrontBaseURL = "https://ik.imagekit.io/yksbahzmx/connect/";
  const placeholderImage = cloudFrontBaseURL + "images/job-placeholder.jpg";

  useEffect(() => {
    let mounted = true;

    const loadData = async () => {
      if (!featuredJobs) {
        setLoading(true);

        await dispatch(setFeaturedJobs());

        setLoading(false);
      }
    };
    if (mounted) {
      loadData();
    }

    return () => {
      mounted = false;
    };
  }, []);

  const allFeatured = () => {
    dispatch(setJobFilters({specialties: []}));
    dispatch(setJobFilters({isfeatured: true}));

    setPage(1);
    dispatch(getSearchJobs(page));
    setNewSearch(true);
  }

  return (
    <div className="dashboard-box">
      <div className="subheader">
        <h5 className="w-full text-lg leading-6 font-medium text-gray-900 flex justify-between">
          Featured Jobs
          <span>
            <LinkButton url="/search?type=featured" textButton text={(<>View All <span aria-hidden="true">→</span></>)} />
          </span>
        </h5>
      </div>
      <div>
        {!isLoading ? (
          featuredJobs && featuredJobs?.total > 0 ? (
            <>
              <Hidden smDown>
                <Carousel centerMode={true} responsive={responsive} customRightArrow={<CustomRightArrow />} customLeftArrow={<CustomLeftArrow />} containerClass="matched-jobs explore-area highlight">
                  {featuredJobs?.jobList.map((area, index) => {
                    return (
                      <Link key={area.jobId} to={`/job/${area.jobId}`}>
                        <div className="job-card condensed">
                          <JobCardCondensed
                            job={area}
                            hidePills={true}
                          />
                        </div>
                      </Link>
                    )
                  })}
                </Carousel>
              </Hidden>

              <Hidden mdUp>
                {featuredJobs?.jobList.map((area, index) => {
                    return (
                      <Link key={area.jobId} to={`/job/${area.jobId}`}>
                        <div className="job-card condensed">
                          <JobCardCondensed
                            job={area}
                            hidePills={true}
                          />
                        </div>
                      </Link>
                    )
                  })}
              </Hidden>
            </>
          ) : (
            <p className="center-align">No featured jobs</p>
          )
        ) : (
          <div className="matched-jobs-skeleton">
          
              <Skeleton
                variant="rect"
                className="matched-card"
                height="12rem"
              ></Skeleton>
              <Skeleton
                variant="rect"
                className="matched-card"
                height="12rem"
              ></Skeleton>
              <Skeleton
                variant="rect"
                className="matched-card"
                height="12rem"
              ></Skeleton>
              <Skeleton
                variant="rect"
                className="matched-card"
                height="12rem"
              ></Skeleton>
          </div>
        )}
      </div>
    </div>
  );
};

export default FeaturedJobs;
