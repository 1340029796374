import axios from "axios";
import API_URL from '../helpers/globals.js';

function getPublicContent () {
  return axios.get(API_URL + "UserData/");
};

function getUserBoard() {
  return axios.get(API_URL + "UserData/");
};

function getModeratorBoard()  {
  return axios.get(API_URL + "UserData/");
};

function getAdminBoard() {
  return axios.get(API_URL + "UserData/");
};

async function getUser() {
    const response = await axios.get(API_URL + "user/");
    return response.data;
}

async function updateForgotPasswordStatus(emailOrUsername) {
    const response = await axios.put(API_URL + "user/forgotpasswordstatus", { EmailOrUsername: emailOrUsername });
    return response.data;
}

async function getUserType(username) {
    const response = await axios.post(
      API_URL + "user/signupmethod/",
      { EmailOrUsername: username }
    );
    return response.data;
}

async function resetTestUser() {
  const response = await axios.post(API_URL + "user/resettest");
  return response.data;
}

async function createUser(user) {
  let request = {FirstName: '', LastName: ''};
  if (user && user.attributes) {
    request.FirstName = user.attributes.given_name;
    request.LastName = user.attributes.family_name;
  }

  return axios.put(API_URL + "user/", JSON.stringify(request),
  {
    headers: {
      "Content-Type": "application/json"
    }
  });
}

async function sendSupportMessage(name, email, phone, description) {
  let request = {Name: name, Email: email, Phone: phone, Description: description};
  return await axios.post(API_URL + "user/SendSupportMessage", JSON.stringify(request),
  {
    headers: {
      "Content-Type": "application/json"
    }
  });
}

async function getRecruiterInfo(name, specialty, email, phone) {
  let request = {Name: name, Specialty: specialty, Email: email, Phone: phone};

  return await axios.post(API_URL + "user/RecruiterInfo", JSON.stringify(request),
  {
    headers: {
      "Content-Type": "application/json"
    }
  });
}

async function assignRecruiter(specialtyCategory, specialtyList, state, registrationId) {
  let request = {SpecialtyCategory: specialtyCategory, SpecialtyList: specialtyList, State: state, RegistrationId: registrationId};

  return await axios.post(API_URL + "user/AssignRecruiter", JSON.stringify(request),
  {
    headers: {
      "Content-Type": "application/json"
    }
  });
}

async function checkExists(email) {
  const body = {
    Email: email
  };

  let response = await axios.post(API_URL + "user/exists", body);
  return response.data;
};

async function getSupportTeam() {
  const response = await axios.get(API_URL + "user/SupportTeam");
  return response.data;
}

async function addToRecruiterGroup() {
  return await axios.post(API_URL + "admin/recruitergroup",
  {
    headers: {
      "Content-Type": "application/json"
    }
  });
}

async function getCandidatePreRegistration(id) {
  const response = await axios.get(API_URL + "admin/candidateregistration?id=" + id);
  return response.data;
}

async function getRecruiterPreRegistration(id) {
  const response = await axios.get(API_URL + "admin/recruiterregistration?id=" + id);
  return response.data;
}

export default {
  getPublicContent,
  getUserBoard,
  getModeratorBoard,
  getAdminBoard,
  getUser,
  getUserType,
  createUser,
  sendSupportMessage,
  getRecruiterInfo,
  assignRecruiter,
  checkExists,
  getSupportTeam,
  getCandidatePreRegistration,
  getRecruiterPreRegistration,
  addToRecruiterGroup,
  resetTestUser,
  updateForgotPasswordStatus
};
