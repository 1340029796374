import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Container,
  Box,
  Select,
  InputLabel,
  MenuItem,
  FormControl,
  FormHelperText,
  TextField,
  CircularProgress
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import DateFnsUtils from "@date-io/date-fns";
import { Delete } from "@material-ui/icons";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider
} from "@material-ui/pickers";

import { AddIcon } from "../../../common/Icons";
import ConfirmationModal from "../../../common/ConfirmationModal/ConfirmationModal";
import { getSchools } from "../../../../services/datalist";
import { useDebouncedSearch } from "../../../../hooks/search";
import { deleteData } from "../../../../actions/candidateEntry";
import "./Education.scss";

const useSchoolSearch = () => useDebouncedSearch((text) => getSchools(text));

const EducationForm = ({
  change,
  error,
  clearError,
  addForm,
  handleDelete,
  values = []
}) => {
  const { inputText, setInputText, searchResults } = useSchoolSearch();
  const { loading, result = [] } = searchResults;
  const schools = result.map((school) => school.name);
  const states = useSelector((state) =>
    state.states ? state.states.map((state) => state.name) : []
  );
  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const [educationId, setEducationId] = useState();
  const [open, setOpen] = useState(false);
  const [key, setKey] = useState(undefined);
  const [isDeleting, setDeleting] = useState(false);
  const dispatch = useDispatch();

  //get States List and add initial form
  useEffect(() => {
    values.length === 0 && add();
  }, [values]);

  const openSelect = (id) => {
    setKey(id);
    setOpen(true);
  };

  const closeSelect = (id) => {
    setKey(id);
    setOpen(false);
  };

  const handleChange = (field, value, id) => {
    if (values && values.length > 0) {
      const updatedValues = values.map((item, index) => {
        if (index === id) {
          item[field] = value;
        }
        return item;
      });
      change(updatedValues);
    }
  };

  const add = () => {
    const newValue = addForm();
    const updatedValues = [...values];
    updatedValues.push(newValue);

    change(updatedValues);
  };

  const deleteCard = (id) => {
    const newValues = [...values];
    const filteredValues = newValues.filter((value, index) => index !== id);
    change(filteredValues);
  };

  const deleteEducation = (id) => {
    setConfirmationOpen(true);
    setEducationId(id);
  };

  const confirmDeletion = async () => {
    setDeleting(true);
    try {
      await handleDelete(educationId);
    } catch (err) {
      console.log(err);
    }
    setDeleting(false);
    setConfirmationOpen(false);
  };

  const degrees = ["AA", "AS", "BA", "BS", "BSN", "Diploma", "GED", "MA", "MS", "MSN"];

  return (
    <>
      <Container width={1} data-testid="education" maxWidth="md">
        <div className="header ">
          <h6 className="text-lg leading-6 font-medium text-gray-900">Education</h6>
          <div className="add" onClick={add}>
            <AddIcon iconClass="blue-icon" />
          </div>
        </div>
        <Box width={1}>
          {values &&
            values.map((value, index) => {
              const errors = error && error[`${index}`];
              return (
                <div key={index} className="edit-card education-edit-card">
                  <Autocomplete
                    freeSolo
                    id={`school${index}`}
                    fullWidth
                    open={key === index ? open : false}
                    onOpen={() => {
                      openSelect(index);
                    }}
                    onClose={() => {
                      closeSelect(index);
                    }}
                    value={value.school}
                    getOptionSelected={(option, value) => {
                      if (value === "") {
                        return true;
                      } else if (option === value) {
                        return true;
                      }
                    }}
                    filterOptions={(options, object) => options}
                    options={schools}
                    loading={loading}
                    getOptionLabel={(option) => option}
                    onChange={(e, val) => handleChange("school", val, index)}
                    blurOnSelect
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="School*"
                        variant="outlined"
                        onChange={(e) => setInputText(e.target.value)}
                        error={errors?.school ? true : false}
                        helperText={errors?.school || "required"}
                        onFocus={() => clearError(index, "school")}
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <React.Fragment>
                              {loading ? (
                                <CircularProgress color="inherit" size={20} />
                              ) : null}
                              {params.InputProps.endAdornment}
                            </React.Fragment>
                          )
                        }}
                      />
                    )}
                  />
                  {states && (
                    <FormControl
                      fullWidth
                      error={errors?.state ? true : false}
                      variant="outlined"
                    >
                      <InputLabel id="location-label">Location*</InputLabel>
                      <Select
                        label="Location*"
                        id="location-select"
                        value={value.state ? value.state : ""}
                        data-testid="state"
                        onChange={(e, val) =>
                          handleChange("state", e.target.value, index)
                        }
                        onFocus={() => clearError(index, "state")}
                      >
                        {states?.map((state) => (
                          <MenuItem key={state} value={state}>
                            {state}
                          </MenuItem>
                        ))}
                      </Select>
                      <FormHelperText>
                        {errors?.state || "required"}
                      </FormHelperText>
                    </FormControl>
                  )}
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      id={`graduation${index}`}
                      margin="normal"
                      autoOk={true}
                      label="Graduation*"
                      format="MM/dd/yyyy"
                      placeholder="MM/DD/YYYY"
                      value={value.graduationDate}
                      onChange={(e, val) =>
                        handleChange("graduationDate", val, index)
                      }
                      onFocus={() => clearError(index, "graduationDate")}
                      error={errors?.graduationDate ? true : false}
                      helperText={errors?.graduationDate || "required"}
                      fullWidth
                      KeyboardButtonProps={{
                        "aria-label": "change date"
                      }}
                    />
                  </MuiPickersUtilsProvider>

                  <FormControl
                    fullWidth
                    error={errors?.degree ? true : false}
                    variant="outlined"
                  >
                    <InputLabel id="degree-label">Degree*</InputLabel>
                    <Select
                      label="Degree*"
                      id="degree-select"
                      value={value.degree ? value.degree : ""}
                      data-testid="degree"
                      onChange={(e, val) =>
                        handleChange("degree", e.target.value, index)
                      }
                      onFocus={() => clearError(index, "degree")}
                    >
                      {degrees?.map((degree) => (
                        <MenuItem key={degree} value={degree}>
                          {degree}
                        </MenuItem>
                      ))}
                    </Select>
                    <FormHelperText>
                      {errors?.degree || "required"}
                    </FormHelperText>
                  </FormControl>

                  {value.id && (
                    <div
                      onClick={() => deleteEducation(value.id)}
                      data-testid="delete"
                    >
                      <Delete className="delete-icon" />
                    </div>
                  )}
                  {!value.id && values.length > 1 && (
                    <div onClick={() => deleteCard(index)} data-testid="delete">
                      <Delete className="delete-icon" />
                    </div>
                  )}
                </div>
              );
            })}
        </Box>
      </Container>
      {confirmationOpen && (
        <ConfirmationModal
          open={confirmationOpen}
          confirm={() => confirmDeletion()}
          handleClose={() => setConfirmationOpen(false)}
          message="Are you sure you want to delete this Education?"
          subMessage="All of your changes will be lost"
          cancelButtonText="Cancel"
        />
      )}
    </>
  );
};

export default EducationForm;
