import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import store from "./actions/store";
import { Provider } from "react-redux";
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import TagManager from 'react-gtm-module';

const logProfiler = ({
  ProfilerId,
  Phase,
  ActualTime,
  StartTime,
  CommitTime,
  Interactions
}) => {
  console.log({
    ProfilerId,
    Phase,
    ActualTime,
    StartTime, //time at which render starts
    CommitTime,
    Interactions // this is gotten from the rapping API
  });
};

if (process.env.REACT_APP_STAGE === "production") {
  const tagManagerArgs = {
      gtmId: 'GTM-W76CZCM'
  }

  TagManager.initialize(tagManagerArgs);
}

ReactDOM.render(
  //  <React.StrictMode>
  //<Profiler id="image-grid" onRender={logProfiler} >
  //   </Profiler>
  <Provider store={store}>
      <App />
  </Provider>,
  //  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
