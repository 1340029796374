import React from "react";
import { ReactComponent as Logo } from "./night.svg";
import SvgIcon from "@material-ui/core/SvgIcon";

import "../Icons.scss";

const NightIcon = (props) => {
  const { iconClass } = props;
  return (
    <SvgIcon className={iconClass} component={Logo} viewBox="0 0 480 480" />
  );
};

export default NightIcon;
