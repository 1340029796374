import React from "react";
import { useSelector } from "react-redux";
import DateFnsUtils from "@date-io/date-fns";

import DetailCard from "../../../common/DetailCard/DetailCard";

import "./JobPreferences.scss";
import { CalendarToday, WbSunny } from "@material-ui/icons";
import {
  HeartIcon,
  NightIcon,
  LocationIcon,
  DayNightIcon
} from "../../Icons";
const dateFns = new DateFnsUtils();

const JobPreferencesDetails = ({ handleClick }) => {
  const { candidateData } = useSelector((state) => state);

  const { jobPreferences } = candidateData;
  const { locations, shifts } = jobPreferences || [];
  const { startDate, quality } = jobPreferences || "";
  return (
    <div>
      {jobPreferences && (
        <div className="details job-preferences">
          <DetailCard title="Job Preferences" handleClick={handleClick}>
            <div>
              {shifts && (
                <div>
                  {shifts.map((shift, index) => (
                    <div key={index} className="job-detail">
                      {shift === 'nights' && <NightIcon iconClass="personal-icon" key={index+1}/>}
                      {shift === 'days' && <WbSunny className="personal-icon" key={index+2}/>}
                      {shift === '' && <DayNightIcon iconClass="personal-icon"key={index+3}/>}
                      <p> 
                        {`${shift}${index < shifts.length - 1 ? "," : ""}`}
                      </p>
                    </div>
                  ))}
                </div>
              )}
              {locations && (
                <div>
                  {locations.map((location, index) => (
                    <div key={index} className="job-detail">
                        <LocationIcon iconClass="personal-icon" />
                        <p>{`${location}`}</p>
                    </div>
                  ))}
                </div>
              )}
              {startDate && (
                <div className="job-detail">
                  <CalendarToday className="personal-icon" />
                  <p>{dateFns.format(new Date(startDate), "MM/dd/yy")}</p>
                </div>
              )}

              {quality && (
                <div className="job-detail">
                  <HeartIcon iconClass="personal-icon" />
                  <p>{quality}</p>
                </div>
              )}
            </div>
          </DetailCard>
        </div>
      )}
    </div>
  );
};

export default JobPreferencesDetails;
