import React from "react";
import { ReactComponent as TeamIcon } from "./myteam.svg";
import SvgIcon from "@material-ui/core/SvgIcon";

import "../Icons.scss";

const MyTeamIcon = (props) => {
  const { iconClass } = props;
  return (
    <SvgIcon
      className={iconClass}
      component={TeamIcon}
      style={{ fill: 'none' }}
    />
  );
};

export default MyTeamIcon;
