import React from "react";
import Geocode from "react-geocode";

import {
  Grid,
  TextField,
  Box,
  Container,
  FormControlLabel,
  FormLabel,
  RadioGroup,
  Radio
} from "@material-ui/core";

import { Autocomplete } from "@material-ui/lab";
import InputMask from "react-input-mask";
import PrimaryButton from "../../../common/Buttons/PrimaryButton";
import { LocationIcon } from "../../../common/Icons";
import { getLocations } from "../../../../services/datalist";
import { useDebouncedSearch } from "../../../../hooks/search";
import PhoneVerificationConsent from '../../PhoneVerificationConsent/PhoneVerificationConsent';

const useLocationSearch = () =>
  useDebouncedSearch((text) => getLocations(text, true));

const About = (props) => {
  const { firstname, lastname, phone, contactMethod, location } =
    props.data;

  const { formErrors, handleChange, clearError, nextStep, prevStep } = props;

  const { setInputText, searchResults } = useLocationSearch();
  const { result = [] } = searchResults;
  const locationOptions = result.map((location) => location.name);
  let containsNumber = new RegExp(/([0-9])+/g);

  const changeLocations = (value) => {
    handleChange("about", "location", value);
    setInputText("");
  };

  const zipLocation = (value) => {
    if(value.length > 0) {
      formErrors.location = undefined;
    }

    if(!containsNumber.test(value)) {
      setInputText(value);
      handleChange("about", "location", value);
    } else {
      handleChange("about", "location", value)
    }
  }

  const success = (position) => {
    Geocode.setApiKey(process.env.REACT_APP_GOOGLE_API);
    Geocode.fromLatLng(position.coords.latitude, position.coords.longitude).then(
      (response) => {
        let city, state;
        for (let i = 0; i < response.results[0].address_components.length; i++) {
          for (let j = 0; j < response.results[0].address_components[i].types.length; j++) {
            switch (response.results[0].address_components[i].types[j]) {
              case "locality":
                city = response.results[0].address_components[i].long_name;
                break;
              case "administrative_area_level_1":
                state = response.results[0].address_components[i].short_name;
                break;
            }
          }
        }

        changeLocations(city + ', ' + state);
        formErrors.location = undefined;
      },
      (error) => {
        changeLocations(' ');
        formErrors.location = "Could not retrieve location";
        changeLocations('');
      }
    );
  }

  const error = (err) => {
    changeLocations(' ');
    formErrors.location = "Could not retrieve location";
    changeLocations('');
  }

  const getLocation = () => {
    navigator.geolocation.getCurrentPosition(success, error);
  }

  return (
    <Container maxWidth="sm">
      <Box width={1} display="flex" alignItems="center">
        <h1 className="text-lg leading-6 font-medium text-gray-900">Now, let's get to know each other</h1>
      </Box>
      <Box width={1} display="flex" mb={3}>
        <Grid
          container
          spacing={1}
          alignItems="flex-end"
          className="input-field"
        >
          <Grid item xs={11}>
            <TextField
              id="firstname"
              className="input-field"
              name="firstname"
              label="First Name*"
              value={firstname}
              error={formErrors && formErrors.firstname !== undefined}
              helperText={
                formErrors.firstname ? formErrors.firstname : ""
              }
              onChange={(e) =>
                handleChange("about", e.target.id, e.target.value)
              }
              onFocus={(e) => clearError(e.target.id)}
              fullWidth
              inputProps={{maxLength: 50}}
            />
          </Grid>
        </Grid>
        <Grid container spacing={1} alignItems="flex-end">
          <Grid item xs={11}>
            <TextField
              id="lastname"
              className="input-field"
              name="lastname"
              label="Last Name*"
              type="text"
              value={lastname}
              error={formErrors && formErrors.lastname !== undefined}
              helperText={
                formErrors.lastname ? formErrors.lastname : ""
              }
              onChange={(e) =>
                handleChange("about", e.target.id, e.target.value)
              }
              onFocus={(e) => clearError(e.target.id)}
              fullWidth
              inputProps={{maxLength: 50}}
            />
          </Grid>
        </Grid>
      </Box>
      <Box width={1} display="flex" mb={3}>
        <Grid container spacing={1} alignItems="flex-end">
          <Grid item xs={10}>
            <FormLabel component="legend">
              <div  className="text-md mt-4">Best number to reach you?</div>
            </FormLabel>
            <InputMask
              data-testid="phone-input"
              mask="999-999-9999"
              value={phone}
              disabled={false}
              onChange={(e) =>
                handleChange("about", e.target.id, e.target.value)
              }
              onFocus={(e) => clearError(e.target.id)}
              maskChar=" "
            >
              {() => (
                <TextField
                  id="phone"
                  className="input-field"
                  name="phone"
                  label="Mobile Phone*"
                  type="text"
                  error={formErrors && formErrors.phone !== undefined}
                  helperText={formErrors.phone ? formErrors.phone : ""}
                  fullWidth
                />
              )}
            </InputMask>
            <PhoneVerificationConsent isForgotPassword={false} useCenterTextAlignment={false}></PhoneVerificationConsent>
          </Grid>
        </Grid>
      </Box>
      <Box width={1} display="flex" mb={3}>
        <Grid container spacing={1}>
          <Grid item>
            <FormLabel component="legend">
              <div  className="text-md mt-4">Preferred method of contact</div>
            </FormLabel>
            <RadioGroup aria-label="gender" name="gender1">
              <FormControlLabel value="Text" checked={contactMethod == "Text"} onClick={(e) => handleChange("about", "contactMethod", e.target.value)} control={<Radio color="primary" />} label="Text me" />
              <FormControlLabel value="Email" checked={contactMethod == "Email"} onClick={(e) => handleChange("about", "contactMethod", e.target.value)} control={<Radio color="primary" />} label="Email me" />
            </RadioGroup>
          </Grid>
        </Grid>
      </Box>
      <Box width={1} display="flex" mb={3}>
        <Grid container spacing={1} alignItems="flex-end">
          <Grid item xs={12} className="location-grid">
            <FormLabel component="legend">
              <div  className="text-md mt-4">Where do you call home?</div>
            </FormLabel>
            {locationOptions && (
              <Autocomplete
                id="locations-autocomplete"
                fullWidth
                value={location}
                options={locationOptions}
                onChange={(e, val) => changeLocations(val)}
                getOptionLabel={(option) => option}
                blurOnSelect
                freeSolo
                renderInput={(params) => (
                  <TextField
                    {...params}
                    id="location"
                    className="input-field"
                    name="location"
                    label="Zip Code or City, State"
                    value={location}
                    error={formErrors && formErrors.location !== undefined}
                    helperText={
                      formErrors.location ? formErrors.location : ""
                    }
                    onChange={(e) =>
                      zipLocation(e.target.value)
                    }
                    fullWidth
                    InputProps={{
                      ...params.InputProps,
                    }}
                  />
                )}
                fullWidth
              />
            )}
            <span onClick={() => getLocation()}>
              <LocationIcon/>
            </span>
          </Grid>
        </Grid>
      </Box>
      <Box width={1} display="flex" justifyContent="flex-end">
        <Grid container spacing={1} alignItems="flex-end">
          <Grid item xs={4}>
            <PrimaryButton type="button" text="Back" buttonClass="color-button previous-step" handleClick={prevStep} />
          </Grid>
          <Grid item xs={4}>
            <PrimaryButton type="button" text="Next" handleClick={nextStep} buttonClass="color-button ml-2" />
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};

export default About;
