import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import SplashScreen from "../../common/SplashScreen/SplashScreen";
import NavLayout from "../../NavLayout/NavLayout";
import CandidateData from "../../CandidateData/CandidateData";

import { getCand, updateData } from "../../../actions/candidateEntry";
import { getStatesList } from "../../../actions/datalists";

const ProfileContainer = () => {
  const userData = useSelector((state) => state.candidateData);
  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    let mounted = true;
    const loadData = async () => {
      if (Object.keys(userData).length === 0) {
        setIsLoading(true);
        await dispatch(getCand());
        setIsLoading(false);
      }
    };
    if (mounted) {
      loadData();
    }

    return () => {
      mounted = false;
    };
    
  }, []);
  return (
    <>
      {isLoading ? (
        <SplashScreen />
      ) : (
        <NavLayout headerText="Profile" showProgress>
          <CandidateData showOptional userData={userData}/>
        </NavLayout>
      )}
    </>
  );
};

export default ProfileContainer;
