import React from "react";

import "./ProgressBar.scss";

const ProgressBar = ({ percentage }) => {
  return (
    <div className="progress-bar">
      <div className="filler" style={{ width: `${percentage}` }} />
    </div>
  );
};

export default ProgressBar;
