import React, { useState } from "react";
import { ArrowBackIos } from "@material-ui/icons";
import ContactModal from "../../ContactModal/ContactModal";
import { OperatorIcon } from "../Icons";

import "./ModalNav.scss";

const ModalNav = ({ showRecruiterText, handleClose, jobTitle }) => {
  const [modalOpen, setModalOpen] = useState(false);
  return (
    <>
      <div className="nav">
        <div className="back-icon" onClick={handleClose}>
          <ArrowBackIos />
        </div>
        <div>
          <div
            className="contact-recruiter"
            data-testid="open-modal"
            onClick={() => setModalOpen(true)}
          >
            {showRecruiterText && <p>Apply with a recruiter</p>}
            <OperatorIcon iconClass="nav-header-icon" />
          </div>
        </div>
      </div>
      <ContactModal
        open={modalOpen}
        handleClose={() => setModalOpen(false)}
        selectedJob={jobTitle}
      />
    </>
  );
};

export default ModalNav;
