import React from "react";
import { ReactComponent as Logo } from "./url.svg";
import SvgIcon from "@material-ui/core/SvgIcon";

import "../Icons.scss";

const UrlIcon = (props) => {
  const { iconClass } = props;
  return (
    <SvgIcon className={iconClass} component={Logo} viewBox="0 0 512 512" />
  );
};

export default UrlIcon;
