import React from "react";

import "./PhoneVerificationConsent.scss";

const PhoneVerificationConsent = (props) => {
  const { isForgotPassword, useCenterTextAlignment } = props;
  const textAlignStyle = props.useCenterTextAlignment ? "phone-verification-center" : "phone-verification-left";

  return (
      <div>
        {!props.isForgotPassword && (
          <div className={`phone-verification-consent ${textAlignStyle}`}>By providing your phone number, you are consenting to a one-time text message delivering a verification code to verify your account. To stop receiving messages, reply with "STOP". Message and data rates may apply.</div>
        )}
        {props.isForgotPassword && 
          <div className={`phone-verification-consent ${textAlignStyle}`}>By clicking the send button, you are consenting to a one-time text message delivering a verification code to recover your password. To stop receiving messages, reply with "STOP". Message and data rates may apply.</div>
        }

        <div className={`${textAlignStyle}`}>
          <a className={`phone-verification-consent-link ${textAlignStyle}`} href="https://www.advantismed.com/sms-terms" target="_blank">Terms & Conditions</a>
          <a className={`phone-verification-consent-link ${textAlignStyle}`} href="https://www.advantismed.com/privacy" target="_blank">Privacy Policy</a>
        </div>
      </div>
  );
};

export default PhoneVerificationConsent;
