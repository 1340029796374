import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Container
} from "@material-ui/core";

import { Delete } from "@material-ui/icons";

import UploadFile from "../../../common/UploadFile/UploadFile";
import PrimaryButton from "../../../common/Buttons/PrimaryButton";
import ConfirmationModal from "../../../common/ConfirmationModal/ConfirmationModal";
import "./ResumeUploadForm.scss";
import { deleteData } from "../../../../actions/candidateEntry";
import { getCandidateFile } from "../../../../services/candidateData";

const ResumeUploadForm = ({ change, values = {}, error, clearError }) => {
  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const { candidateData } = useSelector((state) => state);
  const dispatch = useDispatch();
  const { resumes } = candidateData;

  const [fileId, setFileId] = useState();

  const saveFile = (file) => {
    clearError("fileName");
    change(file.name, "fileName");
    change(file.fileBase64Str, "fileBase64Str");
  };

  const getFile = (fileId, fileName) => {
    getCandidateFile(fileId, fileName);
  };

  const removeFile = (id) => {
    setFileId(id);
    setConfirmationOpen(true);
  };

  const confirmDeletion = async () => {
    await dispatch(deleteData("resume", fileId));
    setConfirmationOpen(false);
  };

  return (
    <>
      <Container width={1} data-testid="resumeUpload" maxWidth="md">
        <div className="header">
          <h6 className="text-lg leading-6 font-medium text-gray-900">Resume</h6>
        </div>

        {(!resumes || resumes.length === 0) && (
          <UploadFile
            prefix='resume-'
            storeFile={saveFile}
            hideUpload
            error={error?.fileName}
            possibleFileExtensions={["txt", "pdf", "doc", "docx"]}
          />
        )}
        {resumes && resumes.length > 0 && (
          <>

              <div className="mt-4 text-sm font-medium text-gray-500">Uploaded</div>
              <div className="mt-1 text-sm text-gray-900">
              {[resumes[0]].map((value, index) => (
                <>
                  <div key={index} role="list" className="p-4 border border-gray-50 rounded-md ">
                      <div className="pl-3 pr-4 py-3 flex items-center justify-between text-sm">
                        <div className="w-0 flex-1 flex items-center">
                          <svg className="flex-shrink-0 h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M15.172 7l-6.586 6.586a2 2 0 102.828 2.828l6.414-6.586a4 4 0 00-5.656-5.656l-6.415 6.585a6 6 0 108.486 8.486L20.5 13" />
                          </svg>
                          <span className="ml-2 flex-1 w-0 truncate">{value.fileName}</span>
                        </div>
                      </div>
                  </div>
                  <div className="mt-4 flex-shrink-0 flex">
                    <PrimaryButton
                      buttonClass="link-button"
                      text="Download"
                      handleClick={() => getFile(value.fileId, value.fileName)}
                    />
                    <PrimaryButton
                      buttonClass="link-button ml-4 font-medium text-red-700 hover:text-red-600"
                      text="Delete"
                      handleClick={() => removeFile(value.fileId)}
                    />
                  </div>
                </>
              ))}
              </div>
          </>
        )}
      </Container>
      {confirmationOpen && (
        <ConfirmationModal
          open={confirmationOpen}
          confirm={() => confirmDeletion()}
          handleClose={() => setConfirmationOpen(false)}
          message="Are you sure you want to delete this resume?"
          subMessage="All of your changes will be lost"
          cancelButtonText="Cancel"
        />
      )}
    </>
  );
};

export default ResumeUploadForm;
