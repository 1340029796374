import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";


import DetailCard from "../../../common/DetailCard/DetailCard";
import ConfirmationModal from "../../../common/ConfirmationModal/ConfirmationModal";
import { deleteData } from "../../../../actions/candidateEntry";
import { getCandidateFile } from "../../../../services/candidateData";
import PrimaryButton from "../../../common/Buttons/PrimaryButton";
import { DocumentIcon } from "../../Icons";

import { Grid } from "@material-ui/core";

const ResumeUploadDetails = ({ handleClick }) => {
  const dispatch = useDispatch();
  const { candidateData } = useSelector((state) => state);
  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const [isDeleting, setDeleting] = useState(false);
  const [fileId, setResumeId] = useState();

  const getFile = (fileId, fileName) => {
    getCandidateFile(fileId, fileName);
  };

  const handleDeleteClick = async (id) => {
    setConfirmationOpen(true);
    setResumeId(id);
  };

  const confirmDeletion = async () => {
    setDeleting(true);
    try {
      await dispatch(deleteData("resume", fileId));
    } catch (err) {
      console.log(err);
    }
  };

  const { resumes: resumeList } = candidateData;

  return (
    <>
      <div className="resume-card" data-testid="resume-upload-details">
        {resumeList && resumeList.length > 0 && (
          <DetailCard title="Resume" handleClick={handleClick}>
             {[resumeList[0]].map((resume, index) => (
              <div className="details-card" key={index}>
                <Grid container>
                  <Grid item className="resume-card-data" xs={12} sm={12} md={12} lg={12}>
                    <div className="details text-sm font-smalltext-gray-900 truncate"> 
                        <DocumentIcon />               
                        <PrimaryButton
                          buttonClass="link-button"
                          text={resume.fileName}
                          handleClick={() => getFile(resume.fileId, resume.fileName)}
                        />
                    </div>
                  </Grid>
                  <Grid item className="resume-buttons" xs={12} sm={12} md={12} lg={12}>
                    <div  data-testid="editClick" className="buttons edit">
                      <PrimaryButton
                          buttonClass="link-button"
                          text="Edit"
                          handleClick={handleClick}
                        />
                    </div>
                    <div data-testid="deleteClick" className="buttons">
                      <PrimaryButton
                          buttonClass="link-button"
                          text="Delete"
                          handleClick={() => handleDeleteClick(resume.fileId)}
                        />
                    </div>
                  </Grid>
                </Grid>
              </div>
            ))}
          </DetailCard>
        )}
      </div>
      {confirmationOpen && (
        <ConfirmationModal
          open={confirmationOpen}
          confirm={() => confirmDeletion()}
          handleClose={() => setConfirmationOpen(false)}
          loading={isDeleting}
          message="Are you sure you want to delete this Education?"
          cancelButtonText="Cancel"
        />
      )}
    </>
  );
};

export default ResumeUploadDetails;
