import { useDispatch, useSelector } from "react-redux";
import { Box, Button, Container } from "@material-ui/core";
import React, { useState, useEffect } from "react";
import { useHistory, Link, useLocation  } from "react-router-dom";
import LogoutIcon from "@material-ui/icons/ExitToApp";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import AuthService from "../../../../services/auth.service.js";
import { mainStyles } from "../../../mainStyles.js";
import Hidden from "@material-ui/core/Hidden";
import { connect } from "react-redux";
import { globalState } from "../../../sharedstate.js";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";


import {
  HomeIcon,
  ProfileIcon,
  ConnectIcon,
  MyTeamIcon
} from "../../../common/Icons";

import { isAdmin } from "../../services/admin";

import "./Frame.scss"

const useStyles = mainStyles;

const mapStateToProps = (state) => {
  return {
    currentUser: state.auth,
    navig: state.nav.navig
  };
};

const Frame = (props) => {
  const dispatch = useDispatch();

  const history = useHistory();
  const location = useLocation();

  const [open, setOpen] = React.useState(
    props.navig == "admin" || props.navig == "adminu" || props.navig == "adminr" ? true : false
  );

  const [mobileOpen, setMobileOpen] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const lo = () => {
    AuthService.logout();
  };


  const { user: currentUser } = useSelector((state) => state.auth);

  const classes = useStyles();
 
  var pageheader = "";

  const cl_home = () => {
    props.dispatch({ type: "SET_NAV", navig: "home" });

    //   history.push("/home");
  };

  const cl_admin_a = () => {
    props.dispatch({ type: "SET_NAV", navig: "admina" });

    history.push("/assessments");
  };

  const cl_admin_u = () => {
    props.dispatch({ type: "SET_NAV", navig: "adminu" });

    history.push("/users");
  };

  const cl_admin_r = () => {
    props.dispatch({ type: "SET_NAV", navig: "adminr" });

    history.push("/recruiters");
  };

  switch (props.navig) {
    case "home":
      pageheader = "Home";
      break;
    case "adminu":
      pageheader = "Users";
      break;
    case "admind":
      pageheader = "Templates";
      break;
    case "adminr":
      pageheader = "Recruiters";
      break;
    case "account":
      pageheader = "Account";
      break;

    default:
    // code block
  }

  let adminarea;

  let adminareas = "";

  if (isAdmin(currentUser)) {
    adminareas = (
      <>
        <ListItem
          button
          selected={location.pathname === "/users"}
          className={
            props.navig === "adminu"
              ? classes.selectedTab
              : classes.unselectedTab
          }
          onClick={cl_admin_u}
        >
          <div
              className={`icon-box ${
              location.pathname === "/users" ? "blue-underline" : ""
              }`}
          >
            <ListItemIcon className="user">
                <ProfileIcon />
            </ListItemIcon>
            <ListItemText primary="Users" />
          </div>
        </ListItem>
        {currentUser?.groupList?.includes('admin') ? (
          <ListItem
            button
            selected={location.pathname === "/assessments"}
            className={
              props.navig === "admina"
                ? classes.selectedTab
                : classes.unselectedTab
            }
            onClick={cl_admin_a}
          >
            <div
                className={`icon-box ${
                location.pathname === "/assessments" ? "blue-underline" : ""
                }`}
            >
              <ListItemIcon>
                    <ProfileIcon iconClass={"basic-icon"} active={location.pathname === "/assessments"}/>
              </ListItemIcon>
              <ListItemText primary="Assessments" />
            </div>
          </ListItem>
        ) : null}
    </>
    );
  }

  if (isAdmin(currentUser)) {
    adminarea = (
      <div>
          <List component="div" disablePadding>
            {adminareas}
          </List>
        <Divider />
      </div>
    );
  } else {
    adminarea = (
      <div>
        {" "}
        <ListItem
          button
          className={
            props.navig === "home" ? classes.selectedTab : classes.unselectedTab
          }
          onClick={cl_home}
        >
          <ListItemIcon>
            <HomeIcon />
          </ListItemIcon>
          <ListItemText primary="Home" />
        </ListItem>
        {adminareas}
      </div>
    );
  }

  if (globalState.emu) {
    adminarea = (
      <div>
        <ListItem
          button
          className={
            props.navig === "home" ? classes.selectedTab : classes.unselectedTab
          }
          onClick={cl_home}
        >
          <ListItemIcon>
            <HomeIcon />
          </ListItemIcon>
          <ListItemText primary="Home" />
        </ListItem>
          <List component="div" disablePadding>
            {adminareas}
          </List>
      </div>
    );
  }

  const drawer = (
    <div>
      <div className={classes.toolbar + " header-logo"}>
        <ConnectIcon iconClass={"home-icon"} />
      </div>

      <div>
        {adminarea}
        <ListItem button onClick={lo}>
          <ListItemIcon>
            <LogoutIcon />
          </ListItemIcon>
          <ListItemText primary="Logout" />
        </ListItem>
      </div>
    </div>
  );

  return (
    <div className={classes.root + ' admin-menu'}>
      <Hidden mdUp>
        <Drawer
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          classes={{
            paper: classes.drawerPaper
          }}
          ModalProps={{
            keepMounted: true // Better open performance on mobile.
          }}
        >
          {drawer}
        </Drawer>
      </Hidden>

      <Hidden smDown implementation="css">
        <Drawer
          variant="permanent"
          open
          classes={{
            paper: classes.drawerPaper
          }}
        >
          {drawer}
        </Drawer>
      </Hidden>

      <main className={classes.content}>
        <div width="100%">
          <Box display="flex" className={classes.boxHeader}>
            <Hidden mdUp>
              <IconButton
                color="inherit"
                aria-label="Open drawer"
                onClick={handleDrawerToggle}
                className={classes.navIconHide}
              >
                <MenuIcon />
              </IconButton>
            </Hidden>
            {props.showSave === "1" ? (
              <Box p={1} className={classes.saveBox}>
                <Button className={classes.saveBox} onClick={props.saveAction}>
                  Save{" "}
                </Button>
              </Box>
            ) : (
              ""
            )}
          </Box>
        </div>

        <Container>
          <div className="classes.container">{props.innerFrame}</div>
        </Container>
      </main>
    </div>
  );
};

export default connect(mapStateToProps)(Frame);
