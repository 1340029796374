import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Container,
  TextField,
  Select,
  InputLabel,
  MenuItem,
  FormControl,
  FormHelperText,
  CircularProgress,
  Box
} from "@material-ui/core";

import { Autocomplete } from "@material-ui/lab";
import DateFnsUtils from "@date-io/date-fns";
import InputMask from "react-input-mask";
import { Delete } from "@material-ui/icons";

import { AddIcon } from "../../Icons";
import { getFacilities } from "../../../../services/datalist";
import { useDebouncedSearch } from "../../../../hooks/search";
import { deleteData } from "../../../../actions/candidateEntry";
import ConfirmationModal from "../../ConfirmationModal/ConfirmationModal";

import "./References.scss";

const dateFns = new DateFnsUtils();

const useFacilitySearch = () =>
  useDebouncedSearch((text) => getFacilities(text));

const ReferenceForm = ({
  change,
  values = [],
  error,
  clearError,
  addForm,
  handleDelete
}) => {
  const { setInputText, searchResults } = useFacilitySearch();
  const { loading, result: facilities = [] } = searchResults;
  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const [referenceId, setReferenceId] = useState();
  const [open, setOpen] = useState(false);
  const [key, setKey] = useState(undefined);
  const [isDeleting, setDeleting] = useState(false);

  const dispatch = useDispatch();

  const { candidateData } = useSelector((state) => state);
  const { work: workList } = candidateData;
  const [workListFiltered, setWorkListFiltered] = useState([]);

  const prevYear = new Date();
  prevYear.setFullYear(prevYear.getFullYear()-1);

  useEffect(() => {
    values.length === 0 && add();
  }, [values]);

  useEffect(() => {
    let filtered = [];

    filtered = workList.filter(function(obj) {
      return (new Date(obj.endDate) >= new Date(prevYear) || obj.isCurrent == true) && obj.facility != null && obj.facility != "";
    });

    setWorkListFiltered(filtered);
  }, [workList]);

  const openSelect = (id) => {
    setKey(id);
    setOpen(true);
  };

  const closeSelect = (id) => {
    setKey(id);
    setOpen(false);
  };

  const handleChange = (field, value, id) => {
    if (values.length > 0) {
      const updatedValues = values.map((item, index) => {
        if (index === id) {
          item[field] = value;
        }
        return item;
      });
      change(updatedValues);
    }
  };

  const add = () => {
    const newValue = addForm();
    const updatedValues = [...values];
    updatedValues.push(newValue);

    change(updatedValues);
  };

  const deleteCard = (id) => {
    const newValues = [...values];
    const filteredValues = newValues.filter((value, index) => index !== id);
    change(filteredValues);
  };

  const deleteReference = (id) => {
    setConfirmationOpen(true);
    setReferenceId(id);
  };

  const confirmDeletion = async () => {
    setDeleting(true);
    try {
      await handleDelete(referenceId);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      <div className="reference-form" data-testid="references">
        <div className="header">
          <h6>References</h6>{" "}
          <div className="add" onClick={add} className="icon-button">
            <AddIcon iconClass="blue-icon" />
          </div>
        </div>
        <p>Note: Once a reference is added, Advantis Medical will contact your references to help speed up the application process.</p>
        {values &&
          values.map((value, index) => {
            const errors = error && error[`${index}`];
            return (
              <Box key={index} className="edit-card reference-card" mt={2}>
                {value.id && (
                  <div
                    onClick={() => deleteReference(value.id)}
                    data-testid="delete"
                    className="flex items-center justify-right"
                  >
                    <span className="font-medium text-xs uppercase text-red-600">Delete</span>
                    <Delete className="delete-icon" />
                  </div>
                )}
                {!value.id && values.length > 1 && (
                  <div onClick={() => deleteCard(index)} data-testid="delete">
                    <Delete className="delete-icon" />
                  </div>
                )}
                <TextField
                  name="referenceName"
                  variant="outlined"
                  label="Reference's First Name*"
                  data-testid="firstName"
                  onChange={(e) =>
                    handleChange("firstName", e.target.value, index)
                  }
                  onFocus={() => clearError(index, "firstName")}
                  error={errors?.firstName ? true : false}
                  helperText={errors?.firstName || "required"}
                  value={value.firstName}
                  mb={2}
                  fullWidth
                  inputProps={{maxLength: 50}}
                />
                <TextField
                  name="referenceName"
                  variant="outlined"
                  label="Reference's Last Name*"
                  data-testid="lastName"
                  onChange={(e) =>
                    handleChange("lastName", e.target.value, index)
                  }
                  onFocus={() => clearError(index, "lastName")}
                  error={errors?.lastName ? true : false}
                  helperText={errors?.lastName || "required"}
                  value={value.lastName}
                  mb={2}
                  fullWidth
                  inputProps={{maxLength: 50}}
                />
                <TextField
                  name="referenceJobTitle"
                  data-testid="job"
                  variant="outlined"
                  label="Reference's Job Title*"
                  onChange={(e) =>
                    handleChange("jobTitle", e.target.value, index)
                  }
                  value={value.jobTitle}
                  mb={2}
                  fullWidth
                  error={errors?.jobTitle ? true : false}
                  helperText={errors?.jobTitle || "required"}
                  onFocus={() => clearError(index, "jobTitle")}
                  inputProps={{maxLength: 50}}
                />
                <FormControl
                  fullWidth
                  error={errors?.preferredContactMethod ? true : false}
                >
                  <InputLabel id="simple-select-label">
                    Preferred contact method*
                  </InputLabel>
                  <Select
                    labelId="simple-select-label"
                    id="simple-select"
                    value={value.preferredContactMethod}
                    data-testid="contact"
                    onFocus={() => clearError(index, "preferredContactMethod")}
                    onChange={(e) =>
                      handleChange(
                        "preferredContactMethod",
                        e.target.value,
                        index
                      )
                    }
                  >
                    <MenuItem value="Email">Email</MenuItem>
                    <MenuItem value="Text">Text</MenuItem>
                  </Select>
                  <FormHelperText>
                    {errors?.preferredContactMethod || "required"}
                  </FormHelperText>
                </FormControl>
                <TextField
                  name="refEmail"
                  variant="outlined"
                  label="Reference's email address*"
                  data-testid="email"
                  value={value.email}
                  onChange={(e) => handleChange("email", e.target.value, index)}
                  onFocus={() => clearError(index, "email")}
                  error={errors?.email ? true : false}
                  helperText={errors?.email || "required"}
                  mb={2}
                  fullWidth
                  inputProps={{maxLength: 50}}
                />
                <InputMask
                  mask="999-999-9999"
                  value={value.phone}
                  disabled={false}
                  onChange={(e) => handleChange("phone", e.target.value, index)}
                  onFocus={() => clearError(index, "phone")}
                  maskChar=" "
                >
                  {() => (
                    <TextField
                      variant="outlined"
                      label="Reference's mobile number*"
                      data-testid="mobile"
                      fullWidth
                      error={errors?.phone ? true : false}
                      helperText={errors?.phone || "required"}
                    />
                  )}
                </InputMask>
                <Box mt={2}>
                  <p className="caption">If you don't see the work location listed for this reference, please update your work experience and it will appear</p>
                  <FormControl
                    fullWidth
                    error={errors?.companyName ? true : false}
                  >
                    <InputLabel id="simple-select-label">
                      Where did you work with {value.firstName ? `${value.firstName}` : `this reference` }? *
                    </InputLabel>
                    <Select
                      labelId="simple-select-label"
                      id="simple-select"
                      value={value.companyName}
                      data-testid="contact"
                      onFocus={() => clearError(index, "companyName")}
                      onChange={(e) =>
                        handleChange(
                          "companyName",
                          e.target.value,
                          index
                        )
                      }
                    >
                      {value.companyName ? (
                        <MenuItem value={`${value.companyName}`}>{value.companyName}</MenuItem>
                      ) : (
                        null
                      )}

                      {workListFiltered?.map(work => {
                        return work.facility != value.companyName ?
                          <MenuItem key={work.id} value={`${work.facility}`}>{work.facility}</MenuItem>
                          : null
                      })}

                    </Select>
                    <FormHelperText>
                      {errors?.companyName || "required"}
                    </FormHelperText>
                  </FormControl>
                </Box>

                <TextField
                  name="refRatio"
                  variant="outlined"
                  data-testid="ratio"
                  label="Average nurse/patient ratio"
                  onChange={(e) => handleChange("ratio", e.target.value, index)}
                  value={value.ratio}
                  mb={2}
                  fullWidth
                  inputProps={{maxLength: 500}}
                />
              </Box>
            );
          })}
      </div>
      {confirmationOpen && (
        <ConfirmationModal
          open={confirmationOpen}
          confirm={() => confirmDeletion()}
          handleClose={() => setConfirmationOpen(false)}
          message="Are you sure you want to delete this Reference?"
          subMessage="All of your changes will be lost"
          cancelButtonText="Cancel"
          loading={isDeleting}
        />
      )}
    </>
  );
};

export default ReferenceForm;
