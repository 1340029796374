export const areas = [
  { displayName: "Alaska", searchNames: ["Alaska", "AK"], pictureUrl: "/images/jobs/locations/AK.jpg" },
  { displayName: "Alabama", searchNames: ["Alabama", "AL"], pictureUrl: "/images/jobs/locations/AL.jpg" },
  { displayName: "Albany, NY", searchNames: ["Albany, NY", "Albany, New York"], pictureUrl: "/images/jobs/locations/Albany_NY.jpg" },
  { displayName: "Arkansas", searchNames: ["Arkansas", "AR"], pictureUrl: "/images/jobs/locations/AR.jpg" },
  { displayName: "Atlanta, GA", searchNames: ["Atlanta, GA", "Atlanta, Georgia"], pictureUrl: "/images/jobs/locations/Atlanta_GA.jpg" },
  { displayName: "Austin, TX", searchNames: ["Austin, TX", "Austin, Texas"], pictureUrl: "/images/jobs/locations/Austin_TX.jpg" },
  { displayName: "Arizona", searchNames: ["Arizona", "AZ"], pictureUrl: "/images/jobs/locations/AZ.jpg" },
  { displayName: "Baltimore, MD", searchNames: ["Baltimore, MD", "Baltimore, Maryland"], pictureUrl: "/images/jobs/locations/Baltimore_MD.jpg" },
  { displayName: "Birmingham, AL", searchNames: ["Birmingham, AL", "Birmingham, Alabama"], pictureUrl: "/images/jobs/locations/Birmingham_AL.jpg" },
  { displayName: "Boise, ID", searchNames: ["Boise, ID", "Boise, Idaho"], pictureUrl: "/images/jobs/locations/Boise_ID.jpg" },
  { displayName: "Boston, MA", searchNames: ["Boston, MA", "Boston, Massachusetts"], pictureUrl: "/images/jobs/locations/Boston_MA.jpg" },
  { displayName: "Buffalo, NY", searchNames: ["Buffalo, NY", "Buffalo, New York"], pictureUrl: "/images/jobs/locations/Buffalo_NY.jpg" },
  { displayName: "California", searchNames: ["California", "CA"], pictureUrl: "/images/jobs/locations/CA.jpg" },
  { displayName: "Charleston, SC", searchNames: ["Charleston, SC", "Charleston, South Carolina"], pictureUrl: "/images/jobs/locations/Charleston_SC.jpg" },
  { displayName: "Charlotte, NC", searchNames: ["Charlotte, NC", "Charlotte, North Carolina"], pictureUrl: "/images/jobs/locations/Charlotte_NC.jpg" },  
  { displayName: "Chattanooga, TN", searchNames: ["Chattanooga, TN", "Chattanooga, Tennessee"], pictureUrl: "/images/jobs/locations/Chattanooga_TN.jpg" },
  { displayName: "Chicago, IL", searchNames: ["Chicago, IL", "Chicago, Illinois"], pictureUrl: "/images/jobs/locations/Chicago_IL.jpg" },
  { displayName: "Cincinnati, OH", searchNames: ["Cincinnati, OH", "Cincinnati, Ohio"], pictureUrl: "/images/jobs/locations/Cincinnati_OH.jpg" },
  { displayName: "Cleveland, OH", searchNames: ["Cleveland, OH", "Cleveland, Ohio"], pictureUrl: "/images/jobs/locations/Cleveland_OH.jpg" },
  { displayName: "Colorado", searchNames: ["Colorado", "CO"], pictureUrl: "/images/jobs/locations/CO.jpg" },
  { displayName: "Columbus, OH", searchNames: ["Columbus, OH", "Columbus, Ohio"], pictureUrl: "/images/jobs/locations/Columbus_OH.jpg" },
  { displayName: "Connecticut", searchNames: ["Connecticut", "CT"], pictureUrl: "/images/jobs/locations/CT.jpg" },
  { displayName: "Dallas, TX", searchNames: ["Dallas, TX", "Dallas, Texas"], pictureUrl: "/images/jobs/locations/Dallas_TX.jpg" },
  { displayName: "Delaware", searchNames: ["Delaware", "DE"], pictureUrl: "/images/jobs/locations/DE.jpg" },
  { displayName: "Denver, CO", searchNames: ["Denver, CO", "Denver, Colorado"], pictureUrl: "/images/jobs/locations/Denver_CO.jpg" },
  { displayName: "Des Moines, IA", searchNames: ["Des Moines, IA", "Des Moines, Iowa"], pictureUrl: "/images/jobs/locations/Des_Moines_IA.jpg" },
  { displayName: "Detroit, MI", searchNames: ["Detroit, MI", "Detroit, Michigan"], pictureUrl: "/images/jobs/locations/Detroit_MI.jpg" },
  { displayName: "Florida", searchNames: ["Florida", "FL"], pictureUrl: "/images/jobs/locations/FL.jpg" },
  { displayName: "Fort Lauderdale, FL", searchNames: ["Fort Lauderdale, FL", "Fort Lauderdale, Florida"], pictureUrl: "/images/jobs/locations/Fort_Lauderdale_FL.jpg" },
  { displayName: "Fort Worth, TX", searchNames: ["Fort Worth, TX", "Fort Worth, Texas"], pictureUrl: "/images/jobs/locations/Fort_Worth_TX.jpg" },
  { displayName: "Georgia", searchNames: ["Georgia", "GA"], pictureUrl: "/images/jobs/locations/GA.jpg" },
  { displayName: "Hartford, CT", searchNames: ["Hartford, CT", "Hartford, Connecticut"], pictureUrl: "/images/jobs/locations/Hartford_CT.jpg" },
  { displayName: "Hawaii", searchNames: ["Hawaii", "HI"], pictureUrl: "/images/jobs/locations/HI.jpg" },
  { displayName: "Honolulu, HI", searchNames: ["Honolulu, HI", "Honolulu, Hawaii"], pictureUrl: "/images/jobs/locations/Honolulu_HI.jpg" },
  { displayName: "Houston, TX", searchNames: ["Houston, TX", "Houston, Texas"], pictureUrl: "/images/jobs/locations/Houston_TX.jpg" },
  { displayName: "Iowa", searchNames: ["Iowa", "IA"], pictureUrl: "/images/jobs/locations/IA.jpg" },
  { displayName: "Idaho", searchNames: ["Idaho", "ID"], pictureUrl: "/images/jobs/locations/ID.jpg" },
  { displayName: "Illinois", searchNames: ["Illinois", "IL"], pictureUrl: "/images/jobs/locations/IL.jpg" },
  { displayName: "Indiana", searchNames: ["Indiana", "IN"], pictureUrl: "/images/jobs/locations/IN.jpg" },
  { displayName: "Indianapolis, IN", searchNames: ["Indianapolis, IN", "Indianapolis, Indiana"], pictureUrl: "/images/jobs/locations/Indianapolis_IN.jpg" },
  { displayName: "Jacksonville, FL", searchNames: ["Jacksonville, FL", "Jacksonville, Florida"], pictureUrl: "/images/jobs/locations/Jacksonville_FL.jpg" },
  { displayName: "Kansas City, MO", searchNames: ["Kansas City, MO", "Kansas City, Missouri"], pictureUrl: "/images/jobs/locations/Kansas_City_MO.jpg" },
  { displayName: "Kansas", searchNames: ["Kansas", "KS"], pictureUrl: "/images/jobs/locations/KS.jpg" },
  { displayName: "Kentucky", searchNames: ["Kentucky", "KY"], pictureUrl: "/images/jobs/locations/KY.jpg" },
  { displayName: "Louisiana", searchNames: ["Louisiana", "LA"], pictureUrl: "/images/jobs/locations/LA.jpg" },
  { displayName: "Lansing, MI", searchNames: ["Lansing, MI", "Lansing, Michigan"], pictureUrl: "/images/jobs/locations/Lansing_MI.jpg" },
  { displayName: "Las Vegas, NV", searchNames: ["Las Vegas, NV", "Las Vegas, Nevada"], pictureUrl: "/images/jobs/locations/Las_Vegas_NV.jpg" },
  { displayName: "Los Angeles, CA", searchNames: ["Los Angeles, CA", "Los Angeles, California"], pictureUrl: "/images/jobs/locations/Los_Angeles_CA.jpg" },
  { displayName: "Louisville, KY", searchNames: ["Louisville, KY", "Louisville, Kentucky"], pictureUrl: "/images/jobs/locations/Louisville_KY.jpg" },
  { displayName: "Massachusetts", searchNames: ["Massachusetts", "MA"], pictureUrl: "/images/jobs/locations/MA.jpg" },
  { displayName: "Madison, WI", searchNames: ["Madison, WI", "Madison, Wisconsin"], pictureUrl: "/images/jobs/locations/Madison_WI.jpg" },
  { displayName: "Maryland", searchNames: ["Maryland", "MD"], pictureUrl: "/images/jobs/locations/MD.jpg" },
  { displayName: "Maine", searchNames: ["Maine", "ME"], pictureUrl: "/images/jobs/locations/ME.jpg" },
  { displayName: "Memphis, TN", searchNames: ["Memphis, TN", "Memphis, Tennessee"], pictureUrl: "/images/jobs/locations/Memphis_TN.jpg" },
  { displayName: "Michigan", searchNames: ["Michigan", "MI"], pictureUrl: "/images/jobs/locations/MI.png" },
  { displayName: "Miami, FL", searchNames: ["Miami, FL", "Miami, Florida"], pictureUrl: "/images/jobs/locations/Miami_FL.jpg" },
  { displayName: "Milwaukee, WI", searchNames: ["Milwaukee, WI", "Milwaukee, Wisconsin"], pictureUrl: "/images/jobs/locations/Milwaukee_WI.jpg" },
  { displayName: "Minneapolis, MN", searchNames: ["Minneapolis, MN", "Minneapolis, Minnesota"], pictureUrl: "/images/jobs/locations/Minneapolis_MN.jpg" },
  { displayName: "Minnesota", searchNames: ["Minnesota", "MN"], pictureUrl: "/images/jobs/locations/MN.jpg" },
  { displayName: "Missouri", searchNames: ["Missouri", "MO"], pictureUrl: "/images/jobs/locations/MO.jpg" },
  { displayName: "Mobile, AL", searchNames: ["Mobile, AL", "Mobile, Alabama"], pictureUrl: "/images/jobs/locations/Mobile_AL.jpg" },
  { displayName: "Mississippi", searchNames: ["Mississippi", "MS"], pictureUrl: "/images/jobs/locations/MS.jpg" },
  { displayName: "Montana", searchNames: ["Montana", "MT"], pictureUrl: "/images/jobs/locations/MT.jpg" },
  { displayName: "Myrtle Beach, SC", searchNames: ["Myrtle Beach, SC", "Myrtle Beach, South Carolina"], pictureUrl: "/images/jobs/locations/Myrtle_Beach_SC.jpg" },
  { displayName: "Nashville, TN", searchNames: ["Nashville, TN", "Nashville, Tennessee"], pictureUrl: "/images/jobs/locations/Nashville_TN.jpg" },
  { displayName: "North Carolina", searchNames: ["North Carolina", "NC"], pictureUrl: "/images/jobs/locations/NC.jpg" },
  { displayName: "North Dakota", searchNames: ["North Dakota", "ND"], pictureUrl: "/images/jobs/locations/ND.jpg" },
  { displayName: "Nebraska", searchNames: ["Nebraska", "NE"], pictureUrl: "/images/jobs/locations/NE.png" },
  { displayName: "New Orleans, LA", searchNames: ["New Orleans, LA", "New Orleans, Louisiana"], pictureUrl: "/images/jobs/locations/New_Orleans_LA.jpg" },
  { displayName: "New York, NY", searchNames: ["New York City", "New York, NY", "New York, New York", "New York City, New York"], pictureUrl: "/images/jobs/locations/New_York_NY.jpg" },
  { displayName: "New Hampshire", searchNames: ["New Hampshire", "NH"], pictureUrl: "/images/jobs/locations/NH.jpg" },
  { displayName: "New Jersey", searchNames: ["New Jersey", "NJ"], pictureUrl: "/images/jobs/locations/NJ.jpg" },
  { displayName: "New Mexico", searchNames: ["New Mexico", "NM"], pictureUrl: "/images/jobs/locations/NM.jpg" },
  { displayName: "Nevada", searchNames: ["Nevada", "NV"], pictureUrl: "/images/jobs/locations/NV.jpg" },
  { displayName: "New York", searchNames: ["New York", "NY"], pictureUrl: "/images/jobs/locations/NY.jpg" },
  { displayName: "Ohio", searchNames: ["Ohio", "OH"], pictureUrl: "/images/jobs/locations/OH.jpg" },
  { displayName: "Oklahoma", searchNames: ["Oklahoma", "OK"], pictureUrl: "/images/jobs/locations/OK.jpg" },
  { displayName: "Oklahoma City, OK", searchNames: ["Oklahoma City, OK", "Oklahoma City, Oklahoma"], pictureUrl: "/images/jobs/locations/Oklahoma_City_OK.jpg" },
  { displayName: "Omaha, NE", searchNames: ["Omaha, NE", "Omaha, Nebraska"], pictureUrl: "/images/jobs/locations/Omaha_NE.jpg" },
  { displayName: "Oregon", searchNames: ["Oregon", "OR"], pictureUrl: "/images/jobs/locations/OR.jpg" },
  { displayName: "Orlando, FL", searchNames: ["Orlando, FL", "Orlando, Florida"], pictureUrl: "/images/jobs/locations/Orlando_FL.jpg" },
  { displayName: "Panama City, FL", searchNames: ["Panama City, FL", "Panama City, Florida"], pictureUrl: "/images/jobs/locations/Panama_City_FL.jpg" },
  { displayName: "Pennsylvania", searchNames: ["Pennsylvania", "PA"], pictureUrl: "/images/jobs/locations/PA.jpg" },
  { displayName: "Philadelphia, PA", searchNames: ["Philadelphia, PA", "Philadelphia, Pennsylvania"], pictureUrl: "/images/jobs/locations/Philadelphia_PA.jpg" },
  { displayName: "Phoenix, AZ", searchNames: ["Phoenix, AZ", "Phoenix, Arizona"], pictureUrl: "/images/jobs/locations/Phoenix_AZ.jpg" },
  { displayName: "Pittsburgh, PA", searchNames: ["Pittsburgh, PA", "Pittsburgh, Pennsylvania"], pictureUrl: "/images/jobs/locations/Pittsburgh_PA.jpg" },
  { displayName: "Portland, OR", searchNames: ["Portland, OR", "Portland, Oregon"], pictureUrl: "/images/jobs/locations/Portland_OR.jpg" },
  { displayName: "Providence, RI", searchNames: ["Providence, RI", "Providence, Rhode Island"], pictureUrl: "/images/jobs/locations/Providence_RI.jpg" },
  { displayName: "Raleigh, NC", searchNames: ["Raleigh, NC", "Raleigh, North Carolina"], pictureUrl: "/images/jobs/locations/Raleigh_NC.jpg" },
  { displayName: "Rhode Island", searchNames: ["Rhode Island", "RI"], pictureUrl: "/images/jobs/locations/RI.jpg" },
  { displayName: "Richmond, VA", searchNames: ["Richmond, VA", "Richmond, Virginia"], pictureUrl: "/images/jobs/locations/Richmond_VA.jpg" },
  { displayName: "Riverside, CA", searchNames: ["Riverside, CA", "Riverside, California"], pictureUrl: "/images/jobs/locations/Riverside_CA.jpg" },
  { displayName: "Sacramento, CA", searchNames: ["Sacramento, CA", "Sacramento, California"], pictureUrl: "/images/jobs/locations/Sacramento_CA.jpeg" },
  { displayName: "Salt Lake City, UT", searchNames: ["Salt Lake City, UT", "Salt Lake City, Utah"], pictureUrl: "/images/jobs/locations/Salt_Lake_City_UT.jpg" },
  { displayName: "San Antonio, TX", searchNames: ["San Antonio, TX", "San Antonio, Texas"], pictureUrl: "/images/jobs/locations/San_Antonio_TX.jpg" },
  { displayName: "San Diego, CA", searchNames: ["San Diego, CA", "San Diego, California"], pictureUrl: "/images/jobs/locations/San_Diego_CA.jpg" },
  { displayName: "San Francisco, CA", searchNames: ["San Francisco, CA", "San Francisco, California"], pictureUrl: "/images/jobs/locations/San_Francisco_CA.jpg" },
  { displayName: "San Jose, CA", searchNames: ["San Jose, CA", "San Jose, California"], pictureUrl: "/images/jobs/locations/San_Jose_CA.jpg" },
  { displayName: "Savannah, GA", searchNames: ["Savannah, GA", "Savannah, Georgia"], pictureUrl: "/images/jobs/locations/Savannah_GA.jpg" },
  { displayName: "Seattle, WA", searchNames: ["Seattle, WA", "Seattle, Washington"], pictureUrl: "/images/jobs/locations/Seattle_WA.jpg" },
  { displayName: "Saint Louis, MO", searchNames: ["Saint Louis, MO", "Saint Louis, Missouri"], pictureUrl: "/images/jobs/locations/St._Louis_MO.jpg" },
  { displayName: "South Carolina", searchNames: ["South Carolina", "SC"], pictureUrl: "/images/jobs/locations/SC.jpg" },
  { displayName: "South Dakota", searchNames: ["South Dakota", "SD"], pictureUrl: "/images/jobs/locations/SD.jpeg" },
  { displayName: "Tampa, FL", searchNames: ["Tampa, FL", "Tampa, Florida"], pictureUrl: "/images/jobs/locations/Tampa_FL.jpg" },
  { displayName: "Tennessee", searchNames: ["Tennessee", "TN"], pictureUrl: "/images/jobs/locations/TN.jpg" },
  { displayName: "Texas", searchNames: ["Texas", "TX"], pictureUrl: "/images/jobs/locations/TX.jpg" },
  { displayName: "Utah", searchNames: ["Utah", "UT"], pictureUrl: "/images/jobs/locations/UT.jpg" },
  { displayName: "Vermont", searchNames: ["Vermont", "VT"], pictureUrl: "/images/jobs/locations/VT.jpg" },
  { displayName: "Virginia", searchNames: ["Virginia", "VA"], pictureUrl: "/images/jobs/locations/VA.jpg" },
  { displayName: "Virginia Beach, VA", searchNames: ["Virginia Beach, VA", "Virginia Beach, Virginia"], pictureUrl: "/images/jobs/locations/Virginia_Beach_VA.jpg" },
  { displayName: "Washington", searchNames: ["Washington", "WA"], pictureUrl: "/images/jobs/locations/WA.jpg" },
  { displayName: "Washington DC", searchNames: ["Washington DC", "Washington D.C.", "DC", "D.C."], pictureUrl: "/images/jobs/locations/Washington_DC.jpg" },
  { displayName: "West Virginia", searchNames: ["West Virginia", "WV"], pictureUrl: "/images/jobs/locations/WV.jpg" },
  { displayName: "Wisconsin", searchNames: ["Wisconsin", "WI"], pictureUrl: "/images/jobs/locations/WI.jpg" },
  { displayName: "Wyoming", searchNames: ["Wyoming", "WY"], pictureUrl: "/images/jobs/locations/WY.jpg" }
];

export const stateLocation = {
  "AL": "Alabama",
  "AK": "Alaska",
  "AS": "American Samoa",
  "AZ": "Arizona",
  "AR": "Arkansas",
  "CA": "California",
  "CO": "Colorado",
  "CT": "Connecticut",
  "DE": "Delaware",
  "DC": "District Of Columbia",
  "FM": "Federated States Of Micronesia",
  "FL": "Florida",
  "GA": "Georgia",
  "GU": "Guam",
  "HI": "Hawaii",
  "ID": "Idaho",
  "IL": "Illinois",
  "IN": "Indiana",
  "IA": "Iowa",
  "KS": "Kansas",
  "KY": "Kentucky",
  "LA": "Louisiana",
  "ME": "Maine",
  "MH": "Marshall Islands",
  "MD": "Maryland",
  "MA": "Massachusetts",
  "MI": "Michigan",
  "MN": "Minnesota",
  "MS": "Mississippi",
  "MO": "Missouri",
  "MT": "Montana",
  "NE": "Nebraska",
  "NV": "Nevada",
  "NH": "New Hampshire",
  "NJ": "New Jersey",
  "NM": "New Mexico",
  "NY": "New York",
  "NC": "North Carolina",
  "ND": "North Dakota",
  "MP": "Northern Mariana Islands",
  "OH": "Ohio",
  "OK": "Oklahoma",
  "OR": "Oregon",
  "PW": "Palau",
  "PA": "Pennsylvania",
  "PR": "Puerto Rico",
  "RI": "Rhode Island",
  "SC": "South Carolina",
  "SD": "South Dakota",
  "TN": "Tennessee",
  "TX": "Texas",
  "UT": "Utah",
  "VT": "Vermont",
  "VI": "Virgin Islands",
  "VA": "Virginia",
  "WA": "Washington",
  "WV": "West Virginia",
  "WI": "Wisconsin",
  "WY": "Wyoming"
};