import React, { useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { VpnKey } from "@material-ui/icons";
import {
  Grid,
  TextField,
  Box,
  Dialog,
  DialogTitle,
  DialogContent
} from "@material-ui/core";
import Form from "react-validation/build/form";
import TopLogoLayout from "../../TopLogoLayout/TopLogoLayout";
import PrimaryButton from "../../common/Buttons/PrimaryButton";
import LinkButton from "../../common/Buttons/LinkButton";
import ReactPixel from 'react-facebook-pixel';

import "./ConfirmSignUp.scss";
import { clearMessage } from "../../../actions/message";
import { Auth } from 'aws-amplify';
import { Redirect } from "react-router-dom";
import Alert from '@material-ui/lab/Alert';
import CheckIcon from '@material-ui/icons/Check';
import Snackbar from '@material-ui/core/Snackbar';
import { history } from "../../../helpers/history";
import { login } from "../../../actions/auth";
import authService from "../../../services/auth.service";
import { LOGIN_SUCCESS } from "../../../actions/types";
import { updateCandidateData } from "../../../services/candidateData";
import InputMask from "react-input-mask";
import isMobilePhone from "validator/lib/isMobilePhone";
import userService from "../../../services/user.service";
import PhoneVerificationConsent from '../PhoneVerificationConsent/PhoneVerificationConsent';

const ConfirmSignUp = (props) => {
  const form = useRef();
  const checkBtn = useRef();

  const [code, setCode] = useState("");
  const [fieldError, setFieldError] = useState({});
  const [loading, setLoading] = useState(false);
  const [showResentCodeAlert, setShowResentCodeAlert] = useState(false);

  const [showPhoneDialog, setShowPhoneDialog] = React.useState(false);
  const [updatingPhone, setUpdatingPhone] = React.useState(false);

  const timer = React.useRef();

  const { password } = useSelector((state) => state.auth);
  const { message } = useSelector((state) => state.message);
  const orig = localStorage.getItem("orig");
  const userFromStorage = JSON.parse(localStorage.getItem("user"));
  const [phone, setPhone] = useState(props?.location?.state?.phone ? props.location.state.phone.replace("+1", "") : (userFromStorage?.phone ? userFromStorage.phone.replace("+1", "") : "None"));
  const [isValidPhone, setIsValidPhone] = useState(false);

  const dispatch = useDispatch();

  const onChangeCode = (e) => {
    const code = e.target.value;
    setCode(code);
  };

  const handleFormValidation = (code) => {
    let errors = {};
    let formIsValid = true;
    if (code.length === 0) {
      errors["code"] = "Code is required";
      formIsValid = false;
    }

    setFieldError(errors);
    return formIsValid;
  };

  const redirectAfterConfirming = () => {
    let skipCongrats = localStorage.getItem("skipCongrats");
    if (skipCongrats) {
      localStorage.removeItem("skipCongrats");
      history.push("/home");
    } else {
      history.push("/congrats");
    }
  }

  const handleValidation = async (e) => {
    e.preventDefault();

    setLoading(true);
    setFieldError({});
    dispatch(clearMessage());
    const validForm = handleFormValidation(
      e.target.elements.code.value
    );
    let errors = {};

    if (validForm) {
      try {
        let phoneConfirmed = await authService.confirmPhone(code);
        const socialSignup = localStorage.getItem("isSocialSignup");
        const isSocialSignup = socialSignup ? JSON.parse(socialSignup).isSocialSignup : false;

        if(phoneConfirmed) {
          ReactPixel.trackCustom('AmedConfirmSignup');
        }

        if (!phoneConfirmed) {
          throw {message: "Invalid code"};
        } else if (isSocialSignup) {
          await authService.updateUser(dispatch);
          localStorage.removeItem("isSocialSignup");
          const user = JSON.parse(localStorage.getItem("user"));
          dispatch({
            type: LOGIN_SUCCESS,
            payload: { user, orig: user?.username }
          });

          redirectAfterConfirming();
        } else if (orig) {
          await authService.updateUser(dispatch);
          redirectAfterConfirming();
        } else {
          history.push("/login");
        }
      } catch (error) {
        errors["code"] = error.message;
        setFieldError(errors);
        setLoading(false);
      }
    }
  };

  const resendConfirmationCode = async () => {
    try {
        await authService.verifyPhone();
        setShowResentCodeAlert(true);
    } catch (error) {
        console.log('Error resending code', error);
    }
  }

  const logout = async () => {
    try {
      if (!userFromStorage || userFromStorage.emailAddress === "advantistest@advantistest.com") {
        await userService.resetTestUser();
      }

      await authService.logout();
    } catch (error) {
        console.log('Error logging out', error);
    }
  }

  const handleClose = (event, reason) => {
    setShowResentCodeAlert(false);
  }

  const updatePhone = async (e) => {
    e.preventDefault();
    let phone = e.target.elements.phone.value;
    setUpdatingPhone(true);
    await authService.updateCognitoFields(dispatch, {phone: phone});
    await updateCandidateData("phone", {phone: phone}, false);
    setPhone(phone);
    setUpdatingPhone(false);
    setShowPhoneDialog(false);
  }

  const togglePhoneDialog = (toggle) => {
    setIsValidPhone(false);
    setShowPhoneDialog(toggle);
  }

  if (!orig) {
    return <Redirect to="/login" />;
  }

  return (
    <div className="confirm-container-main">
      <div className="confirm-form-container">
        <TopLogoLayout>
          <Form
            data-testid="form"
            onSubmit={handleValidation}
            ref={form}
            className="confirm-form"
          >
            <h4 className="text-lg leading-6 font-medium text-gray-900 text-center">Check your phone</h4>
            <Box width={1} mt={2}>
              <Grid
                container
                spacing={1}
                alignItems="flex-end"
                justify="center"
                className="input-field"
              >
                <Grid className="input-field-container" item xs={10} sm={12} md={12} lg={12}>
                  <p className="text-sm mb-4">We've sent a 6-digit verification code to your phone, {phone}.</p>
                  <TextField
                    id="code"
                    size="small"
                    className="input-field confirm-code"
                    name="code"
                    label="Type your code"
                    value={code}
                    onChange={onChangeCode}
                    error={
                      message !== undefined || fieldError["code"] !== undefined
                    }
                    helperText={fieldError.code ? fieldError.code : ""}
                    fullWidth
                  />
                </Grid>
              </Grid>
            </Box>

            {message && (
              <div className="form-group">
                <div className="alert alert-danger" role="alert">
                  {message}
                </div>
              </div>
            )}

            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              width="100% !important"
              paddingLeft="1.75em"
              m={3}
            >
              <PrimaryButton type="submit" text="Next" buttonClass="color-button" disabled={loading} loading={loading}/>
            </Box>
            <p className="sign-up text-sm text-gray-600">
              Incorrect phone?
              <span>
                <LinkButton text="Change" textButton handleClick={() => togglePhoneDialog(true)}/>
              </span>
            </p>
            <p className="sign-up text-sm text-gray-600">
              Didn't receive a code?
              <span>
                <LinkButton text="Resend Code" textButton handleClick={resendConfirmationCode}/>
              </span>
            </p>
            <p className="sign-up text-sm text-gray-600">
              <span>
                <LinkButton text="Logout" textButton handleClick={logout}/>
              </span>
            </p>
          </Form>
          <Snackbar open={showResentCodeAlert} autoHideDuration={6000} onClose={handleClose}>
            <Alert icon={<CheckIcon fontSize="inherit" />} severity="success">
                Code Resent
            </Alert>
          </Snackbar>
          <Dialog open={showPhoneDialog} onClose={() => togglePhoneDialog(false)}>
            <DialogTitle id="alert-dialog-title" className="dialog-title">
              <p className="verify-close" onClick={() => togglePhoneDialog(false)}>X</p>
            </DialogTitle>
            <DialogContent>
            <Form
              data-testid="form"
              onSubmit={updatePhone}
              ref={form}
              className="update-phone-form"
            >
              <div className="update-phone-input-container">
              <InputMask
                data-testid="phone-input"
                mask="999-999-9999"
                disabled={false}
                onChange={(e) => {
                  setIsValidPhone(isMobilePhone(e.target.value));
                }}
                maskChar=" "
              >
                {() => (
                  <div>
                    <TextField
                      variant="outlined"
                      id="phone"
                      className="input-field"
                      name="phone"
                      label="Mobile Phone"
                      type="text"
                      fullWidth
                    />
                    <PhoneVerificationConsent isForgotPassword={false} useCenterTextAlignment={true}></PhoneVerificationConsent>
                  </div>
                )}
              </InputMask>
              </div>
              <div className="update-phone-button-container">
                <PrimaryButton type="submit" text="Update" buttonClass="color-button update-phone-button" disabled={!isValidPhone} loading={updatingPhone}/>
              </div>
            </Form>
          </DialogContent>
        </Dialog>
        </TopLogoLayout>
      </div>
    </div>
  );
};

export default ConfirmSignUp;
