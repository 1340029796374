import React from "react";
import { ReactComponent as Logo } from "./calendar-and-clock.svg";
import { ReactComponent as LogoCalendar } from "./calendar.svg";
import SvgIcon from "@material-ui/core/SvgIcon";

import "../Icons.scss";

const CalendarIcon = ({ iconClass, calendarOnly }) => (
  <SvgIcon className={iconClass} component={calendarOnly ? LogoCalendar : Logo} />
);

export default CalendarIcon;
