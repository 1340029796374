import React, { useState } from "react";
import { ArrowBack, ArrowForward } from "@material-ui/icons";

import "./InfoCarousel.scss";

const InfoCarousel = (props) => {
  const images = [{
    url: "https://d2wqn2ab54ir3q.cloudfront.net/images/staff.jpg",
    message: "Your next job is one click away",
    altText: "staff"
  }, {
    url: "https://d2wqn2ab54ir3q.cloudfront.net/images/city.png",
    message: "Travel while getting paid",
    altText: "city"
  }];

  const [imageIndex, setImageIndex] = useState(0);

  const [carouselImage, setImage] = useState({
    src: images[imageIndex].url,
    altText: images[imageIndex].altText
  });
  const [message, changeMessage] = useState(images[imageIndex].message);

  const handleChange = (newIndex) => {
    setImageIndex(newIndex);
    setImage({
      src: images[newIndex].url,
      altText: images[newIndex].altText
    });
    changeMessage(images[newIndex].message);
  };

  const moveForward = () => {
    let newIndex = (imageIndex + 1) === images.length ? 0 : imageIndex + 1;
    handleChange(newIndex);
  };

  const moveBackward = () => {
    let newIndex = (imageIndex - 1) < 0 ? images.length - 1 : imageIndex - 1;
    handleChange(newIndex);
  };

  return (
    <div className="info-carousel-container">
    <div
      data-testid="image-container"
      className="info-carousel-content"
    >
      <img
        className="carousel-image"
        src={carouselImage && carouselImage.src}
        alt={carouselImage && carouselImage.altText}
      />

      <h3 className="text-lg font-medium">{message}</h3>
      <div className="carousel-switch-container">
        <ArrowBack className="arrow-button input-icon" onClick={moveBackward}/>
        <ArrowForward className="arrow-button input-icon" onClick={moveForward}/>
      </div>
      {/* <div className="circles">
        <div
          className={`circle ${
            carouselImage && carouselImage.src === staff ? "active" : ""
          }`}
        ></div>
        <div
          className={`circle ${
            carouselImage && carouselImage.src === city ? "active" : ""
          }`}
        ></div>
        <div className="circle"></div>
      </div> */}
    </div>
  </div>
  );
};

export default InfoCarousel;
