import { SET_MATCHEDJOBS, CLEAR_MATCHEDJOBS } from "../actions/types";

const matchedJobs = (state = null, action) => {
  const { type, payload } = action;
  switch (type) {
    case SET_MATCHEDJOBS:
      return payload;
    case CLEAR_MATCHEDJOBS:
      return null;
    default:
      return state;
  }
};

export default matchedJobs;
