import React, { useState } from "react";
import { useSelector, useDispatch} from "react-redux";

import DateFnsUtils from "@date-io/date-fns";

import DetailCard from "../../../common/DetailCard/DetailCard";
import ConfirmationModal from "../../../common/ConfirmationModal/ConfirmationModal";
import { deleteData } from "../../../../actions/candidateEntry";
import PrimaryButton from "../../../common/Buttons/PrimaryButton";
import { getCandidateFile } from "../../../../services/candidateData";

import { EditIcon, TrashIcon } from "../../Icons";
import { Grid } from "@material-ui/core";

const dateFns = new DateFnsUtils();

const LicenseDetails = ({ handleClick }) => {
  const dispatch = useDispatch();
  const { candidateData } = useSelector((state) => state);
  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const [isDeleting, setDeleting] = useState(false);
  const [certId, setCertId] = useState();

  const { license } = candidateData;

  const handleDeleteClick = async (id) => {
    setConfirmationOpen(true);
    setCertId(id);
  };

  const confirmDeletion = async () => {
    setDeleting(true);
    try {
      await dispatch(deleteData("license", certId));
    } catch (err) {
      console.log(err);
    }
  };

  const getFile = (fileId, fileName) => {
    getCandidateFile(fileId, fileName);
  };

  return (
    <>
      <div data-testid="license-details">
        {license && (
          <DetailCard title="Licenses" handleClick={handleClick}>
              <div className="details-card">
                <Grid container>
                  <Grid item className="license-details" xs={9} sm={9} md={9} lg={9}>
                    <div className="details text-sm font-smalltext-gray-900">
                      {license.isCompactLicense && <p>eNLC</p>}
                      <p className="text-sm font-smalltext-gray-900">
                        States:
                        {license.licenseStateList?.map((state, index) => (
                          <span key={index}>
                            {state}
                            {index < license.licenseStateList.length - 1 ? ", " : ""}
                          </span>
                        ))}
                      </p>
                      {license?.licenseDocs?.map((doc, index) => (
                        doc.expirationDate &&
                        <div className="license-data" key={index}>
                          <p className="text-sm font-smalltext-gray-900">
                            {"Expires: " + dateFns.format(new Date(doc.expirationDate), "MMMM yyyy")}
                          </p>
                          <PrimaryButton
                            buttonClass="link-button"
                            text={doc.fileName}
                            handleClick={() => getFile(doc.fileId, doc.fileName)}
                          />
                        </div>
                      ))}
                  </div>
                  </Grid>
                  <Grid item className="main-content buttons" xs={3} sm={3} md={3} lg={3}>
                    <div onClick={handleClick} data-testid="editClick" className="icon-button edit">
                      <EditIcon iconClass="blue-icon" />
                    </div>
                    {license.licenseDocs && license.licenseDocs.length >= 1 && (
                      <div onClick={() => handleDeleteClick(license.licenseDocs[0].id)} data-testid="deleteClick" className="icon-button">
                        <TrashIcon className="delete-icon" />
                      </div>
                    )}
                  </Grid>
                </Grid>
              </div>
          </DetailCard>
        )}
      </div>
      {confirmationOpen && (
        <ConfirmationModal
          open={confirmationOpen}
          confirm={() => confirmDeletion()}
          handleClose={() => setConfirmationOpen(false)}
          loading={isDeleting}
          message="Are you sure you want to delete this License?"
          cancelButtonText="Cancel"
        />
      )}
    </>
  );
};

export default LicenseDetails;
