import {
    REGISTER_SUCCESS,
    REGISTER_FAIL,
    LOGIN_SUCCESS,
    LOGIN_FAIL,
    LOGOUT,
    SET_USER
  } from "../actions/types";
  
  const user = JSON.parse(localStorage.getItem("user"));
  const orig = localStorage.getItem("orig");
  
  const initialState = user
    ? { isLoggedIn: true, user, orig }
    : { isLoggedIn: false, user: null, orig: '' };
  
  export default function (state = initialState, action) {
    const { type, payload, username, password } = action;
  
    switch (type) {
      case REGISTER_SUCCESS:
        return {
          ...state,
          isLoggedIn: false,
          user: payload,
          orig: payload.username || username,
          password: password
        };
      case REGISTER_FAIL:
        return {
          ...state,
          isLoggedIn: false
        };
      case LOGIN_SUCCESS:
        return {
          ...state,
          isLoggedIn: true,
          user: payload.user,
          orig: payload.orig
        };
      case LOGIN_FAIL:
        return {
          ...state,
          isLoggedIn: false,
          user: null,
          orig: payload.orig
        };
      case SET_USER:
        return {
          ...state,
          user: payload,
          orig: payload.username
        };
      case LOGOUT:
        return {
          ...state,
          isLoggedIn: false,
          user: null,
          orig: null
        };
      default:
        return state;
    }
  }