import React, { useState, useEffect } from "react";
import DateFnsUtils from "@date-io/date-fns";
import ReactGA from "react-ga";
import MixPanel from "mixpanel-browser";
import { useSelector, useDispatch } from "react-redux";
import { HeartIcon } from "../../common/Icons";
import { Grid } from "@material-ui/core";
import PrimaryButton from "../../common/Buttons/PrimaryButton";
import { addFavorite, removeFavorite } from "../../../actions/jobs";
import { stateLocation } from "../../views/JobSearch/explore";

import "./JobCardCondensed.scss";

const dateFns = new DateFnsUtils();


const JobCardCondensed = ({ job, hidePills, hasImage, candidateData, featuredJobs}) => {
    const cloudFrontBaseURL = "https://ik.imagekit.io/yksbahzmx/connect/";
    const placeholderImage = cloudFrontBaseURL + "images/job-placeholder.jpg";

    const { favoriteJobs } = useSelector((state) => state);
    const [isFavorite, setFavorite] = useState(false);
    const [addingFavorite, setAddingFavorite] = useState(false);

    const formatDateAdded = (date) => {
      if (date) {
          const curentDate = new Date();
          const pastDate = new Date(date);

          let difference = curentDate.getTime() - pastDate.getTime();
          let differenceDays = difference / (1000 * 3600 * 24);
          let dateString = "";

          if (differenceDays < 8) {
              dateString = Math.ceil(differenceDays) + 'd ago';
          } else if (differenceDays >= 8) {
              const weeks = Math.floor((differenceDays - 1) / 7);
              dateString =  weeks + 'w ago';
          }

          return dateString;
      }
  }

    const selectShiftType = (shift) => {
        const value = shift?.toLowerCase();
        let shiftType = "";
        if (shift){
            if (value.includes('am') || value.includes('day') || value.includes("07:00-19:00") || value.includes("morning")){
                if (!value.includes('noc') && !value.includes('night') && !value.includes('evening') && !value.includes("19:00-07:00")){
                    shiftType = ' Days';
                }
            }
            if (value.includes('noc') || value.includes('night') || value.includes('evening') || value.includes("19:00-07:00")){
                if (!value.includes('am') && !value.includes('day') && !value.includes("07:00-19:00") && !value.includes("morning")){
                    shiftType = ' Nights';
                }
            }
        }
        return shiftType;
    }

    const getShiftPill = () => {
      const desiredShifts = candidateData?.desiredShiftPreferenceList;
      const firstDesiredShift = shiftType?.toLocaleLowerCase().includes(desiredShifts[0]);
      const secondDesiredShift = shiftType?.toLocaleLowerCase().includes(desiredShifts[1]);

      if (firstDesiredShift && desiredShifts[0] === 'days' || secondDesiredShift && desiredShifts[1] === 'days') {
        return (
          <span className="shift text-sm status pill">
            Day Shift
          </span>
        )
      } else if (firstDesiredShift && desiredShifts[0] === 'nights' || secondDesiredShift && desiredShifts[1] === 'nights') {
        return (
          <span className="shift text-sm status pill">
            Night Shift
          </span>
        )
      }  else if (shiftType === "" && desiredShifts.length > 1) {
        return (
          <span className="shift text-sm status pill">
            Day and Night Shift
          </span>
        )
      }
    }

    const getLocationValue = () => {
      const state = job.location.split(', ')[1];
      const preferredLocation = candidateData.desiredLocationList.includes(stateLocation[state]);

      return preferredLocation ?  ( <span className="job-location text-sm status pill">Preferred Location</span> ) : null;
    }

    const dispatch = useDispatch();

    const addToFavorites = async (event) => {
        event.preventDefault();
        event.stopPropagation();
        setAddingFavorite(true);
        const isFavoriteJob = checkIsFavorite(job.jobId);
        if (isFavoriteJob) {
          await dispatch(removeFavorite(job.jobId));
          setFavorite(false);
        } else {
          await dispatch(addFavorite(job.jobId));
          setFavorite(true);
        }
        setAddingFavorite(false);
        ReactGA.event({ category: "JobDetails", action: "Add Favorite" });
        MixPanel.track("JobDetails", { action: "Add Favorite" });
    };

    const checkIsFavorite = (id) => {
        let isFavorite = false;

        favoriteJobs &&
          favoriteJobs.jobList.forEach((favorite) => {
            if (favorite.jobId === id) isFavorite = true;
            return;
          });

        return isFavorite;
      };

    const checkFavorite = () => {
      const favorite = favoriteJobs?.jobList?.find(
        (currentJob) => currentJob.jobId === job.jobId
      );
      if (favorite) setFavorite(true);
    };

    const shiftType = selectShiftType(job.shift);

    useEffect(() => {
        checkFavorite();
    }, [favoriteJobs]);



    return (
        <>
          {
            hasImage ? (
              <div className="no-shrink">
                    <img className="cover size-full" src={job.pictureUrlPath ? cloudFrontBaseURL + "tr:w-1400,h-700/" + job.pictureUrlPath : placeholderImage} />
              </div>
            ) : null
          }
          <div className="w-full relative pt-4 pb-5 px-4 ">
            <div className="flex justify-between w-full">
              <div className="min-w-0 flex-1">
                <div className="block">
                  <p className="text-sm font-smalltext-gray-900 truncate">{job.jobTitle}</p>
                  <p className="text-sm text-blue-500 truncate">{job.location}</p>
                </div>
              </div>
              <div className="favorite text-right" onClick={addToFavorites}>
                  <HeartIcon iconClass="small-blue-icon" active={isFavorite} />
              </div>
            </div>

            <div className="w-full mt-1">
            { job?.weeklySalary && job.weeklySalary !== 0 ? (
                  <p className="line-clamp-2 text-sm text-gray-600">
                  {`$${job.weeklySalary.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}`}
                  <span className="week">/week</span>,
                  {` ${parseInt(job.durationWeeks)} `} weeks
                  </p>) : (
                  <p className="line-clamp-2 text-sm text-gray-600">
                    Call/Text for Details, {parseInt(job.durationWeeks)} weeks
                  </p>
              )}

                {job.shift && (
                  <p className="line-clamp-2 text-xs text-gray-500 mt-1">
                    {shiftType === ' Nights'}
                    {shiftType === ' Days'}
                    {shiftType === ''}
                    {shiftType || " Days/Nights"}
                    &nbsp; shift
                  </p>
                )}

                {/* {job.shift && (
                  <p className="line-clamp-2 text-xs text-gray-500 mt-1">
                    {formatDateAdded(job?.dateAdded)}
                  </p>
                )} */}

                { !!candidateData && !hidePills && (
                    <div className="condensed-job-pill-section">
                        {featuredJobs?.jobList.map((currentJob) => (
                            currentJob.jobId === job.jobId && <span key="job-featured-0" className="job-featured text-sm status pill">Featured Job</span>
                          ))
                        }
                        {job.shift && candidateData?.desiredShiftPreferenceList?.length ? (
                            getShiftPill()
                          ) : null
                        }
                        { job.source === 'Direct' &&
                          <span className="job-specialty text-sm status pill">Exclusive</span>
                        }
                        { candidateData?.desiredLocationList?.length ? (
                            getLocationValue()
                          ) : null
                        }
                        {candidateData.specialtyList?.map((specialty, index) => (
                            job.specialtyRequirementList?.map((requirement, index) => (
                              specialty.id === requirement.id && <span key={index} className="job-specialty text-sm status pill">Specialty</span>
                          ))
                        ))}
                    </div>
                  )}
            </div>
          </div>
        </>
    )
}

export default JobCardCondensed;
