import React, {useEffect, useState} from "react";
import { useSelector, useDispatch } from "react-redux";
import { getSpecialties } from "../../../../services/datalist";

import DetailCard from "../../../common/DetailCard/DetailCard";
import ConfirmationModal from "../../../common/ConfirmationModal/ConfirmationModal";
import { updateData } from "../../../../actions/candidateEntry";

import { EditIcon, TrashIcon } from "../../Icons";
import { Grid } from "@material-ui/core";

import "./Specialties.scss";

const SpecialtyDetails = ({ handleClick }) => {
  const dispatch = useDispatch();
  const { candidateData } = useSelector((state) => state);
  const { specialties: specialtyData } = candidateData;
  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const [isDeleting, setDeleting] = useState(false);
  const [specialtyName, setSpecialtyName] = useState();
  const [specialties, setSpecialties] = useState([]);
  const [currentSpecialtyList, setSpecialtyList] = useState([]);

  const setList = (curerntList) => {
    let labelList = [];

    if (curerntList.length) {
      for (let j = 0; j < curerntList.length; j++) {
        labelList.push(curerntList[j].name);
      }
    
      return labelList;
    }
  }

  const handleDeleteClick = async (name) => {
    setConfirmationOpen(true);
    setSpecialtyName(name);
  };

  const confirmDeletion = async () => {
    let list = [];

    const filteredCurrentList = currentSpecialtyList.filter((value) => {
      return value !== specialtyName;
    });

    for (let i = 0; i < specialties.length; i++) {
      for (let j = 0; j < filteredCurrentList.length; j++) {
        if (specialties[i].label === filteredCurrentList[j]) {
          list.push(specialties[i].id);
        }
      }
    }

    const data = {specialties: { primarySpecialty: { id: list[0] }, secondarySpecialty: null, specialtyList: list}};

    setDeleting(true);
    
    try {
      await dispatch(updateData('specialties', data));
    } catch (err) {
      console.log(err);
    }
    
  };

  useEffect(() => {
    let mounted = true;
    getSpecialties()
      .then((data) => {
        if (mounted) {
          setSpecialties(data);
          setSpecialtyList(setList(specialtyData.specialtyList));
        }
      })
      .catch((err) => console.log(err));
    return () => (mounted = false);
  }, []);

  return (
    <>
    <div data-testid="specialty-details">
      {specialtyData && (
        <DetailCard title="Specialties" handleClick={handleClick}>
          {specialtyData.specialtyList.map((specialty, index) => (
            <div className="details-card" key={specialty.id} value={specialty.id}>
              <Grid container>
                <Grid item className="specialty-card" xs={9} sm={9} md={9} lg={9}>
                  <div className="details text-sm font-smalltext-gray-900 truncate">
                      {specialty.name}
                  </div>
                </Grid>
                <Grid item className="main-content buttons" xs={3} sm={3} md={3} lg={3}>
                  <div onClick={handleClick} data-testid="editClick" className={
                    specialtyData.specialtyList && specialtyData.specialtyList.length > 1 ? "icon-button edit" : "icon-button"
                  }>
                    <EditIcon iconClass="blue-icon" />
                  </div>
                  {specialtyData.specialtyList && specialtyData.specialtyList.length > 1 && (
                    <div onClick={() => handleDeleteClick(specialty.name)} data-testid="deleteClick" className="icon-button">
                      <TrashIcon className="delete-icon" />
                    </div>
                  )}
                </Grid>
              </Grid>
            </div>
          ))}
        </DetailCard>
      )}
      </div>
      {confirmationOpen && (
        <ConfirmationModal
          open={confirmationOpen}
          confirm={() => confirmDeletion()}
          handleClose={() => setConfirmationOpen(false)}
          loading={isDeleting}
          message="Are you sure you want to delete this Specialty?"
          cancelButtonText="Cancel"
        />
      )}
    </>
  );
};

export default SpecialtyDetails;
