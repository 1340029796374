import axios from "axios";
import API_URL from "../helpers/globals.js";

export const logError = async (error, errorTrace) => {
    const body = {
        Message: error,
        StackTrace: errorTrace
    };

    try {
        const response = await axios.post(
            `${API_URL}Error/frontenderror`,
            JSON.stringify(body),
            {
                headers: {
                    "Content-Type": "application/json"
                }
            }
        );

        if (response) {
            return response.data;
        }
    } catch (err) {
        console.log(err);
    }
};
