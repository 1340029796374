import axios from "axios";
import API_URL from "../helpers/globals.js";

// export const getSkillsTemplate = () => {
//   return axios
//     .get(API_URL + "SkillsDocument/")
//     .then((response) => console.log(response))
//     .catch((err) => console.log(err));
// };

export const getAssessments = async () => {
  try {
    const response = await axios.get(API_URL + "Admin/skillassessments");
    if (response) {
      return response.data;
    }
  } catch (err) {
    console.log(err);
  }
};

export const getSkills = async () => {
  try {
    const response = await axios.get(API_URL + "Candidate/skills");
    if (response) {
      return response.data;
    }
  } catch (err) {
    console.log(err);
  }
};

export const getSkill = async (assessmentId) => {
  try {
    const response = await axios.get(
      `${API_URL}Candidate/skill?assessmentId=${assessmentId}`
    );
    if (response) {
      return response.data;
    }
  } catch (err) {
    console.log(err);
  }
};
