import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import {
    Avatar,
    Grid,
    Paper
} from "@material-ui/core";

import {
    ChatIcon,
    EmailIcon,
    CallIcon,
  } from "../../common/Icons";

import NavLayout from "../../NavLayout/NavLayout";
import UserService from "../../../services/user.service";
import Skeleton from "@material-ui/lab/Skeleton";

import './MyTeam.scss'

const MyTeam = () => {
    const [loading, setLoading] = useState(true);
    const { personalInformation } = useSelector((state) => state);
    const [supportTeam, setSupportTeam] = useState();

    useEffect(() => {
        let mounted = true;

        const loadData = async () => {
            setLoading(true);
            let support = await UserService.getSupportTeam();
            setSupportTeam(support);
        };

        if (mounted) {
          loadData();
          setLoading(false);
        }

        return () => {
          mounted = false;
        };
    }, []);

    return (
        <NavLayout headerText="My Team">
            <div className="myteam_container">
                <div className="myteam_header">
                    <h4 className="text-lg font-smalltext-gray-900">Support Team</h4>
                </div>

                {!loading && supportTeam != undefined ? (
                    <>
                    {supportTeam?.memberList.length > 0 ? (
                        <Grid container spacing={2} className="team_container">
                            {supportTeam.memberList.map((member, index) => (
                                    <Grid item className="team_item" xs={12} md={6} lg={3} key={index}>
                                        <Paper className="team_paper">
                                            <div className="team_picture">
                                                <Avatar
                                                    alt="recruiter photo"
                                                    src={member.headshot ? `https://d2wqn2ab54ir3q.cloudfront.net/${member.headshot}` : "https://d2wqn2ab54ir3q.cloudfront.net/images/avatar-placeholder.png"}
                                                    style={{ width: "64px", height: "64px", margin: "0 auto" }}
                                                />
                                            </div>

                                            <div className="team_info">
                                                <h6 className="team_name text-lg font-smalltext-gray-900">{member.name}</h6>
                                                <p className="team_position">{member.jobTitle}</p>
                                                {member.email != null ? (
                                                    <p className="team_contact">
                                                        <a className="text-sm font-smalltext-gray-900" href={`mailto:${member.email}`} target="_blank" title="Email">{member.email}</a>
                                                    </p>
                                                ) : (
                                                    null
                                                )}
                                                {member.phone != null ? (
                                                    <p className="team_contact">
                                                        <a className="text-sm font-smalltext-gray-900" href={`tel:${member.phone}`} target="_blank" title="Phone">{member.phone}</a>
                                                    </p>
                                                ) : (
                                                    null
                                                )}
                                            </div>

                                        </Paper>
                                    </Grid>
                                ))}
                            </Grid>
                        ) : (
                            <p>No team members found</p>
                        )}
                    </>
                ) : (
                    <Grid container spacing={2} className="team_container">
                        <Grid item className="team_item" xs={12} md={6} lg={3}>
                            <Skeleton
                                variant="rect"
                                className="matched-card"
                                height="8rem"
                            ></Skeleton>
                        </Grid>
                        <Grid item className="team_item" xs={12} md={6} lg={3}>
                            <Skeleton
                                variant="rect"
                                className="matched-card"
                                height="8rem"
                            ></Skeleton>
                        </Grid>
                        <Grid item className="team_item" xs={12} md={6} lg={3}>
                            <Skeleton
                                variant="rect"
                                className="matched-card"
                                height="8rem"
                            ></Skeleton>
                        </Grid>
                        <Grid item className="team_item" xs={12} md={6} lg={3}>
                            <Skeleton
                                variant="rect"
                                className="matched-card"
                                height="8rem"
                            ></Skeleton>
                        </Grid>
                    </Grid>
                )}
            </div>
        </NavLayout>
    )
}

export default MyTeam;
