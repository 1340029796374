import React from "react";
import { ReactComponent as Circle } from "./circle.svg";
import SvgIcon from "@material-ui/core/SvgIcon";

import "../Icons.scss";

const CircleIcon = (props) => {
    const { iconClass} = props;
    return (
    <SvgIcon 
        className={iconClass} 
        component={Circle} />
    )
};

export default CircleIcon;