import isEmail from "validator/lib/isEmail";
import isMobilePhone from "validator/lib/isMobilePhone";
import { isValid } from "date-fns";
import { notify } from "../../toasts/toasts";

export const validateCertification = (data) => {
  let valid = true;
  let errors = {};
  if (!data?.certificationId) {
    errors["certificationId"] = "Certification Required";

    valid = false;
  }

  if (!data?.expirationDate) {
    errors["expirationDate"] = "Expiration Date Required";
    valid = false;
  } else if (!isValid(new Date(data.expirationDate))) {
    errors["expirationDate"] = "Invalid date";
    valid = false;
  }
  if (!data?.fileName) {
    errors["fileName"] = "Please upload a copy of your certification";
    valid = false;
  }
  return { valid: valid, errors };
};

export const validateResumeUpload = (data) => {
  let valid = true;
  let errors = {};

  if (!data?.fileName) {
    errors["fileName"] = "Please upload a copy of your resume";
    valid = false;
  }

  return { valid: valid, errors };
};

export const validateLicense = (data) => {
  let valid = true;
  let errors = {};
  if (!data?.licenseStateList || data?.licenseStateList.length < 1) {
    errors["licenseStateList"] = "You must select a state";
    valid = false;
  }
  if (data.fileName) {
    if (!data?.expirationDate) {
      errors["expirationDate"] = "Expiration Date Required";
      valid = false;
    }
  }

  if (data?.expirationDate && !isValid(new Date(data.expirationDate))) {
    errors["expirationDate"] = "Invalid date";
    valid = false;
  }

  if (data?.expirationDate && !data.fileName) {
    errors["fileName"] = "Upload a copy of your license";
    valid = false;
  }
  return { valid: valid, errors };
};

export const validateWork = (data) => {
  let errors = {};
  let valid = true;
  if (data) {
    for (let i = 0; i < data.length; i++) {
      let workHistory = data[i];
      if (!workHistory.startDate) {
        errors[`${i}`] = {
          ...errors[`${i}`],
          ...{ startDate: "Start date is required" }
        };
        valid = false;
      } else if (!isValid(new Date(workHistory.startDate))) {
        errors[`${i}`] = {
          ...errors[`${i}`],
          ...{ startDate: "Invalid date" }
        };
        valid = false;
      }
      if (!workHistory.isCurrent && !workHistory.endDate) {
        errors[`${i}`] = {
          ...errors[`${i}`],
          ...{ endDate: "End date is required, or Select Current Position" }
        };
        valid = false;
      } else if (
        !workHistory.isCurrent &&
        workHistory.endDate &&
        !isValid(new Date(workHistory.endDate))
      ) {
        errors[`${i}`] = {
          ...errors[`${i}`],
          ...{ endDate: "Invalid Date" }
        };
        valid = false;
      }
      if (
        workHistory.startDate &&
        workHistory.endDate &&
        new Date(workHistory.startDate) > new Date(workHistory.endDate)
      ) {
        errors[`${i}`] = {
          ...errors[`${i}`],
          ...{
            startDate: "Your selected start date is later than the end date."
          }
        };
        valid = false;
      }
    }
  }

  return { valid: valid, errors };
};

export const validatePersonalInformation = (data) => {
  let errors = {};
  let valid = true;
  const fields = [
    "firstName",
    "lastName",
    "phone",
    "email",
    "city",
    "state",
    "dateOfBirth",
    "ssn"
  ];

  for (let i = 0; i < fields.length; i++) {
    if (!data[fields[i]]) {
      errors[fields[i]] = "Required";
      valid = false;
    }
  }

  for (let key in data) {
    if (key === "phone" && !isMobilePhone(data[key])) {
      errors[key] = "Not a valid phone number";
      valid = false;
    } else if (key === "email" && !isEmail(data[key])) {
      errors[key] = "Not a valid email address";
      valid = false;
    } else if (key === "dateOfBirth" && !isValid(new Date(data[key]))) {
      errors[key] = "Invalid date";
      valid = false;
    }
  }
  return { valid: valid, errors };
};

export const validateEducation = (data) => {
  let errors = {};
  let valid = true;
  if (data) {
    for (let i = 0; i < data.length; i++) {
      let education = data[i];
      if (!education?.school) {
        errors[`${i}`] = {
          ...errors[`${i}`],
          ...{ school: "School is required" }
        };
        valid = false;
      }
      if (!education?.state) {
        errors[`${i}`] = {
          ...errors[`${i}`],
          ...{ state: "Location is required" }
        };
        valid = false;
      }
      if (!education.graduationDate) {
        errors[`${i}`] = {
          ...errors[`${i}`],
          ...{
            graduationDate: "Graduation Date is required"
          }
        };
        valid = false;
      } else if (!isValid(new Date(education.graduationDate))) {
        errors[`${i}`] = {
          ...errors[`${i}`],
          ...{
            graduationDate: "Invalid date"
          }
        };
        valid = false;
      }
      if (!education?.degree) {
        errors[`${i}`] = {
          ...errors[`${i}`],
          ...{
            degree: "Degree is required"
          }
        };
        valid = false;
      }
    }
  }
  return { valid: valid, errors };
};

export const validateReferences = (data) => {
  let errors = {};
  let valid = true;
  if (data) {
    for (let i = 0; i < data.length; i++) {
      let reference = data[i];
      if (!reference?.firstName) {
        errors[`${i}`] = {
          ...errors[`${i}`],
          ...{
            firstName: "First name is required"
          }
        };
        valid = false;
      }
      if (!reference?.lastName) {
        errors[`${i}`] = {
          ...errors[`${i}`],
          ...{
            lastName: "Last name is required"
          }
        };
        valid = false;
      }
      if (!reference?.jobTitle) {
        errors[`${i}`] = {
          ...errors[`${i}`],
          ...{
            jobTitle: "Job Title is required"
          }
        };
        valid = false;
      }
      if (!reference?.preferredContactMethod) {
        errors[`${i}`] = {
          ...errors[`${i}`],
          ...{
            preferredContactMethod: "Contact Method is required"
          }
        };
        valid = false;
      }
      if (!reference?.email) {
        errors[`${i}`] = {
          ...errors[`${i}`],
          ...{
            email: "Email is required"
          }
        };
        valid = false;
      } else if (!isEmail(reference?.email)){
        errors[`${i}`] = {
          ...errors[`${i}`],
          ...{
            email: "Invalid email address"
          }
        };
        valid = false;
      }
      if (!reference?.phone) {
        errors[`${i}`] = {
          ...errors[`${i}`],
          ...{
            phone: "Mobile is required"
          }
        };
        valid = false;
      } else if (!isMobilePhone(reference?.phone)) {
        errors[`${i}`] = {
          ...errors[`${i}`],
          ...{
            phone: "Not a valid phone number"
          }
        };
        valid = false;
      }
      if (!reference?.companyName) {
        errors[`${i}`] = {
          ...errors[`${i}`],
          ...{
            companyName: "Location is required"
          }
        };
        valid = false;
      }
    }
  }
  return { valid: valid, errors };
};

export const validateSpecialties = (data) => {
  let errors = {};
  let valid = true;
  if (!data?.specialtyList.length) {
    errors["specialtyList"] = "Specialty selection required";
    valid = false;
  }
  return { valid: valid, errors };
};

export const validateJobPreferences = (data) => {
  let valid = true;
  let errors = {};
  if (data.startDate && !isValid(new Date(data.startDate))) {
    errors["startDate"] = "Invalid date";
    valid = false;
  }

  return { valid: valid, errors };
};

export const validateSkills = (data) => {
  let valid = true;
  let errors = {};

  if(data.incomplete.length > 0) {
    valid = false;
    document.getElementById(data.incomplete[0]).previousSibling.scrollIntoView({behavior: 'smooth'});
    notify("Please complete all questions before submitting", "error");
  }

  return { valid: valid, errors };
}
