import { GET_JOBDETAILS, SET_FEATUREDJOBS, SET_MATCHEDJOBS, SET_SIMILARJOBS, SET_FAVORITEJOBS, SET_HIGHESTPAYJOBS } from "./types";

import {
  getJobDetails,
  getFeaturedJobs,
  getMatchedJobs,
  getSimilarJobs,
  getFavoriteJobs,
  deleteFavoriteJob,
  addFavoriteJob,
  getHighestPayJobs
} from "../services/jobs";

export const getJobDetailData = (id) => async (dispatch) => {
  try {
    const results = await getJobDetails(id);

    if (results) {
      dispatch({
        type: GET_JOBDETAILS,
        payload: results
      });
    }
  } catch (err) {
    console.log(err);
  }
};

export const setFeaturedJobs = () => async (dispatch) => {
  try {
    const results = await getFeaturedJobs();

    if (results) {
      dispatch({
        type: SET_FEATUREDJOBS,
        payload: results
      });
      return results;
    }
  } catch (err) {
    console.log(err);
  }
};

export const setMatchedJobs = () => async (dispatch) => {
  try {
    const results = await getMatchedJobs();

    if (results) {
      dispatch({
        type: SET_MATCHEDJOBS,
        payload: results
      });
      return results;
    }
  } catch (err) {
    console.log(err);
  }
};

export const setFavoriteJobs = () => async (dispatch) => {
  try {
    const results = await getFavoriteJobs();

    if (results) {
      dispatch({
        type: SET_FAVORITEJOBS,
        payload: results
      });
      return results;
    }
  } catch (err) {
    console.log(err);
  }
};

export const setSimilarJobs = (id) => async (dispatch) => {
  try {
    const results = await getSimilarJobs(id);

    if (results) {
      dispatch({
        type: SET_SIMILARJOBS,
        payload: results
      });
      return results;
    }
  } catch (err) {
    console.log(err);
  }
};

export const removeFavorite = (id) => async (dispatch) => {
  try {
    const results = await deleteFavoriteJob(id);

    if (results) {
      await dispatch(setFavoriteJobs());
    }
  } catch (err) {
    console.log(err);
  }
};

export const addFavorite = (id) => async (dispatch) => {
  try {
    const results = await addFavoriteJob(id);

    if (results) {
      await dispatch(setFavoriteJobs());
      return results;
    }
  } catch (err) {
    console.log(err);
  }
};

export const setHighestPayJobs = () => async (dispatch) => {
  try {
    const results = await getHighestPayJobs();

    if (results) {
      dispatch({
        type: SET_HIGHESTPAYJOBS,
        payload: results
      });
      return results;
    }
  } catch (err) {
    console.log(err);
  }
};
