import {
  SET_JOBFILTERS,
  CLEAR_JOBFILTERS,
  REMOVE_JOBFILTER,
  SET_JOBSEARCH
} from "../actions/types";

const jobFilters = (state = null, action) => {
  const { type, payload } = action;
  switch (type) {
    case SET_JOBFILTERS:
      return payload;
    case CLEAR_JOBFILTERS:
      return null;
    case REMOVE_JOBFILTER:
      return payload;
    default:
      return state;
  }
};

export default jobFilters;
