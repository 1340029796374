import React from "react";
import { ReactComponent as Logo } from "./email.svg";
import { ReactComponent as LogoMail } from "./mail.svg";
import SvgIcon from "@material-ui/core/SvgIcon";
import "../Icons.scss";

const EmailIcon = ({ iconClass, myTeam }) => (
  <SvgIcon className={iconClass} component={myTeam ? LogoMail : Logo} viewBox={!myTeam ? "0 0 470 485" : "0 0 24 24"} />
);

export default EmailIcon;
