export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAIL = "REGISTER_FAIL";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT = "LOGOUT";
export const SET_MESSAGE = "SET_MESSAGE";
export const CLEAR_MESSAGE = "CLEAR_MESSAGE";
export const UPDATE_CANDIDATE = "UPDATE_CANDIDATE";
export const CLEAR_CANDIDATE = "CLEAR_CANDIDATE";
export const UPDATE_ADMIN_CANDIDATE = "UPDATE_ADMIN_CANDIDATE";
export const UPDATE_PROGRESS = "UPDATE_PROGRESS";
export const GET_STATES = "GET_STATES";
export const VALIDATE_PHONE = "VALIDATE_PHONE";
export const SET_USER = "SET_USER";
export const GET_JOBDETAILS = "GET_JOBDETAILS";
export const SET_FEATUREDJOBS = "SET_FEATUREDJOBS";
export const SET_MATCHEDJOBS = "SET_MATCHEDJOBS";
export const CLEAR_MATCHEDJOBS = "CLEAR_MATCHEDJOBS";
export const SET_SIMILARJOBS = "SET_SIMILARJOBS";
export const CLEAR_SIMILARJOBS = "CLEAR_SIMILARJOBS";
export const SET_FAVORITEJOBS = "SET_FAVORITEJOBS";
export const CLEAR_FAVORITEJOBS = "CLEAR_FAVORITEJOBS";
export const SET_JOBFILTERS = "SET_JOBFILTERS";
export const REMOVE_JOBFILTER = "REMOVE_JOBFILTER";
export const CLEAR_JOBFILTERS = "CLEAR_JOBFILTERS";
export const SET_JOBSEARCH = "SET_JOBSEARCH";
export const CLEAR_JOBSEARCH = "CLEAR_JOBSEARCH";
export const SET_JOBAPPLICATIONS = "SET_JOBAPPLICATIONS";
export const CLEAR_JOBAPPLICATIONS = "CLEAR_JOBAPPLICATIONS";
export const SET_HIGHESTPAYJOBS = "SET_HIGHESTPAYJOBS";
