import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { Card, styled, Grid, Checkbox } from "@material-ui/core";
import LinkButton from "../../common/Buttons/LinkButton";

import ProgressBox from "../../common/ProgressBox/ProgressBox";
import ProgressBar from "../../common/ProgressBar/ProgressBar";

import "./ProgressCard.scss";

const ProgressCard = () => {
    const location = useLocation();
    const { profileProgress } = useSelector((state) => state);

    const { completed } = profileProgress || 0;
    const { total } = profileProgress || 10;

    const [percentage, setPercentage] = useState(undefined);

    useEffect(() => {
        setPercentage(`${Math.round((completed / total) * 100)}%`);
    }, [setPercentage, completed, total]);

    return (
        <Grid container spacing={2} className="progress-card-grid-contaner">
            <Grid item xs={12} sm={12} md={12} lg={12}>
                <Card className="progress-card-container">
                    <h3 className="progress-header text-lg font-medium text-gray-900">
                    { (percentage === '100%') ?
                    (
                      <span>Profile Complete!</span>
                    ):(
                      <span>Complete your Profile</span>
                    )}
                    </h3>
                    <Grid container spacing={2} className="progress-card-grid-contaner">
                        <Grid item xs={12}>
                            <ProgressBar percentage={percentage} className="progress-bar"/>
                        </Grid>
                        <Grid item xs={12}>
                            <ProgressBox className="progress-box"/>
                        </Grid>
                        <Grid item xs={12}
                            className={`${
                                location.pathname === "/profile" ? "hide-complete-button" : ""
                            }`}>
                            {percentage != '100%' ? (
                                <LinkButton url="/profile" text="Complete Profile" buttonClass="color-button next-step" />
                            ) : (
                                null
                            )}
                        </Grid>
                    </Grid>
                </Card>
            </Grid>
        </Grid>
    );
};

export default ProgressCard;
