import React from "react";

import "./Logo.scss";

const Logo = (props) => {
  const logo = "https://d2wqn2ab54ir3q.cloudfront.net/images/logo-white.png";
  return (
    <div className="logo-box">
      <img className="logo" src={logo} alt="amed-logo" />
      <span className="text">ADVANTIS MEDICAL</span>
    </div>
  );
};

export default Logo;
