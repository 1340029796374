import React from "react";
import { Box, styled } from "@material-ui/core";
import { AddTileIcon } from "../Icons";
import DetailCard from "../../common/DetailCard/DetailCard";

const AddTileButton = ({ handleClick, text, secondaryText = "", descrition="" }) => {
  return (
    <DetailCard title={text} handleClick={handleClick}></DetailCard>
  );
};

export default AddTileButton;