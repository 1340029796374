import React from "react";
import { Modal } from "@material-ui/core";
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile
} from "react-device-detect";
import {
  CalendarIcon,
  OperatorIcon,
  CallIcon,
  EmailIcon,
  ChatIcon
} from "../common/Icons";
import "./ContactModal.scss";

const ContactModal = ({ open, handleClose, selectedJob }) => {
  return (
    <Modal
      open={open}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      className="modal"
    >
      <div className="modal-content contact">
        <div className="modal-header">
          <OperatorIcon iconClass="contact-icon" />
          <div className="close-btn" onClick={handleClose}>
            X
          </div>
        </div>
        <h1 data-testid="contact-modal-header">
          {selectedJob
            ? "Let us help get this job!"
            : "We are here to help you"}
        </h1>
        <p>
          {selectedJob
            ? "Our recruiters will be happy to help during your application for this job."
            : "Our recruiters can answer questions for you or help with your job applications."}
        </p>
        <MobileView>
          <div className="contact-options">
            {/* <a data-testid="call-link" href="tel:+14694595885">
              <div className="option">
                <CallIcon iconClass="help-icon" />
                <p>Call</p>
              </div>
            </a>
            <a data-testid="chat-link" href="sms:+14694595885">
              <div className="option">
                <ChatIcon iconClass="help-icon" />
                <p>Text</p>
              </div>
            </a> */}
            <a
              data-testid="appointment-link"
              href="https://calendly.com/cnoisette"
              target="__blank"
            >
              <div className="option">
                <CalendarIcon iconClass="help-icon" />
                <p>Appointment</p>
              </div>
            </a>
            <a data-testid="email-link" href="mailto:connect@advantismed.com">
              <div className="option">
                <EmailIcon iconClass="help-icon" />
                <p>Email</p>
              </div>
            </a>
          </div>
        </MobileView>
        <BrowserView>
          <div className="browser-contact-options">
            {/* <div className="browser-option">
              <h3 data-testid="call-link">Call or Text: </h3>
              <p>(469)-459-5885</p>
            </div> */}
            <div className="browser-option">
              <h3>Email: </h3>
              <a data-testid="email-link" href="mailto:connect@advantismed.com">
                connect@advantismed.com
              </a>
            </div>
          </div>
        </BrowserView>

        {selectedJob && (
          <div className="selected-job">
            <p className="job-header">Job Selected</p>
            <p className="job-title">{selectedJob}</p>
          </div>
        )}
      </div>
    </Modal>
  );
};

export default ContactModal;
