import React from "react";

import Logo from "../common/Logo/Logo";
import "./TopLogoLayout.scss";

const TopLogoLayout = ({ children }) => {
  return (
    <div className="wrapper">
      <div className="top-logo">
        <Logo />
      </div>
      <main>{children}</main>
    </div>
  );
};

export default TopLogoLayout;
