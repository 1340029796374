import React from "react";
import { Modal } from "@material-ui/core";

import "./PdfModal.scss";

const PdfModal = ({ open, pdf, close }) => {
  return (
    <Modal
      open={open}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      className="modal"
    >
      <div className="modal-content pdf">
        <div className="modal-header">
          <div className="close-btn" onClick={close}>
            X
          </div>
        </div>

        <div className="iframe-container">
          <iframe src={pdf} title="pdf"></iframe>
        </div>
      </div>
    </Modal>
  );
};

export default PdfModal;
