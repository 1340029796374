import React, { useEffect, useState } from "react";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { 
  Modal, 
  CircularProgress, 
  Container,
  Grid,
  TextField,
  Box,
  MenuItem,
  FormControl,
  FormHelperText,
  InputLabel,
  IconButton,
  FormGroup,
  Checkbox,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup
 } from "@material-ui/core";
import { Select as MaterialSelect } from "@material-ui/core";
import Select from "react-select";
import isEmail from "validator/lib/isEmail";
import isMobilePhone from "validator/lib/isMobilePhone";
import InputMask from "react-input-mask";
import AvatarC from "../../../../avatar/avatar";

import ConfirmationModal from "../../../common/ConfirmationModal/ConfirmationModal";
import userService from "../../../../services/user.service";
import { clearMessage } from "../../../../actions/message";
import { updateRecruiterDetails, addNewRecruiter} from "../../services/recruiterDataStore";
import { withStyles } from '@material-ui/core/styles';
import { grey } from '@material-ui/core/colors';
import { getSpecialties } from "../../../../services/datalist";
import PhoneVerificationConsent from '../../../views/PhoneVerificationConsent/PhoneVerificationConsent';

import "./ViewOrAddUserModal.scss";

import ModalFooter from "../../../common/ModalFooter/ModalFooter";
import { cleanObject } from "./helpers";

var _ = require("lodash");

const ViewOrAddRecruiterModal = ({
  open,
  handleClose,
  handleUpdate,
  isAddNewRecruiter,
  userData,
  authId,
  isAdmin,
  authProvider
}) => {
  const cloudFrontBaseURL = "https://d2wqn2ab54ir3q.cloudfront.net/";
  const fieldStateData = _.cloneDeep(userData);
  const dispatch = useDispatch();
  const [openConfirm, setOpenConfirm] = useState(false);
  const [isDisabled, setDisabled] = useState(false);
  const [formErrors, setErrors] = useState({});
  const [isSaving, setIsSaving] = useState(false);
  const [loading, setLoading] = useState(false);
  const { message } = useSelector((state) => state.message);
  const [specialties, setSpecialties] = useState([]);
  const [currentSpecialtyList, setSpecialtyList] = useState([]);
  const [fieldState, setFieldState] = useState({
      biography: fieldStateData.biography ? fieldStateData.biography: "",
      authId: authId ? authId: "",
      assignedTeam: fieldStateData.assignedTeam ? fieldStateData.assignedTeam : "",
      calendlyLink: fieldStateData.calendlyLink ? fieldStateData.calendlyLink : "",
      firstName: fieldStateData.firstName ? fieldStateData.firstName : "",
      lastName: fieldStateData.lastName ? fieldStateData.lastName : "",
      email: fieldStateData.email ? fieldStateData.email : "",
      emailVerified: fieldStateData.emailVerified,
      phone: fieldStateData.phone ? fieldStateData.phone: "",
      phoneVerified: fieldStateData.phoneVerified,
      avatar: fieldStateData.pictureUrlPath ? {src: cloudFrontBaseURL + fieldStateData.pictureUrlPath } : {},
      useRoundRobin: fieldStateData.useRoundRobin
      
  });
  const [primaryCategory, setCategory] = useState(getPrimarySpecialty(fieldStateData));
  const user = JSON.parse(localStorage.getItem("user"));

  const labelStyle = {
    fontFamily: 'Ubuntu',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '24px',
    letterSpacing: '0em',
    textAlign: 'left',
    color: '#020606'
  }

  const handleChange = (field, value) => {
    setValue(field, value);
  };

  const setValue = (type, value) => {
    const newState = { ...fieldState };
    newState[type] = value;
    setFieldState(newState);
  };

  const getOptionValue = (option) => option;
  const getOptionLabel = (option) => option;

  const validateField = async(type, value, errors) => {
    if (!value && type !=="authId" && type !=="phoneVerified" && type !=="emailVerified" && type !=="useRoundRobin" && type !=="biography") {
      errors[type] = "required";
      return;
    }

    switch (type) {
        case "firstName":
            const validFirstName = Boolean(value.length);
            if (!validFirstName) errors["firstName"] = "Please enter a first name";
        break;
        case "lastName":
            const validLastName = Boolean(value.length);
            if (!validLastName) errors["lastName"] = "Please enter a last name";
        break;
        case "email":
            const validEmail = isEmail(value);
            if (!validEmail) {
            errors["email"] = "Please enter a valid email";
            } else if (isAddNewRecruiter){
            const userExists = await userService.checkExists(value);
            if (userExists) {
                errors["email"] = "Email already in use";
            }
            }
        break;
        case "phone":
            const validPhone = isMobilePhone(value);
            if (!validPhone) errors["phone"] = "Please enter a valid phone number";
        break;
        case "calendlyLink":
            const validCalendlyLink = Boolean(value.length);
            if (!validCalendlyLink) errors["calendlyLink"] = "Please enter a valid link";
        break;
      default:
    }
  };

  const validateEmail = async(value) => {
    let errors = {};
    await validateField('email', value, errors);

    if (Object.keys(errors).length > 0) {
      setErrors(errors);
    }    
  };

  const validateForm = async() => {
    setErrors({});
    let errors = {};
    let isValid = true;

    for (let key in fieldState) {
      await validateField(key, fieldState[key], errors);
    }

    if (Object.keys(errors).length > 0) {
      setErrors(errors);
      isValid = false;
    }
    return isValid;
  };

  const isValidFileName = async(fileName) => {
    const fileNameRegex = /(\..*){2,}/;
    return !fileNameRegex.test(fileName);
  }

  const handleSubmit = async () => {
    dispatch(clearMessage());
    const isValid = await validateForm();
    if (isValid) {
      let user = {};
  
      const {
        authId,
        biography,
        email,
        emailVerified,
        firstName,
        lastName,
        calendlyLink,
        assignedTeam,
        phone,
        phoneVerified,
        avatar,
        specialtyList,
        useRoundRobin
      } = fieldState;

      setIsSaving(true);
      if (isAddNewRecruiter) {
        try {
          await addNewRecruiter({
            authId,
            biography,
            email,
            firstName,
            lastName,
            calendlyLink,
            assignedTeam,
            phone,
            pictureFileName: avatar?.file?.name && isValidFileName(avatar.file.name) ? avatar.file.name : "",
            pictureFileBase64Str: avatar?.file?.fileBase64Str,
            specialtyUidList: specialtyList
        });
    

        await handleUpdate();
        setIsSaving(false);
        closeModals();

        } catch (err) {
          console.log(err);
          setIsSaving(false);
        }
      } else {
        try {
          await updateRecruiterDetails(authProvider, {
                authId,
                biography,
                email,
                emailVerified,
                firstName,
                lastName,
                calendlyLink,
                assignedTeam,
                phone,
                phoneVerified,
                pictureFileName: avatar?.file?.name && isValidFileName(avatar.file.name) ? avatar.file.name : "",
                pictureFileBase64Str: avatar?.file?.fileBase64Str,
                specialtyUidList: specialtyList,
                useRoundRobin
            });
    
            await handleUpdate();

            setIsSaving(false);
            closeModals();

        } catch (err) {
          console.log(err);
          setIsSaving(false);
        }
      }
    }
  };

  const changeSpecialty = (value) => {
    setSpecialtyList(value);
  };

  const setList = (fullSpecialtyList, currentList) => {
    let labelList = [];

    if (currentList.length) {
      for (let j = 0; j < currentList.length; j++) {
        let specialty = fullSpecialtyList?.find(x => x.uid === currentList[j]);
        if (specialty) {
          labelList.push(specialty.label);
        }
      }
    
      return labelList;
    }
  }

  function getPrimarySpecialty(data) {
    return !data.specialtyCategory || data.specialtyCategory === 'Registered Nurse' ? 'Nurse' : data.specialtyCategory;
  }

  function getModifiedSpecialtyCategory(category) {
    return category === 'Nurse' ? 'Registered Nurse' : category;
  }

  useEffect(() => {
    let mounted = true;
    if (mounted && specialties.length && currentSpecialtyList) {
      const updatedValues = { ...fieldState};
      let list = [];

      for (let i = 0; i < specialties.length; i++) {
        for (let j = 0; j < currentSpecialtyList.length; j++) {
          if (specialties[i].label === currentSpecialtyList[j] && specialties[i].category === getModifiedSpecialtyCategory(primaryCategory)) {
            list.push(specialties[i].uid);
          }
        }
      }
      setFieldState({...updatedValues, primarySpecialty: { uid: list[0] },  specialtyList: list});
    }
    return () => (mounted = false);
  }, [currentSpecialtyList]);

  useEffect(() => {
    let mounted = true;

    getSpecialties()
      .then((data) => {
        if (mounted) {
          setSpecialties(data);
          setSpecialtyList(setList(data, fieldStateData.specialtyList));
        }
      })
      .catch((err) => console.log(err));
    return () => (mounted = false);
  }, []);

  useEffect(() => {
    setFieldState(fieldState);
  }, [fieldState]);

  useEffect(() => {
    setDisabled(checkDisabled());
  }, [isSaving, fieldState]);

  useEffect(() => {
    message && setLoading(false);
    if (message?.includes("User already exists")) {
      setErrors({ username: "Username already in use." });
    }
    if (message?.includes("Username cannot be of email format")){
      setErrors({username: "Username cannot be an email address"})
    }
  }, [message]);

  const cancelChanges = () => {
    if (JSON.stringify(cleanObject(fieldState)) === JSON.stringify(cleanObject(fieldState))) {
      closeModals();
      return;
    }
    setOpenConfirm(true);
  };

  const closeModals = () => {
    setFieldState(null);
    handleClose();

    setOpenConfirm(false);
  };

  const clearError = (type) => {
    const errors = { ...formErrors };
    if (errors[type]) delete errors[type];
    setErrors(errors);
  };

  const checkDisabled = () => {
    if (isSaving === true) {
      return true;
    } else if (Array.isArray(fieldState)){
      if (fieldState.length === 0) return true;
    } else if (fieldState && Object.keys(fieldState).length === 0){
      return true;
    } else {
      return false;
    }
  }

  const onCropDefault = (preview, file) => {
    var base64result = preview.split(",")[1];

    file["fileBase64Str"] = base64result;

    setFieldState({ ...fieldState, avatar: {
        file,
        src: preview
      }
    });
  };

  const handlePhoneVerifiedChange = (event) => {
    setValue("phoneVerified", event.target.checked);
  };

  const handleEmailVerifiedChange = (event) => {
    setValue("emailVerified", event.target.checked);
  };

  const handleUseRoundRobinChange = (event) => {
    setValue("useRoundRobin", event.target.checked);
  };

  const currentUserIsSameUser = () => {
    return user?.authId === authId;
  };

  const GreenCheckbox = withStyles({
    root: {
      color: grey[400],
      '&$checked': {
        color: '#6dd19d',
      },
    },
    checked: {},
  })((props) => <Checkbox color="default" {...props} />);

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    height: '90%',
    p: 4,
  };

  return (
    <div className="view-user-modal">
      <Modal
        open={open}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        className="modal view-user-data recruiter"
      >
        <div style={style} className="modal-content white-background">
          {isSaving ? (
            <div className="spinner">
              <CircularProgress size={80} />
              <p>Saving...</p>
            </div>
          ) : (
            <>
            {fieldState ? (
              <Container maxWidth="sm">
                <Box width={1} display="block" mb={3}>
                  <Grid
                    container
                    spacing={1}
                    className="input-field"
                  >
                    <Grid className="recruiter-avatar" item xs={12} sm={12} md={12} lg={12}>
                        <AvatarC
                            width={110}
                            height={110}
                            borderStyle={{
                                margin: 'auto auto auto 1em',
                                position: 'relative',
                                textAlign: 'center'
                            }}
                            loaderStyle={{
                                margin: 'auto auto auto 1em'
                            }}
                            imgStyle={{
                                marginLeft: '-2em',
                            }}
                            label={'Upload'}
                            labelStyle={{
                                marginTop: '2.2em',
                                marginLeft: '6.5em',
                                padding: '0.5em 1.5em',
                                fontWeight: 400,
                                fontSize: '18px',
                                color: 'white',
                                display: 'inline-block',
                                fontFamily: 'sans-serif',
                                cursor: 'pointer',
                                borderRadius: '2px',
                                background: '#008dd0'
                            }}
                            onCrop={onCropDefault}
                            initialImage={
                                fieldState.avatar?.src && fieldState.avatar.src.length > 0
                                ? fieldState.avatar.src
                                : "https://d2wqn2ab54ir3q.cloudfront.net/images/avatar-placeholder.png"
                            }
                            className="avatar-edit"
                        />
                    </Grid>
                  </Grid>
                  <Grid container spacing={1} alignItems="flex-end">
                        <Grid item xs={12} sm={6} md={6} lg={6}>
                        <TextField
                            variant="outlined"
                            id="firstName"
                            className="input-field"
                            name="firstName"
                            label="First Name*"
                            value={fieldState.firstName}
                            error={formErrors && formErrors.firstName !== undefined}
                            helperText={
                                formErrors.firstName ? formErrors.firstName : "required"
                            }
                            onChange={(e) =>
                                handleChange(e.target.id, e.target.value)
                            }
                            onFocus={(e) => clearError(e.target.id)}
                            fullWidth
                            inputProps={{maxLength: 50}}
                        />
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} lg={6}>
                            <TextField
                                variant="outlined"
                                id="lastName"
                                className="input-field"
                                name="lastName"
                                label="Last Name*"
                                type="text"
                                value={fieldState.lastName}
                                error={formErrors && formErrors.lastName !== undefined}
                                helperText={
                                formErrors.lastName ? formErrors.lastName : "required"
                                }
                                onChange={(e) =>
                                    handleChange(e.target.id, e.target.value)
                                }
                                onFocus={(e) => clearError(e.target.id)}
                                fullWidth
                                inputProps={{maxLength: 50}}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                            <TextField
                                variant="outlined"
                                id="email"
                                className="input-field"
                                name="email"
                                label="Email*"
                                value={fieldState.email}
                                error={formErrors && formErrors.email !== undefined}
                                helperText={formErrors.email ? formErrors.email : "required"}
                                onChange={(e) =>
                                    handleChange( e.target.id, e.target.value)
                                }
                                onFocus={(e) => clearError(e.target.id)}
                                fullWidth
                                onBlur={(e) => validateEmail(e.target.value)}
                                inputProps={{maxLength: 100}}
                            />
                            <div className="verified">
                              <FormControl component="fieldset">
                                <FormGroup>
                                  <FormControlLabel disabled={loading || isAddNewRecruiter} checked={fieldState.emailVerified} name={'emailVerified'} onChange={handleEmailVerifiedChange} control={<GreenCheckbox />} label={'Email Verified'} />
                                </FormGroup>
                              </FormControl>
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                            <InputMask
                            data-testid="phone-input"
                            mask="999-999-9999"
                            value={fieldState.phone}
                            disabled={false}
                            onChange={(e) =>
                                handleChange(e.target.id, e.target.value)
                            }
                            onFocus={(e) => clearError(e.target.id)}
                            maskChar=" "
                            >
                            {() => (
                                <TextField
                                variant="outlined"
                                id="phone"
                                className="input-field"
                                name="phone"
                                label="Phone Number*"
                                type="text"
                                error={formErrors && formErrors.phone !== undefined}
                                helperText={formErrors.phone ? formErrors.phone : "required"}
                                fullWidth
                                />
                            )}
                            </InputMask>
                            {!isAddNewRecruiter && (<PhoneVerificationConsent isForgotPassword={false} useCenterTextAlignment={false}></PhoneVerificationConsent>)}
                            <div className="verified">
                              <FormControl component="fieldset">
                                <FormGroup>
                                  <FormControlLabel disabled={loading || isAddNewRecruiter} checked={fieldState.phoneVerified} name={'phoneVerified'} onChange={handlePhoneVerifiedChange} control={<GreenCheckbox />} label={'Phone Verified'} />
                                </FormGroup>
                              </FormControl>
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                            <div className="useRoundRobin">
                              <FormControl component="fieldset">
                                <FormGroup>
                                  <FormControlLabel disabled={loading || isAddNewRecruiter || !isAdmin} checked={fieldState.useRoundRobin} name={'useRoundRobin'} onChange={handleUseRoundRobinChange} control={<GreenCheckbox />} label={'Use Round Robin'} />
                                </FormGroup>
                              </FormControl>
                            </div>
                        </Grid>                        
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                          <FormLabel style={labelStyle} component="legend">Type</FormLabel>
                            <FormControl
                                error={formErrors && formErrors?.primaryCategory ? true : false}
                                fullWidth
                            >
                              <RadioGroup
                                className="primary-speciality-options"
                                row
                                aria-label="compact"
                                name="compact"
                                value={primaryCategory}
                                onChange={(e) => {
                                    setSpecialtyList([]);
                                    setCategory(e.target.value);
                                  }
                                }
                              >
                                <FormControlLabel
                                    value="Nurse"
                                    control={<Radio color="primary" />}
                                    label="Nurse"
                                />
                                <FormControlLabel
                                  value="Allied"
                                  control={<Radio color="primary" />}
                                  label="Allied"
                                />
                                <FormControlLabel
                                  value="LPN"
                                  control={<Radio color="primary" />}
                                  label="LPN"
                                />
                                <FormControlLabel
                                  value="CNA"
                                  control={<Radio color="primary" />}
                                  label="CNA"
                                />
                            </RadioGroup>
                          </FormControl>
                          </Grid>
                          {specialties && (
                            <Grid item xs={12} sm={12} md={12} lg={12}>
                              <FormControl
                                error={formErrors && formErrors?.specialtyList ? true : false}
                                fullWidth
                                disabled={!primaryCategory}
                                variant="outlined"
                                className="speciality-select"
                              >
                                <Select
                                  data-testid="workSpecialty"
                                  id="currentSpecialtyList"
                                  name="currentSpecialtyList"
                                  options={specialties?.filter((specialty) => {
                                      return specialty && specialty.label !== "EKG/Tele Tech" && specialty.category && specialty.category.includes(primaryCategory);
                                  }).map((specialty) => (
                                      specialty.label
                                  ))}
                                  isSearchable={false}
                                  isMulti
                                  onFocus={() => clearError("specialtyList")}
                                  value={currentSpecialtyList}
                                  getOptionValue={getOptionValue}
                                  getOptionLabel={getOptionLabel}
                                  onChange={changeSpecialty}
                                  placeholder="List of Specialties"
                                />
                                <FormHelperText>
                                  {formErrors && formErrors?.specialtyList}
                                </FormHelperText>
                            </FormControl>
                          </Grid>
                        )}
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                            <TextField
                                variant="outlined"
                                id="calendlyLink"
                                className="input-field"
                                name="calendlyLink"
                                label="Meeting Link*"
                                value={fieldState.calendlyLink}
                                error={formErrors && formErrors.calendlyLink !== undefined}
                                helperText={
                                    formErrors.calendlyLink ? formErrors.calendlyLink : "required"
                                }
                                onChange={(e) =>
                                    handleChange(e.target.id, e.target.value)
                                }
                                onFocus={(e) => clearError(e.target.id)}
                                fullWidth
                                inputProps={{maxLength: 50}}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                          <FormControl
                            variant="outlined" 
                            fullWidth
                            error={formErrors && formErrors.assignedTeam !== undefined}>
                                <InputLabel id="assigned-team-label">Assigned Team</InputLabel>
                                <MaterialSelect
                                    name="assignedTeam" 
                                    id="assigned-team"
                                    className="assigned-team"
                                    value={fieldState.assignedTeam}
                                    onFocus={(e) => clearError(e.target.id)}
                                    onChange={(e) =>
                                        setFieldState({ ...fieldState, assignedTeam: e.target.value })
                                    }                      
                                >
                                  <MenuItem value="Allied">Allied</MenuItem>
                                  <MenuItem value="Aya/Qualivis/Vaya">Aya/Qualivis/Vaya</MenuItem>
                                  <MenuItem value="CrossCountry">CrossCountry</MenuItem>
                                  <MenuItem value="Direct">Direct</MenuItem>
                                  <MenuItem value="HealthTrust">HealthTrust</MenuItem>
                                  <MenuItem value="RightSourcing">RightSourcing</MenuItem>
                                  <MenuItem value="Post-Acute">Post-Acute</MenuItem>
                                </MaterialSelect>
                                <FormHelperText>
                                    {formErrors?.assignedTeam  || "required"}
                                </FormHelperText>
                          </FormControl>
                        </Grid>
                    </Grid>
                </Box>
                <Box width={1} display="flex" mb={3}>
                    <TextField
                        variant="outlined"
                        id="biography"
                        className="input-field"
                        name="biography"
                        label="biography"
                        type="text"
                        value={fieldState.biography}
                        onChange={(e) =>
                            setFieldState({ ...fieldState, biography: e.target.value })
                        }
                        fullWidth
                        multiline
                        rows={4}
                        maxrows={4}
                    />
                </Box>
              </Container>
            ) : null}
            </>
          )}
          {!isSaving && (
          <ModalFooter
            handleClose={cancelChanges}
            handleSave={() => handleSubmit()}
            disableCancel={isSaving}
            disableSave={isDisabled}
            closeButtonText="Cancel"
            saveButtonText={'Save'}
          />)}
        </div>
      </Modal>
      {openConfirm && (
        <ConfirmationModal
          open={openConfirm}
          confirm={closeModals}
          handleClose={() => setOpenConfirm(false)}
          message="Are you sure you want to close this page?"
          subMessage="All your changes will be lost"
          cancelButtonText="Cancel"
        />
      )}
    </div>
  );
};

export default ViewOrAddRecruiterModal;
