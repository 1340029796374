import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Form from "react-validation/build/form";
import DateFnsUtils from "@date-io/date-fns";
import PrimaryButton from "../../common/Buttons/PrimaryButton";
import { getLocations } from "../../../services/datalist";
import { setJobFilters, clearJobFilters } from "../../../actions/jobFilters";
import { shiftOptions, dateRanges, dates } from "./options";
import "./FilterJobs.scss";
import ReactGA from "react-ga";
import ReactPixel from 'react-facebook-pixel';
import MixPanel from "mixpanel-browser";
import CloseIcon from "@material-ui/icons/Close";
import {
  CircularProgress,
  TextField,
  FormControl,
  FormControlLabel,
  Checkbox
} from "@material-ui/core";
import Select from "react-select";
import { Autocomplete } from "@material-ui/lab";
import { useDebouncedSearch } from "../../../hooks/search";

import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file

// Accordion
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

// RadioGroup
import FormGroup from '@material-ui/core/FormGroup';
import { withStyles } from '@material-ui/core/styles';
import { grey } from '@material-ui/core/colors';

const dateFns = new DateFnsUtils();

const useLocationSearch = () =>
  useDebouncedSearch((text) => getLocations(text));

const FilterJobs = ({
  handleClose,
  specialtyOptions,
  handleSearch,
  currentSpecialtyList,
  currentLocations,
  updateSpecialties,
  updateRemovedSpecialty,
  updateLocations,
  updateRemovedLocation,
  updateDates,
  dateArray,
  shiftArray
}) => {
  const { inputText, setInputText, searchResults } = useLocationSearch();
  const { loading, result = [] } = searchResults;
  const locationOptions = result.map((location) => location.name);
  const [disabled, setDisabled] = useState(false);
  const { filters } = useSelector((state) => state.jobFilters || {});
  const dispatch = useDispatch();
  const [specialtyList, setSpecialtyList] = useState(currentSpecialtyList);
  const [currentLocationsList, setCurrentLocations] = useState(currentLocations);
  const [datesArr, setDatesArr] = useState(dateArray || [] );

  let currentShiftsArr = [...shiftArray]

  const { locations, shifts, range, isfeatured } =
    filters || {};

  const changeShift = (event) => {
    currentShiftsArr = shifts;

    if(currentShiftsArr == undefined && event.target.checked == true) {
      currentShiftsArr = [];
      currentShiftsArr.push(event.target.name);
    } else if(currentShiftsArr != undefined && event.target.checked == true) {
      currentShiftsArr.push(event.target.name);
    } else {
      currentShiftsArr = currentShiftsArr.filter(function(item) {
        return item != event.target.name;
      });
    }

    dispatch(setJobFilters({ shifts: currentShiftsArr }));
  };

  const handleDateChange = (event) => {
    var start = range?.startDateBegin;
    var end = range?.startDateEnd;
    let currentDates = [...datesArr];

    if(event.target.checked === true) {
      if(!currentDates.includes(event.target.name)) {
        currentDates.push(event.target.name);
      }
    } else {
      currentDates = currentDates.filter((item) => {
        let currentItem = event.target.name;
        return item != currentItem;
      });
    }

    if (!currentDates.length) {
      start = null;
      end = null;
    }

    if(currentDates.includes('0')) {
      start = dates.now;
    } else if((!currentDates.includes('0') && currentDates.includes('1') && !currentDates.includes('2')) || (!currentDates.includes('0') && currentDates.includes('1') && currentDates.includes('2'))) {
      start = dates.nextStart;
    } else if(!currentDates.includes('0') && !currentDates.includes('1') && currentDates.includes('2')) {
      start = dates.twoStart;
    }

    if(currentDates.includes('2')) {
      end = dates.twoEnd;
    } else if((!currentDates.includes('0') && currentDates.includes('1') && !currentDates.includes('2')) || currentDates.includes('0') && currentDates.includes('1') && !currentDates.includes('2')) {
      end = dates.nextEnd;
    } else if(currentDates.includes('0') && !currentDates.includes('1') && !currentDates.includes('2')) {
      end = dates.monthEnd;
    }

    dispatch(
      setJobFilters({
        range: {
          startDateBegin: start,
          startDateEnd: end
        }
      })
    );
    updateDates(event, false, false);
    setDatesArr(currentDates);
  }


  const featureChange = (event) => {
    dispatch(setJobFilters({ isfeatured: event.target.checked }));
  };

  const getOptionValue = (option) => option;
  const getOptionLabel = (option) => option;

  const changeSpecialty = (value) => {
    if (!specialtyList.includes(value[0])) {
      let currentList = [...specialtyList];
      setSpecialtyList([...currentList, value[0]]);
      updateSpecialties(value);
    }
  };

  const removeSpecialty = (value) => {
    if (specialtyList.includes(value)) {
      let currentList = [...specialtyList];
      const list = currentList.filter((item) => { return item !== value});
      setSpecialtyList(list);
      updateRemovedSpecialty(value);
    }
  };

  useEffect(() => {
    if(!currentLocationsList !== undefined && locations !== undefined) {
      setCurrentLocations(locations);
  }
}, [locations]);

  const changeLocations = (value) => {
    if (!currentLocationsList.includes(value[0])) {
      let currentList = [...currentLocationsList];
      setCurrentLocations([...currentList, value[0]]);
      updateLocations(value);
      setInputText("");
    }
  };

  const removeLocation = (value) => {
    if (currentLocationsList.includes(value)) {
      let currentList = [...currentLocationsList];
      const list = currentList.filter((item) => { return item !== value});
      setCurrentLocations(list);
      updateRemovedLocation(value);
    }
  };

  const handleSave = () => {
    ReactGA.event({ category: "JobSearch", action: "Apply Filters" });
    MixPanel.track("JobSearch", { action: "Apply Filters" });
    setDisabled(true);
    handleSearch(true);
  };

  const onCancel = () => {


    handleClose();
  }

  const GreenCheckbox = withStyles({
    root: {
      color: grey[400],
      '&$checked': {
        color: '#6dd19d',
      },
    },
    checked: {},
  })((props) => <Checkbox color="default" {...props} />);


  return (
    <div>
      <Form className="filter-container" data-testid="form">
        <div className="filter-category">
          <FormControl fullWidth variant="outlined">
            <Select
              className="specialty-select"
              data-testid="JobSearchSpecialty"
              id="currentSpecialtyList"
              name="currentSpecialtyList"
              options={specialtyOptions?.filter((specialty) => {
                return specialty && specialty.label !== "EKG/Tele Tech";
              }).map((specialty) => (
                  specialty.label
              ))}
              isMulti
              value="Select specialties"
              getOptionValue={getOptionValue}
              getOptionLabel={getOptionLabel}
              onChange={changeSpecialty}
              placeholder="Select specialties"
              disabled={loading}
            />
          </FormControl>

          <div className="condensed-pill-section">
            {specialtyList?.map((specialty, index) => (
                <div key={`specialty-${index}`} onClick={()=>{removeSpecialty(specialty, false)}} className="text-sm status pill-blue text-hover">
                  {specialty}<div className="margin-l-10 close" ><CloseIcon className="icon-button text-hover" /></div>
                </div>
              ))
            }
          </div>
        </div>
        <div className="filter-category">
            {locationOptions && (
                <Autocomplete
                  multiple
                  id="locations-autocomplete"
                  fullWidth
                  value={[]}
                  options={locationOptions || []}
                  onChange={(e, val) => changeLocations(val)}
                  inputValue={inputText || ""}
                  getOptionLabel={(option) => option || ""}
                  blurOnSelect
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="City or City, State"
                      variant="outlined"
                      onChange={(e) => setInputText(e.target.value)}
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <React.Fragment>
                            {loading ? (
                              <CircularProgress color="inherit" size={20} />
                            ) : null}
                            {params.InputProps.endAdornment}
                          </React.Fragment>
                        )
                      }}
                    />
                  )}
                />
            )}
            <div className="condensed-pill-section">
              {currentLocationsList?.map((location, index) => (
                <div key={`location-${index}`} onClick={()=>{removeLocation(location, false)}} className="text-sm status pill-blue text-hover">
                  {location}<div className="margin-l-10 close" ><CloseIcon className="icon-button text-hover" /></div>
                </div>
              ))}
            </div>
        </div>
        <FormControl component="fieldset">
          <FormGroup>
            <FormControlLabel checked={isfeatured} onChange={featureChange} name={'featured'} control={<GreenCheckbox />} label={'Featured jobs'} />
          </FormGroup>
        </FormControl>
        <Accordion defaultExpanded={true}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            Shift
          </AccordionSummary>
          <AccordionDetails>
            <FormControl component="fieldset">
              <FormGroup>
                {shiftOptions?.map((option, index) => (
                  <FormControlLabel key={index} checked={shifts != undefined && shifts.includes(option.value)} onChange={changeShift} name={`${option.value}`} control={<GreenCheckbox />} label={option.label} />
                ))}
              </FormGroup>
            </FormControl>
          </AccordionDetails>
        </Accordion>

        <Accordion defaultExpanded={true}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            Start date
          </AccordionSummary>
          <AccordionDetails>
            <FormControl component="fieldset">
              <FormGroup>
                {dateRanges?.map((option, index) => (
                  <FormControlLabel key={index} checked={datesArr.includes(option.value)} onChange={handleDateChange} name={`${option.value}`} control={<GreenCheckbox />} label={option.label} />
                ))}
              </FormGroup>
            </FormControl>
          </AccordionDetails>
        </Accordion>
      </Form>
      <footer className="filter-footer">
        <PrimaryButton
          handleClick={() => { onCancel() }}
          text="Cancel"
          type="button"
          buttonClass="transparent-button font-medium text-xs"
        />
        <button
          className="font-medium text-xs uppercase color-button mr-2"
          onClick={handleSave}
          disabled={disabled}
        >
          {disabled && <CircularProgress size={20} />}
          <span>Show Results</span>
        </button>
      </footer>
    </div>
  );
};

export default FilterJobs;
