import { CircularProgress } from "@material-ui/core";
import React from "react";

import PrimaryButton from "../Buttons/PrimaryButton";

import "./ModalFooter.scss";

const ModalFooter = ({
  closeButtonText,
  saveButtonText,
  handleSave,
  handleClose,
  type,
  disableCancel,
  disableSave,
  isSaving
}) => {
  return (
    <footer className="footer-profile-modal">
      <PrimaryButton
        text={closeButtonText}
        type="button"
        buttonClass="transparent-button text-sm font-medium"
        handleClick={handleClose}
        disabled={disableCancel}
      />

      {saveButtonText && (
        <>
          <button
            className="font-medium color-button text-sm uppercase"
            onClick={handleSave}
            disabled={disableSave || isSaving}
            type={type || "button"}
          >
            {isSaving ? <CircularProgress size={20} /> : <>{saveButtonText}</>}
          </button>
        </>
      )}
    </footer>
  );
};

export default ModalFooter;
