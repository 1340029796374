import React from "react";
import { ReactComponent as Logo } from "./daynight.svg";
import SvgIcon from "@material-ui/core/SvgIcon";

import "../Icons.scss";

const DayNightIcon = (props) => {
  const { iconClass } = props;
  return (
    <SvgIcon className={iconClass} component={Logo} viewBox="0 0 100 100" />
  );
};

export default DayNightIcon;