import React from "react";
import EducationDetails from "../Education/EducationDetails";
import JobPreferencesDetails from "../JobPreferences/JobPreferencesDetails";
import LicenseDetails from "../Licenses/LicenseDetails";
import PersonalInformationDetails from "../PersonalInformation/PersonalInformationDetails";
import ProfessionalSummaryDetails from "../ProfessionalSummary/ProfessionalSummaryDetails";
import CertificationDetails from "../Certifications/CertificationDetails";
import MedicalCertificationDetails from "../Certifications/MedicalCertificationDetails";
import ReferenceDetails from "../References/ReferenceDetails";
import SkillsChecklistDetails from "../SkillsChecklist/SkillsChecklistDetails";
import SpecialtyDetails from "../Specialties/SpecialtyDetails";
import WorkExperienceDetails from "../WorkExperience/WorkExperienceDetails";
import ResumeUploadDetails from "../ResumeUpload/ResumeUploadDetails";

const CandidateDetails = ({ detailType, handleClick, handleOpenModal }) => {
  switch (detailType) {
    case "personalInformation":
      return <PersonalInformationDetails handleClick={handleClick} />;
    case "professionalSummary":
      return <ProfessionalSummaryDetails handleClick={handleClick} handleOpenModal={handleOpenModal} />;
    case "education":
      return <EducationDetails handleClick={handleClick} />;
    case "work":
      return <WorkExperienceDetails handleClick={handleClick} />;
    case "specialties":
      return <SpecialtyDetails handleClick={handleClick} />;
    case "license":
      return <LicenseDetails handleClick={handleClick} />;
    case "certifications":
      return <CertificationDetails handleClick={handleClick} />;
      case "medicalCertifications":
        return <MedicalCertificationDetails handleClick={handleClick} />;
    case "jobPreferences":
      return <JobPreferencesDetails handleClick={handleClick} />;
    case "skills":
      return <SkillsChecklistDetails handleClick={handleClick} />;
    case "references":
      return <ReferenceDetails handleClick={handleClick} />;
    case "resumeUpload":
        return <ResumeUploadDetails handleClick={handleClick} />;
    default:
      return null;
  }
};

export default CandidateDetails;
