import React, { useState } from "react";

import {
  CircularProgress,
  InputAdornment,
  Grid,
  TextField,
  Box,
  Container,
  Button
} from "@material-ui/core";
import {
  MailOutline,
  Lock,
  KeyboardBackspace,
  AccountBox,
} from "@material-ui/icons";
import { VisibilityIcon, GoogleIcon, FacebookIcon } from "../../../common/Icons";
import { Auth } from 'aws-amplify';
import LinkButton from "../../../common/Buttons/LinkButton";
import PrimaryButton from "../../../common/Buttons/PrimaryButton";
import "./Account.scss";

const Account = (props) => {
  const [showPassword, setShowPassword] = useState({
    isShown: false,
    text: "show",
  });
  const { email, password, passwordConfirm, referral } = props.data;
  const { formErrors, handleChange, clearError, nextStep, validateEmail } =
    props;
  // const usernameHelperText = "Username must be between 6 and 128 characters";
  const passwordHelperText =
    "Password must at least 8 characters and include at least 1 uppercase letter, 1 lowercase letter, 1 number, and 1 special character";

  const handleClickShowPassword = () => {
    setShowPassword({
      isShown: !showPassword.isShown,
      text: showPassword.isShown ? "show" : "hide",
    });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <Container maxWidth="sm">
      <Box width={1} display="flex" alignItems="center" flexDirection="column" alignItems="flex-start" className="signup-basic">
        <h1 data-testid="account-header" className="mt-4 text-lg leading-6 font-medium text-gray-900">Sign up to find your next travel adventure</h1>
        <p className="mt-2 text-sm text-gray-500">You'll be able to compare the highest paying jobs for your specialty, be instantly matched with your perfect recruiter and manage the entire process with your Connect Account</p>
      </Box>

      <Box width={1} display="flex" mb={3}>
        <Grid
          container
          spacing={1}
          alignItems="flex-end"
          className="input-field"
        >
          <Grid item xs={12}>
            <TextField
              id="email"
              className="input-field"
              name="email"
              label="Email*"
              value={email}
              error={formErrors && formErrors.email !== undefined}
              helperText={formErrors.email ? formErrors.email : ""}
              onChange={(e) =>
                handleChange("account", e.target.id, e.target.value)
              }
              onFocus={(e) => clearError(e.target.id)}
              fullWidth
              onBlur={(e) => validateEmail(e.target.value)}
              inputProps={{maxLength: 100}}
            />
          </Grid>
        </Grid>
      </Box>
      <Box width={1} display="flex" mb={3}>
        <Grid container spacing={1} alignItems="flex-start" >
          <Grid item xs={12}>
            <TextField
              data-testid="password"
              id="password"
              className="input-field"
              name="password"
              label="Password*"
              type={showPassword.isShown ? "text" : "password"}
              value={password}
              error={formErrors && formErrors.password !== undefined}
              helperText={
                formErrors.password ? formErrors.password : ""
              }
              onChange={(e) =>
                handleChange("account", e.target.id, e.target.value)
              }
              onFocus={(e) => clearError(e.target.id)}
              fullWidth
              InputProps={{
                endAdornment: (
                  <InputAdornment position="start">
                    <div
                      className="show-password"
                      aria-label="toggle visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                    >
                      <VisibilityIcon
                        active={showPassword.isShown}
                      ></VisibilityIcon>
                    </div>
                  </InputAdornment>
                )
              }}
            />
          </Grid>
        </Grid>
      </Box>
      <Box width={1} display="flex" mb={3}>
        <Grid container spacing={1} alignItems="flex-start" >
          <Grid item xs={12}>
            <TextField
              data-testid="passwordConfirm"
              id="passwordConfirm"
              className="input-field"
              name="passwordConfirm"
              label="Confirm Password*"
              type={showPassword.isShown ? "text" : "password"}
              value={passwordConfirm}
              onChange={(e) =>
                handleChange("account", e.target.id, e.target.value)
              }
              onFocus={(e) => clearError(e.target.id)}
              fullWidth
              InputProps={{
                endAdornment: (
                  <InputAdornment position="start">
                    <div
                      className="show-password"
                      aria-label="toggle visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                    >
                      <VisibilityIcon
                        active={showPassword.isShown}
                      ></VisibilityIcon>
                    </div>
                  </InputAdornment>
                )
              }}
            />
          </Grid>
        </Grid>
      </Box>
      <Box width={1} display="flex" mb={3}>
        <Grid
          container
          spacing={1}
          alignItems="flex-end"
          className="input-field"
        >
          <Grid item xs={12}>
            <TextField
              id="referral"
              className="input-field"
              name="referral"
              label="Referral Code"
              type="number"
              value={referral}
              onChange={(e) =>
                handleChange("account", e.target.id, e.target.value)
              }
              fullWidth
              inputProps={{maxLength: 10}}
            />
          </Grid>
        </Grid>
      </Box>
      <Box width={1} display="flex" justifyContent="flex-end">
        <PrimaryButton
          className="color-button"
          type="button"
          text="Continue"
          handleClick={nextStep}
        />
      </Box>

      <p className="txt-center padding-t-5p text-sm text-gray-500">
        Already have an account?
        <span>
          <LinkButton text="Log in here" url="/login" textButton />
        </span>
      </p>
    </Container>
  );
};

export default Account;
