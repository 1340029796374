import React, { useEffect, useState } from "react";
import { Card, Grid } from "@material-ui/core";
import Item from "@material-ui/core/Grid";
import { getCandidateDetails } from "../../../services/candidateData";
import { CopyIcon } from "../../common/Icons";
import { notify } from "../../../toasts/toasts";
import "./ReferCode.scss";

const ReferCode = () => {
    const [ candidateId, setCandidateId] = useState();

    useEffect(() => {
        let mounted = true;
        const loadData = async () => {
            const userDetails = await getCandidateDetails();

            if(userDetails) {
                const { basicInfo } = userDetails;
                setCandidateId(basicInfo.id);
            }
        };
        if (mounted) {
          loadData();
        }

        return () => {
          mounted = false;
        };
    }, []);

    const copyToClipboard = (id) => {
        navigator.clipboard.writeText(`${window.location.origin}/signup?Referral=${id}`);
        notify("Refer link copied to clipboard.", "success");
    };

    return (
        <>
            {candidateId ? (
                <Grid container spacing={3} className="refer-a-friend">
                    <Item item xs={12}>
                        <Card align="center" className="refer-a-friend-card">
                            <h6 className="text-lg leading-6 font-medium text-gray-900">Earn $500</h6>
                            <p className="text-md">For each friend you invite</p>
                            <div className="input-wrapper">
                                <input value={candidateId} disabled />
                                <div className="no-fill" onClick={() => copyToClipboard(candidateId)}>
                                    <CopyIcon />
                                </div>
                            </div>

                            <button className="color-button next-step text-sm font-medium" onClick={() => copyToClipboard(candidateId)}>Copy Link</button>
                            <p className="caption">You earn $500 for each of your friends who joins and works with us. There’s no limit to how many friends you invite, and it’s not a requirement for you to have worked with us before.</p>
                        </Card>
                    </Item>
                </Grid>
            ) : (
                null
            )}
        </>
    );
}

export default ReferCode;
