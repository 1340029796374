import React, { useState } from "react";

import {
CircularProgress,
  Grid,
  Box,
  Container,
  TextField,
} from "@material-ui/core";
import { Auth } from "aws-amplify";
import PrimaryButton from "../../../common/Buttons/PrimaryButton";
import authService from "../../../../services/auth.service";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import ErrorOutline from "@material-ui/icons/ErrorOutline";

import "./RecruiterSignup.scss";

const RecruiterVerifyAccount = (props) => {
    const {
        username,
        password,
        phone,
        email,
    } = props.data;

    const { handleChange, handleFinalSubmit, loading} = props;
    const [verifyingPhoneFailure, setVerifyingPhoneFailure] = useState(false);
    const [confirmingPhone, setConfirmingPhone] = useState(false);
    const [confirmingPhoneSuccess, setConfirmingPhoneSuccess] = useState(false);
    const [confirmingPhoneFailure, setConfirmingPhoneFailure] = useState(false);
    const [completedPhoneVerification, setCompletedPhoneVerification] = useState(false);
    const [verifyingEmailAddressFailure, setVerifyingEmailAddressFailure] = useState(false);
    const [confirmingEmailAddress, setConfirmingEmailAddress] = useState(false);
    const [confirmingEmailAddressSuccess, setConfirmingEmailAddressSuccess] = useState(false);
    const [confirmingEmailAddressFailure, setConfirmingEmailAddressFailure] = useState(false);
    const [completedEmailAddressVerification, setCompletedEmailAddressVerification] = useState(false);
    const [openEmail, setOpenEmail] = useState(false);
    const [openPhone, setOpenPhone] = useState(false);

    /*
        Confrim email and phone functions
    */

    const verifyPhone = async () => {
        sendPhoneCode();
        setOpenPhone(true);
    };

    const sendPhoneCode = async () => {
        let user = await Auth.signIn(username, password);
        if(user) {
            let phoneVerified = await authService.verifyPhone();
            if (phoneVerified)
            {
                setVerifyingPhoneFailure(false);
            } else {
                setVerifyingPhoneFailure(true);
            }
        }
    };

    const confirmPhone = async (phoneCode) => {
        setConfirmingPhone(true);
        
        let phoneConfirmed = await authService.confirmPhone(phoneCode);
        
        if (phoneConfirmed) {
            setConfirmingPhoneSuccess(true);
            setConfirmingPhoneFailure(false);
            setConfirmingPhone(false);
            setCompletedPhoneVerification(true);
            localStorage.setItem("user", JSON.stringify({
                ...props.userData,
                phoneVerified: true,
            }));
            handleChange("verifyAccount", "phoneVerified", true);
        
        } else {
            setConfirmingPhoneSuccess(false);
            setConfirmingPhoneFailure(true);
            setConfirmingPhone(false);

            handleChange("verifyAccount", "phoneVerified", false);
        }
    };

    const verifyEmailAddress = async () => {
            sendEmailCode();
            setOpenEmail(true);
    };

    const confirmEmailAddress = async (code) => {
        setConfirmingEmailAddress(true);
        
        let confirmed = await authService.confirmEmailAddress(code);
        if (confirmed) {
            await authService.addToRecruiterGroup();
            setConfirmingEmailAddressSuccess(true);
            setConfirmingEmailAddressFailure(false);
            setConfirmingEmailAddress(false);
            setCompletedEmailAddressVerification(true);
            localStorage.setItem("user", JSON.stringify({
                ...props.userData,
                emailVerified: true,
            }));
            handleChange("verifyAccount", "emailVerified", true);
    
        } else {
            setConfirmingEmailAddressSuccess(false);
            setConfirmingEmailAddressFailure(true);
            setConfirmingEmailAddress(false);

            handleChange("verifyAccount", "emailVerified", false);
        }
      };

    const sendEmailCode = async () => {
        let user = await Auth.signIn(username, password);
        if(user) {
            let emailVerified = await authService.verifyEmailAddress();

            if (emailVerified)
            {
                setVerifyingEmailAddressFailure(false);
            } else {
                setVerifyingEmailAddressFailure(true);
            }
        }
    };
  
    return (
      <Container maxWidth="sm">
        <Box width={1} display="block" mb={3}>
            <Grid
                container
                spacing={1}
                className="input-field"
            >
                {email && (
                    <div className="detail detail-contact email">
                        <div className="email-wrapper">
                            <p className="email">Please verify your email address: {email}</p>
                            {
                                !completedEmailAddressVerification && !openEmail
                                ? <PrimaryButton className="verify-contact" type="button" text="Verify Email" handleClick={verifyEmailAddress} disabled={completedEmailAddressVerification}></PrimaryButton>
                                : null
                            }
                            <div className="code-input">                  
                                {openEmail && !completedEmailAddressVerification && (
                                    <>
                                        <h5>Check your email</h5>
                                        <Box width={1} mt={2}>
                                            <Grid
                                                container
                                                spacing={1}
                                                alignItems="flex-end"
                                                justify="center"
                                                className="input-field"
                                            >
                                                <Grid className="input-field-container email" item xs={10} sm={12} md={12} lg={12}>
                                                    <p>We've sent a 6-digit verification code to your email, {email}.</p>
                                                    <TextField
                                                        id="email-address-code"
                                                        label="Email Code"
                                                        className="input-field"
                                                        name="email-address-code"
                                                        type="number"
                                                        onChange={(e) => {
                                                                if (e.target.value.length === 6) {
                                                                    confirmEmailAddress(e.target.value);
                                                                }
                                                            }
                                                        }
                                                        disabled={confirmingEmailAddress}
                                                        inputProps={{style: {fontSize: 14, width: '100%'}, maxLength: 6}}
                                                        InputLabelProps={{style: {fontSize: 14}}}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Box>              
                                        <Box
                                            display="flex"
                                            flexDirection="column"
                                            alignItems="center"
                                            width="100% !important"
                                            className="resend-code"
                                        >
                                            <p className="sign-up">
                                                Didn't receive a code?
                                                <div className="resend" onClick={sendEmailCode}>
                                                    <span className="color-button">Resend</span>
                                                    {verifyingEmailAddressFailure && (
                                                        <span className="verifying-failure">
                                                            <ErrorOutline fontSize="small" edge="start" aria-label="menu"></ErrorOutline>
                                                        </span> 
                                                    )}  
                                                </div>
                                            </p>
                                            {confirmingEmailAddress && (
                                                <CircularProgress size={20} /> 
                                            )}
                                            {confirmingEmailAddressFailure && (
                                                <div className="confirm-failure">
                                                    <ErrorOutline fontSize="small" edge="start" aria-label="menu"></ErrorOutline>
                                                    <p className="msg-text">Verification Failed</p>
                                                </div> 
                                            )} 
                                        </Box>      
                                    </>
                                )}
                                {confirmingEmailAddressSuccess && (
                                    <div className="confirm-success">
                                        <CheckCircleOutlineIcon fontSize="small" edge="start" aria-label="menu"></CheckCircleOutlineIcon>
                                        <p className="msg-text">Email Verified</p>
                                    </div> 
                                )}
                            </div>
                        </div>
                    </div>
                )}
                {phone && (
                    <div className="detail detail-contact">
                        <div className="phone-wrapper">
                            <p>Please verify your phone number: {phone}</p>
                            {  !completedPhoneVerification && !openPhone
                                ? <PrimaryButton className="verify-contact" type="button" text="Verify Phone" handleClick={verifyPhone} disabled={completedPhoneVerification}></PrimaryButton>
                                : null
                            }
                            <div className="code-input">                  
                                {openPhone && !completedPhoneVerification && (
                                    <>
                                        <h5>Check your phone</h5>
                                        <Box width={1} mt={2}>
                                            <Grid
                                                container
                                                spacing={1}
                                                alignItems="flex-end"
                                                justify="center"
                                                className="input-field"
                                            >
                                                <Grid className="input-field-container phone" item xs={10} sm={12} md={12} lg={12}>
                                                    <p>We've sent a 6-digit verification code to your phone, {phone}.</p>
                                                    <TextField
                                                        id="phone-code"
                                                        label="Phone Code"
                                                        className="input-field"
                                                        name="phone"
                                                        type="number"
                                                        onChange={(e) => {
                                                        if (e.target.value.length === 6) {
                                                                    confirmPhone(e.target.value);
                                                                }
                                                            }
                                                        }
                                                        disabled={confirmingPhone}
                                                        inputProps={{style: {fontSize: 14, width: '100%'}, maxLength: 6}}
                                                        InputLabelProps={{style: {fontSize: 14}}}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Box>              
                                        <Box
                                            display="flex"
                                            flexDirection="column"
                                            alignItems="center"
                                            width="100% !important"
                                            className="resend-code"
                                        >
                                            <p className="sign-up">
                                                Didn't receive a code?
                                                <div className="resend" onClick={sendPhoneCode}>
                                                    <span className="color-button">Resend</span>
                                                    {verifyingPhoneFailure && (
                                                        <span className="verifying-failure">
                                                            <ErrorOutline fontSize="small" edge="start" aria-label="menu"></ErrorOutline>
                                                        </span> 
                                                    )}  
                                                </div>
                                            </p>
                                            {confirmingPhone && (
                                                <CircularProgress size={20} /> 
                                            )}
                                            {confirmingPhoneFailure && (
                                                <div className="confirm-failure">
                                                    <ErrorOutline fontSize="small" edge="start" aria-label="menu"></ErrorOutline>
                                                    <p className="msg-text">Verification Failed</p>
                                                </div> 
                                            )} 
                                        </Box>      
                                    </>
                                )}
                                {confirmingPhoneSuccess && (
                                    <div className="confirm-success">
                                        <CheckCircleOutlineIcon fontSize="small" edge="start" aria-label="menu"></CheckCircleOutlineIcon>
                                        <p className="msg-text">Phone Verified</p>
                                    </div> 
                                )}
                            </div>
                        </div>
                    </div>
                )}
            </Grid>
        </Box>
        <Box width={1} display="flex" justifyContent="flex-end">
          <Grid container spacing={1} alignItems="flex-end">
            <Grid item xs={4}>
                <button disabled={!completedEmailAddressVerification && !completedPhoneVerification} type="button" onClick={(e) => { handleFinalSubmit(e) }} className="color-button next-step">
                    {!loading ? "Submit" : <CircularProgress size={24} className=""/>}
                </button>
            </Grid>
          </Grid>
        </Box>
      </Container>
    );
  };
  
  export default RecruiterVerifyAccount;
