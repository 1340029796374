import React from "react";
import { ReactComponent as Logo } from "./connect-icon.svg";

import "../Icons.scss";

const ConnectIcon = ({ iconClass }) => (
  <Logo className={iconClass} />
);

export default ConnectIcon;
