import React from "react";
import { ReactComponent as HomeLogo } from "./home2.svg";
import { ReactComponent as HomeFillLogo } from "./home-fill.svg";
import SvgIcon from "@material-ui/core/SvgIcon";

import "../Icons.scss";

const HomeIcon = (props, children) => {
  const { iconClass } = props;
  return (
    <SvgIcon
      className={iconClass}
      component={HomeLogo}
    />
  );
};

export default HomeIcon;
