import React from "react";
import { ReactComponent as SearchLogo } from "./monitor.svg";
import SvgIcon from "@material-ui/core/SvgIcon";

import "../Icons.scss";

const MenuSearchIcon = (props) => {
  const { iconClass, active } = props;
  return (
    <SvgIcon
      className={iconClass}
      component={SearchLogo}
    />
  );
};

export default MenuSearchIcon;