const devAPI_URL = "https://localhost:44375/";
var stagingAPI_URL = "https://api.staging.connect.advantismed.com/";
var productionAPI_URL = "https://api.connect.advantismed.com/";
var API_URL;

if (window.location.hostname.includes("localhost")) {
  API_URL = devAPI_URL;
} else if (window.location.hostname.includes("staging")) {
  API_URL = stagingAPI_URL;
} else {
  API_URL = productionAPI_URL;
}

export default API_URL;
