import axios from "axios";
import API_URL from "../helpers/globals.js";
import { globalState } from "../components/sharedstate.js";
import Amplify, { Auth, Hub } from "aws-amplify";
import ReactGA from 'react-ga';
import MixPanel from "mixpanel-browser";
import { LOGIN_SUCCESS, LOGIN_FAIL, LOGOUT, SET_MESSAGE, SET_USER } from "../actions/types";
import { history } from "../helpers/history";
import UserService from "./user.service";
import AuthService from "./auth.service";
import { faTrumpet } from "@fortawesome/pro-light-svg-icons";
import LogRocket from 'logrocket';

//const API_URL = "https://localhost:5001/";

const configureAuth = () => {
  Amplify.configure({
    Auth: {
      // REQUIRED only for Federated Authentication - Amazon Cognito Identity Pool ID
      //identityPoolId: process.env.REACT_APP_AWS_IDENTITY_POOL,
      region: process.env.REACT_APP_AWS_REGION,

      // OPTIONAL - Amazon Cognito Federated Identity Pool Region
      // Required only if it's different from Amazon Cognito Region
      // identityPoolRegion: 'XX-XXXX-X',
      userPoolId: process.env.REACT_APP_AWS_USER_POOL_ID,
      userPoolWebClientId: process.env.REACT_APP_AWS_USER_POOL_WEB_CLIENT_ID,
      oauth: {
        domain: process.env.REACT_APP_AWS_AUTH_DOMAIN,
        scope: [
          "phone",
          "email",
          "profile",
          "openid",
          "aws.cognito.signin.user.admin"
        ],
        redirectSignIn: window.location.origin + "/auth",
        redirectSignOut: window.location.origin + "/login",
        responseType: "code" // or 'token', note that REFRESH token will only be generated when the responseType is code
      }
    }
  });
};

const registerCandidate = async (accountDetails) => {
  try {
    const {
      username,
      email,
      firstname,
      lastname,
      phone,
      contactMethod,
      city,
      state,
      zip,
      password,
      specialtyList,
      yearsExperience,
      topLocations,
      firstTimeBool,
      nextAdventure,
      interests,
      isSocialSignup,
      refCode,
      licenseStateList,
      isCompactLicense
    } = accountDetails;

    let phone_number = `+1${phone.replaceAll("-", "")}`;
    let result = null;
    let user = JSON.parse(localStorage.getItem("user"));

    if (!isSocialSignup) {
      result = await Auth.signUp({
        username,
        password,
        attributes: {
          email,
          phone_number,
          given_name: firstname,
          family_name: lastname,
          name: firstname && lastname ? `${firstname} ${lastname}` : ""
        }
      });
    }

    sessionStorage.setItem("setCognitoPhone", phone_number);

    const body = {
      CognitoId: result && result.userSub ? result.userSub : user.cognitoId,
      Email: email,
      FirstName: firstname,
      LastName: lastname,
      Phone: phone,
      PreferredContactMethod: contactMethod,
      City: city,
      State: state,
      ZipCode: zip,
      SpecialtyList: specialtyList,
      YearsExperience: parseInt(yearsExperience),
      TopPreferredLocations: topLocations,
      ExperienceTravelling: firstTimeBool,
      Interests: nextAdventure,
      OptionalInterests: interests,
      ReferralCode: refCode,
      RegistrationId: localStorage.getItem('candidateRegistrationId'),
      licenseStateList: licenseStateList,
      LicenseIsCompact: isCompactLicense
    };

    let response = await axios.post(API_URL + "user/registercandidate" + (getShouldAutoVerifyPhone() ? "?autoVerifyPhone=true" : "/"), body);
    localStorage.removeItem('candidateRegistrationId');
    localStorage.setItem("user", JSON.stringify(response.data));
    localStorage.setItem("userrecruiter", response.data.recruiter ? JSON.stringify(response.data.recruiter) : null);

    ReactGA.event({
      category: "User",
      action: "Registered an Account"
    });
    MixPanel.track("User", { action: "Registered an Account" });


    return response;
  } catch (error) {
    console.log("Error signing up:", error);
    if (localStorage.getItem("isSocialSignup")) localStorage.removeItem("isSocialSignup");
    throw error;
  }
};

const login = async (username, password) => {
  try {
    var user = await Auth.signIn(username, password);
    ReactGA.event({
      category: "User",
      action: "Logged In"
    });
    MixPanel.track("User", { action: "Logged In" });


    if (process.env.REACT_APP_STAGE === "production" && user?.attributes?.sub) {
      LogRocket.identify(user?.attributes?.sub, {
        name: user?.attributes?.name ? user?.attributes?.name : "Unknown",
        email: user?.attributes?.email ? user?.attributes?.email : "Unknown"
     });
    }

    return user;
  } catch (error) {
    console.log("Error signing in", error);
    return { error };
  }
};

const logout = async () => {
  try {
    localStorage.removeItem("user");
    localStorage.removeItem("orig");
    localStorage.removeItem("v2state");
    sessionStorage.removeItem("BecomeUser");
    globalState.emu = undefined;
    globalState.cand = undefined;
    await Auth.signOut();
  } catch (error) {
    console.log("error signing out: ", error);
  }
};

const listenToCognitoAuthEvents = async (dispatch) => {
  Hub.listen('auth', async ({ payload: { event, data } }) => {
        switch (event) {
          case "signIn":
            await postLoginActions(dispatch);
            break;
          case "signOut":
            dispatch({
              type: LOGOUT
            });
            ReactGA.event({ category: "User", action: "Logged Out" });
            MixPanel.track("User", { action: "Logged Out" });
            break;
          case "signIn_failure":
            console.log("Sign in failure", data);
            dispatch({
              type: LOGIN_FAIL,
              payload: {
                orig: data.username
              }
            });
            var errorMessage = data.message.includes("Email+is+already+in+use")
              ? "Email already in use"
              : "Login Failed";
            dispatch({
              type: SET_MESSAGE,
              payload: errorMessage
            });
            break;
          default:
          //do nothing
        }
      });

      const user = await Auth.currentUserInfo();
      if (user && Object.keys(user).length > 0) {
        var isExternalProvider = false;
        if (user.attributes && user.attributes.identities) {
          var identities = JSON.parse(user.attributes.identities);
          if (identities) {
            isExternalProvider = identities.some(x => x.providerName !== null);
          }
        }

        if (!isExternalProvider) {
          await postLoginActions(dispatch);
        }
      }
}

const postLoginActions = async (dispatch) => {
  let user = await retrieveCurrentUser(dispatch);
  let isSocialSignup = false;

  if (user && !user.id) {
    isSocialSignup = true;
  }

  await updateUserSpecialties(user?.username);
  if (user && !isSocialSignup) {
    dispatch({
      type: LOGIN_SUCCESS,
      payload: { user, orig: user?.username }
    });
  } else if (isSocialSignup) {
    localStorage.setItem("isSocialSignup", JSON.stringify({isSocialSignup: true}));
    history.push("/signup");
  }
};

const retrieveCurrentUser = async (dispatch) => {
  try {
    const user = await UserService.getUser();
    localStorage.setItem("user", JSON.stringify(user));
    return user;
  } catch (error) {
    if (error.response?.status === 400 && error.response.data === "User does not exist") {
      return null;
    }
    dispatch({
      type: SET_MESSAGE,
      payload: "An error occurred logging into your account"
    });
  }
};

const createUser = async (dispatch) => {
  try {
    const user = await Auth.currentAuthenticatedUser();
    await UserService.createUser(user);
    await UserService.getUser();
    localStorage.setItem("user", JSON.stringify(user));
    return user;
  } catch (error) {
    console.log("Failed to create user -> " + error);
    logout(dispatch);
    dispatch({
      type: SET_MESSAGE,
      payload: "An error occurred logging into your account"
    });
  }
};

const updateUserSpecialties = async (username) => {
  const specialties = sessionStorage.getItem("specialties");
  if (specialties) {
  }
}

const updateUserPhoneInCognito = async () => {
  const setCognitoPhone = sessionStorage.getItem("setCognitoPhone");
  if (setCognitoPhone) {
    try {
      await updateUserAttribute("phone_number", setCognitoPhone);
      sessionStorage.removeItem("setCognitoPhone");
    } catch (error) {
      console.log("Failed to update users phone number in cognito -> " + error);
    }
  }
}

const updateUserEmailInCognito = async (email) => {
  try {
    await updateUserAttribute("email", email);
  } catch (error) {
    console.log("Failed to update users email address in cognito -> " + error);
  }
}

const getAccessToken = async () => {
  try {
    const currentSession = await Auth.currentSession();
    return currentSession.getIdToken().getJwtToken();
  } catch (error)
  {
    return "";
  }
}

const updateUserAttribute = async (key, value) => {
    const currentUser = await Auth.currentAuthenticatedUser();
    const userAttribute = {};
    userAttribute[key] = value;
    await Auth.updateUserAttributes(currentUser, userAttribute);
}

const verifyPhone = async () => {
  const currentUser = await Auth.currentAuthenticatedUser();
  try {
    let response = await Auth.verifyUserAttribute(currentUser, "phone_number");
    return true;
  } catch(ex) {
    return false;
  }
}

const confirmPhone = async (code) => {
  const currentUser = await Auth.currentAuthenticatedUser();
  try {
    let response = await Auth.verifyUserAttributeSubmit(currentUser, "phone_number", code);
    return true;
  } catch(ex) {
    return false;
  }
}

const addToRecruiterGroup = async () => {
  try {
    await UserService.addToRecruiterGroup();
  } catch(ex) {
  }
}


const verifyEmailAddress = async () => {
  const currentUser = await Auth.currentAuthenticatedUser();
  try {
    let response = await Auth.verifyUserAttribute(currentUser, "email");
    return true;
  } catch(ex) {
    return false;
  }
}

const confirmEmailAddress = async (code) => {
  const currentUser = await Auth.currentAuthenticatedUser();
  try {
    let response = await Auth.verifyUserAttributeSubmit(currentUser, "email", code);
    return true;
  } catch(ex) {
    return false;
  }
}

const updateCognitoFields = async (dispatch, data) => {
  let updates = false;
  if (data && data.phone) {
    sessionStorage.setItem("setCognitoPhone", `+1${data.phone.replaceAll("-", "")}`);
    await updateUserPhoneInCognito();
    updates = true;
  }

  if (data && data.email) {
    await updateUserEmailInCognito(data.email);
    updates = true;
  }

  if (data && data.firstName) {
    await updateUserAttribute("given_name", data.firstName);
    updates = true;
  }

  if (data && data.lastName) {
    await updateUserAttribute("family_name", data.lastName);
    updates = true;
  }

  if (data && data.firstName && data.lastName) {
    await updateUserAttribute("name", `${data.firstName} ${data.lastName}`);
  }

  if (updates) {
    await updateUser(dispatch);
  }
}

const updateUser = async (dispatch) => {
  const user = await UserService.getUser();
  localStorage.setItem("user", JSON.stringify(user));

  dispatch({
    type: SET_USER,
    payload: user
  });
}

const getShouldAutoVerifyPhone = () => {
  return false;
}

export default {
  registerCandidate,
  login,
  logout,
  listenToCognitoAuthEvents,
  getAccessToken,
  configureAuth,
  verifyPhone,
  confirmPhone,
  verifyEmailAddress,
  confirmEmailAddress,
  updateUserPhoneInCognito,
  updateUserEmailInCognito,
  retrieveCurrentUser,
  updateCognitoFields,
  updateUser,
  addToRecruiterGroup,
  getShouldAutoVerifyPhone
};
