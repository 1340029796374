import React from "react";
import { ReactComponent as Logo } from "./share.svg";

import "../Icons.scss";

const ShareIcon = (props) => {
  const { iconClass } = props;
  return (
    <Logo
      className={iconClass}
    />
  );
};

export default ShareIcon;
