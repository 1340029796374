import "./styles/App.scss";
import { makeStyles } from "@material-ui/core/styles";
import { ToastContainer, toast } from "react-toastify";
import React, { useState, useEffect } from "react";
import {ErrorBoundary} from 'react-error-boundary';
import ReactGA from "react-ga";
import ReactPixel from 'react-facebook-pixel';
import MixPanel from "mixpanel-browser";
import { Router, Switch, Route, Redirect } from "react-router-dom";
import CacheBuster from 'react-cache-buster';
import { version } from '../package.json';
import { isAdmin } from "./components/Admin/services/admin";
import Landing from "./components/views/Landing/Landing";
import Signup from "./components/views/Signup/Signup";
import Login from "./components/views/Login/Login";
import Welcome from "./components/views/Welcome/Welcome";
import Forgot from "./components/views/ForgotPassword/ForgotPassword";
import Reset from "./components/views/Reset/Reset";
import DocTemplates from "./components/archive/DocTemplates/DocTemplates";
import ConfirmPassword from "./components/views/ConfirmPassword/ConfirmPassword";
import ConfirmedEmail from "./components/views/ConfirmedEmail/ConfirmedEmail";
import Assessments from "./components/views/Assessments/Assessments";
import Dashboard from "./components/views/Dashboard/Dashboard";
import MyJobs from "./components/views/MyJobs/MyJobs";
import ProfileContainer from "./components/views/Profile/ProfileContainer";
import Users from "./components/Admin/Components/Users/Users";
import RecruiterUsers from "./components/Admin/Components/Users/RecruiterUsers";
import CandidateUsers from "./components/Admin/Components/Users/Users";
import RecruiterSignup from "./components/Admin/Components/Signup/RecuiterSignup";
import JobFavorites from "./components/views/JobFavorites/JobFavorites";
import JobSearch from "./components/views/JobSearch/JobSearch";
import JobDetails from "./components/views/JobDetails/JobDetails";
import ConfirmSignUp from "./components/views/ConfirmSignUp/ConfirmSignUp";
import Support from "./components/views/Support/Support";
import Apply from "./components/Apply/Apply";
import PrimaryButton from "./components/common/Buttons/PrimaryButton";
import MyTeam from "./components/views/MyTeam/MyTeam";

import ScrollToTop from "./components/common/ScrollToTop/ScrollToTop";

import { clearMessage } from "./actions/message";
import { history } from "./helpers/history";

import { useDispatch, useSelector } from "react-redux";
import authService from "./services/auth.service";
import httpInterceptorsService from "./services/http-interceptors.service";
import SplashScreen from "./components/common/SplashScreen/SplashScreen";
import CongratsSignup from "./components/views/CongratsSignup/CongratsSignup";
import SkillsChecklistForm from "./components/common/Candidate/SkillsChecklist/SkillsChecklistForm";
import "react-toastify/dist/ReactToastify.css";
import { logError } from "./services/errorservice";
import LogRocket from 'logrocket';
import TagManager from 'react-gtm-module';

authService.configureAuth();
const { pathname } = history.location || "/home";
const enableCacheBuster =
  process.env.REACT_APP_STAGE === "production" ||
  process.env.REACT_APP_STAGE === "staging";

const hasCrashFlag = false;

function FallbackComponent({error, resetErrorBoundary}) {
  if (hasCrashFlag) {
    return <div className="error-boundary">
      <p>Something went wrong:</p>
      <pre style={{color: 'red'}}>Down quick for maintenance.  Be back shortly.</pre>
    </div>;
  }
  return <div className="error-boundary">
    <p>Something went wrong:</p>
    <pre style={{color: 'red'}}>{error.message}</pre>
    <PrimaryButton text="Try Again" handleClick={resetErrorBoundary}/></div>;
}

function PrivateRoute({
  component: Component,
  authed,
  type,
  user,
  ...rest
}) {
  if (!authed) {
    return <Redirect to="/login" />;
  }
  if(user && authed && isAdmin(user) && !sessionStorage.getItem("BecomeUser")) {
    return <Redirect to="/users" />
  }

  const userFromStorage = JSON.parse(localStorage.getItem("user"));

  if (userFromStorage && !userFromStorage.id) {
    localStorage.setItem("isSocialSignup", JSON.stringify({isSocialSignup: true}));
    return <Redirect to="/signup" />;
  } else if (userFromStorage && !userFromStorage.phoneConfirmed && userFromStorage.phone) {
    var shouldSkipConfirmSignup = !userFromStorage.userName.includes("internal_");
    if (!shouldSkipConfirmSignup) {
      authService.verifyPhone();
      return <Redirect to="/confirm-signup" />;
    }
  }

  return <Route {...rest} render={(props) => <Component {...props} />} />;
}

function AdminRoute({
  component: Component,
  authed,
  user,
  path,
  ...rest
}) {
  if(path.indexOf("recruitersignup") !== -1) {
      return <Route {...rest} render={(props) => <RecruiterSignup {...props} />} />;
  }

  if (user && authed && !isAdmin(user)) {
   return <Redirect to="/home" />
  }
  else if (!authed) return <Redirect to="/login" />;
  else if (authed && isAdmin(user)) {
    sessionStorage.removeItem("BecomeUser");
    return <Route {...rest} render={(props) => <Component {...props} />} />;
  }
}

function PublicRoute({ component: Component, authed, user, intendedRoute, path, ...rest }) {
  if(user && authed && isAdmin(user) && !sessionStorage.getItem("BecomeUser")) {
    return <Redirect to="/users" />
  } else if (!authed || path.indexOf("confirm-signup") !== -1 || path.indexOf("signup") !== -1) {
    return <Route {...rest} render={(props) => <Component {...props} />} />;
  } else {
    return <Redirect to={intendedRoute && intendedRoute !== path ? intendedRoute : "/home"}/>;
  }
}

const TRACKING_ID =
  process.env.REACT_APP_STAGE === "production"
    ? "UA-194577309-6"
    : "UA-194577309-7"; // YOUR_OWN_TRACKING_ID
if (
  process.env.REACT_APP_STAGE === "production" ||
  process.env.REACT_APP_STAGE === "staging"
)
  ReactGA.initialize(TRACKING_ID);



const GOOGLE_ADS_TRACKING_ID = 'AW-10778014231';


// Meta/FB Pixel
const FB_TRACKING_ID = '900050530684761';
const options = {
  autoConfig: false, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
  debug: false, // enable logs
};
const advancedMatching = {}
ReactPixel.init(FB_TRACKING_ID, advancedMatching, options);


const MP_TRACKING_ID = '23975f3b3239616c171b272645dd2ab0';
const MP_DEBUG = process.env.REACT_APP_STAGE === "production"
  ? false
  : true;
  MixPanel.init(MP_TRACKING_ID, {debug: MP_DEBUG});


const App = () => {
  const [showModeratorBoard, setShowModeratorBoard] = useState(false);
  const [showAdminBoard, setShowAdminBoard] = useState(false);
  const [intendedRoute, setIntendedRoute] = useState();
  const [errorMessage, setErrorMessage] = useState();
  const { user, isLoggedIn } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const drawerWidth = 240;

  httpInterceptorsService.setupRequestInterceptor();
  httpInterceptorsService.setupResponseInterceptor();

  if (process.env.REACT_APP_STAGE === "production") {
    LogRocket.init('wdaog5/advantismedical');
  }

  const useStyles = makeStyles((theme) => ({
    root: {
      display: "flex"
    },
    appBar: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0
    },
    drawerPaper: {
      width: drawerWidth
    },
    // necessary for content to be below app bar
    toolbar: theme.mixins.toolbar,
    content: {
      flexGrow: 1,
      backgroundColor: theme.palette.background.default,
      padding: 0 //theme.spacing(3),
    },
    customColor: {
      // or hex code, this is normal CSS background-color
      backgroundColor: "#800080"
    },
    whiteColor: {
      // or hex code, this is normal CSS background-color
      color: "#FFFFFF"
    }
  }));

  useEffect(() => {
    setIntendedRoute(pathname);
    if(pathname !== "/recruitersignup"){
      authService.listenToCognitoAuthEvents(dispatch);
    }
  }, []);

  useEffect(() => {
    history.listen((location) => {
      var tagManagerArgs = {
          dataLayer: {
            id: GOOGLE_ADS_TRACKING_ID,
            event: 'Pageview',
            pagePath: location.pathname + location.search,
          },
      };
      ReactGA.pageview(location.pathname + location.search);
      ReactPixel.pageView();
      MixPanel.track("Page View", {path:location.pathname, query:location.search});
      dispatch(clearMessage()); // clear message when changing location
    });
  }, [dispatch]);

  useEffect(() => {
    if (user) {
      setShowModeratorBoard(user.roles?.includes("Moderator"));
      setShowAdminBoard(user.roles?.includes("Admin"));
    }
  }, [user]);

  return (
    <Router history={history}>
      <CacheBuster
        currentVersion={version}
        isEnabled={enableCacheBuster} //If false, the library is disabled.
        isVerboseMode={false} //If true, the library writes verbose logs to console.
        loadingComponent={<SplashScreen />} //If not pass, nothing appears at the time of new version check.
      >
        <ErrorBoundary
          FallbackComponent={FallbackComponent}
          onError={(error, errorInfo) => logError(error.message, errorInfo.componentStack )}
          onReset={() => {
            history.push("/");
            window.location.reload();
          }}
        >
          <ScrollToTop>
            <Switch>
              <PrivateRoute
                exact
                path="/job/:id"
                component={JobDetails}
                authed={isLoggedIn}
                user={user}
              />
              <PublicRoute
                exact
                path="/"
                component={Login}
                authed={isLoggedIn}
              />
              <PublicRoute
                exact
                path="/signup"
                component={Signup}
                authed={isLoggedIn}
              />
              <PublicRoute
                exact
                path="/login"
                component={Login}
                authed={isLoggedIn}
                intendedRoute={intendedRoute}
              />
              <PrivateRoute
                exact
                path="/welcome"
                component={Welcome}
                authed={isLoggedIn}
                user={user}
              />
              <PrivateRoute
                exact
                path="/profile"
                component={ProfileContainer}
                authed={isLoggedIn}
                user={user}
              />
              <PrivateRoute
                exact
                path="/home"
                component={Dashboard}
                authed={isLoggedIn}
                user={user}
              />
              <PrivateRoute
                exact
                path="/favorites"
                component={JobFavorites}
                authed={isLoggedIn}
                user={user}
              />
              <PrivateRoute
                exact
                path="/myjobs"
                component={MyJobs}
                authed={isLoggedIn}
                user={user}
              />
              <PrivateRoute
                exact
                path="/search"
                component={JobSearch}
                authed={isLoggedIn}
                user={user}
              />
              <PrivateRoute
                exact
                path="/support"
                component={Support}
                authed={isLoggedIn}
                user={user}
              />
              <PrivateRoute
                exact
                path="/skill/:id"
                component={SkillsChecklistForm}
                authed={isLoggedIn}
                user={user}
              />
              <AdminRoute
                exact
                path="/doctemps"
                component={DocTemplates}
                authed={isLoggedIn}
                user={user}
                type="private"
              />
              {/*<PublicRoute
            exact
            path="/forgot"
            component={Forgot}
            authed={isLoggedIn}
          />
          <PublicRoute
            exact
            path="/reset"
            component={Reset}
            authed={isLoggedIn}
          />
          <PublicRoute
            exact
            path="/confirm"
            component={ConfirmPassword}
            authed={isLoggedIn}
          />
          <PublicRoute
            exact
            path="/confirmedemail"
            component={ConfirmedEmail}
            authed={isLoggedIn}
          />
          <AdminRoute
            exact
            path="/credentials"
            component={Credentials}
            authed={isLoggedIn}
            isAdmin={user && user.roles?.includes("ROLE_ADMINS")}
            type="private"
          /> */}
              {/* <PrivateRoute
            exact
            path="/oldHome"
            component={Home}
            authed={isLoggedIn}
            isAdmin={user && user.roles?.includes("ROLE_ADMINS")}
            type="private"
          /> */}
              <PublicRoute
                exact
                path="/forgot"
                component={Forgot}
                authed={isLoggedIn}
              />
              <PublicRoute
                exact
                path="/reset"
                component={Reset}
                authed={isLoggedIn}
              />
              <PublicRoute
                exact
                path="/confirm-signup"
                component={ConfirmSignUp}
                authed={isLoggedIn}
              />
              <PublicRoute
                exact
                path="/auth"
                component={SplashScreen}
                authed={isLoggedIn}
              />
              <PrivateRoute
                exact
                path="/congrats"
                component={CongratsSignup}
                authed={isLoggedIn}
              />
              <PrivateRoute
                exact
                path="/my-team"
                component={MyTeam}
                authed={isLoggedIn}
                user={user}
              />
              <Route exact path="/confirm" component={ConfirmPassword} />
              <Route exact path="/confirmedemail" component={ConfirmedEmail} />
              <AdminRoute
                exact
                path="/assessments"
                component={Assessments}
                authed={isLoggedIn}
                user={user}
                type="private"
              />
              <AdminRoute
                exact
                path="/users"
                component={CandidateUsers}
                authed={isLoggedIn}
                user={user}
                type="private"
              />
              <AdminRoute
                exact
                path="/recruiters"
                component={RecruiterUsers}
                authed={isLoggedIn}
                user={user}
                type="private"
              />
              <AdminRoute
                exact
                path="/recruitersignup"
                component={RecruiterSignup}
                authed={isLoggedIn}
                user={user}
              />
              <Route path="*">
                <Redirect to="/home" />
              </Route>
            </Switch>
            <ToastContainer />
          </ScrollToTop>
        </ErrorBoundary>
      </CacheBuster>
    </Router>

  );
};

export default App;
