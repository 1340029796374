import React, { useEffect, useState } from "react";

import {
  Grid,
  Box,
  Container,
  InputLabel,
  MenuItem,
  FormControl,
  FormHelperText,
  FormControlLabel,
  RadioGroup,
  Radio
} from "@material-ui/core";

import Select from "react-select";

import "./WorkExperience.scss";

import PrimaryButton from "../../../common/Buttons/PrimaryButton";

const WorkExperience = (props) => {
    const { primarySpecialty, yearsExperience, specialtyList, isCompactLicense, licenseStateList } =
        props.data;
    const { formErrors, handleChange, nextStep, clearError, prevStep, specialties, firstname, states } = props;
    const [currentSpecialtyList, setSpecialtyList] = useState(specialtyList);

    const years = [
        {value: `0`, label: `Less than 1 year`},
        {value: `1`, label: `1 year`},
        {value: `2`, label: `2-3 years`},
        {value: `3`, label: `3-5 years`},
        {value: `5`, label: `5+ years`},
    ];

    const getOptionValue = (option) => option;
    const getOptionLabel = (option) => option;

    const changeSpecialty = (value) => {
        setSpecialtyList(value);
    };

    const changeLocations = (value) => {
        handleChange("work", "licenseStateList", value);
    };

    function getModifiedSpecialtyCategory(category) {
        return category === 'Nurse' ? 'Registered Nurse' : category;
    }

    useEffect(() => {
        let list = [];

        for (let i = 0; i < specialties.length; i++) {
            for (let j = 0; j < currentSpecialtyList.length; j++) {
                if (specialties[i].label === currentSpecialtyList[j] && specialties[i].category === getModifiedSpecialtyCategory(primarySpecialty)) {
                    list.push(specialties[i].id);
                } else if (specialties[i].id === currentSpecialtyList[j]) {
                    list.push(specialties[i].id);
                    currentSpecialtyList[j] = specialties[i].label;
                    handleChange("work", "primarySpecialty", specialties[i].category === "Registered Nurse" ? "Nurse" : specialties[i].category);
                }
            }
        }
        handleChange(
            "work",
            "specialtyList",
            list
        );
    }, [currentSpecialtyList]);

  return (
    <Container maxWidth="sm" className="work-experience-container">
      <Box width={1} display="flex" alignItems="center">
        <h1 className="text-lg leading-6 font-medium text-gray-900">{firstname}, to match you with the best jobs and the perfect recruiter, we just need some simple information:</h1>
      </Box>
      <Box width={1} display="flex" marginTop="1.5em" mb={3}>
        <Grid container spacing={1}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
                <FormControl
                        error={formErrors?.primarySpecialty ? true : false}
                        fullWidth
                    >
                    <h4 className="text-sm font-medium text-gray-900">Are you a Nurse, LPN, CNA, or an Allied Health Professional? *</h4>
                    <RadioGroup
                        className="primary-speciality-options-horizontal"
                        row
                        aria-label="compact"
                        name="compact"
                        value={primarySpecialty ? primarySpecialty : null}
                        onChange={(e) => {
                                setSpecialtyList([]);
                                handleChange("work", "primarySpecialty", e.target.value)
                            }
                        }
                        >
                        <FormControlLabel
                            value="Nurse"
                            control={<Radio color="primary" />}
                            label="Nurse"
                        />
                        <FormControlLabel
                            value="Allied"
                            control={<Radio color="primary" />}
                            label="Allied"
                        />
                        <FormControlLabel
                            value="LPN"
                            control={<Radio color="primary" />}
                            label="LPN"
                        />
                        <FormControlLabel
                            value="CNA"
                            control={<Radio color="primary" />}
                            label="CNA"
                        />
                    </RadioGroup>
                    <FormHelperText>
                    {formErrors?.primarySpecialty || ""}
                    </FormHelperText>
                </FormControl>

            </Grid>
            {specialties && (
                <Grid item xs={12} sm={12} md={12} lg={12}>
                <h4 className="text-sm font-medium text-gray-900 pb-2">What are your Specialities - List of specialties related to Option above (can choose more than one) *</h4>
                {specialties && (
                    <>
                        <FormControl
                        error={formErrors?.specialtyList ? true : false}
                        fullWidth
                        disabled={!primarySpecialty}
                        variant="outlined"
                        className="speciality-select"
                    >
                        <Select
                            data-testid="workSpecialty"
                            id="currentSpecialtyList"
                            name="currentSpecialtyList"
                            options={specialties?.filter((specialty) => {
                                return specialty && specialty.label !== "EKG/Tele Tech" && specialty.category && specialty.category.includes(primarySpecialty);
                            }).map((specialty) => (
                                specialty.label
                            ))}
                            isSearchable={false}
                            isMulti
                            onFocus={() => clearError("specialtyList")}
                            value={currentSpecialtyList}
                            getOptionValue={getOptionValue}
                            getOptionLabel={getOptionLabel}
                            onChange={changeSpecialty}
                            placeholder="Select a specialty"
                        />
                        <FormHelperText>
                            {formErrors?.specialtyList || ""}
                            </FormHelperText>
                        {specialties.length === 0 && (
                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                <h4 className="text-sm font-bold text-red-600 pb-2">Error: Failed to load specialties. Please refresh the page.</h4>
                            </Grid>
                        )}
                        </FormControl>
                    </>
                )}
                </Grid>
            )}
            {!specialties && (
                <Grid item xs={12} sm={12} md={12} lg={12}>
                    <h4 className="text-sm font-bold text-red-600 pb-2">Error: Failed to load specialties. Please refresh the page.</h4>
                </Grid>
            )}
            {states && (
            <Grid item xs={12} sm={12} md={12} lg={12}>
                <h4 className="text-sm font-medium text-gray-900 pb-2">Which states are you licensed in? *</h4>
                {states && (
                    <>
                        <FormControl
                        error={formErrors?.licenseStateList ? true : false}
                        fullWidth
                        variant="outlined"
                        className="license-state-select"
                    >
                        <Select
                            data-testid="licenseStateList"
                            id="licenseStateList"
                            name="licenseStateList"
                            options={states}
                            isSearchable={false}
                            isMulti
                            value={licenseStateList}
                            getOptionValue={getOptionValue}
                            getOptionLabel={getOptionLabel}
                            onChange={changeLocations}
                            placeholder="Select States"
                        />
                        <FormHelperText>
                            {formErrors?.licenseStateList || ""}
                        </FormHelperText>
                        {states.length === 0 && (
                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                <h4 className="text-sm font-bold text-red-600 pb-2">Error: Failed to load states. Please refresh the page.</h4>
                            </Grid>
                        )}
                        </FormControl>
                    </>
                )}

                <FormControl component="fieldset">
                    <h4 className="text-sm font-medium text-gray-900 pb-2">Is this a compact license (eNLC)? *</h4>
                    <RadioGroup
                    row
                    aria-label="compact"
                    name="compact"
                    value={isCompactLicense === true ? "yes" : "no"}
                    onChange={(e) =>
                        handleChange("work", "isCompactLicense", e.target.value === "yes" ? true : false)
                    }
                    >
                    <FormControlLabel
                        value="no"
                        control={<Radio color="primary" />}
                        label="No"
                    />
                    <FormControlLabel
                        value="yes"
                        control={<Radio color="primary" />}
                        label="Yes"
                    />
                    </RadioGroup>
                   <FormHelperText>
                    {formErrors?.isCompactLicense || ""}
                    </FormHelperText>
                </FormControl>
            </Grid>
            )}
            {!states && (
                <Grid item xs={12} sm={12} md={12} lg={12}>
                    <h4 className="text-sm font-bold text-red-600 pb-2">Error: Failed to load states. Please refresh the page.</h4>
                </Grid>
            )}
            {years && (
                <Grid item xs={12} sm={12} md={12} lg={12}>
                <h4 className="text-sm font-medium text-gray-900">How many years of experience do you have? *</h4>
                <FormControl
                    error={formErrors?.yearsExperience ? true : false}
                    fullWidth
                    variant="outlined"
                >
                    <RadioGroup
                        className="years-options"
                        row
                        aria-label="Years*"
                        name="years"
                        data-testid="primary-years"
                        value={yearsExperience || ""}
                        onChange={(event, value) => {
                            handleChange("work", "yearsExperience", event.target.value);
                        }}
                        >
                        {years?.map((year) => (
                            <FormControlLabel
                                key={year.value}
                                value={year.value}
                                control={<Radio color="primary" />}
                                label={year.label}
                            />
                        ))}
                    </RadioGroup>
                    <FormHelperText>
                    {formErrors?.yearsExperience || ""}
                    </FormHelperText>
                </FormControl>
                </Grid>
            )}
        </Grid>
      </Box>

      <Box width={1} display="flex" justifyContent="flex-end">
        <Grid container spacing={1} alignItems="flex-end">
          <Grid item xs={4}>
            <PrimaryButton type="button" text="Back" buttonClass="color-button previous-step" handleClick={prevStep} />
          </Grid>
          <Grid item xs={4}>
            <PrimaryButton type="button" text="Next" handleClick={nextStep} buttonClass="color-button" />
          </Grid>
        </Grid>
      </Box>
      </Container>
  );
};

export default WorkExperience;
