import { UPDATE_CANDIDATE, CLEAR_CANDIDATE } from "../actions/types";

const initialState = {};

export const candidateData = (state = initialState, action) => {
  const newState = action.payload;
  const updatedState = { ...state };
  Object.assign(updatedState, newState);
  switch (action.type) {
    case UPDATE_CANDIDATE:
      return { ...state, ...newState };
    case CLEAR_CANDIDATE:
        return {};
    default:
      return state;
  }
};
