import React from "react";
import { ReactComponent as Logo } from "./google.svg";
import SvgIcon from "@material-ui/core/SvgIcon";

import "../Icons.scss";

const GoogleIcon = () => (
  <SvgIcon className="social-media-icon">
    <Logo />
  </SvgIcon>
);

export default GoogleIcon;
