import { GET_STATES } from "./types";

import { getStates } from "../services/datalist";

export const getStatesList = () => async (dispatch) => {
  try {
    const results = await getStates();

    if (results) {
      dispatch({
        type: GET_STATES,
        payload: results,
      });
    }
  } catch (err) {
    console.log(err);
  }
};
