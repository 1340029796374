import React, { useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Form from "react-validation/build/form";
import CheckButton from "react-validation/build/button";
import { green } from "@material-ui/core/colors";

import { Typography, Grid, Box } from "@material-ui/core";
import { login } from "../../../actions/auth";

const useStyles = makeStyles((theme) => ({
  logoBox: {
    backgroundImage: "linear-gradient(45deg, #3B3E40, #0586CB)",
    display: "flex",
    alignItems: "center",

    display: "flex",
    justifyContent: "center"
  },
  smallLink: {
    color: "#0586CB"
  },

  root: {
    flexGrow: 1,
    width: "100%",
    border: "0px",
    borderColor: "black",
    borderStyle: "solid"
  },
  mainPage: {
    width: "100%",

    marginTop: "50px",

    border: "0px",
    borderColor: "black",
    borderStyle: "solid",
    padding: "4px"
  },
  menuButton: {
    marginRight: theme.spacing(2)
  },
  title: {
    flexGrow: 1
  },
  titleHidden: {
    flexGrow: 1,
    display: "none"
  },
  titleCenter: {
    flexGrow: 3,
    textAlign: "center"
  },

  AppBarLogin: {
    marginBottom: "10px",
    backgroundColor: "white"
  },
  logoHeader: {},
  buttonMod: {
    backgroundColor: "#0586CB",
    color: "#FFF",
    "&:hover": {
      backgroundColor: "#0397e5",
      color: "#FFF"
    }
  },

  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12
  },
  wrapper: {
    margin: theme.spacing(1),
    position: "relative"
  }
}));

const required = (value) => {
  if (!value) {
    return (
      <div className="alert alert-danger" role="alert">
        This field is required!
      </div>
    );
  }
};

const Confirmed = (props) => {
  const classes = useStyles();

  const form = useRef();
  const checkBtn = useRef();

  const [fblogin, setFBLogin] = useState(false);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);

  const timer = React.useRef();

  const [success, setSuccess] = React.useState(false);

  const { isLoggedIn } = useSelector((state) => state.auth);
  const { message } = useSelector((state) => state.message);

  const dispatch = useDispatch();

  const leftLogo = "https://d2wqn2ab54ir3q.cloudfront.net/images/leftlogo.png";

  const onChangeUsername = (e) => {
    const username = e.target.value;
    setUsername(username);
  };

  const onChangePassword = (e) => {
    const password = e.target.value;
    setPassword(password);
  };

  const handleLogin = (e) => {
    e.preventDefault();

    setLoading(true);

    form.current.validateAll();

    if (checkBtn.current.context._errors.length === 0) {
      dispatch(login(username, password))
        .then(() => {
          props.history.push("/");
          window.location.reload();
        })
        .catch(() => {
          setLoading(false);
        });
    } else {
      setLoading(false);
    }
  };

  if (isLoggedIn) {
    return <Redirect to="/" />;
  }

  const handleButtonClick = () => {
    if (!loading) {
      setSuccess(false);
      setLoading(true);
      timer.current = window.setTimeout(() => {
        setSuccess(true);
        setLoading(false);
      }, 2000);
    }
  };

  return (
    <Form onSubmit={handleLogin} ref={form}>
      <div className={classes.mainPage}>
        <Grid
          container
          direction={"column"}
          spacing={2}
          align="center"
          justify="center"
          alignItems="center"
          style={{ width: "300" }}
        >
          <Grid item>
            <Box
              textAlign="center"
              className={classes.logoBox}
              height={80}
              width={350}
            >
              <img src={leftLogo} className={classes.logoLeft} />
            </Box>
          </Grid>
          <Grid item>
            <Box textAlign="center">
              <h1>Reset Password</h1>
            </Box>
          </Grid>
          <Grid item style={{ width: "450px" }}>
            <Typography variant="h5" color="textSecondary" align="center">
              A Reset password link has been sent to the email provided.
            </Typography>
          </Grid>

          <Grid item style={{ width: "350px" }}>
            {message && (
              <div className="form-group">
                <div className="alert alert-danger" role="alert">
                  {message}
                </div>
              </div>
            )}
          </Grid>
        </Grid>
        <CheckButton style={{ display: "none" }} ref={checkBtn} />
      </div>
    </Form>
  );
};

export default Confirmed;
