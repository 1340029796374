import React from "react";
import { ReactComponent as Logo } from "./location.svg";
import SvgIcon from "@material-ui/core/SvgIcon";

import "../Icons.scss";

const LocationIcon = (props) => {
  const { iconClass } = props;
  return <SvgIcon className={iconClass} component={Logo} viewBox="0 0 24 24" />;
};

export default LocationIcon;
