import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal } from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";
import ReactPixel from 'react-facebook-pixel';

import { clearMessage, setMessage } from "../../actions/message";
import { getJobDetails, jobApply } from "../../services/jobs";
import { getJobApplications } from "../../actions/applications";
import "./Apply.scss";
import ModalNav from "../common/ModalNav/ModalNav";
import ModalFooter from "../common/ModalFooter/ModalFooter";
import CandidateData from "../CandidateData/CandidateData";
import { getCand } from "../../actions/candidateEntry";
import { notify } from "../../toasts/toasts";
import PayDisclaimer from '../views/PayDisclaimer/PayDisclaimer';

const Apply = ({ jobDetails, open, handleClose, jobIds }) => {
  const { profileProgress } = useSelector((state) => state);
  const { message } = useSelector((state) => state.message);
  const [isLoading, setIsLoading] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [job, setJobDetails] = useState();

  const dispatch = useDispatch();
  useEffect(() => {
    setIsLoading(true);
    !profileProgress && dispatch(getCand());
    //Gets the job details for applying to a single job via the job details view
    if (!jobDetails && jobIds?.length === 1) {
      getJobDetails(jobIds[0]).then((data) => setJobDetails(data));
    } else if (jobIds?.length === 1) {
      setJobDetails(jobDetails);
    }
    setIsLoading(false);
    return;
  }, []);

  const completionPercentage =
    profileProgress &&
    Math.round((profileProgress.completed / profileProgress.total) * 100);

  const apply = async () => {
    if (profileProgress?.completed === profileProgress?.total) {
      setIsSaving(true);
      await jobApply(jobIds);
      await dispatch(getJobApplications());
      setIsSaving(false);
      ReactPixel.trackCustom('AmedSubmitApplication');
      handleClose();
    } else {
      notify("Please complete your profile before submitting", "error");
    }
  };


  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      className="modal"
    >
      <div className="modal-content profile">
        <ModalNav handleClose={handleClose} jobTitle={job?.jobTitle} />
        <div className="subheader pre-apply">
          <h2 className="text-lg leading-6 font-medium text-gray-900">
            Profile Progress{" "}
            {completionPercentage && `(${completionPercentage}%)`}
          </h2>
        </div>
        {message && (
          <div className="form-group">
            <div className="alert alert-danger" role="alert">
              {message}
            </div>
          </div>
        )}
        {isLoading ? (
          <div className="job-summary">
            <Skeleton height="3rem" width="100%" />
            <Skeleton height="3rem" width="100%" />
          </div>
        ) : (
          <>
            {jobDetails && jobIds?.length === 1 ? (
              <div className="job-summary">
                <p className="job-title">{job?.jobTitle}</p>

                <p className="job-salary-location">
                  {job?.weeklySalary && job?.weeklySalary !== 0
                    ? `$${job.weeklySalary}/week* - `
                    : `Call/Text for Details - `}
                  <span>
                    {typeof job?.location === "string"
                      ? job?.location
                      : job?.location?.formattedAddress &&
                        job?.location?.formattedAddress}
                  </span>
                </p>
                <div class="pay-disclaimer-container"><PayDisclaimer></PayDisclaimer></div>
              </div>
            ) : (
              <div className="apply-header">
                <h3>Applying to {jobIds?.length} jobs</h3>
              </div>
            )}
          </>
        )}

        <CandidateData />
        <ModalFooter
          closeButtonText="Back"
          saveButtonText="Submit"
          handleClose={handleClose}
          handleSave={apply}
          isSaving={isSaving}
        />
      </div>
    </Modal>
  );
};

export default Apply;
