import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Container, Radio } from "@material-ui/core";

import Select from "react-select";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from "@material-ui/pickers";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import "./JobPreferences.scss";
import {
  faDollarSign,
  faUmbrellaBeach,
  faCity,
  faHome,
  faTree,
  faDna,
  faSackDollar,
  faTrees,
  faTemperatureHot,
  faHomeHeart
} from "@fortawesome/pro-light-svg-icons";

const JobPreferencesForm = ({ values, change, error, clearError }) => {
  const { shifts, locations, quality, startDate } = values;
  const shiftOptions = ["days", "nights"];
  const qualities = [
    { name: "Low Cost of Living", icon: faDollarSign },
    { name: "Beach Life", icon: faUmbrellaBeach },
    { name: "City Life", icon: faCity },
    { name: "Suburban Life", icon: faHome },
    { name: "Outdoors", icon: faTree },
    { name: "Flexibility", icon: faDna },
    { name: "Career Growth", icon: faSackDollar },
    { name: "Take Home Pay", icon: faTrees },
    { name: "Warm Weather", icon: faTemperatureHot },
    { name: "Closer to Home", icon: faHomeHeart }
  ];

  const [selectedQuality, setSelectedQuality] = useState();
  const states = useSelector((state) =>
    state.states ? state.states.map((state) => state.name) : []
  );

  useEffect(() => {
    const qualityIndex = qualities.findIndex(
      (option) => option.name === quality
    );
    setSelectedQuality(qualityIndex);
  }, []);

  const changeQuality = (index) => {
    setSelectedQuality(index);

    change(qualities[index].name, "quality");
  };

  const changeStartDate = (value) => {
    change(value, "startDate");
  };

  const changeLocations = (value) => {
    change(value, "locations");
  };

  const changeShifts = (value) => {
    change(value, "shifts");
  };
  const getOptionValue = (option) => option;
  const getOptionLabel = (option) => option;

  return (
    <Container width={1} data-testid="jobPreferences" maxWidth="sm">
      <div className="header ">
        <h3 className="text-lg leading-6 font-medium text-gray-900">Job Preferences</h3>
      </div>

      <form className="preferences" data-testid="form">
        {states && (
          <div className="preference">
            <label htmlFor="locations" className="question">
              Where would you like to work?
            </label>

            <Select
              data-testid="locationPreference"
              id="locations"
              name="locations"
              options={states}
              isSearchable={false}
              isMulti
              value={locations}
              id="locations"
              name="locations"
              getOptionValue={getOptionValue}
              getOptionLabel={getOptionLabel}
              onChange={changeLocations}
            />
          </div>
        )}

        <div className="preference">
          <label htmlFor="shifts" className="question">
            What is your shift preference?
          </label>
          <Select
            id="shifts"
            name="shifts"
            options={shiftOptions}
            isSearchable={false}
            isMulti
            value={shifts}
            onChange={changeShifts}
            getOptionValue={getOptionValue}
            getOptionLabel={getOptionLabel}
          />
        </div>
        <div className="preference">
          <p className="question">When would you like to start?</p>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              variant="inline"
              placeholder="MM/DD/YYYY"
              format="MM/dd/yyyy"
              margin="normal"
              id="startDate"
              label="Select a start date"
              name="startDate"
              autoOk={true}
              value={startDate ? startDate : null}
              onChange={(e, val) => changeStartDate(val)}
              onFocus={() => clearError("startDate")}
              error={error?.startDate ? true : false}
              helperText={error?.startDate}
              KeyboardButtonProps={{
                "aria-label": "change date"
              }}
            />
          </MuiPickersUtilsProvider>
          <h4>-- OR --</h4>
          <div className="startOptions">
            <div className="startOption">
              <Radio
                checked={startDate !== undefined && startDate <= new Date()}
                onChange={(e) => changeStartDate(new Date())}
                value="As soon as possible"
                name="asap"
                data-testid="asap"
                inputProps={{ "aria-label": "ASAP" }}
                color="primary"
              />
              <label htmlFor="asap">As soon as possible</label>
            </div>
            <div className="startOption">
              <Radio
                checked={startDate === undefined}
                onChange={(e) => changeStartDate(undefined)}
                value={"Not actively looking"}
                name="notLooking"
                data-testid="notLooking"
                inputProps={{ "aria-label": "Not looking" }}
                color="primary"
              />
              <label htmlFor="notLooking">Not actively looking</label>
            </div>
          </div>
        </div>
        <div className="preference">
          <p className="question">
            What else is important to you in your next destination?
          </p>
          <div className="qualities">
            {qualities.map((quality, index) => (
              <div
                key={index}
                className={`quality ${
                  index === selectedQuality ? "selected" : "unselected"
                }`}
                onClick={() => changeQuality(index)}
              >
                <FontAwesomeIcon icon={quality.icon} />
                <p>{quality.name}</p>
              </div>
            ))}
          </div>
        </div>
      </form>
    </Container>
  );
};

export default JobPreferencesForm;
