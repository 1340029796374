import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import DateFnsUtils from "@date-io/date-fns";
import { ArrowBackIos } from "@material-ui/icons";
import { Button, Grid } from "@material-ui/core";
import Hidden from "@material-ui/core/Hidden";

import Skeleton from "@material-ui/lab/Skeleton";
import {
  CircleIcon,
  HeartIcon,
  ShareIcon,
  ZapIcon
} from "../../common/Icons";
import { CheckCircle } from "@material-ui/icons";
import { getJobDetails, jobApply } from "../../../services/jobs";

import "./JobDetails.scss";

import { useHistory } from "react-router-dom";
import PrimaryButton from "../../common/Buttons/PrimaryButton";
import ContactModal from "../../ContactModal/ContactModal";
import Share from "./Share";
import WeCareMore from "./WeCareMore";
import { addFavorite, removeFavorite, setFavoriteJobs, setFeaturedJobs } from "../../../actions/jobs";
import { getCandidateDetails } from "../../../services/candidateData";
import { getCand } from "../../../actions/candidateEntry";
import NavLayout from "../../NavLayout/NavLayout";
import Apply from "../../Apply/Apply";
import Refer from "../Refer/Refer";
import Resources from "../Resources/Resources";
import Reward from "../Reward/Reward";
import ReactGA from "react-ga";
import MixPanel from "mixpanel-browser";
import { getJobApplications } from "../../../actions/applications";
import { sendApplicationStarted } from "../../../services/email";
import { stateLocation } from "../../views/JobSearch/explore";
import SimilarJobs from "./SimilarJobs/SimilarJobs";
import PayDisclaimer from '../PayDisclaimer/PayDisclaimer';

const dateFns = new DateFnsUtils();

const JobDetails = (props) => {
  const { id } = props.match.params;
  const { favoriteJobs, featuredJobs, applications } = useSelector((state) => state || {});
  const [isLoading, setIsLoading] = useState(false);
  const [jobDetails, setJobDetails] = useState();
  const [modalOpen, setModal] = useState(false);
  const [showApply, setShowApply] = useState(props?.location?.jobProps?.showApply || false);
  const [addingFavorite, setAddingFavorite] = useState(false);
  const [isFavorite, setFavorite] = useState(false);
  const [openShare, setOpenShare] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [candidateData, setCandidateData] = useState({});
  const { profileProgress } = useSelector((state) => state);

  const { completed } = profileProgress || 0;
  const { total } = profileProgress || 10;

  const [percentage, setPercentage] = useState(undefined);


  const history = useHistory();
  const dispatch = useDispatch();

  const checkApplied = () => {
    if (applications) {
      const applied = applications.filter(
        (application) => application?.job?.jobId?.toString() === id.toString()
      );

      if (applied && applied.length > 0) {
        setDisabled(true);
      }
    }
  };

  const onApplyClicked = async () => {
    setShowApply(true);
    await sendApplicationStarted([parseInt(id)]);
  }

  useEffect(() => {
    let mounted = true;
    const loadData = async () => {
      setIsLoading(true);
      const results = await getJobDetails(id);
      const userDetails = await getCandidateDetails();

      if(userDetails) {
        const { basicInfo } = userDetails;
        setCandidateData(basicInfo)
      }
      if (!applications) {
        await dispatch(getJobApplications());
      }
      if (!favoriteJobs) {
        await dispatch(setFavoriteJobs());
      }
      if (!featuredJobs) {
       await dispatch(setFeaturedJobs());
      }
      if (!profileProgress) {
       await dispatch(getCand());
      }

      await setJobDetails(results);
      checkFavorite();

      setIsLoading(false);
    };
    if (mounted) {
      loadData();
    }

    return () => {
      mounted = false;
    };
  }, [id]);

  useEffect(() => {
    if (applications) {
      checkApplied();
    }
  }, [applications]);

  useEffect(() => {
    setPercentage(`${Math.round((completed / total) * 100)}%`);
  }, [setPercentage, completed, total]);

  const checkFavorite = () => {
    if (favoriteJobs && favoriteJobs.jobList) {
      const favorite = favoriteJobs?.jobList?.find(
        (job) => job.jobId.toString() === id.toString()
      );
      if (favorite) {
        setFavorite(true);
      } else {
        setFavorite(false);
      }
    }
  };

  const addToFavorites = async () => {
    setAddingFavorite(true);
    const isFavoriteJob = isFavorite;
    if (isFavoriteJob) {
      await dispatch(removeFavorite(id));
      setFavorite(false);
    } else {
      await dispatch(addFavorite(id));
      setFavorite(true);
    }

    setAddingFavorite(false);
    ReactGA.event({ category: "JobDetails", action: "Add Favorite" });
    MixPanel.track("JobDetails", { action: "Add Favorite" });
  };

  const closeModal = () => {
    setModal(false);
  };

  const applyToJob = () => {
    jobApply([id]);
    ReactGA.event({ category: "JobDetails", action: "Apply" });
    MixPanel.track("JobDetails", { action: "Apply" });

  };

  const getPictureUrl = (jobDetails) => {
    if (
      jobDetails &&
      jobDetails.location &&
      jobDetails.location.pictureUrlPath
    ) {
      return (
        "https://ik.imagekit.io/yksbahzmx/connect/tr:w-1400,h-700/" +
        jobDetails.location.pictureUrlPath
      );
    } else {
      return "https://ik.imagekit.io/yksbahzmx/connect/tr:w-1400,h-700/images/job-placeholder.jpg";
    }
  };

  const goBack = () => {
    if (history.action !== "POP"){
      history.goBack();
    } else {
      history.push("/search");
    }
  }

  useEffect(() => {
    checkFavorite();
  }, [favoriteJobs]);

  const selectShiftType = (shift) => {
    const value = shift?.toLowerCase();
    let shiftType = "";
    if (shift){
        if (value.includes('am') || value.includes('day') || value.includes("07:00-19:00") || value.includes("morning")){
            if (!value.includes('noc') && !value.includes('night') && !value.includes('evening') && !value.includes("19:00-07:00")){
                shiftType = ' Days';
            }
        }
        if (value.includes('noc') || value.includes('night') || value.includes('evening') || value.includes("19:00-07:00")){
            if (!value.includes('am') && !value.includes('day') && !value.includes("07:00-19:00") && !value.includes("morning")){
                shiftType = ' Nights';
            }
        }
    }
    return shiftType;
  }


  const shiftType = selectShiftType(jobDetails?.shift);

  const getShiftPill = () => {
    const desiredShifts = candidateData?.desiredShiftPreferenceList;
    const firstDesiredShift = shiftType?.toLocaleLowerCase().includes(desiredShifts[0]);
    const secondDesiredShift = shiftType?.toLocaleLowerCase().includes(desiredShifts[1]);

    if (firstDesiredShift && desiredShifts[0] === 'days' || secondDesiredShift && desiredShifts[1] === 'days') {
      return (
        <span className="shift text-sm status pill">
          Day Shift
        </span>
      )
    } else if (firstDesiredShift && desiredShifts[0] === 'nights' || secondDesiredShift && desiredShifts[1] === 'nights') {
      return (
        <span className="shift text-sm status pill">
          Night Shift
        </span>
      )
    }  else if (shiftType === "" && desiredShifts.length > 1) {
      return (
        <span className="shift text-sm status pill">
          Day and Night Shift
        </span>
      )
    }
  }

  const getLocationValue = () => {
    const state = jobDetails.location.state;
    const preferredLocation = candidateData.desiredLocationList.includes(stateLocation[state]);

    return preferredLocation ?  ( <span className="job-location text-sm status pill">Preferred Location</span> ) : null;
  }

  const isClosed = () => {

  }

  return (
    <div className="job-details-section">
      <NavLayout  headerText="My Jobs">
        <Grid container className="jobs-detail-container">

          <Grid item xs={12} sm={12} md={12} lg={9} className="details-grid-container">
            <div className="back-btn">
            <Hidden mdDown>
              <Button variant="text" onClick={goBack}>
                  <ArrowBackIos className="blue-icon" />
                Back to Jobs
              </Button>
            </Hidden>
            </div>
            {!isLoading && jobDetails && (
              <div className="details-container">
                {jobDetails.location && (
                  <div className="job-image">
                    <img src={getPictureUrl(jobDetails)} />
                  </div>
                )}
                <div className="details-wrapper">
                  <div className="job-summary job-section">
                    <Grid container>
                      <Hidden lgUp>
                        <Grid item className="social-icons mobile" item xs={12} sm={12} md={12} lg={12}>
                          <Grid container>
                            <Grid className="mobile-back" item xs={6} sm={6} md={6} lg={6}>
                              <Button variant="text" onClick={goBack}>
                                  <ArrowBackIos className="blue-icon" />
                                Back to Jobs
                              </Button>
                            </Grid>
                            <Grid className="social-icons-container" item xs={6} sm={6} md={6} lg={6}>
                              <div className="footer-button" onClick={addToFavorites}>
                                <HeartIcon iconClass="small-blue-icon" active={isFavorite} />
                                <PrimaryButton
                                  buttonClass="transparent-button"
                                  disabled={addingFavorite}
                                />
                              </div>
                              <div className="footer-button share" onClick={() => setOpenShare(true)}>
                                <ShareIcon iconClass="small-blue-icon" />
                                <PrimaryButton
                                  buttonClass="transparent-button"
                                ></PrimaryButton>
                              </div>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Hidden>
                      <Hidden mdDown>
                        <Grid item className="social-icons" item xs={12} sm={12} md={12} lg={12}>
                          <div className="footer-button" onClick={addToFavorites}>
                            <HeartIcon iconClass="small-blue-icon" active={isFavorite} />
                            <PrimaryButton
                              buttonClass="transparent-button"
                              disabled={addingFavorite}
                            />
                          </div>
                          <div className="footer-button share" onClick={() => setOpenShare(true)}>
                            <ShareIcon iconClass="small-blue-icon" />
                            <PrimaryButton
                              buttonClass="transparent-button"
                            ></PrimaryButton>
                          </div>
                        </Grid>
                      </Hidden>
                    </Grid>
                    <Grid container>
                      <Grid item className="job-location-container" xs={12} sm={12} md={12} lg={12}>
                        <h4 className="text-lg leading-6 font-medium text-gray-900">{jobDetails.jobTitle}</h4>
                        <h6 className="text-md leading-6 font-medium text-gray-500">{jobDetails.location?.formattedAddress}</h6>
                      </Grid>
                    </Grid>
                    <Grid container>
                      <Grid item className="quick-save-button-container" xs={6} sm={6} md={6} lg={6}>
                      { !jobDetails?.isOpen ? (
                        <div className="pill text-center">No longer available</div>
                        ) : (
                        <button
                            className="save-button text-sm"
                            onClick={() => onApplyClicked()}
                            disabled={disabled}
                          >
                            { disabled ? "Applied" : percentage === '100%' ? (
                                  <>
                                    <div className="flex font-medium">
                                      <span className="hide-xs">1 Click</span>
                                      &nbsp;Apply &nbsp;<ZapIcon iconClass="zap-icon" />
                                    </div>
                                  </>
                              ) : (
                                "Apply"
                              )
                            }
                        </button>
                      )}
                      </Grid>
                      <Grid item className="job-title-container" xs={6} sm={6} md={6} lg={6}>
                          <p className="text-gray-500 text-sm">
                            Weekly Pay*
                            <span className="italic inline-block pl-2 font-light">(Wages &amp; Per Diem)</span>
                          </p>

                          {jobDetails?.weeklySalary && jobDetails.weeklySalary !== 0 ? (
                            <h6 className=" font-medium text-lg">
                              {`$${jobDetails.weeklySalary.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}`}
                            </h6>
                          ) : (
                            <h6 className="salary">Call/Text for Details</h6>
                          )}
                        </Grid>
                    </Grid>
                  </div>
                  <ul className="job-time job-section">
                    {jobDetails.startDate && (
                      <li className="p-4">
                        <p className="uppercase text-xs text-gray-500">Starts</p>

                        <div className="text-md font-medium">
                            {dateFns.format(
                              new Date(jobDetails.startDate),
                              "MM/dd/yy"
                            )}
                        </div>
                      </li>
                    )}
                    {jobDetails.shift && (
                      <li className="p-4">
                        <p className="uppercase text-xs text-gray-500">Shift</p>
                        <div className="text-md font-medium">
                          {jobDetails.shift}
                        </div>
                      </li>
                    )}
                    <li className="p-4">
                      <p className="uppercase text-xs text-gray-500">Duration</p>
                      <div className="text-md font-medium">
                        {`${jobDetails.durationWeeks} weeks`}
                      </div>
                    </li>
                  </ul>
                  <div className="job-section">
                    <h5 className="text-lg font-medium">Description</h5>
                    <div className="description-text"
                      dangerouslySetInnerHTML={{ __html: jobDetails.description }}
                    ></div>
                  </div>

                  { !!candidateData && (
                    <div className="job-section">
                      <h5 className="text-lg font-medium">How did we match this job for you?</h5>

                        {featuredJobs?.jobList.map((currentJob) => {
                          return currentJob.jobId === jobDetails.jobId ?
                            (
                            <span key="job-featured-0" className="job-featured text-sm status pill">Featured Job</span>
                            ) : null
                        })
                        }

                        {jobDetails.shift && candidateData?.desiredShiftPreferenceList?.length ? (
                            getShiftPill()
                          ) : null
                        }
                        { jobDetails.source === 'Direct' &&
                          <span className="job-specialty text-sm status pill">Exclusive</span>
                        }
                        { candidateData?.desiredLocationList?.length ? (
                            getLocationValue()
                          ) : null
                        }
                        {candidateData.specialtyList?.map((specialty, index) => (
                            jobDetails.specialtyRequirementList?.map((requirement, index) => (
                              specialty.id === requirement.id && <span key={index} className="job-specialty text-sm status pill">Specialty</span>
                          ))
                        ))}
                    </div>
                  )}

                  {jobDetails.specialtyRequirementList && (
                    <div className="job-section">
                      <h5 className="text-lg font-medium">Specialty Requirement</h5>
                      <div className="specialty-list">
                          {jobDetails.specialtyRequirementList.map((specialty) => (
                            <div className="specialty-list-item" key={specialty.id} value={specialty.id}>
                                <CircleIcon iconClass="blue-icon" /><p>{specialty.name}</p>
                            </div>
                          ))}
                        </div>
                    </div>
                  )}
                  <div className="job-section">
                    <h5 className="text-lg font-medium">Requirements</h5>
                    {jobDetails.credentialRequirementList && (
                      <ul className="requirement-list">
                        {jobDetails.credentialRequirementList?.map((credential) => (
                          <li key={credential.id}>
                            <CheckCircle className="requirement-icon" />
                            <p>{credential.name}</p>
                          </li>
                        ))}
                      </ul>
                    )}
                    {jobDetails.credentialRequirementList?.length === 0 && (
                      <p>No additional requirements to apply.</p>
                    )}
                  </div>

                  <div className="job-sections">
                    <h5 className="text-lg font-medium">Discover the Advantis Difference - We Care More</h5>
                    <WeCareMore />
                  </div>


                  <div className="job-section location">
                    <h5 className="text-lg font-medium">Location</h5>
                    {jobDetails.location?.companyName && (
                      <p className="location-text">{jobDetails.location?.companyName}</p>
                    )}
                    <p className="location-text">{jobDetails.location?.formattedAddress}</p>
                  </div>


                  <div className="job-section ">
                    <SimilarJobs jobId={jobDetails?.jobId}/>
                  </div>

                  <PayDisclaimer></PayDisclaimer>

                  { jobDetails?.isOpen && (

                    <div className="job-section">
                      <Grid container className="save-button-sticky">
                        <Grid item xs={12} sm={12} md={12} lg={12} className="save-button-container">
                          <button
                            className="save-button"
                            onClick={() => onApplyClicked()}
                            disabled={disabled}
                            >
                              {disabled ? "Applied" : percentage === '100%' ? (
                                  <><p>1 Click Apply</p><ZapIcon iconClass="zap-icon" /></>
                              ) : (
                                "Apply"
                              )}
                          </button>
                        </Grid>
                      </Grid>
                    </div>
                  )}
                </div>
              </div>
            )}
            {isLoading && (
              <div>
                <Skeleton
                  width="100%"
                  height="20rem"
                  className="job-image"
                ></Skeleton>
                <div className="job-details skeleton-details">
                  <Skeleton
                    variant="rect"
                    width="100%"
                    height="3.5rem"
                    className="job-section"
                  ></Skeleton>
                  <Skeleton
                    variant="rect"
                    height="2.5rem"
                    width="100%"
                    className="job-section"
                  ></Skeleton>
                  <Skeleton
                    variant="rect"
                    height="3rem"
                    width="100%"
                    className="job-section"
                  ></Skeleton>
                  <Skeleton
                    variant="rect"
                    height="13rem"
                    width="100%"
                    className="job-section"
                  ></Skeleton>
                </div>
              </div>
            )}
          </Grid>
          <Hidden mdDown>
            <Grid item xs={12} sm={12} md={12} lg={3} className="info-container">
              <div className="info-container">
                <div className="info">
                  <div className="sub-info">
                    <Reward />
                  </div>
                  <div className="sub-info">
                    <Refer />
                  </div>
                </div>
                <div className="info">
                  <Resources />
                </div>
              </div>
            </Grid>
          </Hidden>
          <Hidden lgUp>
            <Grid item xs={12} sm={12}>
              <div className="info-container">
                <div className="info top-info">
                  <div className="sub-info">
                    <Reward />
                  </div>
                  <div className="sub-info">
                    <Refer />
                  </div>
                </div>
                <div className="info">
                  <Resources />
                </div>
              </div>
            </Grid>
          </Hidden>
        </Grid>
      </NavLayout>
      <ContactModal
          open={modalOpen}
          handleClose={closeModal}
          selectedJob={jobDetails?.jobTitle}
      />
      {showApply && (
        <Apply
          open={showApply}
          handleClose={() => setShowApply(false)}
          jobDetails={jobDetails}
          jobIds={[jobDetails?.jobId]}
        />
      )}
      {openShare && (
        <Share openShare={openShare} closeShare={() => setOpenShare(false)} path={`/job/${id}`}/>
      )}
    </div>
  );
};

export default JobDetails;
