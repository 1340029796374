import React from "react";

import "./MobileDownload.scss";

const MobileDownload = () => {
  const cloudFrontBaseURL = "https://ik.imagekit.io/yksbahzmx/connect/";
  const qrCodeImage = cloudFrontBaseURL + "tr:w-225,h-225/" + "images/mobile-qrcode-small.png";
  const appStoreImage = cloudFrontBaseURL + "tr:w-160,h-40/" + "images/appstore-download-button-small.png";
  const googlePlaystoreImage = cloudFrontBaseURL + "tr:w-160,h-40/" + "images/googleplay-download-button-small.png";
  const appStoreUrl = 'https://apps.apple.com/us/app/advantis-connect/id1632057296';
  const googlePlayStoreUrl = 'https://play.google.com/store/apps/details?id=com.advantismed.connect';

  return (
    <div className="mobile-download-container">
      <div className="mobile-download-header-container">Download our Mobile App</div>
      <div className="mobile-download-qrcode-container">
        <img src={qrCodeImage} />
      </div>
      <div className="mobile-download-button-container">
        <a href={appStoreUrl} target="_blank">
          <img src={appStoreImage} />
        </a>
      </div>
      <div className="mobile-download-button-container">
        <a href={googlePlayStoreUrl} target="_blank">
          <img src={googlePlaystoreImage} />
        </a>
      </div>
    </div>
  );
};

export default MobileDownload;
