import React from "react";
import { ReactComponent as ProfileLogo } from "./user.svg";
import SvgIcon from "@material-ui/core/SvgIcon";

import "../Icons.scss";

const ProfileIcon = (props) => {
  const { iconClass, active } = props;
  return (
    <ProfileLogo
      className={iconClass}
    />
  );
};

export default ProfileIcon;
