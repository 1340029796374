import React, { useState } from "react";
import { useSelector, useDispatch} from "react-redux";

import DetailCard from "../../../common/DetailCard/DetailCard";
import ConfirmationModal from "../../../common/ConfirmationModal/ConfirmationModal";
import { deleteData } from "../../../../actions/candidateEntry";

import { EditIcon, TrashIcon } from "../../Icons";
import { Grid } from "@material-ui/core";

import "./References.scss";

const ReferenceDetails = ({ handleClick }) => {
  const dispatch = useDispatch();
  const { candidateData } = useSelector((state) => state);

  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const [isDeleting, setDeleting] = useState(false);
  const [referenceId, setReferenceId] = useState();

  const { license } = candidateData;

  const handleDeleteClick = async (id) => {
    setConfirmationOpen(true);
    setReferenceId(id);
  };

  const confirmDeletion = async () => {
    setDeleting(true);
    try {
      await dispatch(deleteData('reference', referenceId));
    } catch (err) {
      console.log(err);
    }
  };

  const { references: referenceList } = candidateData;

  return (
    <>
      <div data-testid="reference-details">
        {referenceList && (
          <DetailCard title="References" handleClick={handleClick}>
            {referenceList.map((reference, index) => (
              <div className="details-card" key={index}>
                <Grid container>
                  <Grid item className="license-details" xs={9} sm={9} md={9} lg={9}>
                    <div className="details">
                      <h3 className="text-lg font-medium text-gray-900 truncate">{`${reference.firstName} ${reference.lastName}`}</h3>
                      <p className="text-sm font-smalltext-gray-900">Job Title: {reference.jobTitle}</p>
                      <p className="text-sm font-smalltext-gray-900">
                        Preferred Contact Method: {reference.preferredContactMethod}
                      </p>
                      <p className="text-sm font-smalltext-gray-900">Email: {reference.email}</p>
                      <p className="text-sm font-smalltext-gray-900">Mobile: {reference.phone}</p>
                      <p className="text-sm font-smalltext-gray-900">Location: {reference.companyName}</p>
                      <p className="text-sm font-smalltext-gray-900">Nurse/Patient Ratio: {reference.ratio}</p>
                    </div>
                  </Grid>
                  <Grid item className="main-content buttons" xs={3} sm={3} md={3} lg={3}>
                    <div onClick={handleClick} data-testid="editClick" className="icon-button edit">
                      <EditIcon iconClass="blue-icon" />
                    </div>
                    <div onClick={() => handleDeleteClick(reference.id)} data-testid="deleteClick" className="icon-button">
                      <TrashIcon className="delete-icon" />
                    </div>
                  </Grid>
                </Grid>
              </div>
            ))}
          </DetailCard>
        )}
      </div>
      {confirmationOpen && (
        <ConfirmationModal
          open={confirmationOpen}
          confirm={() => confirmDeletion()}
          handleClose={() => setConfirmationOpen(false)}
          loading={isDeleting}
          message="Are you sure you want to delete this Reference?"
          cancelButtonText="Cancel"
        />
      )}
    </>
  );
};

export default ReferenceDetails;
