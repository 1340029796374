import React, { useState } from "react";
import { useSelector, useDispatch} from "react-redux";

import DateFnsUtils from "@date-io/date-fns";

import DetailCard from "../../../common/DetailCard/DetailCard";
import ConfirmationModal from "../../../common/ConfirmationModal/ConfirmationModal";
import { deleteData } from "../../../../actions/candidateEntry";

import { EditIcon, TrashIcon } from "../../Icons";
import { Grid } from "@material-ui/core";

import "./Education.scss";

const dateFns = new DateFnsUtils();

const EducationDetails = ({ handleClick }) => {
  const dispatch = useDispatch();
  const { candidateData } = useSelector((state) => state);
  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const [isDeleting, setDeleting] = useState(false);
  const [educationId, setEducationId] = useState();

  const handleDeleteClick = async (id) => {
    setConfirmationOpen(true);
    setEducationId(id);
  };

  const confirmDeletion = async () => {
    setDeleting(true);
    try {
      await dispatch(deleteData('education', educationId));
    } catch (err) {
      console.log(err);
    }
  };

  const { education: educationList } = candidateData;
  return (
    <>
      <div data-testid="education-details">
        {educationList && (
          <DetailCard title="Education" handleClick={handleClick}>
            {educationList.map((education, index) => (
              <div className="details-card" key={index}>
                <Grid container>
                  <Grid item className="work-details" xs={9} sm={9} md={9} lg={9}>
                    <div className="details">
                        <h3 className=" school text-lg font-medium text-gray-900 truncate">{education.school && education.school}</h3>
                        <p className="text-sm font-small text-gray-900">{education.state && education.state}</p>
                        <p className="content-tag"></p>
                        {education.graduationDate && (
                          <p className="text-sm font-small text-gray-900 truncate">
                            {" "}
                            {dateFns.format(
                              new Date(education.graduationDate),
                              "MMMM yyyy"
                            )}
                          </p>
                        )}
                        <p>{education.degree}</p>
                    </div>
                  </Grid>
                  <Grid item className="main-content buttons" xs={3} sm={3} md={3} lg={3}>
                    <div onClick={handleClick} data-testid="editClick" className="icon-button edit">
                      <EditIcon iconClass="blue-icon" />
                    </div>
                    <div onClick={() => handleDeleteClick(education.id)} data-testid="deleteClick" className="icon-button">
                      <TrashIcon className="delete-icon" />
                    </div>
                  </Grid>
                </Grid>
              </div>
            ))}
          </DetailCard>
        )}
      </div>
      {confirmationOpen && (
        <ConfirmationModal
          open={confirmationOpen}
          confirm={() => confirmDeletion()}
          handleClose={() => setConfirmationOpen(false)}
          loading={isDeleting}
          message="Are you sure you want to delete this Education?"
          cancelButtonText="Cancel"
        />
      )}
    </>
  );
};

export default EducationDetails;
