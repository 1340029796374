import React from "react";
import { Modal } from "@material-ui/core";
import {
  EmailIcon,
  ChatIcon,
  FacebookIcon,
  LinkedinIcon
} from "../../common/Icons";

import "./ReferModal.scss";

const ReferModal = ({ open, handleClose }) => {
  const logo = "https://d2wqn2ab54ir3q.cloudfront.net/images/refer.png";
  return (
    <Modal
      open={open}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      className="modal"
    >
      <div className="modal-content refer">
        <div className="modal-header">
          <div className="close-btn" onClick={handleClose}>
            X
          </div>
        </div>
        <div className="modal-message">
          <h1 data-testid="refer-modal-header">Refer a Friend</h1>
          <p>
            When a friend uses your code you will earn <span>$500</span> as soon
            as they verify their identity.
          </p>
        </div>

        <div className="modal-img">
          <img src={logo}></img>
        </div>

        <div className="refer-options">
          <a data-testid="text-link" href="#">
            <div className="refer-option">
              <ChatIcon iconClass="nav-header-icon" />
            </div>
          </a>
          <a data-testid="email-link" href="mailto:">
            <div className="refer-option">
              <EmailIcon iconClass="nav-header-icon" />
            </div>
          </a>
          <a data-testid="fb-link" href="tel:+19725460528">
            <div className="refer-option">
              <FacebookIcon iconClass="nav-header-icon" />
            </div>
          </a>
          <a data-testid="linkedin-link" href="#">
            <div className="refer-option">
              <LinkedinIcon iconClass="nav-header-icon" />
            </div>
          </a>
        </div>
      </div>
    </Modal>
  );
};

export default ReferModal;
