import React from "react";
import { ReactComponent as Logo } from "./copy.svg";
import SvgIcon from "@material-ui/core/SvgIcon";

import "../Icons.scss";

const CopyIcon = ({ iconClass }) => (
  <SvgIcon className={iconClass} component={Logo} />
);

export default CopyIcon;
