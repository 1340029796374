import React from "react";
import { ReactComponent as Logo } from "./document.svg";
import SvgIcon from "@material-ui/core/SvgIcon";

import "../Icons.scss";

const DocumentIcon = ({ iconClass }) => (
  <SvgIcon className={iconClass} component={Logo} viewBox="0 0 368 368" />
);

export default DocumentIcon;
