import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import DateFnsUtils from "@date-io/date-fns";

import DetailCard from "../../../common/DetailCard/DetailCard";
import ConfirmationModal from "../../../common/ConfirmationModal/ConfirmationModal";
import { deleteData } from "../../../../actions/candidateEntry";
import { getCandidateFile } from "../../../../services/candidateData";
import PrimaryButton from "../../../common/Buttons/PrimaryButton";

import { EditIcon, TrashIcon } from "../../Icons";
import { Grid } from "@material-ui/core";

const dateFns = new DateFnsUtils();

const MedicalCertificationDetails = ({ handleClick }) => {
  const dispatch = useDispatch();
  const { candidateData } = useSelector((state) => state);
  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const [medicalDocumentsList, setMedicalDocumentList] = useState([]);
  const [personalDocumentsList, setPersonalDocumentList] = useState([]);
  const [isDeleting, setDeleting] = useState(false);
  const [certId, setCertId] = useState();
  const { certifications: certificationList } = candidateData;
  const medicalCertificationList = [
        {id: 100423, label :"MMR – 2 vaccines or positive titer"},
        {id: 100467, label :"Varicella – 2 vaccines or positive titer "},
        {id: 100419, label :"Hep B documentation. – 3 vaccines or positive titer"},
        {id: 100463, label :"TDAP – within the last 10 years"},
        {id: 100417, label :"Flu – 2021-2022 season"},
        {id: 100461, label :"TB – negative PPD or IGRA within the last year"},
        {id: null, label :"COVID vaccines or supporting exemption documentation"}
    ];

  const covidCertifications = [
      {id: 100409, label :"COVID vaccines or supporting exemption documentation"},
      {id: 100410, label :"COVID vaccines or supporting exemption documentation"}
  ];

  const coreExamCertifications = [
    {id: 100405, label: "Core Mandatory 1-3 Exams"},
    {id: 100406, label: "Core Mandatory 1-3 Exams"},
    {id: 100407, label: "Core Mandatory 1-3 Exams"}
  ];

  const personalCertificationList = [
      {id: 100412, label: "Drivers License"},
      {id: null, label:  "Copy of your certifications (BLS, ACLS, PALS, NIHSS, TNCC, etc…)"},
      {id: 100437,  label: "Relias  – sent via Relias"},
      {id: 100443, label: "Skills Checklist"},
      {id: null, label: "Core Mandatory 1-3 Exams"},
      {id: 100466, label: "Specialty Unit Exam"},
  ];

  const currentCertificationList = [
      {"id":100390,"label":"ACLS"},
      {"id":100397,"label":"BLS"},
      {"id":100430,"label":"PALS"},
      {"id":100426,"label":"NIHSS"},
      {"id":100465,"label":"TNCC"}
  ];

  const handleDeleteClick = async (id) => {
    setConfirmationOpen(true);
    setCertId(id);
  };

  const confirmDeletion = async () => {
    setDeleting(true);
    try {
      await dispatch(deleteData('certification', certId));
    } catch (err) {
      console.log(err);
    }
  };

    const setPersonalList = (currentList) => {
        let labelList = personalCertificationList;

        if (currentList && currentList.length) {
            for (let j = 0; j < personalCertificationList.length; j++) {
                for (let i = 0; i < currentList.length; i++) {
                    if (currentList[i].certificationId === personalCertificationList[j].id) {
                        labelList[j] = ({
                            label: personalCertificationList[j].label,
                            certificationId: personalCertificationList[j].id,
                            id: currentList[i].id,
                            expirationDate: currentList[i].expirationDate,
                            fileName: currentList[i].fileName,
                            fileBase64Str: currentList[i].fileBase64Str,
                            createdDate: currentList[i].createdDate
                        });
                    } else if (
                      j === 1 && isCertificationList(currentList[i].certificationId) ||
                      j === 4 && isCoreExamCertificationList(currentList[i].certificationId)
                    ) {
                        labelList[j] = ({
                            label: personalCertificationList[j].label,
                            certificationId: currentList[i].certificationId,
                            id: currentList[i].id,
                            expirationDate: currentList[i].expirationDate,
                            fileName: currentList[i].fileName,
                            fileBase64Str: currentList[i].fileBase64Str,
                            createdDate: currentList[i].createdDate
                        });
                    } 
                }
            }
        }

      return labelList;
    }

    const isCovidCertification = (id) => {
        return covidCertifications.some(cert => cert.id === id);
    }

    const isCertificationList = (id) => {
        return currentCertificationList.some(cert => cert.id === id);
    }

    const isCoreExamCertificationList = (id) => {
      return coreExamCertifications.some(cert => cert.id === id);
  }

    const setMedicalList = (currentList) => {
        let labelList = medicalCertificationList;

        if (currentList && currentList.length) {
            for (let j = 0; j < medicalCertificationList.length; j++) {
                for (let i = 0; i < currentList.length; i++) {
                    if (currentList[i].certificationId === medicalCertificationList[j].id) {
                        labelList[j] = ({
                            label: medicalCertificationList[j].label,
                            certificationId: medicalCertificationList[j].id,
                            id: currentList[i].id,
                            expirationDate: currentList[i].expirationDate,
                            fileName: currentList[i].fileName,
                            fileBase64Str: currentList[i].fileBase64Str,
                            createdDate: currentList[i].createdDate
                        });
                    } else if(j === 6 && isCovidCertification(currentList[i].certificationId)) {
                        labelList[j] = ({
                            label: medicalCertificationList[j].label,
                            certificationId: currentList[i].certificationId,
                            id: currentList[i].id,
                            expirationDate: currentList[i].expirationDate,
                            fileName: currentList[i].fileName,
                            fileBase64Str: currentList[i].fileBase64Str,
                            createdDate: currentList[i].createdDate
                        });
                    }
                }
            }
        }

      return labelList;
    }

    useEffect(() => {
    let mounted = true;
        if (mounted) {
            setMedicalDocumentList(setMedicalList(certificationList));
            setPersonalDocumentList(setPersonalList(certificationList));
        }
    return () => (mounted = false);
    }, [certificationList]);

  return (

    <>
      <div data-testid="certification-details" className="medical-certification-container">
        {medicalDocumentsList && medicalDocumentsList.length && (
          <DetailCard title="Medical Documents" handleClick={handleClick}>
            {medicalDocumentsList.map((certification, index) => (
              <div className="details-card medical-certifications" key={index}>
                <Grid container>
                  <Grid item className="work-details" xs={12} sm={7} md={8} lg={8}>
                  <div className="details text-sm font-smalltext-gray-900 truncate">
                    <p>{certification.label}</p>
                    {certification.expirationDate ? 
                    <div className="certificate-data" key={index}>
                      <p> 
                        {"Last Update: " + dateFns.format(new Date(certification.createdDate), "MM-dd-yy")}
                      </p>                  
                    </div> 
                     : 
                    <div className="not-uploaded">Not Uploaded</div>
                    }
                  </div>
                  </Grid>
                  <Grid item className="main-content buttons medical-cert" xs={12} sm={5} md={4} lg={4}>
                  {certification.expirationDate ?
                    <>
                        <div onClick={() => handleDeleteClick(certification.id)} data-testid="deleteClick" className="text-button delete">
                            Delete
                        </div>
                        <div onClick={handleClick} data-testid="editClick" className="text-button edit">
                            Upload Again
                        </div>
                    </>
                    :        
                    <button
                        className="save-button"
                        onClick={handleClick}
                        type="button"
                    >
                        Upload
                    </button>
                  }
                  </Grid>
                </Grid>
              </div>
            ))}
          </DetailCard>
        )}
        {personalDocumentsList && personalDocumentsList.length && (
          <DetailCard title="Written Documents " handleClick={handleClick}>
            {personalDocumentsList.map((certification, index) => (
              <div className="details-card medical-certifications" key={index}>
                <Grid container>
                  <Grid item className="work-details" xs={12} sm={7} md={8} lg={8}>
                  <div className="details text-sm font-smalltext-gray-900 truncate">
                    <p>{certification.label}</p>
                    {certification.expirationDate ? 
                    <div className="certificate-data" key={index}>
                      <p> 
                        {"Last Update: " + dateFns.format(new Date(certification.createdDate), "MM-dd-yy")}
                      </p>                  
                    </div> 
                     : 
                    <div className="not-uploaded">Not Uploaded</div>
                    }
                  </div>
                  </Grid>
                  <Grid item className="main-content buttons medical-cert" xs={12} sm={5} md={4} lg={4}>
                  {certification.expirationDate ?
                    <>
                        <div onClick={() => handleDeleteClick(certification.id)} data-testid="deleteClick" className="text-button delete">
                            Delete
                        </div>
                        <div onClick={handleClick} data-testid="editClick" className="text-button edit">
                            Upload Again
                        </div>
                    </>
                    :        
                    <button
                        className="save-button"
                        onClick={handleClick}
                        type="button"
                    >
                        Upload
                    </button>
                  }
                  </Grid>
                </Grid>
              </div>
            ))}
          </DetailCard>
        )}
      </div>
      {confirmationOpen && (
        <ConfirmationModal
          open={confirmationOpen}
          confirm={() => confirmDeletion()}
          handleClose={() => setConfirmationOpen(false)}
          loading={isDeleting}
          message="Are you sure you want to delete this certification?"
          cancelButtonText="Cancel"
        />
      )}
    </>
  );
};

export default MedicalCertificationDetails;
