import React, { useEffect, useState } from "react";

import {
    Grid,
    TextField,
    Box,
    Container,
    FormControlLabel,
    FormLabel,
    Radio,
    RadioGroup,
    FormHelperText
  } from "@material-ui/core";

import Select from "react-select";
import PrimaryButton from "../../../common/Buttons/PrimaryButton";

const PrefandInterests = (props) => {
    const { topLocations, firstTime, nextAdventure, interests } = props.data;
    const firstname = props.firstname;
    const { formErrors, clearError, prevStep, handleSubmit, handleChange, loading, states } = props;

    const changeLocations = (value) => {
        if(value.length < 4) {
            handleChange("prefandinterests", "topLocations", value);
        }
    };

    const getOptionValue = (option) => option;
    const getOptionLabel = (option) => option;

    return (
        <Container maxWidth="sm">
            <Box width={1} display="flex" alignItems="center" flexDirection="column" alignItems="flex-start" className="signup-basic">
                <h1 className="text-lg leading-6 font-medium text-gray-900">{firstname}, we want to find you the perfect match, so we want to know more about you and what you are looking for</h1>
            </Box>

            <Box width={1} display="flex" mb={3}>
                <Grid container spacing={1} alignItems="flex-end">
                    <Grid item xs={12}>
                    {states && (
                        <>
                        <FormLabel component="legend" className="mt-4 mb-1">What are your top 3 preferred locations?</FormLabel>
                            <Select
                                data-testid="locationPreference"
                                id="topLocations"
                                name="topLocations"
                                options={states}
                                isSearchable={false}
                                isMulti
                                value={topLocations}
                                getOptionValue={getOptionValue}
                                getOptionLabel={getOptionLabel}
                                onChange={changeLocations}
                                placeholder="Select States"
                            />
                        </>
                    )}
                    </Grid>
                </Grid>
            </Box>

            <Box width={1} display="flex" mb={3}>
                <Grid container spacing={1}>
                    <Grid item>
                        <FormLabel component="legend">Is this your first time travelling?</FormLabel>
                        <RadioGroup aria-label="firstime" name="firstime1">
                            <FormControlLabel value="yes" checked={firstTime == "yes"} onClick={(e) => handleChange("prefandinterests", "firstTime", e.target.value)} control={<Radio color="primary" />} label="Yes" />
                            <FormControlLabel value="no" checked={firstTime == "no"} onClick={(e) => handleChange("prefandinterests", "firstTime", e.target.value)} control={<Radio color="primary" />} label="No" />
                        </RadioGroup>
                        <FormHelperText>
                          {formErrors?.firstTime || ""}
                        </FormHelperText>
                    </Grid>
                </Grid>
            </Box>

            <Box width={1} display="flex" mb={3}>
                <Grid
                container
                spacing={1}
                alignItems="flex-end"
                className="input-field"
                >
                    <Grid item xs={12}>
                        <FormLabel component="legend" className="mb-1">Tell us what's important to you for your next adventure:</FormLabel>
                        <TextField
                            multiline
                            rows={3}
                            variant="outlined"
                            id="nextAdventure"
                            className="input-field"
                            name="nextAdventure"
                            placeholder="Type Here"
                            value={nextAdventure}
                            error={formErrors && formErrors.nextAdventure !== undefined}
                            helperText={
                                formErrors.nextAdventure ? formErrors.nextAdventure : ""
                            }
                            onChange={(e) =>
                                handleChange("prefandinterests", e.target.id, e.target.value)
                            }
                            onFocus={(e) => clearError(e.target.id)}
                            fullWidth
                            inputProps={{maxLength: 50}}
                        />
                    </Grid>
                </Grid>
            </Box>

            <Box width={1} display="flex" mb={3}>
                <Grid
                container
                spacing={1}
                alignItems="flex-end"
                className="input-field"
                >
                    <Grid item xs={12}>
                        <FormLabel component="legend" className="mb-1">This part is completely optional but what interests do you have?</FormLabel>
                        <TextField
                            multiline
                            rows={3}
                            variant="outlined"
                            id="interests"
                            className="input-field"
                            name="interests"
                            placeholder="Type Here"
                            value={interests}
                            error={formErrors && formErrors.interests !== undefined}
                            onChange={(e) =>
                                handleChange("prefandinterests", e.target.id, e.target.value)
                            }
                            onFocus={(e) => clearError(e.target.id)}
                            fullWidth
                            inputProps={{maxLength: 50}}
                        />
                    </Grid>
                </Grid>
            </Box>

            <Box width={1} display="flex" justifyContent="flex-end">
                <Grid container spacing={1} alignItems="flex-end">
                    <Grid item xs={4}>
                        <PrimaryButton type="button" text="Back" buttonClass="color-button previous-step" handleClick={prevStep} />
                    </Grid>
                    <Grid item xs={4}>
                        <PrimaryButton type="button" text="Next" loading={loading} buttonClass="color-button" handleClick={handleSubmit} />
                    </Grid>
                </Grid>
            </Box>
        </Container>
    )
}

export default PrefandInterests;
