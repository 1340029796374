import React from "react";

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText
} from "@material-ui/core";
import CloseButton from "../common/Buttons/CloseButton";
import PrimaryButton from "../common/Buttons/PrimaryButton";
import AuthService from "../../services/auth.service";

import "./ConfirmLogout.scss";

const ConfirmLogout = ({ open, close }) => {
  return (
    <Dialog disablePortal open={open} onClose={close}>
      <DialogTitle id="alert-dialog-title" className="text-lg leading-6 font-medium text-gray-900">
        Log out
        <CloseButton handleClick={close} />
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description" className="pb-4">
          You are about to log out. Do you wish to continue?
        </DialogContentText>
        <div className="dialog-buttons">
          <PrimaryButton
            buttonClass="cancel-button"
            text="Cancel"
            handleClick={close}
          />
          <PrimaryButton
            text="Log out"
            buttonClass="color-button logout-button"
            handleClick={() => {
              AuthService.logout();
            }}
          />
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default ConfirmLogout;
