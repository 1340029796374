import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Form from "react-validation/build/form";
import isEmail from "validator/lib/isEmail";
import isMobilePhone from "validator/lib/isMobilePhone";

import ReactPixel from 'react-facebook-pixel';
import TopLogoLayout from "../../TopLogoLayout/TopLogoLayout";
import About from "./About/About";
import Account from "./Account/Account";
import WorkExperience from "./WorkExperience/WorkExperience";
import PrefandInterests from "./PrefandInterests/PrefandInterests";

import { getSpecialties, getStates } from "../../../services/datalist";
import { register } from "../../../actions/auth";
import userService from "../../../services/user.service";
import { clearMessage } from "../../../actions/message";
import InfoCarousel from "../InfoCarousel/InfoCarousel";
import { v4 as uuidv4 } from 'uuid';
import { CircularProgress } from "@material-ui/core";

import "./Signup.scss";

const Signup = (props) => {
  const socialSignup = localStorage.getItem("isSocialSignup");
  const [step, setStep] = useState(socialSignup && JSON.parse(socialSignup).isSocialSignup ? 2 : 1);
  const [loading, setLoading] = useState(false);
  const referUrl = props.location.search;
  const referralCode = (new URLSearchParams(referUrl).get('Referral') == null ? '' : new URLSearchParams(referUrl).get('Referral'));
  const registrationId = (new URLSearchParams(referUrl).get('registrationId') == null ? '' : new URLSearchParams(referUrl).get('registrationId'));
  const [candLoading, setCandLoading] = useState(false);
  const [formData, setFormData] = useState({
    about: {
      firstname: "",
      lastname: "",
      phone: "",
      contactMethod: "Text",
      location: ""
    },
    account: {
      email: "",
      password: "",
      passwordConfirm: "",
      referral: referralCode
    },
    work: {
      primarySpecialty: "",
      yearsExperience: "",
      specialtyList: [],
      isCompactLicense: false,
      licenseStateList: []
    },
    prefandinterests: {
      topLocations: [],
      firstTime: "",
      nextAdventure: "",
      interests: ""
    }
  });
  const [specialties, setSpecialties] = useState([]);
  const [states, setStates] = useState([]);

  const [formErrors, setErrors] = useState({});
  const { message } = useSelector((state) => state.message);

  //display error message
  useEffect(() => {
    message && setLoading(false);
    if (message?.includes("Email is already in use")) {
      setErrors({ email: "Email is already in use." });
    }
    if (message?.includes("User already exists")) {
      setErrors({ username: "Username already in use." });
    }
    if (message?.includes("Username cannot be of email format")){
      setErrors({username: "Username cannot be an email address"})
    }
  }, [message]);

  //get Specialties List
  useEffect(() => {
    let mounted = true;
    getSpecialties()
      .then((data) => {
        if (mounted) {
          setSpecialties(data);
        }
      })
      .catch((err) => {
        if (mounted) {
          console.log(err);
        }
      });
    return () => (mounted = false);
  }, []);

  //get State List
  useEffect(() => {
    let mounted = true;
    getStates()
      .then((data) => {
        if (mounted) {
          setStates(data.map((state) => state.name));
        }
      })
      .catch((err) => {
        if (mounted) {
          console.log(err);
        }
      });
    return () => (mounted = false);
  }, []);

  useEffect(() => {
    if(registrationId != '') {
      getUserData(registrationId);
    } else {
      getCachedStepData();
    }
  }, [registrationId]);

  const dispatch = useDispatch();

  const handleChange = (step, field, value) => {
    setValue(step, field, value);
  };

  const setValue = (step, type, value) => {
    const newState = { ...formData };
    newState[step][type] = value;
    setFormData(newState);
  };

  const nextStep = async() => {
    const validStep = await validateStep();
    if (validStep) {
      cacheStep();
      if (step === 1) {
        localStorage.removeItem("isSocialSignup");
      }
      setStep(step + 1);
    }
  };

  const prevStep = () => {
    setStep(step - 1);
  };

  const validateEmail = async(value) => {
    let errors = {};
    await validateField('email', value, errors);

    if (Object.keys(errors).length > 0) {
      setErrors(errors);
    }
  };

  const getGeneratedUsername = () => {
    return `internal_${uuidv4()}`;
  }

  const handleSubmit = async () => {
    dispatch(clearMessage());
    const isValid = await validateStep();
    if (isValid) {
      let user = {};
      let isSocialSignup = socialSignup && JSON.parse(socialSignup).isSocialSignup;
      let email = null;
      let password = null;

      if (isSocialSignup) {
        user = JSON.parse(localStorage.getItem("user"));
        email = user ? user.emailAddress : null;
      } else {
       email = formData.account.email;
       password = formData.account.password;
      }

      const { firstname, lastname, phone, contactMethod, location } = formData.about;
      const { topLocations, firstTime, nextAdventure, interests } = formData.prefandinterests;
      const { yearsExperience, specialtyList, licenseStateList, isCompactLicense } = formData.work;

      let city = null;
      let state = null;
      let zip = null;

      if(location.indexOf(',') > -1 ) {
        const locationSplit = location.split(',');

        city = locationSplit[0].trim();
        state = locationSplit[1].trim();
      } else {
        zip = location;
      }

      let firstTimeBool = (firstTime == 'yes') ? false : true;
      let username = user?.userName ? user.userName : getGeneratedUsername();
      let refCode = formData.account.referral != '' ? parseInt(formData.account.referral) : 0;

      setLoading(true);
      await dispatch(
        register({
          email,
          username,
          password,
          firstname,
          lastname,
          phone,
          contactMethod,
          city,
          state,
          zip,
          yearsExperience,
          specialtyList,
          topLocations,
          firstTimeBool,
          nextAdventure,
          interests,
          isSocialSignup,
          refCode,
          licenseStateList,
          isCompactLicense
        })
      );
      clearCachedStepData();
      setLoading(false);
      ReactPixel.trackCustom('AmedFinishSignup');
    }
  };

  const validateStep = async() => {
    setErrors({});
    let errors = {};
    let isValid = true;
    const { account, about, work, prefandinterests } = formData;
    let stepData = {};

    switch (step) {
      case 1:
        stepData = account;
        break;
      case 2:
        ReactPixel.trackCustom('AmedStartSignup');
        stepData = about;
        break;
      case 3:
        stepData = work;
        break;
      case 4:
        stepData = prefandinterests;
        break;
      default:
        stepData = account;
        break;
    }

    for (let key in stepData) {
      await validateField(key, stepData[key], errors);
    }

    if (Object.keys(errors).length > 0) {
      setErrors(errors);
      isValid = false;
    }
    return isValid;
  };

  const cacheStep = async() => {
    const { account, about, work, prefandinterests } = formData;
    let stepData = null;

    switch (step) {
      case 1:
        stepData = JSON.parse(JSON.stringify(account));
        stepData.password = "";
        stepData.passwordConfirm = "";
        break;
      case 2:
        stepData = about;
        break;
      case 3:
        stepData = work;
        break;
      case 4:
        stepData = prefandinterests;
        break;
    }

    if (stepData) {
      localStorage.setItem(`signupStep${step}Data`, JSON.stringify(stepData));
    }
  };

  const passwordMatch = () => {
    if(formData.account.password == formData.account.passwordConfirm) {
      return true;
    } else {
      return false;
    }
  }

  const isValidCityState = (value) => {
    return /^[a-zA-Z\s]+,[ ]?[A-Za-z]+$/.test(value);
 }

  const isValidZipCode = (value) => {
    return /^\d{5}$/.test(value);
 }

  const validateField = async(type, value, errors) => {
    if (!value && type != "interests" && type != "referral" && type != "isCompactLicense") {
      errors[type] = "required";
      return;
    }

    switch (type) {
      case "email":
        const validEmail = isEmail(value);
        if (!validEmail) {
          errors["email"] = "Please enter a valid email";
        } else {
          const userExists = await userService.checkExists(value);
          if (userExists) {
            errors["email"] = "Email already in use";
          }
        }

        break;
      case "phone":
        const validPhone = isMobilePhone(value);
        if (!validPhone) errors["phone"] = "Please enter a valid phone number";
        break;
      case "password":
        let containsUpperCase = new RegExp(/([A-Z])+/g);
        let containsLowerCase = new RegExp(/([a-z])+/g);
        let containsNumber = new RegExp(/([0-9])+/g);
        let containsSpecial = new RegExp(/([^\w\s\d])+/g);
        let passwordErrors = [];
        if (!containsUpperCase.test(formData.account.password)) {
          passwordErrors.push("Must contain at least 1 uppercase letter.");
        }
        if (!containsLowerCase.test(formData.account.password)) {
          passwordErrors.push("Must contain at least 1 lowercase letter.");
        }
        if (!containsNumber.test(formData.account.password)) {
          passwordErrors.push("Must contain at least 1 number.");
        }
        if (!containsSpecial.test(formData.account.password)) {
          passwordErrors.push("Must contain at least 1 special character.");
        }
        if (formData.account.password.length < 8) {
          passwordErrors.push("Must be at least 8 characters long.");
        }
        if (passwordErrors.length > 0) {
          errors["password"] = passwordErrors.join(" ");
        }
        break;
      case "username":
        if (formData.account.username.length < 6) {
          errors["username"] = "Must be at least 6 characters long.";
        }
        if (formData.account.username.length > 128) {
          errors["username"] = "Cannot be greater than 128 characters long.";
        }
        let containsWhitespace = new RegExp(/(\s)+/g);
        if (containsWhitespace.test(formData.account.username)) {
          errors["username"] = "Must not contain any whitespace.";
        }
        break;
      case "passwordConfirm":
        const validPassword = passwordMatch();
        if (!validPassword) errors["password"] = "Passwords do not match";
        break;
      case "location":
        if(!isValidCityState(value) && !isValidZipCode(value)) {
          errors["location"] = "Please enter a valid city, state or 5 digit zipcode";
        }
        break;
      case "specialtyList":
        if (value && value.length === 0) {
          errors[type] = "required";
        }
      case "licenseStateList":
        if (value && value.length === 0) {
          errors[type] = "required";
        }
      default:
    }
  };

  const clearError = (type) => {
    const errors = { ...formErrors };
    delete errors[type];
    setErrors(errors);
  };

  const getUserData = async (id) => {
    setCandLoading(true);
    const preRegData = await userService.getCandidatePreRegistration(id);

    if(preRegData) {
      formData.about.firstname = preRegData.firstName;
      formData.about.lastname = preRegData.lastName;
      formData.about.phone = preRegData.phone;
      formData.about.contactMethod = preRegData.preferredContactMethod;

      if(preRegData.zipCode) {
        formData.about.location = preRegData.zipCode;
      } else {
        formData.about.location = preRegData.city + ', ' + preRegData.state;
      }

      formData.account.email = preRegData.email;

      formData.work.primarySpecialty = preRegData.primarySpecialty;
      formData.work.yearsExperience = preRegData.yearsExperience ? preRegData.yearsExperience.toString() : '';
      formData.work.specialtyList = preRegData.specialtyList;

      formData.prefandinterests.topLocations = preRegData.topPreferredLocations;
      formData.prefandinterests.firstTime = (preRegData.experienceTravelling == false) ? 'yes' : 'no';
      formData.prefandinterests.nextAdventure = preRegData.interests;
      formData.prefandinterests.interests = preRegData.optionalInterests;

      setFormData(formData);
      localStorage.setItem('candidateRegistrationId', id);
    }

    setCandLoading(false);
  }

  const getCachedStepData = () => {
    let signupStep1Data = JSON.parse(localStorage.getItem(`signupStep1Data`));
    if (signupStep1Data) {
      formData.account = signupStep1Data;
    }

    let signupStep2Data = JSON.parse(localStorage.getItem(`signupStep2Data`));
    if (signupStep2Data) {
      formData.about = signupStep2Data;
    }

    let signupStep3Data = JSON.parse(localStorage.getItem(`signupStep3Data`));
    if (signupStep3Data) {
      formData.work = signupStep3Data;
    }

    let signupStep4Data = JSON.parse(localStorage.getItem(`signupStep4Data`));
    if (signupStep4Data) {
      formData.prefandinterests = signupStep4Data;
    }
  }

  const clearCachedStepData = () => {
    localStorage.removeItem(`signupStep1Data`);
    localStorage.removeItem(`signupStep2Data`);
    localStorage.removeItem(`signupStep3Data`);
    localStorage.removeItem(`signupStep4Data`);
  }

  let workProps = {
    data: formData.work,
    formErrors,
    specialties,
    states,
    handleChange,
    clearError,
    prevStep,
    nextStep,
    firstname: formData.about.firstname
  };

  const aboutProps = {
    data: formData.about,
    formErrors,
    states,
    handleChange,
    clearError,
    prevStep,
    nextStep
  };

  const accountProps = {
    data: formData.account,
    formErrors,
    handleChange,
    clearError,
    nextStep,
    validateEmail,
    loading
  };
  const prefandinterestsProps = {
    data: formData.prefandinterests,
    formErrors,
    states,
    handleChange,
    clearError,
    prevStep,
    handleSubmit,
    firstname: formData.about.firstname,
    loading
  };

  return (
    <div className="signup-container-main">
      <div className="signup-form-container">
        <TopLogoLayout>
          <Form className="signup-form">
            {!candLoading ? (
              <>
                {step === 1 ? <Account {...accountProps} /> : null}
                {step === 2 ? <About {...aboutProps} /> : null}
                {step === 3 ? <WorkExperience {...workProps} /> : null}
                {step === 4 ? <PrefandInterests {...prefandinterestsProps} /> : null}
              </>
            ) : (
              <div style={{height: `80vh`, textAlign: `center`}}>
                <h4 style={{marginBottom: `1em`}}>Loading Registration Data</h4>
                <CircularProgress className="dataLoading" />
              </div>
            )}
          </Form>
        </TopLogoLayout>
      </div>
      <div className="signup-info-container">
        <InfoCarousel/>
      </div>
    </div>
  );
};

export default Signup;
