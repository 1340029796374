import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  Select,
  InputLabel,
  MenuItem,
  FormControl
} from "@material-ui/core";
import { getAssessments } from "../../../../services/skills";

import "./SkillsChecklist.scss";

const SkillsNewChecklistForm = (props) => {
  const history = useHistory();
  const [assessments, setAssessments] = useState([]);
  const [assessmentOptions, setAssessmentOptions] = useState([]);
  const { skills } = useSelector((state) => state.candidateData);

  //get Assessments List
  useEffect(() => {
    let mounted = true;
    getAssessments()
      .then((data) => {
        if (mounted) {
          setAssessments(data);
          filterAssessments(data);
        }
      })
      .catch((err) => console.log(err));

    return () => (mounted = false);
  }, []);

  const filterAssessments = (assessmentList) => {
    const skillIds = skills?.map((skill) => skill.assessmentId);
    const filteredAssessments = assessmentList.filter(
      (assessment) => !skillIds?.includes(assessment.id)
    );
    setAssessmentOptions(filteredAssessments);
  };

  const handleSpecialtySelect = (value) => {
    const selectedAssessment =
      assessments &&
      assessments.find((assessment) => {
        return assessment.name === value;
      });

    if (selectedAssessment) {
      history.push(`/skill/${selectedAssessment.id}`)
    }
  };

  return (
    <div className="skills-container" data-testid="skills">
    <div className="header">
      <h6 className="text-lg leading-6 font-medium text-gray-900">Skills Checklist</h6>
    </div>
    <div className="specialty-selection">
      <FormControl fullWidth variant="outlined">
        <InputLabel id="specialty-label">Add a new skill</InputLabel>
          <Select
            label="Add a new skill"
            id="specialty-select"
            data-testid="specialty"
            onChange={(e, val) => handleSpecialtySelect(e.target.value)}
          >
            <MenuItem value="">
              <em>None</em>
            </MenuItem>
            {assessmentOptions?.map((specialty) => (
            <MenuItem key={specialty.id} value={specialty.name}>
              {specialty.name}
            </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
  </div>
  );
};

export default SkillsNewChecklistForm;
