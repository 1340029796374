import React from "react";
import { ReactComponent as HeartLogo } from "./heart.svg";
import { ReactComponent as HeartFillLogo } from "./heart-fill.svg";
import SvgIcon from "@material-ui/core/SvgIcon";

import "../Icons.scss";

const HeartIcon = (props) => {
  const { iconClass, active } = props;
  return (
    <SvgIcon
      className={iconClass}
      component={active ? HeartFillLogo : HeartLogo}
      viewBox="0 0 512 512"
    />
  );
};

export default HeartIcon;
