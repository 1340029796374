import React, { useState } from "react";

import PrimaryButton from "../../common/Buttons/PrimaryButton";
import PdfModal from "../PdfModal/PdfModal";

import "./Reward.scss";

const Reward = (props) => {
  const [open, setOpen] = useState(false);

  const bannerImage =
    "https://d2wqn2ab54ir3q.cloudfront.net/images/journey.png";

  return (
    <>
      <div className="reward-banner">
        <img src={bannerImage} alt="journey"></img>
        <div className="reward-content">
          <h4>
            Earn up to <span>$2000</span>
          </h4>
          <h4>Through your Advantis Journey</h4>
          <PrimaryButton text="Learn more" buttonClass="small-color-button" handleClick={() => setOpen(true)} />
        </div>
      </div>
      {open && (
        <PdfModal
          pdf="https://d2wqn2ab54ir3q.cloudfront.net/documents/Advantis Advantage Final.pdf"
          open={open}
          close={() => setOpen(false)}
        />
      )}
    </>
  );
};

export default Reward;
