import React from "react";
import { Link } from "react-router-dom";

import "./Buttons.scss";

const LinkButton = (props) => {
  const { url, text, textButton, handleClick, buttonClass } = props;
  let btnClass = "color-button";
  if (buttonClass) btnClass = buttonClass;
  if (textButton) btnClass = "link-button";

  const click = (e) => {
    e.target.blur();
    handleClick && handleClick();
  };

  return (
    <button
      type="button"
      className={btnClass}
      onClick={(e) => click(e)}
    >
      {url && (<Link className="text-link" to={url}>
        <div className="text-sm font-small">{text}</div>
      </Link>)}
      {handleClick && (<span className="text-link">{text}</span>)}
    </button>
  );
};

export default LinkButton;
