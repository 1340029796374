import React, { useState } from "react";
import { TextField, Box } from "@material-ui/core";

import "./ProfessionalSummary.scss";

const ProfessionalSummaryForm = ({ change, values = "" }) => {
  
  const defaultValue =
    "This is your spot to highlight to a hiring manager the depth of your experience, why you're awesome, and identify your strengths, accomplishments and passions.";

  const handleChange = (e) => {
    change(e.target.value, "professionalSummary");
  };

  return (
    <div
      className="professional-summary-edit"
      data-testid="professionalSummary"
    >
      <div className="header">
        <h3 className="text-lg leading-6 font-medium text-gray-900">Professional Summary</h3>
      </div>

      <Box mt={4}>
        <TextField
          id="outlined-multiline-static"
          multiline
          rows={12}
          placeholder={defaultValue}
          value={values}
          variant="outlined"
          onChange={handleChange}
          inputProps={{
            "data-testid": "summary",
            maxLength: 100000
          }}
          fullWidth
        />
      </Box>
    </div>
  );
};

export default ProfessionalSummaryForm;
