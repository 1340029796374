import React, { useState } from "react";

import LinearProgress from "@material-ui/core/LinearProgress";
import {
  Box,
  Typography,
  Button,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  withStyles
} from "@material-ui/core";
import { Delete } from "@material-ui/icons";
import UploadService from "../../../services/uploadfile.service";

import { DocumentIcon } from "../Icons";
import PrimaryButton from "../Buttons/PrimaryButton";
import Dropzone from "../Dropzone/Dropzone";
import "./UploadFile.scss";

const BorderLinearProgress = withStyles((theme) => ({
  root: {
    height: 15,
    borderRadius: 5
  },
  colorPrimary: {
    backgroundColor: "#EEEEEE"
  },
  bar: {
    borderRadius: 5,
    backgroundColor: "#1a90ff"
  }
}))(LinearProgress);

const UploadFile = ({ storeFile, hideUpload, error, possibleFileExtensions, prefix }) => {
  const [selectedFile, setSelectedFile] = useState();
  const [currentFile, setCurrentFile] = useState();
  const [progress, setProgress] = useState(0);
  const [message, setMessage] = useState("");
  const [isError, setError] = useState(false);

  const removeFile = (file) => {
    setSelectedFile(undefined);
  };

  const isValidFileExtension = (fileName) => {
    return !possibleFileExtensions || 
      possibleFileExtensions.indexOf(fileName.split('.').pop()) !== -1;
  }

  const dropFile = (file) => {
    let formattedFile = file[0];
    if (!isValidFileExtension(formattedFile.name.toLowerCase()))
    {
      setMessage("Invalid file type!");
      setError(true);
    } else {
      setMessage("");
      setError(false);
      
      getBase64(formattedFile)
      .then((result) => {
        const newFile = renameFile(formattedFile);
        newFile["fileBase64Str"] = result;
        return newFile;
      })
      .then((file) => {       
        setSelectedFile(file);
        if (storeFile) storeFile(file);
      });
    }
  };

  const renameFile = (originalFile) => {
    if (originalFile && originalFile.name){
      const fileName = originalFile.name;
      const fileType = fileName.split('.').pop().toLowerCase();
      const index = fileName.lastIndexOf('.');
      const title = `${prefix}${fileName.substring(0, index + 1).replace(/[ ,.]/g, "")}`;
      let newName = `${title}.${fileType}`;
      if (newName.length > 100){
        newName = `${title.substring(0, 100 - (fileType.length + 1))}.${fileType}`;
        console.log(newName);
      }

      return new File([originalFile], newName, {
          type: originalFile.type,
          lastModified: originalFile.lastModified,
      });
    }

    return originalFile;
  
}

  const getBase64 = (file) => {
    return new Promise((resolve) => {
      let fileInfo;
      let baseURL = "";
      // Make new FileReader
      let reader = new FileReader();

      // Convert the file to base64 text
      reader.readAsDataURL(file);

      // on reader load somthing...
      reader.onload = () => {
        // Make a fileInfo Object
        let encoded = reader.result.toString().replace(/^data:(.*,)?/, "");
        if (encoded.length % 4 > 0) {
          encoded += "=".repeat(4 - (encoded.length % 4));
        }
        resolve(encoded);
      };
    });
  };

  const upload = () => {
    setCurrentFile(selectedFile);
    setProgress(0);

    UploadService.upload(currentFile, (event) => {
      setProgress({
        progress: Math.round((100 * event.loaded) / event.total)
      });
    })
      .then((response) => {
        setMessage(response.data.message);
        setError(false);

        return UploadService.getFiles();
      })
      .then((files) => {})
      .catch(() => {
        setMessage("Could not upload the file!");
        setError(true);
        setCurrentFile(undefined);
        setProgress(0);
      });

    setSelectedFile(undefined);
  };

  const formatSize = (size) => {
    const length = size.toString().length;
    if (length < 1000) {
      return `${size} kbs`;
    } else if (length < 1000000) {
      const megabytes = (size / 1000).toFixed(2);
      return `${megabytes} mbs`;
    } else if (length < 1000000000) {
      const gigabytes = (size / 1000000).toFixed(2);
      return `${gigabytes} gbs`;
    }
  };

  return (
    <div className="upload-container">
      <div className="upload-form">
        {!selectedFile && <Dropzone add={dropFile} />}
        {error && (
          <p className="alert alert-danger" role="alert">
            {error}
          </p>
        )}
        <div className="selected-files">
          {selectedFile && (
            <div className="selected-file-item">
              <p className="filename">{selectedFile.name}</p>
              <p>{formatSize(selectedFile.size)}</p>

              <div onClick={() => removeFile()}>
                <Delete className="delete-icon" />
              </div>
            </div>
          )}
        </div>
        {!hideUpload && (
          <PrimaryButton
            type="button"
            text="Upload"
            disabled={!selectedFile}
            handleClick={upload}
          />
        )}

        {!selectedFile && possibleFileExtensions && possibleFileExtensions.length > 0 && (
          <div className="acceptable-file-types">Acceptable file types: {possibleFileExtensions.join(', ')}</div>
        )}

        <Typography
          variant="subtitle2"
          className={`upload-message ${isError ? "error" : ""}`}
        >
          {message}
        </Typography>
      </div>
      {currentFile && (
        <ListItem>
          <ListItemIcon>
            <DocumentIcon />
          </ListItemIcon>
          <ListItemText primary={progress} secondary={""} />
          <BorderLinearProgress variant="determinate" value={progress} />
          <ListItemSecondaryAction>
            <IconButton edge="end" aria-label="delete">
              <Delete className="delete-icon" />
            </IconButton>
          </ListItemSecondaryAction>
        </ListItem>
      )}
    </div>
  );
};

export default UploadFile;
