import authService from "./auth.service";
import axios from "axios";

const setupRequestInterceptor = () => {
  axios.interceptors.request.use(
    async (config) => {
      const token = await authService.getAccessToken();
      if (token) {
        config.headers.Authorization = "Bearer " + token;
      }
      
      let becomeUser = sessionStorage.getItem("BecomeUser");
      if (becomeUser) {
        config.headers.BecomeUser = becomeUser;
      }

      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );
};

const setupResponseInterceptor = (dispatch) => {
  axios.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response?.status === 401) {
        authService.logout();
      }

      // if (error.response.status === 404) {
      //   history.push("/not-found");
      // }
      //console.log(JSON.stringify(error, null, 2));
      return Promise.reject(error);
    }
  );
};

export default {
    setupRequestInterceptor,
    setupResponseInterceptor
};