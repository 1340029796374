import React from "react";
import Fade from "@material-ui/core/Fade";
import { useLocation } from "react-router-dom";
import { useHistory } from "react-router-dom";

import "./SplashScreen.scss";

const SplashScreen = () => {
  const logo = "https://d2wqn2ab54ir3q.cloudfront.net/images/logo-white.png";
  const queryParams = new URLSearchParams(useLocation().search);
  const history = useHistory();

  if (queryParams.get("error_description")) {
    history.push("/login");
  }

  return (
    <Fade in={true}>
      <div className="background">
        <div><img className="logo" src={logo} alt="amed-logo" /></div>
        <div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
      </div>
    </Fade>
  );
};

export default SplashScreen;
