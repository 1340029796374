import React from "react";
import { ReactComponent as Logo } from "./linkedin.svg";
import SvgIcon from "@material-ui/core/SvgIcon";

import "../Icons.scss";

const LinkedinIcon = (props) => {
  const { iconClass } = props;
  return (
    <SvgIcon
      className={iconClass}
      component={Logo}
      viewBox="-21 -35 682.66669 682"
    />
  );
};

export default LinkedinIcon;
