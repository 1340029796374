export const cleanArray = (array) => {
  if(!array) return [];
    const newData = array.filter(object => { 
      const newObject = cleanObject(object);
       if (Object.keys(newObject).length !== 0)
      return newObject;
    });
  
    return newData
  }  

export const cleanObject = (obj) => {
    for (var propName in obj) {
      if (obj[propName] === null || obj[propName] === undefined || obj[propName] === "") {
        delete obj[propName];
      } else if (typeof obj[propName] === "object") {
        cleanObject(obj[propName]);
      }
    }
    return obj;
  };
  
export const arraysEqual = (previousState, newState) => {
    if (previousState === newState) return true;
    if (previousState == null || newState == null) return false;
    if (previousState.length !== newState.length) return false;
  
    for (var i = 0; i < previousState.length; ++i) {
      if (JSON.stringify(previousState[i]) !== JSON.stringify(cleanObject(newState[i]))) return false;
    }
    return true;
  }