import React from "react";
import { ReactComponent as Logo } from "./zap.svg";

import "../Icons.scss";

const ZapIcon = (props) => {
  const { iconClass } = props;
  return (
    <Logo className={iconClass} />
  );
};

export default ZapIcon;