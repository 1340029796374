import React from "react";
import { useSelector } from "react-redux";

import DetailCard from "../../../common/DetailCard/DetailCard";
import "./ProfessionalSummary.scss";
import {
  LocationIcon,
  TimerIcon
} from "../../Icons";
import { GridList } from "@material-ui/core";
import * as moment from 'moment';

const ProfessionalSummaryDetails = ({ handleClick, handleOpenModal }) => {
  const { candidateData } = useSelector((state) => state);

  const { professionalSummary, personalInformation, work } = candidateData;

  const getYearsExperience = ((workArray) => {
    let yearsExp = 0;
    let monthsExp = 0;
    let experienceString = null;

    for(let i = 0; i < workArray.length; i++) {
      let starts = moment(workArray[i].startDate)
      let ends = workArray[i].isCurrent ? moment() : moment(workArray[i].endDate);

      yearsExp = yearsExp + ends.diff(starts, 'years');
      monthsExp = monthsExp + ends.diff(starts, 'months')
    }
    if(yearsExp > 0 && monthsExp > 0) {
      let yearsToMonths = yearsExp * 12;

      monthsExp = yearsToMonths > monthsExp ? yearsToMonths - monthsExp :  monthsExp - yearsToMonths;
    }

    if (monthsExp > 0 && monthsExp === 1 && yearsExp == 0) {
      experienceString = <p>1 month</p>;
    } else if (monthsExp > 1 && yearsExp == 0) {
      experienceString = <p>{monthsExp  + ' months'}</p>
    } else if (monthsExp == 0 && yearsExp === 1) {
      experienceString = <p>1 year</p>
    } else if (monthsExp === 1 && yearsExp === 1) {
      experienceString = <p>1 year and 1 month</p>
    } else if (monthsExp === 1 && yearsExp > 1) {
      experienceString = <p>{yearsExp + ' years and ' + ' 1 month'}</p>
    } else if (monthsExp > 1 && yearsExp > 1) {
      experienceString = <p>{yearsExp + ' years and ' + monthsExp  + ' months'}</p>
    }

    return experienceString;
  });

  const handleOnClick = (type) => {
    if(type) {
      handleOpenModal(type);
    }
}

  return (
    <div data-testid="summary-details" className="summary-details">
      <DetailCard title="About Me" handleClick={handleClick}>
        <div className="summary-wrapper">
          <p className="summary">{professionalSummary}</p>
        </div>
        <div className="footer-data details">
          <div className="footer-item">
            <LocationIcon iconClass="personal-icon" />
            { personalInformation?.city && personalInformation?.state ? 
                <p> {personalInformation.city}, {personalInformation.state} </p> : 
                <a onClick={() => handleOnClick('personalInformation')}>Add location...</a>
            }
          </div>
          <div className="footer-item">
            <TimerIcon iconClass="personal-icon" />
            { work && work.length ?  getYearsExperience(work) : 
                <a onClick={() => handleOnClick('work')}>Add years of experience...</a>
            }
          </div>
        </div>
      </DetailCard>
    </div>
  );
};

export default ProfessionalSummaryDetails;
