import React from "react";
import { ReactComponent as Logo } from "./edit.svg";

import "../Icons.scss";

const EditIcon = (props) => {
  const { iconClass } = props;
  return (
    <Logo></Logo>
  );
};

export default EditIcon;
