import React, { useEffect, useState } from "react";
import { Redirect, useHistory } from "react-router-dom";
import { useSelector, connect } from "react-redux";
import Form from "react-validation/build/form";
import Frame from "../Frame/Frame";

import {
  Box,
  Container,
  Grid,
  Tab, 
  Tabs
} from "@material-ui/core";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import { mainStyles } from "../../../mainStyles.js";
import clsx from "clsx";
import axios from "axios";
import API_URL from "../../../../helpers/globals.js";

import RecruiterDataCard from "../RecruiterDataCard/RecruiterDataCard";
import { getRecruiterList } from "../../services/recruiterDataStore";
import "./Users.scss";

const useStyles = mainStyles;

const mapStateToProps = (state) => {
  return {
    currentUser: state.auth,
    navig: state.nav.navig,
  };
};

const RecruiterUsers = (props) => {
  const [state, setState] = useState({
    right: false,
    type: "a",
    updateUser: "",
  }); // Add this
  const [value, setValue] = useState(0);

  const [assessments, setAssessments] = useState({ users: [] }); // Add this

  const history = useHistory();

  useEffect(() => {
    let mounted = true;

    const load = async () => {
        const dataList = await getRecruiterList(null);

        if (dataList) {
            setAssessments({ ...assessments, 
                userData: { 
                userList: dataList.userList,
                nextPaginationToken: dataList.nextPaginationToken,
                previousPaginationToken: dataList.previousPaginationToken
                }
            });
        }
    };

    if (mounted) {
        load();
    }
    return () => (mounted = false);
  }, []);

  const toggleDrawer = (openClose, typ) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setState({ right: openClose, type: typ });
  };

  const { user: currentUser } = useSelector((state) => state.auth);

  const classes = useStyles();

  if (!currentUser) {
    return <Redirect to="/login" />;
  }

  function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box>
            {children}
          </Box>
        )}
      </div>
    );
  }

  function tabProps(index) {
    return {
      id: `tab-${index}`,
      'aria-controls': `tabpanel-${index}`,
    };
  }

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const innerFrame = (
    <div className={classes.root}>
      <Container maxWidth="lg" className={classes.container + ` user-container`}>
        <Grid container spacing={3} className="user-container-body">
          <Grid item xs={12} className="user-tabs">
            <Tabs 
              className="tabs-container" 
              value={value} 
              onChange={handleChange} 
              textColor="primary"
              indicatorColor="primary"
              aria-label="basic tabs example">
              <Tab label="Recruiters" {...tabProps(0)} />
            </Tabs>
            <TabPanel className="candidate-tab" value={value} index={0}>
              <RecruiterDataCard handleClick={toggleDrawer} userData={assessments.userData}></RecruiterDataCard>
              <Tabs 
              className="tabs-container" 
              value={value} 
              onChange={handleChange} 
              textColor="primary"
              indicatorColor="primary"
              aria-label="basic tabs example">
              <Tab label="Recruiters" {...tabProps(0)} />
            </Tabs>
            <TabPanel className="candidate-tab" value={value} index={0}>
              <RecruiterDataCard handleClick={toggleDrawer} userData={assessments.userData}></RecruiterDataCard>
            </TabPanel>
            </TabPanel>
          </Grid>
        </Grid>
      </Container>
    </div>
  );

  return <Frame className="candidate-container" innerFrame={innerFrame}></Frame>;
};

export default connect(mapStateToProps)(RecruiterUsers);
