import { UPDATE_CANDIDATE, SET_USER } from "./types";
import { updateProgress } from "./profileProgress";

import {
  updateCandidateData,
  deleteCandidateData,
  getCandidateDetails
} from "../services/candidateData";

import { getSkills } from "../services/skills";
import authService from "../services/auth.service";
import userService from "../services/user.service";
import { removeFilter, setJobFilters } from "./jobFilters";

var _ = require("lodash");

const defaultCertId = 100429;

export const uploadDocument = (file) => async (dispatch) => {
  const { fileName, fileBase64Str } = file;
  const certData = {
    certificationList: [
      {
        certificationId: defaultCertId,
        fileName,
        fileBase64Str,
        expirationDate: new Date()
      }
    ]
  };
  await updateCandidateData("certifications", certData);
  dispatch(getCand());
};

export const updateData = (field, values) => async (dispatch, getState) => {
  let updatedData = {};
  let endpoint = field;
  const currentState = getState();
  switch (field) {
    case "jobPreferences":
      updatedData = formatJobPreferences(values[field]);
      break;
    case "personalInformation":
      const { candidateData } = currentState;
      const { personalInformation } = candidateData;
      const { phone: prevPhone } = personalInformation;

      const {
        phone,
        firstName,
        lastName,
        email,
        avatar,
        city,
        state,
        ssn,
        dateOfBirth,
        notificationsActive,
        preferredContactMethod
      } = values[field];
      const { file } = avatar;

      const regex = /(\..*){2,}/;

      updatedData = {
        firstName,
        lastName,
        email,
        phone,
        city,
        state,
        ssn,
        dateOfBirth,
        pictureFileName: file?.name && !regex.test(file.name) ? file.name : "",
        pictureFileBase64Str: file?.fileBase64Str,
        notificationsActive,
        preferredContactMethod
      };

      break;
    case "professionalSummary":
      const { professionalSummary = "" } = values;
      updatedData = {
        summary: professionalSummary
      };
      break;
    case "work":
      updatedData = formatWorkHistory(values[field]);
      endpoint = "workhistory";
      break;
    case "license":
      const { fileName, fileBase64Str, expirationDate } = values[field];
      updatedData = formatLicense(values[field]);
      if (fileName && fileBase64Str) {
        const certData = {
          certificationList: [
            {
              certificationId: defaultCertId,
              fileName,
              fileBase64Str,
              expirationDate: new Date(expirationDate)
            }
          ]
        };
        await updateCandidateData("certifications", certData, true);
      }
      break;
    case "education":
      const { education } = values;
      updatedData = {
        educationList: education
      };
      endpoint = "educations";
      break;
    case "certifications":
      const { certifications } = values;
      certifications.expirationDate = new Date(certifications.expirationDate);
      updatedData = {
        certificationList: [certifications]
      };
      break;
    case "specialties":
      const { specialties } = values;
      const { primarySpecialty, secondarySpecialty, specialtyList} = specialties;

      updatedData = {
        primarySpecialty: {
          specialtyId: primarySpecialty && primarySpecialty.id
        },
        secondarySpecialty: null,
        specialtyList: specialtyList
      };

      break;
    case "references":
      const { references } = values;
      updatedData = {
        referenceList: references
      };
      break;
    case "skills":
      const {skills} = values;
      const {assessment} = skills;
      let hasAssessment = false;
      if (assessment){
        hasAssessment = true;
        updatedData = formatSkills(skills);
      }


      if (skills?.fileBase64Str && skills?.fileName){
        const certData = { certificationList: [
          {certificationId: defaultCertId,
          fileName: skills.fileName,
          fileBase64Str: skills.fileBase64Str,
          expirationData: new Date()
        }]}
        await updateCandidateData("certifications", certData, hasAssessment);
        await dispatch(getCand());
      }
      endpoint = "skill";
      break;
    case "resumeUpload":
      updatedData = values.resumeUpload;
      endpoint = "resume";
      break;

    default:
  }

  try {
    if (updatedData && Object.keys(updatedData).length > 0) {
      const response = await updateCandidateData(endpoint, updatedData);
      await dispatch(getCand());

      if (field === "personalInformation" && (response || response === "")) {
        await authService.updateCognitoFields(dispatch, updatedData);
      }

      return response;
    }
  } catch (err) {
    console.log(err);
  }
};

export const deleteData = (type, id) => async (dispatch) => {
  try {
    await deleteCandidateData(type, id);

    await dispatch(getCand());
  } catch (err) {
    console.log(err);
  }
};

export const getCand = () => async (dispatch, getState) => {
  const { candidateData } = getState();
  const { specialties } = candidateData;
  const { specialtyList: previousSpecialty } = specialties || {};

  try {
    const results = await getCandidateDetails();
    const skills = await getSkills();
    if (results) {
      const data = formatCandidateData(results, skills);

      await dispatch({
        type: UPDATE_CANDIDATE,
        payload: data
      });
      await dispatch(updateProgress());
      //update populated specialty

      dispatch(updateSpecialtyFilter(previousSpecialty));
      return results;
    }
  } catch (err) {
    console.log(err);
  }
};

const updateSpecialtyFilter = (specialty) => (dispatch, getState) => {
  const currentState = getState();

  const { jobFilters, candidateData } = currentState;
  const { primarySpecialty, specialtyList } = candidateData?.specialties;

  if (jobFilters?.filters?.specialties) {
    const specialties = jobFilters.filters.specialties;
    const previousSpecialtyIndex = specialties.findIndex(
      (value) => value === specialty?.id
    );

    let updatedSpecialties = [...specialties];
    if (previousSpecialtyIndex > -1) {
      updatedSpecialties.splice(previousSpecialtyIndex, 1);

      for(let i = 0; i < specialtyList.length; i++) {
        updatedSpecialties.push(specialtyList[i]);
      }

      dispatch(setJobFilters({ specialties: _.uniq(updatedSpecialties) }));
    }
  }
};

const appendSkills = (docs, skills) => {
  const appendedList = [...skills];
  
  appendedList.map((skill) => {
    const assessment = docs.find((doc) =>
      doc.fileName.toLowerCase().includes(skill.assessmentNameWithValidCharacters.toLowerCase())
    );

    if (assessment) {
      skill["fileName"] = assessment.fileName;
      skill["fileSize"] = assessment.fileSize;
      skill["fileId"] = assessment.fileId;
      skill["documentId"] = assessment.id;
      skill["createdDate"] = assessment.createdDate;
    }
  });

  // const uploadedAssessments = docs.filter(doc => doc.name === "OTHER");

  // if (uploadedAssessments && uploadedAssessments.length > 0){
  //   let append = uploadedAssessments.forEach(assessment => {
  //     const trimmedName = assessment.fileName.replace("Skills Checklist-", "");
  //     const name = trimmedName.substr(0, trimmedName.lastIndexOf("."));
  //     appendedList.push({
  //       fileName: assessment.fileName,
  //       fileSize: assessment.fileSize,
  //       assessmentName: name,
  //       fileId: assessment.fileId,
  //       documentId: assessment.id
  //     })
  //   })
  // }

  return appendedList;
};

export const formatCandidateData = (results, skills) => {
  const {
    basicInfo,
    educationList,
    workHistoryList,
    referenceList,
    certificationList,
    resumeList
  } = results;
  const {
    firstName,
    lastName,
    email,
    phone,
    city,
    state,
    dateOfBirth,
    ssn,
    professionalSummary,
    pictureUrlPath,
    desiredLocationList,
    desiredShiftPreferenceList,
    dateAvailable,
    desiredQuality,
    licenseStateList,
    isCompactLicense,
    primarySpecialty,
    primaryYears,
    secondarySpecialty,
    specialtyList,
    notificationsActive,
    preferredContactMethod
  } = basicInfo;
  const specialties = {
    specialtyList,
    primarySpecialty,
    primaryYears,
    secondarySpecialty
  };

  const certifications = certificationList.filter(
    (certification) => certification.fileName?.substring(0, 4) === "cert"
  );

  const licenseDocs = certificationList.filter(
    (certification) => certification.fileName?.substring(0, 7) === "license"
  );

  const skillDocs = certificationList.filter(
    (certification) =>
      certification.fileName.includes("Skills Checklist")
  );

  const skillsList = appendSkills(skillDocs, skills);
  const license = {
    licenseStateList,
    isCompactLicense,
    licenseDocs
  };
  const workList = workHistoryList.map((work) => {
    const {
      id,
      facility,
      present,
      unitBeds,
      city,
      state,
      startDate,
      endDate,
      jobTitle
    } = work;
    const location = formatLocation(city, state);
    return {
      id,
      facility,
      isCurrent: present,
      unitBed: unitBeds,
      location,
      startDate,
      endDate,
      position: jobTitle
    };
  });

  const data = {
    personalInformation: {
      firstName,
      lastName,
      email,
      phone,
      city,
      state,
      dateOfBirth,
      ssn,
      avatar: {
        src:
          pictureUrlPath && pictureUrlPath.length > 0
            ? `https://d2wqn2ab54ir3q.cloudfront.net/${pictureUrlPath}`
            : ""
      },
      notificationsActive,
      preferredContactMethod
    },
    professionalSummary,
    jobPreferences: {
      locations: desiredLocationList,
      shifts: desiredShiftPreferenceList,
      quality: desiredQuality,
      startDate: dateAvailable
    },
    license,
    skills: skillsList,
    education: educationList,
    work: workList,
    references: referenceList,
    certifications,
    specialties,
    documents: certificationList,
    resumes: resumeList
  };

  return clean(data);
};

const clean = (obj) => {
  for (var propName in obj) {
    if (obj[propName] === null || obj[propName] === undefined) {
      delete obj[propName];
    } else if (typeof obj[propName] === "object") {
      // Recurse here if the property is another object.
      clean(obj[propName]);
    }
  }
  return obj;
};

const formatLocation = (city, state) => {
  let location = "";
  if (city) {
    location = city;
  }

  if (state && state.length > 0) {
    location += location.length > 0 ? `, ${state}` : state;
  }
  return location;
};

const formatJobPreferences = (data) => {
  const { locations, shifts, startDate, quality } = data;

  return {
    shiftTypeList: shifts || [],
    locationList: locations,
    startDate,
    quality
  };
};

const formatWorkHistory = (data) => {
  return {
    workHistoryList: data?.map((work) => {
      const {
        id,
        facility,
        startDate,
        endDate,
        isCurrent,
        unitBed,
        position,
        specialty,
        location,
        unit
      } = work;
      const splitLocation = location.split(", ");
      return {
        id,
        facility,
        city: splitLocation[0],
        state: splitLocation[1],
        startDate: new Date(startDate),
        endDate: !isCurrent ? new Date(endDate) : undefined,
        isCurrent,
        unit,
        unitBed: unitBed && unitBed.toString(),
        position,
        specialtyName: specialty
      };
    })
  };
};

const formatLicense = (data) => {
  const { isCompactLicense, licenseStateList } = data;

  return {
    stateList: licenseStateList,
    isCOmpact: isCompactLicense
  };
};

const formatSkills = (data) => {
  const skillAssessmentAnswerRequestList = [];
  const {assessmentId, assessment = [], shouldCreateFile} = data;
  assessment.forEach((category) =>
    category.questionList.forEach((subCategory) =>
      subCategory.questionList.forEach((question) => {
        if (question.score) {
          skillAssessmentAnswerRequestList.push({
            id: question.answerId,
            categoryId: category.id,
            questionId: subCategory.id,
            subQuestionId: question.id,
            score: question.score
          });
        }
      })
    )
  );
  return {
    assessmentId,
    skillAssessmentAnswerRequestList,
    shouldCreateFile
  };
};
