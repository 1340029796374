import { SET_SIMILARJOBS, CLEAR_SIMILARJOBS } from "../actions/types";

const similarJobs = (state = null, action) => {
  const { type, payload } = action;
  switch (type) {
    case SET_SIMILARJOBS:
      return payload;
    case CLEAR_SIMILARJOBS:
      return null;
    default:
      return state;
  }
};

export default similarJobs;
