import React, { useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { VpnKey, Lock } from "@material-ui/icons";
import {
  Grid,
  InputAdornment,
  TextField,
  Box,
} from "@material-ui/core";
import Form from "react-validation/build/form";
import TopLogoLayout from "../../TopLogoLayout/TopLogoLayout";
import PrimaryButton from "../../common/Buttons/PrimaryButton";
import LinkButton from "../../common/Buttons/LinkButton";

import "./ResetPassword.scss";
import { clearMessage } from "../../../actions/message";
import { Auth } from 'aws-amplify';
import { setMessage } from "../../../actions/message";
import Alert from '@material-ui/lab/Alert';
import CheckIcon from '@material-ui/icons/Check';
import Snackbar from '@material-ui/core/Snackbar';
import { history } from "../../../helpers/history";
import { Redirect } from "react-router";

const ResetPassword = (props) => {
  const form = useRef();
  const checkBtn = useRef();

  const [code, setCode] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState({
    isShown: false,
    text: "show",
  });

  const [fieldError, setFieldError] = useState({});
  const [loading, setLoading] = useState(false);
  const [showResentCodeAlert, setShowResentCodeAlert] = useState(false);

  const timer = React.useRef();

  const { user } = useSelector((state) => state.auth);
  const { message } = useSelector((state) => state.message);

  const dispatch = useDispatch();

  const onChangeCode = (e) => {
    const code = e.target.value;
    setCode(code);
  };

  const onChangePassword = (e) => {
    const password = e.target.value;
    setPassword(password);
  };

  const handleFormValidation = (code) => {
    let errors = {};
    let formIsValid = true;
    if (code.length === 0) {
      errors["code"] = "Code is required";
      formIsValid = false;
    }
    if (password.length === 0) {
      errors["password"] = "A password is required";
      formIsValid = false;
    }

    setFieldError(errors);
    return formIsValid;
  };

  const handleClickShowPassword = () => {
    setShowPassword({
      isShown: !showPassword.isShown,
      text: showPassword.isShown ? "show" : "hide",
    });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleReset = async (e) => {
    e.preventDefault();
    setFieldError({});
    dispatch(clearMessage());
    const validForm = handleFormValidation(
      e.target.elements.code.value
    );

    if (validForm) {
        setLoading(true);
        try {
            await Auth.forgotPasswordSubmit(user.username, code, password);
            history.push("/login");
        } catch (error) {
            setLoading(false);
            console.log('error resetting password', error);
            dispatch(setMessage("Failed to reset password"));
        }
    }
  };

  const resendResetCode = async () => {
    try {
        await Auth.forgotPassword(user.username);
        setShowResentCodeAlert(true);
    } catch (error) {
        console.log('Error resending code', error);
    }
  }

  const handleClose = (event, reason) => {
    setShowResentCodeAlert(false);
  }

  if (!user?.username) {
    return <Redirect to="/login"/>;
  }

  return (
    <div className="forgot-password-container">
      <div className="forgot-password-form-container">
      <TopLogoLayout>
        <Form
          data-testid="form"
          onSubmit={handleReset}
          ref={form}
          className="forgot-password-form"
        >
          <h1 className="text-lg leading-6 font-medium text-gray-900">
            Change Password
          </h1>
          <p>
            We sent a verification code to your registered phone number.
            If you do not receive the code shortly, please check the email
            address associated with your account, or click “Resend Code” below.
          </p>
          <Box width={1} mb={3} mt={5}>
            <Grid
              container
              spacing={1}
              alignItems="flex-end"
              justify="center"
              className="input-field"
            >
              <Grid item>
                <VpnKey className="input-icon" />
              </Grid>
              <Grid item xs={10}>
                <TextField
                  id="code"
                  className="input-field"
                  name="code"
                  label="Verification Code"
                  value={code}
                  onChange={onChangeCode}
                  error={
                    message !== undefined || fieldError["code"] !== undefined
                  }
                  helperText={fieldError.code ? fieldError.code : ""}
                  fullWidth
                />
              </Grid>
            </Grid>
          </Box>
          <Box width={1} mt={1} mb={3}>
            <Grid container spacing={1} alignItems="flex-end" justify="center">
              <Grid item>
                <Lock className="input-icon" />
              </Grid>
              <Grid item xs={10}>
                <TextField
                  id="password"
                  className="input-field"
                  name="password"
                  label="New Password"
                  type={showPassword.isShown ? "text" : "password"}
                  value={password}
                  onChange={onChangePassword}
                  fullWidth
                  error={
                    message !== undefined || fieldError["password"] !== undefined
                  }
                  helperText={fieldError.password ? fieldError.password : ""}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="start">
                        <div
                          className="show-password text-sm"
                          aria-label="toggle visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                        >
                          {showPassword.text}
                        </div>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
            </Grid>
          </Box>

          {message && (
            <div className="form-group">
              <div className="alert alert-danger text-center pb-4" role="alert">
                {message}
              </div>
            </div>
          )}

          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            width="80%"
            margin="0 auto"
          >
            <PrimaryButton type="submit" text="Change password" disabled={loading} loading={loading} buttonClass="color-button link-btn-sm w-full"/>
          </Box>
          <p className="sign-up text-sm text-gray-500 text-center pt-2">
            Didn't receive a code?
            <span>
              <LinkButton text="Resend Code" textButton handleClick={resendResetCode}/>
            </span>
          </p>
          <p className="sign-up text-sm text-gray-500 text-center pt-2">
              <span>
              <LinkButton text="Back to Login" url="/login" textButton />
              </span>
          </p>
        </Form>
        <Snackbar open={showResentCodeAlert} autoHideDuration={6000} onClose={handleClose}>
          <Alert icon={<CheckIcon fontSize="inherit" />} severity="success">
              Code Resent
          </Alert>
        </Snackbar>
      </TopLogoLayout>
    </div>
  </div>
  );
};

export default ResetPassword;
